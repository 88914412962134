import {
  ExternalFlowStepName,
  ExternalFlowSteps,
  ExternalHintStatusColorMap,
  ExternalHintStatusIconMap,
} from 'constants/external';
import { ItemKinds } from 'constants/item';
import { PartnershipTypes } from 'constants/partnership';
import {
  ACCEPT,
  EXT_AGREE_VENDOR,
  EXTERNAL,
  EXTERNAL_V2,
  EXT_GET_PAID,
  PAY,
  TAX,
  EXT_AGREE_CUSTOMER,
  EXT_PAY,
} from 'constants/routes';
import { ExternalErrorTitles, ExternalErrorType } from 'constants/ui';

import { EXTERNAL_ERROR_NOT_ALLOWED, EXTERNAL_ERROR_NOT_FOUND } from 'global/images/external';

import { isCompanyPlatformTypeExternal } from 'helpers/currentCompany';
import {
  isExternalItemActionable,
  isItemDueSoon,
  isItemKindPayable,
  isItemKindReceivable,
  isItemOverdue,
  itemDueDateDescription,
} from 'helpers/items';
import { getItemStatusText } from 'helpers/status';
import { capitalize } from 'helpers/stringHelpers';
import * as urlHelpers from 'helpers/urls';
import { isEqual } from 'helpers/utility';

// *************************************
// Paths
// *************************************

export const isExternalPathAny = (location = urlHelpers.getCurrentLocation()) =>
  location.pathname.startsWith(`/${EXTERNAL_V2}`) || urlHelpers.isSubdomainNamespaceExternal();

export const isExternalPathAccept = (location = urlHelpers.getCurrentLocation()) =>
  location.pathname.startsWith(`/${EXTERNAL}/${ACCEPT}`);

export const isExternalPathTax = (location = urlHelpers.getCurrentLocation()) =>
  location.pathname.startsWith(`/${EXTERNAL}/${TAX}`);

export const isExternalPathPay = (location = urlHelpers.getCurrentLocation()) =>
  location.pathname.startsWith(`/${EXTERNAL}/${PAY}`);

/**
 * Returns true if passed path is in connection to V2 external get paid flow
 * @param {Location} location
 * @return {Boolean}
 */
export const isExternalPathV2GetPaid = (location = urlHelpers.getCurrentLocation()) =>
  location.pathname.startsWith(`/${EXTERNAL_V2}/${EXT_GET_PAID}`) ||
  (urlHelpers.isSubdomainNamespaceExternal() && location.pathname.startsWith(`/${EXT_GET_PAID}`));

/**
 * Returns true if passed path is in connection to V2 external pay flow
 * @param {Location} location
 * @return {Boolean}
 */
export const isExternalPathV2Pay = (location = urlHelpers.getCurrentLocation()) =>
  location.pathname.startsWith(`/${EXTERNAL_V2}/${EXT_PAY}`) ||
  (urlHelpers.isSubdomainNamespaceExternal() && location.pathname.startsWith(`/${EXT_PAY}`));

/*
 * Returns true if passed path is in connection to V2 external make payments flow
 * @param {Object} location
 * @returns boolean
 */
export const isExternalPathV2MakePayments = (location = urlHelpers.getCurrentLocation()) =>
  location.pathname.startsWith(`/${EXTERNAL_V2}/${EXT_AGREE_CUSTOMER}`) ||
  (urlHelpers.isSubdomainNamespaceExternal() && location.pathname.startsWith(`/${EXT_AGREE_CUSTOMER}`));

/**
 * Returns true if passed path is in connection to V2 external make payments flow or pay flow.
 * These paths are AKA Accept-Partnership (Customer) & Accept Item (Customer)
 * @param {Location} location
 * @return {Boolean}
 */
export const isPathExternalV2AcceptPartnershipOrItem = (location = urlHelpers.getCurrentLocation()) =>
  isExternalPathV2MakePayments(location) || isExternalPathV2Pay(location);

/**
 * Returns the itemKind that should be used to determine the payment method options
 * @param {Location} [location]
 * @returns {ItemKinds} itemKind
 */
export const getItemKindFromExternalPath = (location) => {
  // If request is Accept Partnership/Item to a Customer
  if (isPathExternalV2AcceptPartnershipOrItem(location)) {
    return ItemKinds.PAYABLE;
  }

  // If Update Payment Method or Accept Partnership/Item to a Vendor
  return ItemKinds.RECEIVABLE;
};

/**
 * Predicate fn that checks if user in new external flow to get paid.
 * @param {Location} location
 * @returns boolean
 */
export const isExternalPathGetPaid = (location = urlHelpers.getCurrentLocation()) =>
  location.pathname.startsWith(`/${EXT_AGREE_VENDOR}`);

// *************************************
// Steps
// *************************************
export const isExternalFlowStepInitial = (step) => isEqual(step, ExternalFlowSteps.INITIAL);
export const isExternalFlowStepContactInfo = (step) => isEqual(step, ExternalFlowSteps.CONTACT_INFO);
export const isExternalFlowStepEntityInfo = (step) => isEqual(step, ExternalFlowSteps.ENTITY_INFO);
export const isExternalFlowStepPaymentInfo = (step) => isEqual(step, ExternalFlowSteps.PAYMENT_INFO);

/**
 * Returns external flow step name or undefined if step is not passed
 * @param {ExternalStep}
 * @returns {?String}
 */
export const getExternalFlowStepName = (step) => step?.stepName;

/**
 * Returns true if the passed external flow step's name matches
 * confimation step name
 * @param {ExternalStep}
 * @returns {Boolean}
 */
export const isExternalFlowStepNameConfirmation = (step) =>
  isEqual(getExternalFlowStepName(step), ExternalFlowStepName.CONFIRMATION);

/**
 * Helper to get step constant (number) from the company
 * @param {Company} company
 * @return {number}
 */
export const getExternalOnboardingStepFromCompany = (company = {}) => {
  if (isCompanyPlatformTypeExternal(company.platformType)) {
    return ExternalFlowSteps.PAYMENT_INFO;
  }

  return ExternalFlowSteps.INITIAL;
};

/**
 * Use the external path to determine the PartnershipType.
 * @function
 * @param {Location} location
 * @returns {PartnershipTypes|undefined}
 */
export const getPartnershipTypeFromExternalPath = (location = urlHelpers.getCurrentLocation()) => {
  if (isExternalPathAccept(location)) {
    return PartnershipTypes.VENDOR;
  }

  if (isExternalPathPay(location)) {
    return PartnershipTypes.CUSTOMER;
  }

  return undefined;
};

// *************************************
// STEP HINTS
// *************************************

/**
 * Returns the correct icon name for given external hint status
 * @param {ExternalHintStatusType} hintStatus
 * @return {string}
 */
export const getExternalStepHintIconFromStatus = (hintStatus) => ExternalHintStatusIconMap[hintStatus];

/**
 * Returns the correct color constant for given external hint status
 * @param {ExternalHintStatusType} hintStatus
 * @return {string}
 */
export const getExternalStepHintColorFromStatus = (hintStatus) => ExternalHintStatusColorMap[hintStatus];

/**
 * Returns external step hint status label string. If the hint status consist of multiple words in snake case,
 * the _ is replaced with space. The hintStatus is always capitalized.
 * @param {ExternalHintStatusType} hintStatus
 * @returns {string}
 */
export const getExternalStepHintStatusLabel = (hintStatus) => capitalize(hintStatus.replace(/_/g, ' '));

// *************************************
// Errors
// *************************************

/**
 * Returns true if external error type is not allowed
 * @param {string} errorType
 * @return {boolean}
 */
export const isExternalErrorTypeNotAllowed = (errorType) => isEqual(errorType, ExternalErrorType.NOT_ALLOWED);

/**
 * Returns true if external error type is not needed
 * @param {string} errorType
 * @return {boolean}
 */
export const isExternalErrorTypeNotNeeded = (errorType) => isEqual(errorType, ExternalErrorType.NOT_NEEDED);

// *************************************
// Strings
// *************************************

/**
 * Returns external error title based on passed error type
 * @param {string} errorType
 * @return {string}
 */
export const getExternalErrorTitle = (errorType) => ExternalErrorTitles[errorType];

/**
 * Returns external error message based on passed error type
 * @param {Object} options
 * @param {string} options.companyName
 * @param {string} options.errorType
 * @return {string}
 */
export const getExternalErrorMessage = ({ companyName, errorType }) => {
  // Generic part of the error message, used in every error type
  const errorMessage = `If you think this is an error please contact ${companyName} for assistance.`;

  if (isExternalErrorTypeNotAllowed(errorType)) {
    // For error type not allowed, we attach message explaining that correct
    // permissions are missing.
    return `You don't have permission to perform this action. ${errorMessage}`;
  }

  // We return the generic error message for all other error types
  return errorMessage;
};

// *************************************
// Images
// *************************************

/**
 * Returns url path to the correct error image based on the provided
 * error type
 * @param {string} errorType
 * @return {string}
 */
export const getExternalErrorImageSource = (errorType) =>
  isExternalErrorTypeNotAllowed(errorType) ? EXTERNAL_ERROR_NOT_ALLOWED : EXTERNAL_ERROR_NOT_FOUND;

/**
 * Returns default partnership funding account based on the external workflow type
 * (receivable fot get_paid, payable for pay flow).
 * @param {Object} options
 * @param {Location} options.location
 * @param {Partnership} options.partnership
 * @return {StringMaybe}
 */
export const getExternalPartnershipDefaultFundingAccount = ({ location, partnership }) =>
  isExternalPathV2GetPaid(location)
    ? partnership.defaultReceivableFundingAccount
    : partnership.defaultPayableFundingAccount;

/**
 * Returns message used in PaymentMethodSameHint external component
 * @see PaymentMethodSameHint
 * @param {Company} company
 * @return {string}
 */
export const getExternalPaymentMethodSameHintBody = (company) =>
  `It looks like you've made no changes to your preferred payment method. If this is an error, please contact ${company.name} to be invited to update your payment method again.`;

/**
 * Returns correct external items table item action text for given item kind
 *
 * @param {import('interfaces/item').Item | import('interfaces/redux').Payment} item
 * @returns {string}
 */
export const getExternalItemsItemActionText = (item) => {
  // If item is not actionable, we want to display the generic (common) item status text.
  if (!isExternalItemActionable(item)) {
    return getItemStatusText(item, item.status, true);
  }

  // If item kind is payable, we always want to return 'Pay now' string
  if (isItemKindReceivable(item)) {
    return 'Accept to receive';
  }

  // At this point, we know that:
  // - item is actionable
  // - item kind is payable

  // if item is due soon or overdue, we return the item due date description.
  if (isItemDueSoon(item) || isItemOverdue(item)) {
    return itemDueDateDescription(item);
  }

  // if item is not due soon or overdue, we return 'Pay now' string.
  return 'Pay now';
};

/**
 * Returns correct external actionable items table item action text for given item kind
 *
 * @param {ItemKinds} itemKind
 * @returns {string}
 */
export const getExternalItemsItemButtonTextFromItemKind = (itemKind) =>
  isItemKindPayable({ kind: itemKind }) ? 'Pay now' : 'Accept payment';
