import { featureFlag, useFeatureFlag } from '@routable/feature-configs';
import { useInternationalBankFields } from '@routable/fund-routes';
import { useModalState } from '@routable/gross-ds';
import clsx from 'clsx';
import _isEmpty from 'lodash/isEmpty';
import React from 'react';
import { ButtonV2, IconNames, Form, LockedCountryInput, ModalFooter, SideBarPanel, ContentBlockShade, Text, } from 'components';
import { ButtonSize } from 'constants/button';
import { commonFormFields } from 'constants/formFields';
import { formNamesFunding } from 'constants/forms';
import { UserTypeToInternationalFundingBusinessType } from 'constants/funding';
import { TextColor, TextSize } from 'constants/styles/typography';
import { Intent } from 'constants/ui';
import { PartnershipCountryCodeKey } from 'enums/partnerships';
import { isExternalPathAny, isExternalPathV2Pay } from 'helpers/external';
import { getPartnershipCurrency, isInternationalPartnership } from 'helpers/partnerships';
import { useFormSelectedCurrency } from 'hooks';
import PartnerCompanyInternationalTaxForm from 'modules/createPartnerCompany/presenters/PartnerCompanyInternationalTaxForm';
import { useValidateInternationalBeneficiaryFields, InternationalBeneficiaryValidation, InternationalBeneficiaryValidationContext, InternationalBeneficiaryValidationConfirmationDialog, } from 'modules/fundingAccount/components/AddInternationalPartnerFundingAccountForm/components';
import { InternationalPartnerFundingAccountBeneficiaryDetails } from 'modules/fundingAccount/components/AddInternationalPartnerFundingAccountForm/components/InternationalPartnerFundingAccountBeneficiaryDetails';
import { ManuallyAddBankAccountAddressDetails, ManuallyAddBankAccountBankDetails, ManuallyAddBankAccountErrorHint, ManuallyAddBankAccountHelpText, } from './components';
import { shouldDisplayErrorHint } from './helpers';
const ManuallyAddBankAccountSideSheet = ({ availableCurrencies, company, currencyCodeMap, errors, form, fundingAccounts, isFormValid, onClose, partnerCompany, partnership, sidePanel, submitting, handleSubmit, }) => {
    const selectedCurrency = useFormSelectedCurrency(form);
    const enableBeneficiaryValidation = useFeatureFlag(featureFlag.Enum.global_only_international_bank_account_validation);
    const { data: fields, isFetching: isFetchingFields } = useInternationalBankFields({
        currencyCode: selectedCurrency.code,
        countryCode: partnership.countryCodeCompany,
        businessType: UserTypeToInternationalFundingBusinessType[company.companyType],
    });
    const { data: validationData, info: validationInfo, isLoading: validationIsLoading, errors: validationErrors, warnings: validationWarnings, triggerValidation, } = useValidateInternationalBeneficiaryFields(form, partnership, company, 'countryCodeCompany');
    const isInternational = isInternationalPartnership(partnership, PartnershipCountryCodeKey.COMPANY);
    const showSingularCurrencyHelpText = !availableCurrencies || availableCurrencies?.length === 1;
    const isExternal = isExternalPathAny();
    const isCustomerFlow = isExternalPathV2Pay();
    const showCompanyInternationalFields = isInternational && isExternal && !isCustomerFlow && !fundingAccounts.length;
    const showInternationalFieldsValidation = enableBeneficiaryValidation && isInternational;
    const modalConfirmationState = useModalState({});
    return (React.createElement(SideBarPanel, { headerText: "Add your bank account information", onClose: onClose, sidePanel: sidePanel },
        React.createElement(ContentBlockShade, { className: "height--100-percent", isShady: isFetchingFields },
            React.createElement(Form, { id: form, onSubmit: handleSubmit },
                showSingularCurrencyHelpText && (React.createElement(ManuallyAddBankAccountHelpText, { partnerCompany: partnerCompany, partnershipCurrency: getPartnershipCurrency(currencyCodeMap, partnership) })),
                !isInternational && (React.createElement(LockedCountryInput, { className: "margin-top--m-large", name: commonFormFields.PARTNER_COUNTRY, reason: `If you need to modify your country, please reach out to ${partnerCompany.name}` })),
                React.createElement(InternationalBeneficiaryValidationContext.Provider, { value: { triggerValidation, validationErrors, validationInfo } },
                    React.createElement(ManuallyAddBankAccountBankDetails, { availableCurrencies: availableCurrencies, bankExtraFields: fields?.bankExtraByName, className: clsx({ 'mt-5': showSingularCurrencyHelpText && isInternational }), company: company, fields: fields?.bank, formName: form, isInternational: isInternational, partnership: partnership })),
                showCompanyInternationalFields && (React.createElement("div", { className: "margin-top--m-large" },
                    React.createElement(PartnerCompanyInternationalTaxForm, { companyType: company.companyType, countryCode: partnership.countryCodeCompany, formName: formNamesFunding.ADD_MANUAL_BANK }))),
                showInternationalFieldsValidation && (React.createElement("div", { className: "mt-5" },
                    React.createElement(InternationalBeneficiaryValidation, { data: validationData, errors: validationErrors, isLoading: validationIsLoading, warnings: validationWarnings }))),
                isInternational && React.createElement(ManuallyAddBankAccountAddressDetails, { partnership: partnership }),
                !!fields?.beneficiary.length && (React.createElement("div", { className: "flex flex-col mt-7" },
                    React.createElement(Text.Bold, { color: TextColor.BLUE_DARK, size: TextSize.LEVEL_475 }, "Additional information required"),
                    React.createElement(Text.Regular, null,
                        "To receive ",
                        selectedCurrency.name,
                        " (",
                        selectedCurrency.code,
                        "), additional information is required.",
                        ' ',
                        "Everything must match the receiving bank's information."),
                    React.createElement(InternationalPartnerFundingAccountBeneficiaryDetails, { defaultValues: {
                            country_of_nationality: partnership.countryCodeCompany,
                            phone_number: partnership.countryCodeCompany,
                        }, fields: fields.beneficiaryByName, formName: form, labels: { in_fcra: 'My business is an NGO (Non-Government Organization) beneficiary' } })))),
            shouldDisplayErrorHint(errors, fields?.all) && React.createElement(ManuallyAddBankAccountErrorHint, null)),
        React.createElement(ModalFooter, null, _isEmpty(validationErrors) && !_isEmpty(validationWarnings) ? (React.createElement(ButtonV2, { id: "external_internationalFundingAccount_submit_btn", intent: Intent.EXTERNAL, isDisabled: !isFormValid || isFetchingFields, isLoading: submitting, leftIconClassName: "margin-right--xm", leftIconName: IconNames.TICK_CIRCLE, onClick: () => modalConfirmationState.open(), rightIconClassName: "margin-left--auto", rightIconName: IconNames.ARROW_RIGHT, size: ButtonSize.LARGE }, "Add bank account")) : (React.createElement(ButtonV2, { htmlFor: form, id: "external_internationalFundingAccount_submit_btn", intent: Intent.EXTERNAL, isDisabled: !_isEmpty(validationErrors) || !isFormValid || isFetchingFields, isLoading: submitting, leftIconClassName: "margin-right--xm", leftIconName: IconNames.TICK_CIRCLE, rightIconClassName: "margin-left--auto", rightIconName: IconNames.ARROW_RIGHT, size: ButtonSize.LARGE, type: "submit" }, "Add bank account"))),
        React.createElement(InternationalBeneficiaryValidationConfirmationDialog, { additionalText: "We recommend verifying bank account details before proceeding.", formName: form, isSubmitting: submitting, modalState: modalConfirmationState, submitButtonText: "Add bank account" })));
};
export default ManuallyAddBankAccountSideSheet;
