import { change, untouch } from 'redux-form';

import { storeAccessor as store } from 'store/accessor';

import { getFieldValue } from '../components/ProgressBarStepperV2';
import { signupFlowForm } from '../constants';
import { getViewToFieldsMap } from '../fields';

// This function iterates over the existing fields in each view,
// and dispatches built-in Redux Form actions to clear the values,
// and then mark them as 'untouched' to prevent errors
// like 'Required' to be triggered by a non-user action.
export const clearViewFields = (activeView) => {
  const formInitialState = store.getState().form[signupFlowForm]?.initial;
  const allFields = getViewToFieldsMap();

  allFields[activeView].forEach((field) => {
    store.dispatch(
      change(
        signupFlowForm,
        field.name,
        getFieldValue({
          name: field.name,
          formValues: formInitialState,
        }),
      ),
    );

    store.dispatch(untouch(signupFlowForm, field.name));
  });
};
