import { all } from 'redux-saga/effects';
import { routinePromiseWatcherSaga } from 'redux-saga-routines';

import addresses from './addresses';
import affiliates from './affiliates';
import { apiSaga } from './api';
import apiTokens from './apiTokens';
import auth from './auth';
import billing from './billing';
import bulkActions from './bulkActions';
import countries from './countries';
import createItem from './createItem';
import currencies from './currencies';
import currentCompany from './currentCompany';
import errors from './errors';
import existingItem from './existingItem';
import exportFile from './exportFile';
import external from './external';
import externalFundingStates from './externalFundingStates';
import files from './files';
import funding from './funding';
import integrations from './integrations';
import item from './item';
import itemAttachment from './itemAttachment';
import itemMember from './itemMember';
import maintenance from './maintenance';
import memberships from './memberships';
import namespace from './namespace';
import navigation from './navigation';
import notifications from './notifications';
import oldVersion from './oldVersion';
import partnershipMember from './partnershipMember';
import partnerships from './partnerships';
import passwordInput from './passwordInput';
import permissions from './permissions';
import quickswitch from './quickswitch';
import roles from './roles';
import sockets from './sockets';
import sso from './sso';
import tables from './tables';
import tags from './tags';
import transactions from './transactions';
import ui from './ui';
import user from './user';
import webhooks from './webhooks';

export default function* rootSaga() {
  yield all([
    routinePromiseWatcherSaga(),
    addresses(),
    affiliates(),
    apiSaga(),
    apiTokens(),
    auth(),
    billing(),
    bulkActions(),
    countries(),
    createItem(),
    currencies(),
    currentCompany(),
    errors(),
    existingItem(),
    exportFile(),
    external(),
    externalFundingStates(),
    files(),
    funding(),
    integrations(),
    item(),
    itemAttachment(),
    itemMember(),
    maintenance(),
    memberships(),
    namespace(),
    navigation(),
    notifications(),
    oldVersion(),
    partnershipMember(),
    partnerships(),
    passwordInput(),
    permissions(),
    quickswitch(),
    roles(),
    sockets(),
    sso(),
    tables(),
    tags(),
    transactions(),
    ui(),
    user(),
    webhooks(),
  ]);
}
