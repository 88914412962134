import React from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';

import { ReduxFormFieldRenderInput, Textarea } from 'components';
import CannotUpdateFieldHint from 'components/hintTypes/CannotUpdateFieldHint';
import InputValueHighlighter from 'components/input/InputValueHighlighter';

import { requiredValidator, companyNameValidator, optionalLooseUrlValidator } from 'helpers/fieldValidation';

import { useFieldValue } from 'hooks';

import { containerWidthMap } from 'modules/signup-v3/constants/container';
import { openDocumentModal } from 'modules/signup-v3/helpers/helpDocs';

import {
  currentCompanyVerificationUpdateAllowedSelector,
  isUpdatingCurrentCompanySelector,
} from 'selectors/currentCompanySelectors';

import { signupFlowForm } from '../constants';
import { BUSINESS_NAME_VALIDATION_REGEX_HIGHLIGHTER } from '../constants/regex';
import FlowStepperMain from '../FlowStepperMain';
import { Label, LabelHelper } from '../FlowStepperMain.styles';
import { viewSteps } from '../helpers/viewSteps';

import { Container, LabelLink } from './LegalBusinessName.styles';

const LegalBusinessNameMain = ({ activeView, invalid }) => {
  const updatedAllowed = useSelector(currentCompanyVerificationUpdateAllowedSelector);
  const isUpdating = useSelector(isUpdatingCurrentCompanySelector);
  const currentLegalNameValue = useFieldValue(signupFlowForm, 'info.legalName');

  return (
    <FlowStepperMain
      containerWidth={containerWidthMap.small}
      fields={
        <Container>
          {!updatedAllowed && <CannotUpdateFieldHint />}
          <Container>
            <Label htmlFor="name">Your company name</Label>
            <LabelHelper>The name used for communicating with vendors and customers.</LabelHelper>
            <Field
              component={ReduxFormFieldRenderInput}
              inputId="name"
              name="name"
              placeholder="Company name"
              validate={[requiredValidator]}
            />
          </Container>
          <Container>
            <Label htmlFor="info.legalName">What&apos;s the legal entity name of your business?</Label>
            <LabelHelper>
              The name you enter here must match the government-issued document from{' '}
              <LabelLink onClick={openDocumentModal}>this list</LabelLink>. This name will show up on bank statements.
            </LabelHelper>
            <Field
              component={ReduxFormFieldRenderInput}
              inputId="info.legalName"
              name="info.legalName"
              placeholder="Legal business name"
              validate={[requiredValidator, companyNameValidator]}
            >
              <InputValueHighlighter regex={BUSINESS_NAME_VALIDATION_REGEX_HIGHLIGHTER} value={currentLegalNameValue} />
            </Field>
          </Container>
          <Container>
            <Label htmlFor="info.dbaName">What&apos;s your Doing Business As (DBA) name?</Label>
            <LabelHelper>
              Does your business go by a name other than its official, legal name? Enter it here.
            </LabelHelper>
            <Field
              component={ReduxFormFieldRenderInput}
              inputId="info.dbaName"
              isRequired={false}
              name="info.dbaName"
              placeholder="DBA name"
            />
          </Container>
          <Container>
            <Label htmlFor="info.businessDescription">Describe your business</Label>
            <LabelHelper>Please share a brief description of your business and what you do.</LabelHelper>
            <Field
              component={ReduxFormFieldRenderInput}
              inputId="info.businessDescription"
              name="info.businessDescription"
              placeholder="Business description"
              validate={[requiredValidator]}
            />
          </Container>
          <Container>
            <Label htmlFor="info.businessUrl">What&apos;s your business URL?</Label>
            <LabelHelper>Link to your company&apos;s website, if you have one. If not, skip this.</LabelHelper>
            <Field
              component={ReduxFormFieldRenderInput}
              inputId="info.businessUrl"
              isRequired={false}
              name="info.businessUrl"
              placeholder="Business URL"
              validate={[optionalLooseUrlValidator]}
            />
          </Container>
          <Container>
            <Label htmlFor="info.purposeOfPayments">Purpose of payments</Label>
            <LabelHelper>Please share a brief description how your business plans on using Routable.</LabelHelper>
            <Field
              component={Textarea}
              isRequired
              name="info.purposeOfPayments"
              placeholder="Ex. I will use Routable to pay contractors around the globe"
              rows={5}
              validate={[requiredValidator]}
            />
          </Container>
        </Container>
      }
      invalid={invalid || !updatedAllowed}
      onBack={viewSteps[activeView].previous}
      onNext={{
        ...viewSteps[activeView].next,
        isLoading: isUpdating,
      }}
      onSkip={updatedAllowed && viewSteps[activeView].skip}
      title={{ content: "Let's identify your business" }}
    />
  );
};

export default LegalBusinessNameMain;
