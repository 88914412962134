import { apiJsonApiCall } from '@routable/framework';
import { getQueryString } from 'helpers/queryParams';
import { itemApiResponseModel, itemsApiResponseModel } from './items.models';
export const itemsService = {
    get: ({ id }) => apiJsonApiCall({
        method: 'get',
        responseModel: itemApiResponseModel,
        url: `items/${id}/`,
    }),
    getAll: (filters) => apiJsonApiCall({
        method: 'get',
        responseModel: itemsApiResponseModel,
        url: `items/?${getQueryString(filters)}`,
    }),
};
