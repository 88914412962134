import { createMultiReferenceModel, createReferenceModel } from '@routable/shared';
import { z } from 'zod';
import { fundingInfoPayPalDirectModel } from './fundingInfo.model';
import { fundingSourceModel } from './fundingSource.model';
export const fundingAccountAttributesModel = z.object({
    created: z.string().datetime(),
    currencyCode: z.string(),
    direction: z.enum(['payables', 'receivables']),
    isDeleted: z.boolean(),
    isDisabled: z.boolean(),
    isExternal: z.boolean(),
    isValid: z.boolean(),
    ledgerRef: z.string().uuid().nullish(),
    ledgerRefPayable: z.string().uuid().nullish(),
    ledgerRefReceivable: z.string().uuid().nullish(),
    name: z.string(),
    usable: z.enum(['yes', 'no']),
});
export const fundingAccountRelationshipsModel = z.object({
    address: createReferenceModel('Address'),
    balance: createReferenceModel('FundingBalance'),
    bank: createReferenceModel('FundingInfoBank'),
    company: createReferenceModel('Company'),
    creator: createReferenceModel('Membership'),
    fundingSources: createMultiReferenceModel('FundingSource'),
    international: createReferenceModel('FundingInfoInternational'),
    paypalDirect: createReferenceModel('FundingInfoPayPalDirect'),
});
export const fundingAccountModel = z.object({
    id: z.string().uuid(),
    type: z.literal('FundingAccount'),
    attributes: fundingAccountAttributesModel,
    relationships: fundingAccountRelationshipsModel,
});
export const createFundingAccountPayPalManualApiResponseModel = z.object({
    fundingAccount: z.record(z.string().uuid(), fundingAccountModel),
    fundingInfoPayPalDirect: z.record(z.string().uuid(), fundingInfoPayPalDirectModel),
    fundingSources: z.record(z.string().uuid(), fundingSourceModel),
});
export const createFundingAccountPayPalManualApiErrorResponseModel = z.object({
    parsedErrors: z.object({
        errors: z.object({
            general: z.object({
                detail: z.array(z.string()),
            }),
            fields: z.object({
                paypal_email: z.array(z.string()),
            }),
        }),
    }),
});
