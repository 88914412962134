import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ButtonV2 } from 'components/buttonV2';
import { Modal, ModalFooter, ModalFooterButtonCancel, ModalContentWrapper, ModalText, ModalTitle, } from 'components/modal';
import { Intent } from 'constants/ui';
import {} from './Prompt.types';
const Prompt = ({ cancelText = 'Cancel', children, confirmText = 'Confirm', message, onCancel, onConfirm, shouldPrompt, title, }) => {
    const [showModal, setShowModal] = useState(false);
    const [shouldNavigate, setShouldNavigate] = useState();
    const [attemptedPath, setAttemptedPath] = useState();
    const history = useHistory();
    const unblockRef = useRef();
    const confirmPrompt = useCallback(async (response) => {
        setShouldNavigate(response);
        if (response) {
            unblockRef.current?.();
            if (onConfirm) {
                await onConfirm();
            }
        }
        else if (onCancel) {
            await onCancel();
        }
    }, [onCancel, onConfirm]);
    useEffect(() => {
        setShouldNavigate(!shouldPrompt);
    }, [shouldPrompt]);
    useEffect(() => {
        if (shouldPrompt && !shouldNavigate) {
            unblockRef.current = history.block((loc) => {
                setAttemptedPath(loc.pathname);
                setShowModal(true);
                return false;
            });
        }
        return () => {
            unblockRef.current?.();
        };
    }, [attemptedPath, confirmPrompt, history, shouldNavigate, shouldPrompt]);
    useEffect(() => {
        if (shouldPrompt && shouldNavigate) {
            history.push(attemptedPath);
        }
    }, [attemptedPath, history, shouldNavigate, shouldPrompt]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { body: React.createElement("div", null,
                React.createElement(ModalContentWrapper, null,
                    React.createElement(ModalTitle, null, title),
                    React.createElement(ModalText, null, message)),
                React.createElement(ModalFooter, { style: {
                        backgroundColor: 'var(--color-white)',
                    } },
                    React.createElement(ModalFooterButtonCancel, { buttonText: cancelText, onCloseModal: () => {
                            confirmPrompt(false);
                            setShowModal(false);
                        } }),
                    React.createElement(ButtonV2, { intent: Intent.PRIMARY, onClick: () => {
                            confirmPrompt(true);
                            setShowModal(false);
                        } }, confirmText))), hasInlineFooter: true, hideHeader: true, modalState: showModal, onCloseModal: () => setShowModal(false), overrideStyle: {
                content: {
                    width: '31.25rem',
                },
                overlay: {
                    backgroundColor: 'rgba(76,84,103,0.3)',
                },
            } }),
        children));
};
export default Prompt;
