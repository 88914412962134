import { featureFlagsQueryKey } from '@routable/feature-configs';
import { queryClient } from '@routable/shared';
import { call, put, select, spawn, take } from 'redux-saga/effects';

import * as actions from 'actions/currentCompany';
import { handleRequestErrors } from 'actions/errors';
import { externalOnboardingChangeStep } from 'actions/externalOnboarding';
import { refreshIntegrationConfigRequest } from 'actions/integrations';
import { openIntegrationSyncOptionsModal } from 'actions/modals';
import * as routines from 'actions/routines/currentCompany';
import {
  enableInternationalPaymentsRoutine,
  updateCurrentCompanySettingsIntegrationRoutine,
} from 'actions/routines/currentCompany';
import { deleteCompanyDocumentRoutine, postCompanyDocumentRoutine } from 'actions/routines/documents';
import { fetchSupportedCountriesRoutine } from 'actions/routines/funding';
import { fetchItemStructureTablesRoutine } from 'actions/routines/tables';
import { showSuccessUi } from 'actions/ui';

import { SuccessIndicatorMessages } from 'constants/ui';

import { CompanySettingsPaymentsType } from 'enums/company';
import { TopOrEntityOptions } from 'enums/integrations';

import { getRequestErrorAction, parseCaughtError, parseErrorResponse } from 'helpers/errors';
import { getExternalOnboardingStepFromCompany } from 'helpers/external';
import { isLedgerApplicationTypeSageIntacct } from 'helpers/ledger';
import { getCurrentCompanyId } from 'helpers/localStorage';

import { deleteCompanyDocument, postCompanyDocument } from 'sagas/currentCompany/companyDocumentsSagas';
import { handleSocketDisconnect } from 'sagas/sockets/sagas';

import { currentCompanySelector, currentCompanySettingsIntegrationSelector } from 'selectors/currentCompanySelectors';
import { ledgerIntegrationSelector } from 'selectors/integrationsSelectors';

import { payloadToCamelCase } from 'services/api/formatHelpers';

import * as types from 'types/currentCompany';
import * as socketTypes from 'types/socket';

import * as api from './api';

/**
 * Select between domestic or international payments for current company.
 * @return {IterableIterator<*>}
 */
export function* enableInternationalPayments(action) {
  yield put(enableInternationalPaymentsRoutine.request());

  let errorData;

  try {
    const { paymentType } = action.payload;

    const companyId = getCurrentCompanyId();
    const response = yield call(api.enableInternationalPayments, companyId, paymentType);

    if (response.ok) {
      const companyIntegrationSettings = yield select(currentCompanySettingsIntegrationSelector);
      const ledger = yield select(ledgerIntegrationSelector);
      const isLedgerSageIntacct = isLedgerApplicationTypeSageIntacct(ledger);

      yield put(enableInternationalPaymentsRoutine.success(response.data));

      const isTopLevelSageAccount = isLedgerSageIntacct && !ledger.reconnectionData?.entityRef;

      // Update sync settings for top-level Sage account if they have not been set yet
      // This is because if the user is connected to a top-level Sage account and opts for domestic payments
      // -> we re-sync and close the self-serve flow
      if (
        paymentType === CompanySettingsPaymentsType.DOMESTIC &&
        isTopLevelSageAccount &&
        !companyIntegrationSettings.sageIntacctBankLevel
      ) {
        yield put(
          updateCurrentCompanySettingsIntegrationRoutine.trigger({
            enableBaseCurrencyMode: null,
            sageIntacctBankLevel: TopOrEntityOptions.TOP,
            sageIntacctCompanyLevel: TopOrEntityOptions.TOP,
          }),
        );
      } else if (
        // trigger manual re-sync and close the modal if
        // - user has opted for domestic payments
        // - AND ledger is not Sage Intacct
        // - OR if connected to a TOP level account in Sage Intacct
        // - OR if sageIntacctBankLevel has already been set
        paymentType === CompanySettingsPaymentsType.DOMESTIC &&
        (!isLedgerSageIntacct ||
          isTopLevelSageAccount ||
          (isLedgerSageIntacct && companyIntegrationSettings.sageIntacctBankLevel))
      ) {
        yield put(refreshIntegrationConfigRequest(ledger.id));
      } else if (paymentType === CompanySettingsPaymentsType.INTERNATIONAL) {
        // fetch supported countries, create item table structure, and feature flags after enabling intl payments
        yield put(fetchSupportedCountriesRoutine.trigger());
        yield put(fetchItemStructureTablesRoutine.trigger());
        yield call(queryClient.invalidateQueries, { queryKey: featureFlagsQueryKey });
        yield put(showSuccessUi(SuccessIndicatorMessages.INTERNATIONAL_PAYMENTS_ENABLED));

        if (ledger?.id) {
          // always set enableBaseCurrencyMode to null, so it forces user to submit the "integration sync options" form
          // ie they will be prompted with the form until they submit (trying to redirect / refresh will not get rid of the form)
          yield put(
            updateCurrentCompanySettingsIntegrationRoutine.trigger({
              enableBaseCurrencyMode: null,
            }),
          );
          yield put(openIntegrationSyncOptionsModal());
        }
      }
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  yield put(handleRequestErrors(enableInternationalPaymentsRoutine.failure, errorData));
}

/**
 * Request data for the current company.
 * @return {IterableIterator<*>}
 */
export function* requestCurrentCompany() {
  let errorData = {};

  try {
    const companyId = getCurrentCompanyId();
    const response = yield call(api.getCurrentCompany, companyId);

    if (response.ok) {
      const meta = { companyId };
      yield put(actions.getCurrentCompanySuccess(response.data, meta));
      return yield response;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  yield put(handleRequestErrors(actions.getCurrentCompanyFailure, errorData));
  return yield undefined;
}

export function* requestCurrentCompanySettingsItemApprovals({ payload }) {
  let errorData = {};

  yield put(routines.fetchCurrentCompanySettingsItemApprovalRoutine.request());

  try {
    const companyId = getCurrentCompanyId();
    const { id: companySettingsItemApprovalId } = Object(payload);
    const response = yield call(api.getCurrentCompanySettingsItemApprovals, companyId, companySettingsItemApprovalId);

    if (response.ok) {
      yield put(routines.fetchCurrentCompanySettingsItemApprovalRoutine.success(response.data));
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  const errorAction = getRequestErrorAction(errorData);
  yield put(errorAction(routines.fetchCurrentCompanySettingsItemApprovalRoutine.failure, errorData));
}

export function* updateCurrentCompanySettingsIntegration(action) {
  yield put(routines.updateCurrentCompanySettingsIntegrationRoutine.request());

  let errorData = {};

  try {
    const { payload } = action;

    const currentCompany = yield select(currentCompanySelector);

    const data = {
      ...payload,
      id: currentCompany.settingsIntegration,
    };

    const apiParams = [currentCompany.id, currentCompany.settingsIntegration, data];
    const response = yield call(api.updateCurrentCompanySettingsIntegration, ...apiParams);

    if (response.ok) {
      const parsedResponse = payloadToCamelCase(response.data);
      yield put(routines.updateCurrentCompanySettingsIntegrationRoutine.success(parsedResponse));

      // Trigger integration sync ONLY if we are updating the enableBaseCurrencyMode prop
      // OR if we are updating the bank level sync option
      if (typeof payload.enableBaseCurrencyMode === 'boolean' || payload.sageIntacctBankLevel) {
        const ledger = yield select(ledgerIntegrationSelector);
        yield put(refreshIntegrationConfigRequest(ledger.id));
      }
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  const errorAction = getRequestErrorAction(errorData);
  yield put(errorAction(routines.updateCurrentCompanySettingsIntegrationRoutine.failure, errorData));
}

/**
 * Request data for the current company, from external payment flow.
 * This calls the normal requestCurrentCompany saga, then changes the
 * external onboarding step if needed.
 * @return {IterableIterator<*>}
 */
export function* externalPaymentRequestCurrentCompany() {
  const response = yield call(requestCurrentCompany);
  if (response) {
    const {
      originalData: { data },
    } = response;
    const { attributes: companyAttributes } = data;
    const newStep = getExternalOnboardingStepFromCompany(companyAttributes);
    yield put(externalOnboardingChangeStep(newStep));
  }
}

/**
 * Listens for redux actions related to the current company.
 * @return {IterableIterator<*>}
 */
export function* watch() {
  while (true) {
    const action = yield take([
      socketTypes.DISCONNECT_SOCKET,
      types.EXTERNAL_PAYMENT_GET_CURRENT_COMPANY_REQUEST,
      types.GET_CURRENT_COMPANY_REQUEST,
      routines.enableInternationalPaymentsRoutine.TRIGGER,
      routines.fetchCurrentCompanySettingsItemApprovalRoutine.TRIGGER,
      routines.updateCurrentCompanySettingsIntegrationRoutine.TRIGGER,
      deleteCompanyDocumentRoutine.TRIGGER,
      postCompanyDocumentRoutine.TRIGGER,
    ]);

    switch (action.type) {
      case socketTypes.DISCONNECT_SOCKET:
        yield spawn(handleSocketDisconnect);
        break;

      case types.EXTERNAL_PAYMENT_GET_CURRENT_COMPANY_REQUEST:
        yield spawn(externalPaymentRequestCurrentCompany);
        break;

      case types.GET_CURRENT_COMPANY_REQUEST:
        yield spawn(requestCurrentCompany);
        break;

      case routines.enableInternationalPaymentsRoutine.TRIGGER:
        yield spawn(enableInternationalPayments, action);
        break;

      case routines.fetchCurrentCompanySettingsItemApprovalRoutine.TRIGGER:
        yield spawn(requestCurrentCompanySettingsItemApprovals, action);
        break;

      case routines.updateCurrentCompanySettingsIntegrationRoutine.TRIGGER:
        yield spawn(updateCurrentCompanySettingsIntegration, action);
        break;

      case deleteCompanyDocumentRoutine.TRIGGER:
        yield spawn(deleteCompanyDocument, action);
        break;

      case postCompanyDocumentRoutine.TRIGGER:
        yield spawn(postCompanyDocument, action);
        break;

      default:
        yield null;
    }
  }
}

/**
 * Root current company saga.
 * @return {IterableIterator<*>}
 */
export default function* currentCompany() {
  yield watch();
}
