import PropTypes from 'prop-types';
import React from 'react';

import { countryListOptions } from 'constants/i18n';

import { getUpdatePath } from 'modules/address/helpers/updatePath';
import AddressReduxForm from 'modules/address/presenters/AddressReduxForm';

import { InternationalAddressForm } from './components';

/**
 * Highest level address container which controls whether to show the international or domestic address form.
 * @param {Object} props
 * @param {String} [props.fieldClassName]
 * @param {String} props.formId
 * @param {Boolean} [props.forceHideAddressPrintOnCheckToggle]
 * @param {Boolean} [props.isCountryEditable]
 * @param {Boolean} [props.isInternational]
 * @param {String} [props.lockedCountryReason]
 * @param {Function} [props.onInvalidCountry]
 * @param {String} props.stateKey
 * @param {String} [props.stateKeyPrefix]
 * @returns {StatelessComponent}
 */
const AddressContainer = ({
  fieldClassName,
  formId,
  forceHideAddressPrintOnCheckToggle,
  isCountryEditable,
  isInternational,
  lockedCountryReason,
  onInvalidCountry,
  stateKey,
  stateKeyPrefix,
}) => {
  const addressPath = getUpdatePath(stateKeyPrefix, stateKey);

  if (isInternational) {
    return (
      <InternationalAddressForm
        addressPath={addressPath}
        fieldClassName={fieldClassName}
        isCountryEditable={isCountryEditable}
        lockedCountryReason={lockedCountryReason}
      />
    );
  }

  return (
    <AddressReduxForm
      addressPath={addressPath}
      countryOptions={countryListOptions.US_AND_CANADA}
      fieldClassName={fieldClassName}
      forceHideAddressPrintOnCheckToggle={forceHideAddressPrintOnCheckToggle}
      formName={formId}
      onInvalidCountry={onInvalidCountry}
    />
  );
};

AddressContainer.propTypes = {
  fieldClassName: PropTypes.string,
  formId: PropTypes.string.isRequired,
  forceHideAddressPrintOnCheckToggle: PropTypes.bool,
  isCountryEditable: PropTypes.bool,
  isInternational: PropTypes.bool,
  lockedCountryReason: PropTypes.string,
  onInvalidCountry: PropTypes.func,
  stateKey: PropTypes.string.isRequired,
  stateKeyPrefix: PropTypes.string,
};

AddressContainer.defaultProps = {
  fieldClassName: '',
  forceHideAddressPrintOnCheckToggle: undefined,
  isInternational: undefined,
  lockedCountryReason: undefined,
  onInvalidCountry: undefined,
  stateKeyPrefix: undefined,
};

export default AddressContainer;
