import { useEvent } from '@routable/framework';
import { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { DASHBOARD, OCR_TRAINING_CALLBACK_URL } from 'constants/routes';
import { localStorageSet, localStorageGet } from 'helpers/localStorage';
import { OCREvents } from './OcrOverlays.types';
import { useAnnotationPoll } from './useAnnotation';
export const useOcrTraining = () => {
    const [isLoadingOcrTraining, setIsLoadingOcrTraining] = useState(false);
    const ocrTrainingMatch = useRouteMatch(OCR_TRAINING_CALLBACK_URL);
    const history = useHistory();
    const ocrTrainingData = JSON.parse(localStorageGet('ocr-training') || '{}');
    const annotationId = ocrTrainingMatch ? ocrTrainingData.annotationId : undefined;
    const { data: annotation } = useAnnotationPoll(annotationId);
    useEvent(OCREvents.updated, () => {
        setIsLoadingOcrTraining(false);
    });
    useEffect(() => {
        if (!ocrTrainingMatch) {
            return;
        }
        setIsLoadingOcrTraining(true);
        if (annotation?.status !== 'done') {
            return;
        }
        const { id, itemEdit, kind, ocrAction } = ocrTrainingMatch.params;
        if (ocrAction === 'cancel_url') {
            localStorageSet('ocr-training-action', 'discard');
        }
        else {
            localStorageSet('ocr-training-action', 'success');
        }
        if (!id) {
            history.replace('/dashboard');
            setIsLoadingOcrTraining(false);
            return;
        }
        const isItemEdit = itemEdit === 'true';
        if (isItemEdit) {
            if (kind === 'payable') {
                history.replace(`/${DASHBOARD}/payable/${id}/edit?fromOcrTraining=true`);
            }
            return;
        }
        history.replace(`/${DASHBOARD}/create_bill/new?id=${id}&fromOcrTraining=true`);
    }, [annotation, ocrTrainingMatch, history]);
    return {
        isLoadingOcrTraining,
    };
};
