import { Close } from '@carbon/icons-react';
import { Clickable } from '@routable/gross-ds';
import React from 'react';
import { components } from 'react-select';
const { MultiValueRemove } = components;
const TagMultiValueRemove = ({ data, innerProps, selectProps }) => {
    const { onClose } = data;
    const { onClick } = innerProps;
    return (React.createElement(MultiValueRemove, { data: data, innerProps: { ...innerProps, onClick: undefined }, selectProps: selectProps },
        React.createElement(Clickable, { "aria-label": "Remove value", onClick: (evt) => {
                onClick(evt);
                onClose?.(evt, data);
            } },
            React.createElement(Close, null))));
};
export default TagMultiValueRemove;
