import { createSelector } from 'reselect';

import { allValues } from 'helpers/utility';

/**
 * Selects the approvals by id from the state
 * @param {object} state - Redux state
 */
export const approvalsSelector = (state) => state.approvals.byId;

/**
 * Selects approvals and returns an array
 * @param {object} state - Redux state
 */
export const allApprovalsSelector = createSelector([approvalsSelector], (approvals) => allValues(approvals));
