import { z } from 'zod';
export const fundingSourceProviderClass = z.enum(['address', 'bank', 'thirdparty']);
export const fundingSourceProviderStatus = z.enum([
    'valid',
    'invalid',
    'invalid_micro_deposits_failed',
    'invalid_micro_deposits_failed_verification',
    'invalid_micro_deposits_pending',
    'invalid_micro_deposits_processed',
]);
export const fundingSourceProviderSubClass = z.enum([
    'bank_ach',
    'address_mailing',
    'bank_balance',
    'bank_check',
    'external_ledger',
    'thirdparty_paypal',
]);
export const fundingSourceAttributesModel = z.object({
    dateMicroDepositsExpected: z.string().datetime().nullish(),
    providerClass: fundingSourceProviderClass,
    providerStatus: fundingSourceProviderStatus,
    providerSubClass: fundingSourceProviderSubClass,
});
export const fundingSourceModel = z.object({
    type: z.literal('FundingSource'),
    id: z.string().uuid(),
    attributes: fundingSourceAttributesModel,
});
