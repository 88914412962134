import { sortByOrdinal } from 'helpers/sort';

/**
 * Add Permission resource mapped to a feature flag here.
 *
 * example:
 * ```
 * const featureFlagPermissionsMapper = {
 *  [PermissionResourceAction.COMPANY_TAX_VIEW]: FEATURE_FLAG_GLOBAL_ONLY_TAX_SELF_SERVE,
 *  [PermissionResourceAction.COMPANY_TAX_EDIT]: FEATURE_FLAG_GLOBAL_ONLY_TAX_SELF_SERVE,
 * };
 * ```
 */
const featureFlagPermissionsMapper = {};

/**
 * Filter permissions depending on feature flags from `featureFlagPermissionsMapper`, this will hide the permission from the Disclosure list
 * if the feature flag is not enabled.
 *
 * If there's no feature flag in the `featureFlagPermissionsMapper` it will return all the permissions.
 *
 *  Usage:
 *     If a new feature flag needs to be considered for displaying a permission you can update the featureFlagPermissionsMapper as follows:
 *
 *     import { featureFlag } from '@routable/feature-configs';
 *
 *     const featureFlagPermissionsMapper = {
 *        ...[the_existing_values],
 *        [PermissionResourceAction.PERMISSION_XYZ]: featureFlag.Enum.feature_flag_name,
 *      };
 *
 * @param {OptionsArg} options
 * @param {Permissions[]} options.permissions
 * @param {import('hooks').FeatureFlagsMap} options.activeFeatureFlags
 */
const filterByActiveFeatureFlags = (activeFeatureFlags, permissionsMapper) => (permission) => {
  const permissionFeatureFlag = permissionsMapper[permission.resourceAction];
  if (permissionFeatureFlag) {
    return activeFeatureFlags[permissionFeatureFlag];
  }
  return true;
};

/**
 * Given permissions, sort them by ordinal and format them into items to pass to DisclosureList.
 *
 * @param {OptionsArg} options
 * @param {AnyComponent} options.component
 * @param {Permissions[]} options.permissions
 * @param {Object[]} options.activeFeatureFlags
 * @return {Object[]}
 */
export const transformPermissionsIntoDisclosureListItems = ({
  component,
  permissions,
  activeFeatureFlags,
  permissionsMapper = featureFlagPermissionsMapper,
}) =>
  sortByOrdinal(
    permissions?.filter(filterByActiveFeatureFlags(activeFeatureFlags, permissionsMapper)).map((permission) => ({
      data: permission,
      component,
      wrapWithCol: false,
    })),
    'data.ordinal',
  );
