import {} from '@routable/types';
import React from 'react';
import { TruncatableText } from '../../../TruncatableText';
import { fixCellWidths } from '../../helpers/tablematic.table.helpers';
import { getHeaderOrBodyCellProps } from '../../tablematic.table.types';
import { HeaderContainer, StyledHeaderCell } from './tablematic.table.header.styled';
const InternalTableHeader = ({ fieldProps, getTableSectionFieldSize, headerContainerRef, headersConfigById, isOnDetailsPage, section, subSectionId, }) => {
    const isPoSection = subSectionId.endsWith('_po_lines');
    return (React.createElement(React.Fragment, null,
        React.createElement(HeaderContainer, { className: "tablematic-table-header-container w-full", key: `${section.id}.headers`, ref: headerContainerRef }, section?.headers.map((header, idx) => {
            const headerConfig = headersConfigById?.[header.id];
            if (!headerConfig?.isVisible) {
                return null;
            }
            const fieldSize = getTableSectionFieldSize(fieldProps[header.id]);
            const headerCellProps = fixCellWidths(getHeaderOrBodyCellProps(fieldSize));
            const isRequired = !isOnDetailsPage && !isPoSection && Boolean(fieldProps?.[header.id]?.getIsRequired?.getIsRequired?.());
            return (React.createElement(StyledHeaderCell, { key: `header-cell-${header.id || idx}`, ...headerCellProps, colSpan: 1, role: "columnheader", isCurrencyField: fieldProps[header.id]?.minDecimalScale > 0, isOnDetailsPage: isOnDetailsPage, isQuantityField: header.id.includes('quantity') },
                React.createElement("div", { className: "flex min-w-0" },
                    React.createElement(TruncatableText, { className: "header-cell-text", dataFullStory: false, position: "top", text: header.Header }),
                    isRequired && React.createElement("span", { className: "text-blue-40" }, "*"))));
        }))));
};
export const TablematicTableHeader = React.memo(InternalTableHeader);
