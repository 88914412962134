import { apiJsonApiCall } from '@routable/framework';
import { createFundingAccountPayPalManualApiErrorResponseModel, createFundingAccountPayPalManualApiResponseModel, } from '../models';
export const fundingAccountsService = {
    createPayPalManual: async (paypalEmail) => apiJsonApiCall({
        method: 'post',
        url: '/funding_accounts/create_paypal_direct_manual/',
        body: {
            data: { type: 'FundingAccount' },
            meta: { paypal_email: paypalEmail },
        },
        responseModel: createFundingAccountPayPalManualApiResponseModel,
        errorResponseModel: createFundingAccountPayPalManualApiErrorResponseModel,
    }),
};
