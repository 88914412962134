import styled from 'styled-components';
export const ProvenanceWrapper = styled.div `
  // WRAPPER STYLE
  position: relative;
  width: 100%;

  // BEGIN SELECT/INPUT PROVENANCE (Lightning Icon)
  &[data-ocr='true'][data-edit='false'] .select--label:before,
  &[data-ocr='true'][data-edit='false'] .input-container label:before {
    display: inline-block;
    content: '';
    width: 12px;
    height: 12px;
    background: transparent
      url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%23a8378f'  %3E%3Cpath d='M9 11H6a1 1 0 01-1-1L5.91.9a1 1 0 01.995-.9h6.256a.839.839 0 01.779 1.15L11.2 8h2.978a.822.822 0 01.748 1.162l-4.764 10.481A.608.608 0 019 19.392V11z' fill-rule='evenodd'%3E%3C/path%3E%3C/svg%3E")
      no-repeat center;
    position: relative;
    top: 2px;
    margin-right: 6px;
  }
  // END SELECT/INPUT PROVENANCE (Lightning Icon)

  // BEGIN LEDGER FORM FIELD
  & .column-content {
    position: relative;
    overflow: hidden;
  }

  &[data-ocr='true'][data-edit='false'] .column-content:after {
    display: inline-block;
    content: '';
    left: 0;
    top: 0;
    border-width: 12px 12px 0 0;
    border-color: #ed7cd4 transparent transparent transparent;
    border-style: solid;
    position: absolute;
    z-index: 2;
  }
  // END LEDGER FORM FIELD
`;
