import React, { useMemo } from 'react';
import { Field } from 'redux-form';
import { SelectFieldV2 } from 'components/selectV2';
import { MissingCurrencyFooter, Tooltip } from './components';
const VendorReceivingCurrency = ({ addMissingCurrencyFooter, className, fieldLabel, fieldName, isFetching, isLocked, options, tooltipText, }) => {
    const inferredIsLocked = isLocked || isFetching;
    const hideLabel = isLocked && !isFetching && options.length === 0;
    const placeholder = useMemo(() => {
        if (isLocked && !isFetching) {
            return fieldLabel;
        }
        return isFetching ? 'Fetching currencies...' : 'Select currency';
    }, [isLocked, isFetching, fieldLabel]);
    return (React.createElement(Field, { className: className, component: SelectFieldV2, footer: addMissingCurrencyFooter && React.createElement(MissingCurrencyFooter, null), hideLabel: hideLabel, isDisabled: inferredIsLocked, isLoading: isFetching, isLocked: inferredIsLocked, isRequired: !isFetching, label: fieldLabel, lockedTooltipProps: inferredIsLocked && tooltipText
            ? { title: React.createElement(Tooltip, { showTooltip: inferredIsLocked, tooltipText: tooltipText }) }
            : {}, name: fieldName, options: options, placeholder: placeholder }));
};
export default VendorReceivingCurrency;
