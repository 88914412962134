import { PaymentDeliveryMethodType } from '@routable/shared';
import PropTypes from 'prop-types';
import React from 'react';

import { Modal } from 'components/modal';

import { ZIndexLayers } from 'constants/ui';

import { getSidePanelHeaderTextForPaymentMethod } from 'helpers/addPartnerFundingAccount';
import { getZIndex } from 'helpers/zIndex';

import { AddPartnerFundingAccountForm } from 'modules/fundingAccount/components';

const AddPartnerFundingAccountModal = ({ modalState, onCloseModal }) => {
  const { formContext, paymentDeliveryMethod, partnershipId } = modalState;

  return (
    <Modal
      body={
        <AddPartnerFundingAccountForm
          formName={formContext}
          onClose={onCloseModal}
          partnershipId={partnershipId}
          paymentDeliveryMethod={paymentDeliveryMethod}
        />
      }
      hasInlineFooter
      modalHeader={getSidePanelHeaderTextForPaymentMethod(paymentDeliveryMethod)}
      modalState={modalState.open}
      onCloseModal={onCloseModal}
      overrideStyle={{
        // Making sure the overlay's z-index is more than the sidesheet index as this modal can potentially
        // be rendered together with the sidesheet (example is in Ready-to-send sidesheet)
        overlay: { zIndex: getZIndex(ZIndexLayers.SLIDEOUT_PRIME.OVERLAY, 5) },
      }}
      testId="add-partner-funding-account-modal"
    />
  );
};

AddPartnerFundingAccountModal.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  modalState: PropTypes.shape({
    formContext: PropTypes.string,
    isSubmitting: PropTypes.bool,
    open: PropTypes.bool,
    partnershipId: PropTypes.string,
    paymentDeliveryMethod: PropTypes.oneOf(Object.values(PaymentDeliveryMethodType)),
  }).isRequired,
};

export default AddPartnerFundingAccountModal;
