import { Tooltip } from '@routable/components';
import React from 'react';
import { ButtonV2 } from 'components/buttonV2';
import { IconNames } from 'components/icon';
import { colors } from 'constants/styles';
import { Intent } from 'constants/ui';
import { isCurrentCompanyTypePersonal } from 'helpers/currentCompany';
import { getAddFundingProviderMemoTooltipText, getRemittanceInfoOrAddendaRecordText, } from 'helpers/fundingProviderMemo';
import { lengthOf } from 'helpers/utility';
import {} from './AddFundingProviderMemoButton.types';
const AddFundingProviderMemoButton = ({ isDeliveryOptionRTP, onOpenFundingProviderMemoModal, partnerCompany, selectedInvoices, }) => {
    const areMultipleBillsSelected = lengthOf(selectedInvoices) > 1;
    const isCompanyTypePersonal = isCurrentCompanyTypePersonal(partnerCompany);
    const tooltipText = getAddFundingProviderMemoTooltipText({
        areMultipleBillsSelected,
        isCompanyTypePersonal,
        isDeliveryOptionRTP,
    });
    const isButtonDisabled = areMultipleBillsSelected || (isCompanyTypePersonal && !isDeliveryOptionRTP);
    return (React.createElement("div", { className: "display--inline-block" },
        React.createElement(Tooltip, { "data-testid": "funding-provider-memo-button--tooltip", position: "top", size: "medium", tooltip: tooltipText, variant: "light" },
            React.createElement(ButtonV2, { intent: Intent.NEUTRAL, isDisabled: isButtonDisabled, leftIconClassName: "margin-right--xm", leftIconColor: colors.colorGreyXDarkHex, leftIconName: IconNames.ADD, onClick: onOpenFundingProviderMemoModal, type: "button" }, `Add ${getRemittanceInfoOrAddendaRecordText(isDeliveryOptionRTP)}`))));
};
export default AddFundingProviderMemoButton;
