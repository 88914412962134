import { statusVariants } from '@routable/gross-ds';
import { ItemStatuses } from 'enums/items';
import {} from 'interfaces/item';
export const getStatusToConsider = (item) => item?.lockState ? ItemStatuses.QUEUED : item?.status;
export const itemStatusToVariant = {
    [ItemStatuses.BULK_UPLOAD]: statusVariants.pending,
    [ItemStatuses.CANCELED]: statusVariants.default,
    [ItemStatuses.COMPLETED]: statusVariants.success,
    [ItemStatuses.COMPLIANCE_HOLD]: statusVariants.warning,
    [ItemStatuses.CREATED]: statusVariants.warning,
    [ItemStatuses.DRAFT]: statusVariants.default,
    [ItemStatuses.EXTERNALLY_PAID]: statusVariants.success,
    [ItemStatuses.FAILED]: statusVariants.error,
    [ItemStatuses.IMPORT]: statusVariants.pending,
    [ItemStatuses.INITIATED]: statusVariants.success,
    [ItemStatuses.ISSUE]: statusVariants.error,
    [ItemStatuses.NEEDS_APPROVAL]: statusVariants.warning,
    [ItemStatuses.NEW]: statusVariants.warning,
    [ItemStatuses.PENDING]: statusVariants.warning,
    [ItemStatuses.PO_DISCREPANCY]: statusVariants.warning,
    [ItemStatuses.PROCESSING]: statusVariants.processing,
    [ItemStatuses.QUEUED]: statusVariants.processing,
    [ItemStatuses.READY_TO_SEND]: statusVariants.pending,
    [ItemStatuses.SCHEDULED]: statusVariants.success,
};
export const itemStatusToText = {
    [ItemStatuses.BULK_UPLOAD]: 'Bulk upload',
    [ItemStatuses.CANCELED]: 'Canceled',
    [ItemStatuses.COMPLETED]: 'Completed',
    [ItemStatuses.COMPLIANCE_HOLD]: 'Compliance hold',
    [ItemStatuses.DRAFT]: 'Draft',
    [ItemStatuses.EXTERNALLY_PAID]: 'Completed',
    [ItemStatuses.FAILED]: 'Failed',
    [ItemStatuses.IMPORT]: 'Import',
    [ItemStatuses.INITIATED]: 'In-transit',
    [ItemStatuses.ISSUE]: 'Issue found',
    [ItemStatuses.NEEDS_APPROVAL]: 'Needs approval',
    [ItemStatuses.NEW]: 'Pending',
    [ItemStatuses.PENDING]: 'Pending',
    [ItemStatuses.PO_DISCREPANCY]: 'PO discrepancy',
    [ItemStatuses.PROCESSING]: 'Processing',
    [ItemStatuses.SCHEDULED]: 'Scheduled',
    [ItemStatuses.READY_TO_SEND]: 'Ready to send',
    [ItemStatuses.QUEUED]: 'Queued',
};
export const externalItemToStatusToText = {
    ...itemStatusToText,
    [ItemStatuses.COMPLIANCE_HOLD]: 'Payment hold',
};
export const payablesStatusFiltersToTextDescriptive = {
    [ItemStatuses.CANCELED]: 'Canceled',
    [ItemStatuses.COMPLETED]: 'Completed',
    [ItemStatuses.COMPLIANCE_HOLD]: 'Compliance hold',
    [ItemStatuses.EXTERNALLY_PAID]: 'Externally paid',
    [ItemStatuses.FAILED]: 'Failed',
    [ItemStatuses.INITIATED]: 'In-transit',
    [ItemStatuses.ISSUE]: 'Issue found',
    [ItemStatuses.NEEDS_APPROVAL]: 'Needs approval',
    [ItemStatuses.PENDING]: 'Pending',
    [ItemStatuses.PO_DISCREPANCY]: 'PO discrepancy',
    [ItemStatuses.PROCESSING]: 'Processing',
    [ItemStatuses.SCHEDULED]: 'Scheduled',
    [ItemStatuses.READY_TO_SEND]: 'Ready to send',
};
export const receivablesStatusFiltersToTextDescriptive = {
    [ItemStatuses.CANCELED]: 'Canceled',
    [ItemStatuses.COMPLETED]: 'Completed',
    [ItemStatuses.FAILED]: 'Failed',
    [ItemStatuses.INITIATED]: 'In-transit',
    [ItemStatuses.ISSUE]: 'Issue found',
    [ItemStatuses.NEW]: 'New',
    [ItemStatuses.PENDING]: 'Pending',
    [ItemStatuses.PROCESSING]: 'Processing',
    [ItemStatuses.READY_TO_SEND]: 'Ready to send',
    [ItemStatuses.SCHEDULED]: 'Scheduled',
};
export const getItemStatusProps = (item, options = { useExternalFacingTexts: false }) => {
    const status = getStatusToConsider(item);
    const texts = options.useExternalFacingTexts ? externalItemToStatusToText : itemStatusToText;
    return {
        status,
        statusVariant: itemStatusToVariant[status],
        statusText: texts[status],
    };
};
