import { cva } from 'class-variance-authority';
export const linkStyles = cva(['focus-visible:underline font-normal'], {
    variants: {
        variant: {
            black: 'text-black hover:text-blue-40 underline',
            grey: 'text-grey-70 hover:text-blue-40 underline',
            default: 'text-blue-40 hover:underline',
        },
    },
    defaultVariants: {
        variant: 'default',
    },
});
