import { TableCell } from '@routable/components';
import styled from 'styled-components';
export const TableCellWrapper = styled(TableCell).withConfig({
    displayName: 'TableCellWrapper',
}) `
  padding: 20px 12px;
  // The global line height is being overwritten by a component in the Table,
  // so we must set it again here.
  line-height: 1.5;
  text-overflow: ${({ truncate = true }) => (truncate ? 'ellipsis' : 'unset')};
`;
