/**
 * @fileOverview Relationships for parsing API-returned entities.
 */

export const FundingAccount = 'FundingAccount';

export const FundingInfoAddress = 'FundingInfoAddress';

// Company custom relationships
export const partnerCompanyRelationship = { kind: 'Company', name: 'partner' };

// Membership custom relationships
export const itemApproversRelationship = {
  children: [{ kind: 'CompanySettingsItemApprovalLevel', name: 'level' }, 'membership'],
  kind: 'ItemApprover',
  name: 'itemApprovers',
};

// Funding account custom relationships
export const partnerReceivableAccountRelationship = {
  kind: FundingAccount,
  name: 'partnerReceivableAccount',
};

// Item custom relationships
export const itemAttachmentRelationship = {
  kind: 'ItemAttachment',
  name: 'attachments',
};

export const itemMembersRelationship = {
  children: ['partnershipMember'],
  kind: 'ItemMember',
  name: 'itemMembers',
};

// Invoice custom relationships
export const submitInvoiceRelationships = [
  'ItemImport',
  'fundingAccount',
  itemMembersRelationship,
  itemApproversRelationship,
  partnerReceivableAccountRelationship,
];

/**
 * Relationships for objects in state.funding.fundingAccounts
 * @type {Relationship[]}
 */
export const fundingAccountRelationships = [
  'address',
  'balance',
  'bank',
  'company',
  'creator',
  'fundingSources',
  'international',
  'paypalDirect',
];

/**
 * Relationships for objects in state.partnershipFundingStates
 * @type {Relationship[]}
 */
export const partnershipFundingStateRelationships = ['fundingAccount'];

/**
 * Relationships for objects in state.items
 * @type {string[]}
 */
export const itemRelationships = [
  'annotationAttachments',
  'approvals',
  'attachments',
  'fees',
  'fundingAccount',
  'itemMembers',
  'partner',
  'partnerFundingAccount',
  'partnership',
  'partnershipRequest',
  'payment',
  'primaryAttachment',
  'sender',
  'tagLinks',
  'thread',
  'uploadedBy',
];

/**
 * Relationships for objects in state.tagLink
 * @type {Relationship[]}
 */
export const tagLinkRelationships = [
  'tag',
  'taggedObject', // the item (or other obj) being tagged
];

/**
 * Relationships for objects in state.tag
 * @type {Relationship[]}
 */
export const tagsRelationships = ['tagLink'];

/**
 * Relationships for objects in state.itemMembers
 * @type {Relationship[]}
 */
export const itemMemberRelationships = ['partnershipMember'];

/**
 * Relationships for objects in state.transactions
 * @type {Relationship[]}
 */
export const transactionRelationships = [
  'destinationFundingAccount',
  'item',
  'originationFundingAccount',
  'transactionAch',
  'transactionCheck',
  'transactionPaymentConfirmation',
];

/**
 * Relationships for objects in state.partnerships
 * @type {Relationship[]}
 */
export const fetchedPartnershipRelationships = [
  'companyInfo',
  'defaultPayableFundingAccount',
  'defaultReceivableFundingAccount',
  'items',
  'lastNameChange',
  'partner',
  'partnerReceivableFundingAccount',
  'partnershipMembers',
];

/**
 * Relationships for partnership objects when submitting.
 * @type {Relationship[]}
 */
export const submitPartnershipRelationships = [
  partnerCompanyRelationship,
  'partnershipMembers',
  'companyMailingAddress',
];

/**
 * Relationships for data object when sending a partnership invite.
 * @type {Relationship[]}
 */
export const sendPartnershipInviteRelationships = ['partnershipMembers'];

/**
 * Relationships for data object when updating a partnership.
 * @type {Relationship[]}
 */
export const updatePartnershipRelationships = ['partner'];

/**
 * Relationships for data object when updating a team member.
 * @type {Relationship[]}
 */
export const updateMembershipRelationships = ['user', 'role'];

/**
 * Relationships for item objects when submitting.
 * @type {Relationship[]}
 */
export const submitItemRelationships = [
  'item',
  itemApproversRelationship,
  itemAttachmentRelationship,
  'fundingAccount',
  'lineItems',
  partnerReceivableAccountRelationship,
  'partnership',
  itemMembersRelationship,
];

/**
 * @type {Relationship[]}
 */
export const fetchedPartnershipFundingAccountRelationships = ['fundingAccount', 'partnership'];

/**
 * Relationships for ledger partnerships when consuming a fetching in Redux.
 * @type {Relationship[]}
 */
export const fetchedLedgerPartnershipsRelationships = ['countryPaymentOptions', 'partner', 'partnershipMembers'];

/**
 * Relationships for a MembershipMatch when searching for a membership for emails.
 * @type {Relationship[]}
 */
export const fetchedMembershipMatchRelationships = ['memberships', 'membershipInvites'];

/**
 * Relationship for a PartnershipRequest when fetching initial request data
 * @type {string[]}
 */
export const partnershipRequestRelationships = [
  'company',
  'companyInfo',
  'fundingAccountStates',
  'membership',
  'meta',
  'partnership',
  'partnershipRequestMembers',
  'preferredFundingAccount',
];

/**
 * Relationships for a PartnershipRequestStateFundingAccount when submitting a fundingAccount.
 * @type {Relationship[]}
 */
export const partnershipRequestStateFundingAccountRelationships = [
  'PartnershipRequestStateFundingAccount',
  'fundingAccount',
];

/**
 * Relationships for Rate Estimate. Each Rate estimate has multiple Fees, which are stored separately in state.
 * @type {Relationship[]}
 */
export const rateEstimateRelationship = ['fees'];

export const requesterRelationship = {
  kind: 'membership',
  name: 'requester',
};

/**
 * Relationships for a Role. Each Role has multiple Permissions, which are stored separately in state.
 * @type {Relationship[]}
 */
export const roleRelationships = ['billingCode', 'permissions'];

/**
 * Relationships for a Permission. Each Permission has a PermissionGroup, which is stored separately in state.
 * @type {Relationship[]}
 */
export const permissionRelationships = ['group'];

/**
 * Company mailingAddress and info relationships for partnership requests in external flow.
 * @type {Relationship[]}
 */
export const companyMailingAddressInfoRelationship = [
  'company',
  { name: 'mailingAddress', kind: 'Address' },
  { name: 'info', kind: 'CompanyInfo' },
];

/**
 * Relationships for BulkImport resource data.
 * @type {Relationship[]}
 */
export const bulkImportRelationships = ['company', 'creator'];

/**
 * Relationships for BulkImport with importType 'item_*' finalization.
 * @type {Relationship[]}
 */
export const bulkImportSubmitFinalizeItemRelationships = [itemApproversRelationship];

/**
 * Relationship for currencies resource data.
 * @type {Relationship}
 */
export const referenceCurrency = 'referenceCurrency';

/**
 * Key for a pseudo-DB model which shows which countries support which payment options.
 * @type {string}
 */
export const countryPaymentOption = 'countryPaymentOption';

/**
 * Relationships for a Annotations.
 * @type {Relationship[]}
 */
export const annotationRelationships = ['lineItems', 'partnerships'];

/**
 * Relationships for a Annotations.
 * @type {Relationship[]}
 */
export const attachmentRelationships = ['latestOcrAnnotation'];

/**
 * Relationships for a log entry
 * @type {Relationship[]}
 */
export const logEntryRelationships = ['modifiedBy'];

/**
 * Relationships for fetched companies
 * @type {Relationship[]}
 */
export const fetchedCompaniesRelationships = [
  'brand',
  'documents',
  'info',
  'mailingAddress',
  'physicalAddress',
  'settings',
  'lastNameChange',
];
