const denormalize = (state, entity) => {
    const result = {
        id: entity.id,
        ...entity.attributes,
    };
    if (!entity.relationships) {
        return result;
    }
    return processRelationships(result, entity.relationships, state, []);
};
const processRelationships = (entity, relationships, state, included) => {
    for (const type in relationships) {
        const relationship = relationships[type];
        const { data } = relationship;
        if (!data) {
            continue;
        }
        const thisIncluded = { ...included };
        if (Array.isArray(data)) {
            const entities = [];
            data.forEach((item) => {
                const rel = createRelationship(item, state, thisIncluded);
                if (rel)
                    entities.push(rel);
            });
            entity[type] = entities;
        }
        else {
            const currentEntity = createRelationship(data, state, thisIncluded);
            if (currentEntity)
                entity[type] = currentEntity;
        }
    }
    return entity;
};
const lowercase = (str = '') => {
    return str.charAt(0).toLowerCase() + str.slice(1);
};
const createRelationship = (data, state, included) => {
    const type = lowercase(data.type);
    if (!state[type] || !state[type][data.id] || (included.hasOwnProperty(type) && included[type].includes(data.id))) {
        return {
            id: data.id,
        };
    }
    const includedType = included[type] || [];
    includedType.push(data.id);
    included[type] = includedType;
    const entity = state[type][data.id];
    const result = {
        id: entity.id,
        ...entity.attributes,
    };
    if (!entity.relationships) {
        return result;
    }
    return processRelationships(result, entity.relationships, state, included);
};
export default denormalize;
