import { isSearchExecuted } from 'helpers/search';
export const isPaginationBlockButtonDisabled = (url, isFetching) => !url || isFetching;
export const getPaginationBlockResultText = (props) => {
    const { currentPage, isFetching, pageSize, searchData, totalResults } = props;
    if (isFetching || typeof currentPage === 'undefined') {
        return 'Loading ...';
    }
    if (totalResults < 1) {
        return 'No results found';
    }
    const previousPage = currentPage - 1;
    const isLastPage = currentPage * pageSize >= totalResults;
    const firstValue = totalResults > 0 ? pageSize * previousPage + 1 : 1;
    const lastValue = isLastPage ? totalResults : currentPage * pageSize;
    let resultText = `Showing ${firstValue}-${lastValue} of ${totalResults}`;
    if (isSearchExecuted(searchData)) {
        resultText += ' results';
    }
    return resultText;
};
