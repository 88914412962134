export const initialState = {
  billing: false,
  countries: false,
  currencies: false,
  currentCompany: false,
  fundingAccounts: false,
  integrationConfigs: false,
  integrations: false,
  memberships: false,
  permissionGroups: false,
  permissions: false,
  roles: false,
  supportedCountries: false,
};
