import isObject from 'lodash/isObject';
import snakeCase from 'lodash/snakeCase';
import transform from 'lodash/transform';
import { z } from 'zod';
import { RoutableNetwork } from '../index';
const squareBracketsRegex = new RegExp(/\[(.*?)\]/);
const customSnakeCase = (val) => {
    if (squareBracketsRegex.test(val)) {
        const result = squareBracketsRegex.exec(val);
        return result ? `${customSnakeCase(val.replace(result[0], ''))}${result[0]}` : val;
    }
    return val.split('__').map(snakeCase).join('__');
};
const convertToSnakeCaseTransformer = (acc, value, key, target) => {
    const camelKey = Array.isArray(target) || z.string().uuid().safeParse(key).success ? key : customSnakeCase(key);
    acc[camelKey] = isObject(value) ? convertToSnakeCase(value) : value;
};
export const convertToSnakeCase = (obj) => transform(obj, convertToSnakeCaseTransformer);
export const convertToSnakeCaseAsync = async (obj) => transform(obj, async (...rest) => convertToSnakeCaseTransformer(...rest));
const removeInitialSlash = (url) => {
    return url.startsWith('/') ? url.slice(1) : url;
};
const networkApiCall = (parseResponse) => async ({ url, method = 'get', queryParams = {}, headers, responseModel, errorResponseModel, body, ...rest }) => {
    const snakeCasedParams = await convertToSnakeCaseAsync(queryParams);
    return new Promise((resolve, reject) => {
        RoutableNetwork[method]({
            url: removeInitialSlash(url),
            headers,
            params: snakeCasedParams,
            data: body,
            parseResponse,
            ...rest,
        })
            .then(async (resp) => {
            const status = resp?.options?.status ?? 200;
            const isStatusOk = status >= 200 && status < 300;
            if ((resp && resp.parsedErrors?.errors) || !isStatusOk) {
                if (errorResponseModel) {
                    const parsed = await errorResponseModel.safeParseAsync(resp.parsedErrors);
                    if (parsed.success) {
                        reject(parsed.data);
                        return;
                    }
                }
                reject(resp);
                return;
            }
            const parsed = await responseModel.safeParseAsync(resp);
            if (!parsed.success) {
                resolve(resp);
            }
            resolve(parsed.data || resp);
        })
            .catch((err) => {
            reject(err);
        });
    });
};
export const apiCall = networkApiCall('NONE');
export const apiRelatedDataCall = networkApiCall('RELATED_DATA');
export const apiJsonApiCall = networkApiCall('JSON_API');
