import { queryContext } from '@routable/shared';
import { useQuery } from '@tanstack/react-query';
import { generateKeys } from '../../helpers';
import { fundDataService } from '../../services';
export const useDwollaBusinessCategories = () => {
    return useQuery({
        context: queryContext,
        queryKey: generateKeys.fundDataDwollaBusinessCategories(),
        queryFn: async () => fundDataService.listDwollaBusinessCategories(),
        staleTime: Infinity,
    });
};
