import { useFeatureFlags } from '@routable/feature-configs';
import PropTypes from 'prop-types';
import React from 'react';

import { DisclosureList } from 'components';

import { PermissionDisclosureListRow } from './components';
import {
  getPermissionSectionDisclosureListConditionalProps,
  transformPermissionsIntoDisclosureListItems,
} from './helpers';

/**
 * A DisclosureList which displays a PermissionGroup as a header and the group's enabled/disabled permissions in the
 * body. The header renders according to how many of the permissions are enabled (all, some, none). Each of the
 * permissions renders based on its enabled status.
 *
 * @param {ComponentProps} props
 * @param {PermissionGroup.name} props.permissionGroupName
 * @param {Permission[]} props.permissions
 * @parma {boolean} [props.startOpen=false] - Should the list be open by default?
 * @returns {StatelessComponent}
 */
const PermissionSectionDisclosureList = ({ permissionGroupName, permissions, startOpen }) => {
  const activeFeatureFlags = useFeatureFlags();
  const { headerText, iconName, iconProps } = getPermissionSectionDisclosureListConditionalProps({
    permissionGroupName,
    permissions,
  });
  const permissionItems = transformPermissionsIntoDisclosureListItems({
    component: PermissionDisclosureListRow,
    permissions,
    activeFeatureFlags,
  });

  return (
    <DisclosureList
      bodyClassName="flex-col padding-left--m"
      bodyRowProps={{ isGrid: false }}
      headerIconName={iconName}
      headerIconProps={iconProps}
      headerText={headerText}
      items={permissionItems}
      keyExtractor={(permission) => permission.data.id}
      startOpen={startOpen}
    />
  );
};

PermissionSectionDisclosureList.propTypes = {
  startOpen: PropTypes.bool,
  permissionGroupName: PropTypes.string.isRequired,
  permissions: PropTypes.arrayOf(
    PropTypes.shape({
      enabled: PropTypes.bool,
      id: PropTypes.string.isRequired,
      resourceActionText: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

PermissionSectionDisclosureList.defaultProps = {
  startOpen: undefined,
};

export default PermissionSectionDisclosureList;
