import PropTypes from 'prop-types';
import React from 'react';

import { CloseButton } from 'components/buttonTypes';
import { reduxFormInputPropType } from 'components/commonProps';

import { PopoverSelectField, PopoverSelectTitle } from './components';

/**
 * Renders the area of the PopoverSelect that contains the select field, as well as
 * the title and close button.
 * @param {Object} props
 * @param {Function} props.onChange
 * @param {EventHandlerNonNull} props.onClose
 * @param {Function} props.onCreate
 * @param {Object[]} props.options
 * @param {Function} props.SelectComponent
 * @param {Object} props.selectProps
 * @param {string} props.title
 * @return {JSX.Element}
 */
const PopoverSelectSelectionContent = ({ input, onClose, onCreate, options, SelectComponent, selectProps, title }) => (
  <div>
    <div className="flex justify-between items-center">
      <PopoverSelectTitle>{title}</PopoverSelectTitle>
      <CloseButton onClick={onClose} />
    </div>

    <PopoverSelectField
      input={input}
      onClosePopover={onClose}
      onCreate={onCreate}
      options={options}
      SelectComponent={SelectComponent}
      {...selectProps}
    />
  </div>
);

PopoverSelectSelectionContent.propTypes = {
  input: reduxFormInputPropType.isRequired,
  onClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape()),
  SelectComponent: PropTypes.elementType.isRequired,
  selectProps: PropTypes.shape(),
  title: PropTypes.string.isRequired,
};

PopoverSelectSelectionContent.defaultProps = {
  onCreate: undefined,
  options: [],
  selectProps: {},
};

export default PopoverSelectSelectionContent;
