import clsx from 'clsx';
import React from 'react';
import { RadioInput, Text, WhiteSpace } from 'components';
import { IconNames, Icon } from 'components/icon';
import { colors, typography } from 'constants/styles';
import { convertToLowerCase } from 'helpers/stringHelpers';
import BankAccountHeaderRtpEnabledTag from 'modules/fundingAccount/bankAccount/components/BankAccountHeader/components/BankAccountHeaderRtpEnabledTag';
const PaymentMethodHeader = ({ currencyCode, fundingAccountId, fundingIcon, input, isExpanded, isRTPEnabled = false, isSelectable = false, onToggleExpand, status, statusEl, subtitle, title, }) => (React.createElement("div", { className: "payment_method__header-container" },
    isSelectable && (React.createElement("div", { className: "payment_method__header-container__radio" },
        React.createElement(RadioInput, { id: fundingAccountId, input: input, isDisabled: input?.disabled }))),
    React.createElement("div", { className: "payment_method__header-container__content", onClick: onToggleExpand, role: "presentation" },
        React.createElement("div", { className: clsx('payment_method__titles-icon-container', `payment_method__titles-icon-container--${convertToLowerCase(status)}`) },
            React.createElement(Icon, { className: "payment_method__funding-icon font-sm", size: 14, ...fundingIcon }),
            React.createElement("div", { className: "payment_method__title-subtitle-wrapper" },
                React.createElement("div", { className: "payment_method__title", "data-fullstory": true },
                    title,
                    currencyCode && (React.createElement(Text.Regular, { color: typography.TextColor.GREY_DARK, size: typography.TextSize.LEVEL_200 },
                        React.createElement(WhiteSpace, null),
                        currencyCode))),
                !!subtitle && (React.createElement("div", { className: "payment_method__subtitle-wrapper" },
                    React.createElement("div", { className: "payment_method__subtitle", "data-fullstory": true }, subtitle))))),
        isRTPEnabled && React.createElement(BankAccountHeaderRtpEnabledTag, null),
        statusEl,
        React.createElement("div", { className: "payment_method__chevron" },
            React.createElement(Icon, { color: colors.colorGreyMedHex, icon: isExpanded ? IconNames.CHEVRON_UP : IconNames.CHEVRON_DOWN })))));
export default PaymentMethodHeader;
