import { featureFlag, useFeatureFlag } from '@routable/feature-configs';
import { RoutableEvents, useEvent } from '@routable/framework';
import { useEffect, useState } from 'react';
import {} from '../components';
import { pullHeaderConfigFromIndexDb, pushHeaderConfigToIndexDb, } from '../components/columnselect/TableColumnSelect.helpers';
import {} from '../table.extended.types';
export const useTableColumnSelect = ({ headersConfig, tableName, }) => {
    const [newHeadersConfig, setNewHeadersConfig] = useState(headersConfig);
    const setHeadersConfig = (latestCheckboxesState) => {
        const newConf = headersConfig.filter((header) => latestCheckboxesState?.[header.columnId]?.checked);
        setNewHeadersConfig(newConf);
    };
    const syncHeadersConfigWithIndexDBAndLocalState = async () => {
        const checkboxesStateFromIndexDB = await pullHeaderConfigFromIndexDb(tableName, headersConfig);
        await pushHeaderConfigToIndexDb(tableName, checkboxesStateFromIndexDB);
        setHeadersConfig(checkboxesStateFromIndexDB);
        RoutableEvents.Publish(`columnselect:${tableName}:columns:updated`, {});
    };
    useEffect(() => {
        syncHeadersConfigWithIndexDBAndLocalState();
    }, []);
    useEvent(`columnselect:${tableName}:columns:update`, (checkboxesState) => {
        pushHeaderConfigToIndexDb(tableName, checkboxesState);
        setHeadersConfig(checkboxesState);
    });
    const isColumnPickerEnabled = useFeatureFlag(featureFlag.Enum.column_picker);
    return { headersConfig: isColumnPickerEnabled ? newHeadersConfig : headersConfig };
};
