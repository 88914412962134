import dayjs from 'dayjs';
import { TransactionStatus, TransactionStatusText, transactionStatusToVariant } from 'constants/transactions';
import { ItemStatuses } from 'enums/items';
import { transactionHelpers } from 'helpers/transactions';
export const formatDate = (date) => (date ? dayjs(date).format('MMM D, YYYY') : '');
var StatusText;
(function (StatusText) {
    StatusText["CANCELED"] = "Canceled";
    StatusText["COMPLETED"] = "Completed";
    StatusText["FAILED"] = "Failed";
    StatusText["IN_TRANSIT"] = "In-transit";
    StatusText["INITIATED"] = "Initiated";
    StatusText["INVISIBLE"] = "Invisible";
    StatusText["ISSUE"] = "Issue";
    StatusText["MARKED_AS_PAID"] = "Marked as paid";
    StatusText["PENDING"] = "Pending";
    StatusText["PROCESSING"] = "Processing";
    StatusText["QUEUED"] = "Queued";
    StatusText["SCHEDULED"] = "Scheduled";
    StatusText["UNSCHEDULED"] = "Unscheduled";
})(StatusText || (StatusText = {}));
export const statusIntentMap = {
    [StatusText.CANCELED]: 'warning',
    [StatusText.COMPLETED]: 'success',
    [StatusText.FAILED]: 'critical',
    [StatusText.INITIATED]: 'blue',
    [StatusText.INVISIBLE]: 'default',
    [StatusText.ISSUE]: 'critical',
    [StatusText.MARKED_AS_PAID]: 'success',
    [StatusText.PENDING]: 'blue',
    [StatusText.PROCESSING]: 'blue',
    [StatusText.QUEUED]: 'blue',
    [StatusText.SCHEDULED]: 'purple',
    [StatusText.UNSCHEDULED]: 'default',
};
export const itemStatusTextMap = {
    [ItemStatuses.BULK_UPLOAD_ACTION_CONTINUE]: StatusText.PROCESSING,
    [ItemStatuses.BULK_UPLOAD_ACTION_REQUIRED]: StatusText.UNSCHEDULED,
    [ItemStatuses.BULK_UPLOAD_COMPLETE]: StatusText.COMPLETED,
    [ItemStatuses.BULK_UPLOAD_ERROR]: StatusText.FAILED,
    [ItemStatuses.BULK_UPLOAD_STARTING]: StatusText.INITIATED,
    [ItemStatuses.BULK_UPLOAD]: StatusText.UNSCHEDULED,
    [ItemStatuses.CANCELED]: StatusText.CANCELED,
    [ItemStatuses.COMPLETED]: StatusText.COMPLETED,
    [ItemStatuses.CREATED]: StatusText.UNSCHEDULED,
    [ItemStatuses.DRAFT]: StatusText.UNSCHEDULED,
    [ItemStatuses.EXTERNALLY_PAID]: StatusText.MARKED_AS_PAID,
    [ItemStatuses.FAILED]: StatusText.FAILED,
    [ItemStatuses.IMPORT]: StatusText.UNSCHEDULED,
    [ItemStatuses.INITIATED]: StatusText.IN_TRANSIT,
    [ItemStatuses.INVISIBLE]: StatusText.INVISIBLE,
    [ItemStatuses.ISSUE]: StatusText.ISSUE,
    [ItemStatuses.NEEDS_APPROVAL]: StatusText.UNSCHEDULED,
    [ItemStatuses.NEW]: StatusText.UNSCHEDULED,
    [ItemStatuses.OCR]: StatusText.UNSCHEDULED,
    [ItemStatuses.PENDING]: StatusText.PENDING,
    [ItemStatuses.PO_DISCREPANCY]: StatusText.UNSCHEDULED,
    [ItemStatuses.PROCESSING]: StatusText.PROCESSING,
    [ItemStatuses.QUEUED]: StatusText.QUEUED,
    [ItemStatuses.READY_TO_SEND]: StatusText.UNSCHEDULED,
    [ItemStatuses.SCHEDULED]: StatusText.SCHEDULED,
};
export const canceledStatuses = [ItemStatuses.CANCELED.toString()];
export const failedStatuses = [
    ItemStatuses.BULK_UPLOAD_ERROR.toString(),
    ItemStatuses.FAILED.toString(),
    ItemStatuses.ISSUE.toString(),
    TransactionStatus.FAILED,
    TransactionStatus.ISSUE,
];
export const completedStatuses = [
    'COMPLETE',
    ItemStatuses.COMPLETED.toString(),
    ItemStatuses.BULK_UPLOAD_COMPLETE.toString(),
    TransactionStatus.PROCESSED.toString(),
];
export const getTransactionStatus = (transaction) => {
    const status = transactionHelpers.getTransactionStatusToUse(transaction);
    return {
        status,
        text: TransactionStatusText[status],
        variant: transactionStatusToVariant[status],
    };
};
export const getItemStatus = (itemStatus, hasScheduledDate) => {
    let itemStatusText = itemStatusTextMap[itemStatus];
    if (hasScheduledDate && itemStatusText === StatusText.UNSCHEDULED) {
        itemStatusText = StatusText.SCHEDULED;
    }
    return {
        status: itemStatus,
        text: itemStatusText,
        variant: statusIntentMap[itemStatusText],
    };
};
export const getTransactionOrItemStatus = (itemStatus, transaction, hasScheduledDate) => {
    return transaction?.providerStatus ? getTransactionStatus(transaction) : getItemStatus(itemStatus, hasScheduledDate);
};
export const getFailureReason = (transaction) => {
    const { providerFailureCode, providerFailureDescription } = transaction;
    if (transactionHelpers.shouldTransactionDisplayIssueStatus(transaction) ||
        transactionHelpers.isTransactionFailed(transaction)) {
        return `${providerFailureCode}: ${providerFailureDescription}`;
    }
    return undefined;
};
