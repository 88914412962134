import { apiCall } from '@routable/framework';
import { featureFlagsApiResponseModel, featureSettingsApiResponseModel } from '../models';
export const featureConfigsService = {
    listFeatureFlags: async () => apiCall({
        headers: { accept: 'application/json' },
        url: '/feature_config/feature_flags/',
        method: 'get',
        camelCaseResponse: true,
        responseModel: featureFlagsApiResponseModel,
    }),
    listFeatureSettings: async () => apiCall({
        headers: { accept: 'application/json' },
        url: '/feature_config/feature_settings/',
        method: 'get',
        camelCaseResponse: true,
        responseModel: featureSettingsApiResponseModel,
    }),
};
