import { IconNames } from 'components';
import { getFormattedDetailsOfFundingInfoAddressWithAddress, getFundingAccountMask, getFundingInfoAddressDisplayName, isFundingAccountBalance, isFundingAccountBankAccount, isFundingAccountDeleted, isFundingAccountDisabled, isFundingAccountPayPalDirect, isFundingAccountValid, isFundingSourceInvalid, isFundingSourceInvalidMicroDepositsFailed, isFundingSourceInvalidMicroDepositsFailedVerification, isFundingSourceInvalidMicroDepositsPending, isFundingSourceInvalidMicroDepositsProcessed, isFundingSourceInvalidOrMicroDepositState, isFundingSourceValid, } from 'helpers/funding';
import { PaymentMethodStatusType } from './enums';
export const getPaymentMethodHeaderCheck = (fundingInfoAddress = {}) => {
    const title = getFundingInfoAddressDisplayName(fundingInfoAddress);
    const subtitle = getFormattedDetailsOfFundingInfoAddressWithAddress(fundingInfoAddress);
    return { title, subtitle };
};
export const getPaymentMethodHeaderBank = (fundingInfo) => {
    const mask = !!fundingInfo && 'iban' in fundingInfo ? fundingInfo.iban : fundingInfo?.accountNumber;
    const subtitle = mask ? getFundingAccountMask(mask) : '';
    return { title: fundingInfo?.institutionName ?? '', subtitle };
};
export const getPaymentMethodHeaderBalance = (fundingAccount) => {
    const { name: title = '' } = Object(fundingAccount);
    return { title };
};
export const getPaymentMethodHeader = ({ fundingAccount, fundingInfoAddress, fundingInfoBankAccount, fundingInfoInternational, fundingInfoPayPalDirect, }) => {
    if (isFundingAccountBalance(fundingAccount)) {
        return getPaymentMethodHeaderBalance(fundingAccount);
    }
    if (isFundingAccountBankAccount(fundingAccount)) {
        const fundingInfo = fundingAccount.international ? fundingInfoInternational : fundingInfoBankAccount;
        return getPaymentMethodHeaderBank(fundingInfo);
    }
    if (isFundingAccountPayPalDirect(fundingAccount)) {
        return { title: fundingInfoPayPalDirect?.paypalEmail };
    }
    return getPaymentMethodHeaderCheck(fundingInfoAddress);
};
export const getPaymentMethodStatus = ({ fundingAccount, fundingSources = [], isPartnerAccountInDashboard, partnershipFundingAccount, shouldTreatInvalidStatusAsPendingVerification = false, }) => {
    if (partnershipFundingAccount?.isPrimary) {
        return PaymentMethodStatusType.PRIMARY;
    }
    if (fundingSources.some(isFundingSourceInvalidMicroDepositsPending)) {
        return PaymentMethodStatusType.PENDING_MICRO_DEPOSITS;
    }
    const isPendingVerificationCheck = shouldTreatInvalidStatusAsPendingVerification
        ? isFundingSourceInvalidOrMicroDepositState
        : isFundingSourceInvalidMicroDepositsProcessed;
    if (fundingSources.some(isPendingVerificationCheck)) {
        return PaymentMethodStatusType.PENDING_VERIFICATION;
    }
    if (fundingSources.some((source) => isFundingSourceInvalid(source) ||
        isFundingSourceInvalidMicroDepositsFailed(source) ||
        isFundingSourceInvalidMicroDepositsFailedVerification(source))) {
        return PaymentMethodStatusType.ERROR;
    }
    if (isFundingAccountDisabled(fundingAccount) || isFundingAccountDeleted(fundingAccount)) {
        return PaymentMethodStatusType.DISABLED;
    }
    if (isPartnerAccountInDashboard ||
        isFundingAccountValid(fundingAccount) ||
        fundingSources.some(isFundingSourceValid)) {
        return PaymentMethodStatusType.ACTIVE;
    }
    return undefined;
};
export const getPaymentMethodIcon = (fundingAccount) => {
    if (isFundingAccountBalance(fundingAccount)) {
        return { icon: IconNames.BANK_ACCOUNT };
    }
    if (isFundingAccountPayPalDirect(fundingAccount)) {
        return { icon: IconNames.PAYPAL };
    }
    return isFundingAccountBankAccount(fundingAccount)
        ? { isCustomIcon: true, icon: 'icon-ic-bank' }
        : { isCustomIcon: true, icon: 'icon-ic-mail' };
};
