import styled from 'styled-components';
import { View } from '@routable/gross-ds';
export const Container = styled(View) `
  --sub-header-height: 48px;
  display: flex;
  width: 100%;
  background-color: var(--color-white);
  border-bottom: 1px solid var(--color-grey20) !important;
  height: var(--sub-header-height);
  justify-content: space-between;
  align-items: center;
`;
export const LeftHeader = styled.div `
  display: flex;
  align-items: center;
  text-align: left;
  gap: 10px;
`;
export const RightHeader = styled.div `
  display: flex;
  align-items: center;
  text-align: right;
  gap: 10px;
`;
export const HeaderCaption = styled.div `
  white-space: nowrap;
`;
export const Tabs = styled.div `
  height: 100%;
  display: flex;
  align-items: center;
  text-align: left;
  gap: 24px;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
`;
export const Tab = styled.div `
  height: 100%;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
`;
