export const defaultSidePanelWidth = 768;
export const sidePanelNameContact = 'contact';
export const sidePanelNameExistingItem = 'existingItem';
export const sidePanelNameExistingPayment = 'existingPayment';
export const sidePanelNameItemThreadTags = 'itemThreadTags';
export const sidePanelNameAddPartnerFundingAccount = 'addPartnerFundingAccount';
export const sidePanelNameManuallyAddBankAccount = 'manuallyAddBankAccount';
export const sidePanelNameAddOrUpdateVendorRegisteredAddress = 'addOrUpdateVendorRegisteredAddress';
export const sidePanelNameAddVendorRegisteredAddressInForm = 'addVendorRegisteredAddressInForm';
export const sidePanelNameEditCompanyGeneralInfo = 'editCompanyGeneralInfo';
export const ContactSidePanelType = {
    CREATE: 'CREATE',
    UPDATE: 'UPDATE',
};
