import React, { useCallback, useEffect, useRef, useState } from 'react';
import { RoutableEvents, RoutableStorage } from '@routable/framework';
import { LoadingOption, SearchInput } from '@routable/gross-ds';
import { CheckmarkFilled } from '@carbon/icons-react';
import { COMBOBOX_VALUE_SELECTED } from './combobox.component.types';
import { EmptyResults, FrequentlyUsedOptions, ComboBoxOption, OptionsContainer, ResultsLoading, SearchContainer, } from './combobox.options.styled';
import { fixSelectKey } from './combobox.component.helper';
const sortyByLabel = (getOptionLabel, inputStringLowerCase) => (a, b) => {
    const aLabel = getOptionLabel(a)?.toLowerCase().trim();
    const bLabel = getOptionLabel(b)?.toLowerCase().trim();
    const aIndexOf = aLabel?.split(' ').findIndex((str) => str.startsWith(inputStringLowerCase)) ?? -1;
    const bIndexOf = bLabel?.split(' ').findIndex((str) => str.startsWith(inputStringLowerCase)) ?? -1;
    if (aIndexOf >= 0 && aIndexOf < bIndexOf) {
        return -1;
    }
    if (bIndexOf >= 0 && aIndexOf > bIndexOf) {
        return 1;
    }
    return 0;
};
export const ComboBoxFetch = ({ getOptionValue, getOptionLabel, domId, value, options, selectId, startingFetchString, predictive, loadOptions, }) => {
    const [searchValue, setSearchValue] = useState(startingFetchString);
    const searchRef = useRef(null);
    const [optionValues, setOptionValues] = useState(options ?? []);
    const [highlightedOption, setHighlightedOption] = useState(() => {
        const matchedTo = typeof value === 'string' ? value : getOptionValue(value);
        const t = optionValues.findIndex((item) => getOptionValue(item) === matchedTo);
        if (t >= 0)
            return t;
        return null;
    });
    const [showClear, setShowClear] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const emptyResults = optionValues.length === 0;
    const [cachedValues, setCachedValues] = useState([]);
    const onSelectValue = (valueOption, labelOption) => {
        RoutableEvents.Publish(`${COMBOBOX_VALUE_SELECTED}:${domId}`, {
            valueOption,
            labelOption,
        });
    };
    const searchValues = useCallback(async (inputString) => {
        setIsLoading(true);
        setHighlightedOption(null);
        setSearchValue(inputString);
        const results = (loadOptions && (await loadOptions(inputString).catch((err) => err))) ||
            options?.filter?.((item) => {
                const optionLabel = getOptionLabel(item);
                const optionValue = getOptionValue(item);
                return [optionLabel, optionValue].some((val) => `${val}`.toLowerCase().includes(inputString.toLowerCase()));
            });
        if (results.revert) {
            return;
        }
        if (results && results.length > 0) {
            const inputStringLowerCase = inputString.toLowerCase().trim();
            setOptionValues(results.sort(sortyByLabel(getOptionLabel, inputStringLowerCase)));
            setIsLoading(false);
        }
        else {
            setOptionValues([]);
            setIsLoading(false);
        }
        if (inputString.trim() !== '') {
            setShowClear(true);
        }
        else {
            setShowClear(false);
        }
    }, []);
    const onClearResults = useCallback(() => {
        if (searchRef && searchRef.current) {
            searchRef.current.value = '';
            setShowClear(false);
            searchValues('');
        }
    }, []);
    useEffect(() => {
        if (searchRef?.current) {
            searchRef.current.focus();
        }
        if (selectId && predictive) {
            RoutableStorage.get('routableSelectCache', {
                indexName: 'selectId',
                condition: '==',
                value: fixSelectKey(selectId),
            })
                .then((res) => {
                if (res && res.data && res.data.length > 0) {
                    setCachedValues(res.data);
                    setOptionValues(res.data);
                }
            })
                .catch(() => {
                setCachedValues([]);
            });
        }
    }, []);
    const containerRef = useRef(null);
    useEffect(() => {
        const res = containerRef.current?.querySelector('[data-focus="true"]');
        const timer = setTimeout(() => {
            res?.scrollIntoView?.({ behavior: 'smooth', block: 'nearest' });
        }, 10);
        return () => clearTimeout(timer);
    });
    const handleKeyboard = (e) => {
        if (e.key.startsWith('Arrow')) {
            setHighlightedOption((prev) => {
                if (prev === null) {
                    return 0;
                }
                if (e.key === 'ArrowDown' || e.key === 'ArrowRight') {
                    return Math.min(prev + 1, optionValues.length - 1);
                }
                return Math.max(prev - 1, 0);
            });
        }
        else if ((e.key === 'Enter' || e.key === ' ') && highlightedOption !== null) {
            e.preventDefault();
            const item = optionValues[highlightedOption];
            const optionLabel = getOptionLabel(item);
            onSelectValue(item, optionLabel);
        }
    };
    React.useEffect(() => {
        setSearchValue(startingFetchString);
        searchValues(startingFetchString || '');
    }, [startingFetchString]);
    return (React.createElement("div", { ref: containerRef, className: "grow flex flex-col" },
        React.createElement(SearchContainer, null,
            React.createElement(SearchInput, { "aria-label": "search", onChange: searchValues, onClear: onClearResults, onKeyDown: handleKeyboard, placeholder: "Type to search", ref: searchRef, value: searchValue })),
        React.createElement(OptionsContainer, { className: "use-scrollbars grow", role: "combobox" },
            isLoading && (React.createElement(ResultsLoading, null,
                React.createElement(LoadingOption, null))),
            emptyResults && cachedValues.length === 0 && (React.createElement(EmptyResults, null,
                React.createElement("span", null, "No results to show."))),
            predictive && cachedValues && cachedValues.length > 0 && !showClear && (React.createElement(FrequentlyUsedOptions, null, "Frequently used options")),
            optionValues &&
                optionValues.map((item, idx) => {
                    const optionLabel = getOptionLabel(item);
                    const optionValue = getOptionValue(item);
                    const matchedTo = typeof value === 'string' ? value : getOptionValue(value);
                    const isSelected = matchedTo === optionValue;
                    return (React.createElement(ComboBoxOption, { role: "option", onClick: () => onSelectValue(item, optionLabel), onPointerEnter: () => setHighlightedOption(idx), onPointerLeave: () => setHighlightedOption((prev) => (prev === idx ? null : prev)), key: optionValue, "aria-selected": isSelected, "data-focus": idx === highlightedOption },
                        React.createElement("span", null, optionLabel),
                        React.createElement("span", null, isSelected && React.createElement(CheckmarkFilled, { className: "w-5 h-5 ml-auto" }))));
                }))));
};
