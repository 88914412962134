import { useUpdatePartnershipRequestVendorCountry } from '@routable/companies-management';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { externalOnboardingIncrementStep } from 'actions/externalOnboarding';
import { setVendorCountryFormIsSubmittingState, setVendorCountryFormIsValidState } from 'actions/partnershipRequests';
import { fetchExternalPartnershipRequestRoutine } from 'actions/routines/external';
import { Form, SelectFieldV2 } from 'components';
import { useFormattedCountries } from 'components/form/CountryField/hooks';
import { getErrorsFromFieldState } from 'components/form/helpers';
import { formNamesExternal } from 'constants/forms';
import { MissingCountryHint } from 'modules/customerSupport';
import { partnershipFromCurrentPartnershipRequestSelector } from 'queries/partnershipCompoundSelectors';
import { fundingSupportedCountriesByCountryCode } from 'selectors/fundingSelectors';
import { partnershipRequestFromLocationSelector } from 'selectors/partnershipRequestSelectors';
const FORM_NAME = formNamesExternal.VENDOR_COUNTRY;
const ExternalVendorCountryForm = () => {
    const dispatch = useDispatch();
    const supportedCountryCodes = useSelector(fundingSupportedCountriesByCountryCode);
    const partnership = useSelector(partnershipFromCurrentPartnershipRequestSelector);
    const partnershipRequest = useSelector(partnershipRequestFromLocationSelector);
    const { prioritizedCountries } = useFormattedCountries(supportedCountryCodes, partnership);
    const { mutateAsync: updateVendorCountry } = useUpdatePartnershipRequestVendorCountry();
    const { control, handleSubmit, formState } = useForm();
    const onSubmit = async ({ countryCode }) => {
        await updateVendorCountry({ id: partnershipRequest.id, countryCode });
        dispatch(fetchExternalPartnershipRequestRoutine.trigger());
        dispatch(externalOnboardingIncrementStep());
    };
    useEffect(() => {
        dispatch(setVendorCountryFormIsValidState(formState.isValid));
    }, [dispatch, formState.isValid]);
    useEffect(() => {
        dispatch(setVendorCountryFormIsSubmittingState(formState.isSubmitting));
        return () => {
            dispatch(setVendorCountryFormIsSubmittingState(false));
        };
    }, [dispatch, formState.isSubmitting]);
    return (React.createElement(Form, { id: FORM_NAME, onSubmit: handleSubmit(onSubmit) },
        React.createElement("div", { className: "form-control flex flex-col" },
            React.createElement(Controller, { control: control, name: "countryCode", render: ({ field, fieldState }) => (React.createElement(SelectFieldV2, { errors: getErrorsFromFieldState(fieldState, field.name), input: field, isRequired: true, label: "Country", options: prioritizedCountries, setRef: field.ref })), rules: { required: true } }),
            React.createElement(MissingCountryHint, { className: "mt-3" }))));
};
export default ExternalVendorCountryForm;
