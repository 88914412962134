import React from 'react';
import {} from './Icon.types';
export const PayPal = ({ size = 16, className, 'data-testid': testId = 'icon--paypal', 'aria-label': ariaLabel, }) => (React.createElement("svg", { "data-testid": testId, "aria-label": ariaLabel, className: className, xmlns: "http://www.w3.org/2000/svg", width: size, height: size, fill: "none", viewBox: "0 0 48 48" },
    React.createElement("path", { fill: "#fff", d: "M0 0h48v48H0z" }),
    React.createElement("g", { clipPath: "url(#a)" },
        React.createElement("path", { fill: "#253B80", d: "m15.688 45.026.77-5.017-1.714-.04H6.56L12.247 2.95a.493.493 0 0 1 .16-.29.457.457 0 0 1 .302-.115h13.798c4.58 0 7.742.979 9.392 2.91.774.906 1.267 1.853 1.505 2.895.25 1.094.254 2.4.01 3.993l-.017.116v1.021l.773.45c.652.355 1.17.761 1.567 1.226.662.775 1.09 1.76 1.27 2.927.188 1.2.126 2.629-.18 4.246-.353 1.86-.924 3.481-1.695 4.807a9.798 9.798 0 0 1-2.684 3.02c-1.024.746-2.24 1.312-3.616 1.675-1.332.356-2.852.536-4.519.536H27.24c-.768 0-1.514.284-2.1.793a3.36 3.36 0 0 0-1.094 2.005l-.08.452-1.36 8.841-.062.325c-.016.103-.044.154-.085.189a.224.224 0 0 1-.141.053h-6.63Z" }),
        React.createElement("path", { fill: "#179BD7", d: "M38.903 12.578c-.041.27-.088.546-.141.83-1.82 9.591-8.045 12.904-15.996 12.904h-4.048c-.972 0-1.791.725-1.943 1.71l-2.072 13.494-.587 3.825c-.099.646.386 1.23 1.022 1.23h7.18c.85 0 1.572-.635 1.706-1.496l.07-.374 1.352-8.807.087-.483c.133-.864.856-1.498 1.707-1.498h1.073c6.957 0 12.402-2.9 13.994-11.29.665-3.505.32-6.431-1.439-8.49-.532-.62-1.193-1.135-1.965-1.555Z" }),
        React.createElement("path", { fill: "#222D65", d: "M37 11.799a14.046 14.046 0 0 0-1.77-.403c-1.092-.182-2.287-.268-3.569-.268H20.847c-.267 0-.52.062-.746.174-.499.246-.87.73-.96 1.324l-2.3 14.96-.066.436c.152-.985.97-1.71 1.943-1.71h4.048c7.95 0 14.176-3.314 15.996-12.904.054-.284.1-.56.14-.83-.46-.25-.958-.465-1.495-.648-.132-.045-.27-.09-.408-.131Z" }),
        React.createElement("path", { fill: "#253B80", d: "M19.142 12.626a1.76 1.76 0 0 1 .959-1.323c.228-.111.48-.173.746-.173H31.66c1.282 0 2.477.086 3.569.267a14.119 14.119 0 0 1 2.178.533 9.713 9.713 0 0 1 1.496.648c.542-3.544-.004-5.957-1.87-8.143C34.974 2.03 31.26 1 26.508 1H12.71c-.971 0-1.8.725-1.95 1.71L5.016 40.11c-.114.74.442 1.407 1.17 1.407h8.518l2.138-13.93 2.3-14.96Z" })),
    React.createElement("defs", null,
        React.createElement("clipPath", { id: "a" },
            React.createElement("path", { fill: "#fff", d: "M5 1h38v46H5z" })))));
