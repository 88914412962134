import { ChevronLeft, ChevronRight } from '@carbon/icons-react';
import {} from '@routable/framework';
import { TextInput, Select, Button } from '@routable/gross-ds';
import _debounce from 'lodash/debounce';
import React, { useMemo, useRef, useState } from 'react';
import { useTableStore } from '../../table.store';
import {} from '../../../types/global.types';
import { useDispatchTableEvent, useTableEvent } from '../../hooks';
import { Container } from './footer.styled';
import {} from './footer.types';
const getCurrentPageValue = (pageSize, currentPage) => {
    return pageSize * currentPage;
};
const getPageRecordLabel = (total, pageSize, currentPage) => {
    const start = Math.max(getCurrentPageValue(pageSize, currentPage - 1), 0);
    const end = Math.min(getCurrentPageValue(pageSize, currentPage), total);
    return `${Math.min(end, start + 1)}-${end} of ${total.toLocaleString('en-US')}`;
};
const getGoToPageNumber = (e, pages) => {
    let gotoPageNumber = parseInt(e.target.value);
    if (!gotoPageNumber) {
        return 1;
    }
    if (gotoPageNumber > pages) {
        gotoPageNumber = pages;
    }
    return gotoPageNumber;
};
const pageSizeOptions = [
    {
        value: 25,
        label: '25',
    },
    {
        value: 50,
        label: '50',
    },
    {
        value: 75,
        label: '75',
    },
    {
        value: 100,
        label: '100',
    },
];
const DEBOUNCE_TIME = 400;
export const TableFooter = ({ showRowsPerPage = true, showGoToPage = true, tableName, }) => {
    const dispatchPageUpdate = useDispatchTableEvent({ event: 'page:update', tableName });
    const pageSizeFromStorage = Number(localStorage.getItem(`persistence/${tableName}-page-size`) || 25);
    const [pagingData, setPagingData] = useState({
        hasNextPage: false,
        hasPrevPage: false,
        page: 1,
        pageSize: pageSizeFromStorage,
        pages: 1,
        total: 0,
    });
    const [pageSize, setPageSize] = useState(pageSizeFromStorage);
    const [goToPage, setGoToPage] = useState('1');
    const goToPageRef = useRef(null);
    const { activeTableState: { isFetching }, } = useTableStore(tableName);
    useTableEvent({
        event: 'pagination',
        tableName,
        fn: (data) => {
            if (data.pages === 0) {
                data.pages = 1;
            }
            setPagingData(data);
            setPageSize(data.pageSize);
            setGoToPage(data.page?.toString());
        },
    });
    const handlePageSizeSelectChange = (newPageSize) => {
        dispatchPageUpdate({ pageSize: newPageSize });
        setPageSize(newPageSize);
    };
    const dispatchGoToPageChange = (e) => {
        const gotoPageNumber = getGoToPageNumber(e, pagingData.pages);
        dispatchPageUpdate({
            page: gotoPageNumber,
        });
    };
    const handleGoToPageChangeDebounced = useMemo(() => {
        const debounced = _debounce((e) => dispatchGoToPageChange(e), DEBOUNCE_TIME);
        return function (e) {
            e.persist();
            const newPageNumber = getGoToPageNumber(e, pagingData.pages);
            if (!newPageNumber) {
                setGoToPage('');
                return;
            }
            setGoToPage(newPageNumber.toString());
            return debounced(e);
        };
    }, [pagingData.pages]);
    if (!pagingData || !pagingData.page) {
        return React.createElement(React.Fragment, null);
    }
    return (React.createElement(Container, { className: "table-footer-with-controls" },
        React.createElement("div", { className: "flex items-center" },
            showRowsPerPage && (React.createElement(React.Fragment, null,
                React.createElement("span", null, "Rows per page"),
                React.createElement("div", { className: "ml-3 mr-5 w-[72px]" },
                    React.createElement(Select, { "aria-label": "page size", className: "table-footer-page-select h-8", name: "pageSize", onChange: handlePageSizeSelectChange, options: pageSizeOptions, value: pageSize, valueAs: "number", size: "small" })))),
            isFetching && React.createElement("span", null,
                "Showing ",
                getPageRecordLabel(0, 0, 0)),
            !isFetching && (React.createElement("span", null,
                "Showing ",
                getPageRecordLabel(pagingData.total, pagingData.pageSize, pagingData.page)))),
        React.createElement("div", { className: "flex items-center gap-5" },
            showGoToPage && (React.createElement("div", { className: "hidden md:flex items-center gap-3" },
                React.createElement("span", null, "Go to page"),
                React.createElement("div", { className: "w-12" },
                    React.createElement(TextInput, { disabled: isFetching || pagingData.pages <= 1, inputClassname: "h-8 goto-page", name: "goto-page-input", onChange: handleGoToPageChangeDebounced, ref: goToPageRef, size: "small", value: goToPage, onFocus: () => {
                            goToPageRef.current?.select?.();
                        }, onBlur: () => {
                            setTimeout(() => {
                                setGoToPage(goToPage || pagingData.page.toString());
                            }, DEBOUNCE_TIME);
                        } })))),
            React.createElement("div", { className: "flex" },
                React.createElement(Button, { className: "rounded-r-none", "data-testid": "table:back-button", disabled: isFetching || !pagingData.hasPrevPage, onPress: () => dispatchPageUpdate({
                        page: 'PREV',
                    }), size: "small", variant: "outline" },
                    React.createElement(ChevronLeft, null)),
                React.createElement("div", { "data-testid": "table:pagination-button", className: "flex items-center justify-center min-w-max text-xs text-grey-70 px-4 h-8 bg-white border-grey-20 border-t border-b" },
                    pagingData.page,
                    " of ",
                    pagingData.pages),
                React.createElement(Button, { className: "rounded-l-none", "data-testid": "table:next-button", disabled: isFetching || !pagingData.hasNextPage, onPress: () => dispatchPageUpdate({
                        page: 'NEXT',
                    }), size: "small", variant: "outline" },
                    React.createElement(ChevronRight, null))))));
};
