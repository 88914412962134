import clsx from 'clsx';
import React from 'react';
import { FlexCol } from 'components';
import { ExternalButtonStateTypes } from 'constants/ui';
import { ExternalButton, ExternalColumnHeader, ExternalComponents } from './components';
import {} from './ExternalColumn.types';
const ExternalColumn = ({ body, buttonMap = {}, className, components, currentButton = ExternalButtonStateTypes.INITIAL, header = {}, renderExternalComponent, }) => {
    const buttonProps = Object(buttonMap)[currentButton];
    return (React.createElement(FlexCol, { className: clsx(className, 'external-column') },
        React.createElement(ExternalColumnHeader, { renderExternalComponent: renderExternalComponent, ...header }),
        body && React.createElement("div", { className: "external-column--body" }, body),
        React.createElement(ExternalComponents, { className: "external-column--components-container", components: components, renderExternalComponent: renderExternalComponent }),
        React.createElement(ExternalButton, { ...buttonProps })));
};
export default ExternalColumn;
