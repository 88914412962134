import _includes from 'lodash/includes';
import { fieldMappings } from 'helpers/ocr/constants';
export const hasOCRValues = ({ isBillView, watchProp, watchInclude, conditional, itemFieldMapping = fieldMappings, }) => {
    if (!watchProp && !watchInclude) {
        return false;
    }
    if (!isBillView) {
        return false;
    }
    if (watchInclude && conditional) {
        return true;
    }
    return _includes(itemFieldMapping, watchProp);
};
