import { queryClient } from '@routable/shared';
import { call, put } from 'redux-saga/effects';

import * as routines from 'actions/routines/item';

import * as api from 'sagas/createItem/api';

import { FetchService } from 'services/index';

/**
 * Handle submitting an edited item diff.
 *
 * @returns {IterableIterator<*>}
 */
export function* submitItemEdit(action) {
  yield put(routines.submitItemEditRoutine.request());

  const response = yield call(api.patchItemEdit, action.payload);

  if (response.ok) {
    // this is a timed-out request
    if (FetchService.isResponseAccepted(response)) {
      yield put(routines.submitItemEditRoutine.failure());
    } else {
      yield put(routines.submitItemEditRoutine.success(response.data));
    }

    queryClient.invalidateQueries(['purchaseOrders', 'discrepancies']);
  } else {
    yield put(routines.submitItemEditRoutine.failure());
  }

  return response;
}
