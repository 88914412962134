import { ArrowRight, CheckmarkFilled } from '@carbon/icons-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCreatePayPalFundingAccount } from '@routable/fund-routes';
import { Button, SideSheet, useAddToast, useModalState } from '@routable/gross-ds';
import { PayPal } from '@routable/icons';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { z } from 'zod';
import { createPayPalManualFundingAccount } from 'actions/routines/funding';
import { InputField } from 'components';
import { getErrorsFromFieldState } from 'components/form/helpers';
import { GENERIC_ERROR } from 'constants/error';
const payPalAccountFormSchema = z.object({ email: z.string().email() });
export const GetPaidViaPayPal = () => {
    const dispatch = useDispatch();
    const sideSheetState = useModalState({});
    const addToast = useAddToast();
    const { mutateAsync: createPayPalFundingAccountAsync } = useCreatePayPalFundingAccount();
    const { control, formState, handleSubmit, reset } = useForm({
        resolver: zodResolver(payPalAccountFormSchema),
        mode: 'onBlur',
    });
    const { isValid: isFormValid, isSubmitting } = formState;
    const handleClose = () => {
        sideSheetState.close();
        reset();
    };
    const handleFormSubmit = async ({ email }) => {
        try {
            const response = await createPayPalFundingAccountAsync(email);
            dispatch(createPayPalManualFundingAccount.success(response));
            handleClose();
        }
        catch {
            addToast({ type: 'critical', message: GENERIC_ERROR });
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { className: "!w-full h-auto group gap-4", "data-testid": "btn--get-paid-via-paypal", onPress: () => sideSheetState.open(), variant: "outline" },
            React.createElement(PayPal, null),
            React.createElement("span", { className: "inline-flex flex-col flex-1 items-start" },
                React.createElement("span", { className: "font-semibold" }, "Get paid via PayPal"),
                React.createElement("span", { className: "text-grey-60 group-hover:text-blue-40" }, "Instant verification")),
            React.createElement(ArrowRight, null)),
        React.createElement(SideSheet, { onClose: handleClose, state: sideSheetState, title: "Add your PayPal account" },
            React.createElement("div", { className: "flex-1 flex flex-col" },
                React.createElement("span", { className: "leading-6" }, "Enter the email associated with your PayPal account carefully. Providing an incorrect email may result in payments being sent to the wrong recipient, in which case the funds are unretrievable."),
                React.createElement("div", { className: "flex-1 mt-5" },
                    React.createElement(Controller, { control: control, name: "email", render: ({ field: { ref, ...fieldProps }, fieldState }) => (React.createElement(InputField, { ...fieldProps, errors: getErrorsFromFieldState(fieldState, fieldProps.name), isRequired: true, isUncontrolled: true, placeholder: "PayPal email", setRef: ref })) })),
                React.createElement(Button, { className: "!w-full [--intent-40:47,58,72] [--intent-50:76,84,103] inline-flex items-start [&>.spinner>svg]:!text-white", "data-testid": "btn--submit-paypal-funding-account", disabled: !isFormValid, loading: isSubmitting, onPress: () => handleSubmit(handleFormSubmit)(), size: "large" },
                    React.createElement(CheckmarkFilled, null),
                    React.createElement("span", { className: "flex-1 inline-flex items-start font-semibold" }, "Add PayPal"),
                    React.createElement(ArrowRight, null))))));
};
