import { useQuery } from '@tanstack/react-query';
import { currencyDataService } from '../../services';
import { generateKeys } from '../../helpers';
import { queryContext } from '../../providers';
import {} from '../../models';
export const useCurrencyData = () => {
    return useQuery({
        context: queryContext,
        queryKey: generateKeys.currencyBaseQueryKey(),
        queryFn: async () => currencyDataService.getCurrencies(),
        select: (data) => {
            if (!data) {
                return {};
            }
            const INITIAL_DATA = {
                ANY: {
                    currencyCode: 'USD',
                    decimalPrecision: 2,
                    name: 'United States Dollar',
                },
            };
            return data.reduce((currencyData, currency) => ({
                ...currencyData,
                [currency.currencyCode]: currency,
            }), INITIAL_DATA);
        },
        staleTime: Infinity,
    });
};
