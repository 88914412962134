import { Download } from '@carbon/icons-react';
import { Button, Tooltip } from '@routable/gross-ds';
import clsx from 'clsx';
import React from 'react';
import PermissionResourceAction from 'constants/permissions';
import { usePermissions } from 'hooks';
export const PaginationBlockCSVButton = ({ className, hideCSVButton, isDisabled, onExportVendorData, }) => {
    const permissions = usePermissions([PermissionResourceAction.SETTINGS_EXPORT_DATA]);
    const isButtonDisabled = isDisabled || !permissions.hasPermission;
    const tooltipText = isButtonDisabled ? permissions?.tooltipProps?.title : 'Export to CSV';
    if (hideCSVButton) {
        return null;
    }
    return (React.createElement(Tooltip, { "data-testid": "export-csv-tooltip", tooltip: tooltipText, variant: "dark" },
        React.createElement(Button, { "aria-label": "Export to CSV", className: clsx('group', className), "data-testid": "btn-export-csv", disabled: isButtonDisabled, onPress: onExportVendorData, size: "small", variant: "outline" },
            React.createElement(Download, null))));
};
