import { requiredValidator } from '@routable/shared';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useUnmount } from 'react-use';
import { change, Field } from 'redux-form';
import { SelectFieldV2 } from 'components';
export const BeneficiarySelectField = ({ className, form, field, label, options, placeholder }) => {
    const dispatch = useDispatch();
    useUnmount(() => {
        dispatch(change(form, field, null));
    });
    return (React.createElement("div", { className: className },
        React.createElement(Field, { component: SelectFieldV2, isRequired: true, label: label, name: field, options: options, placeholder: placeholder, validate: [requiredValidator] })));
};
