import { PartnershipMemberAccess } from 'constants/partnershipMember';
import { TagText, TagType } from 'constants/ui';

/**
 * The statuses for a PartnershipMember.
 * @enum {string}
 */
export const PartnershipMemberStatus = {
  ACTIVE: 'active',
  ARCHIVED: 'archived',
  ISSUE: 'issue',
};

/**
 * Text to display given the PartnershipMemberAccess.
 * @enum {string}
 */
export const PartnershipMemberAccessText = {
  [PartnershipMemberAccess.ACTIONABLE]: 'Can take action',
  [PartnershipMemberAccess.READ_ONLY]: 'Read-only',
  [PartnershipMemberAccess.SELF_MANAGED]: TagText[TagType.SELF_MANAGED],
};

export const PartnershipMemberAccessChangeText = {
  [PartnershipMemberAccess.ACTIONABLE]: 'Allow contact to take action on this',
  [PartnershipMemberAccess.READ_ONLY]: 'Change to read-only for this',
};
