import { SettingsAdjust } from '@carbon/icons-react';
import { Popover, Checkbox, Button, Tooltip } from '@routable/gross-ds';
import React from 'react';
import {} from './tablematic.columnselect.types';
import clsx from 'clsx';
export const TablematicTableColumnSelect = ({ checkboxesState, handleCheckboxToggle, handleSubmit, headersConfig, isOnDetailsPage, state, }) => {
    const isLastChecked = Object.keys(checkboxesState).reduce((count, stateKey) => count + (checkboxesState[stateKey] ? 1 : 0), 0) === 1;
    return (React.createElement("div", null,
        React.createElement(Popover, { placement: "bottom right", keepOpen: true, state: state, content: React.createElement(Tooltip, { "data-testid": "column-select-picker", tooltip: "Customize columns", variant: "dark" },
                React.createElement(Button, { className: clsx({
                        'mr-4': isOnDetailsPage,
                    }), "data-testid": "column-settings", intent: "secondary", variant: isOnDetailsPage ? 'outline' : 'floating', size: "small" },
                    React.createElement(SettingsAdjust, null))) },
            React.createElement("div", { className: "p-5 pr-9 flex flex-col gap-3 text-grey-70" }, headersConfig.map(({ id, Header }) => (React.createElement(Checkbox, { checked: checkboxesState[id], key: id, label: Header, name: id, disabled: isLastChecked && checkboxesState[id], onChange: handleCheckboxToggle(id) })))),
            React.createElement("div", { className: "p-5 text-right border-t border-t-grey-10" },
                React.createElement(Button, { "data-testid": "columns-config-apply", intent: "primary", variant: "outline", size: "small", onPress: handleSubmit }, "Apply")))));
};
