import { PaymentDeliveryMethodType } from '@routable/shared';
import PropTypes from 'prop-types';
import React from 'react';

import { Form, ModalBodyContent } from 'components';

import { formNamesFunding } from 'constants/forms';

import { getSubmitButtonText } from 'helpers/addPartnerFundingAccount';

import {
  AddPartnerFundingAccountFooter,
  AddPartnerFundingAccountFormFields,
  AddPartnerFundingAccountOptions,
} from './components';

/**
 * Render form for adding new partner funding account. Should be used inside
 * side panel view.
 * @param {ComponentProps} props
 * @param {string} props.formName
 * @param {function} props.handleSubmit
 * @param {boolean} props.isSubmitting
 * @param {function} props.onClose
 * @param {Partnership} props.partnership
 * @param {ItemPaymentDeliveryMethod} props.paymentDeliveryMethod
 * @return {StatelessComponent}
 */
const AddPartnerFundingAccountForm = ({
  formName,
  handleSubmit,
  isSubmitting,
  onClose,
  partnership,
  paymentDeliveryMethod,
}) => (
  <React.Fragment>
    <Form id={formName} onSubmit={handleSubmit}>
      <ModalBodyContent className="add-partner-funding-account--side-panel-body">
        <AddPartnerFundingAccountFormFields formName={formName} paymentDeliveryMethod={paymentDeliveryMethod} />

        <AddPartnerFundingAccountOptions formName={formName} partnership={partnership} />
      </ModalBodyContent>
    </Form>

    <AddPartnerFundingAccountFooter
      formId={formName}
      isSubmitting={isSubmitting}
      onCloseModal={onClose}
      submitButtonText={getSubmitButtonText(paymentDeliveryMethod)}
    />
  </React.Fragment>
);

AddPartnerFundingAccountForm.propTypes = {
  formName: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  partnership: PropTypes.shape({}).isRequired,
  // PartnershipId is actually not used in this component, but is required due to
  // it's usage in the container by a few selectors in mapStateToProps
  // eslint-disable-next-line react/no-unused-prop-types
  partnershipId: PropTypes.string.isRequired,
  paymentDeliveryMethod: PropTypes.oneOf(Object.values(PaymentDeliveryMethodType)),
};

AddPartnerFundingAccountForm.defaultProps = {
  formName: formNamesFunding.ADD_PARTNER_FUNDING_ACCOUNT,
  isSubmitting: undefined,
  paymentDeliveryMethod: PaymentDeliveryMethodType.ACH,
};

export default AddPartnerFundingAccountForm;
