import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';
import { CountryField } from 'components';
import { createPartnershipFormFields } from 'constants/formFields';
import { formNamesPartnership } from 'constants/forms';
import { requiredValidator, vendorCountryAndAddressCountryMatchValidator } from 'helpers/fieldValidation';
import { getCustomerOrVendorTextByPartnership } from 'helpers/partnerships';
import { MissingCountryHint } from 'modules/customerSupport/MissingCountryHint/MissingCountryHint';
import { currentCompanySettingsIsInternationalPaymentsEnabledSelector } from 'selectors/currentCompanySelectors';
import {} from './PartnerCountryField.types';
const PartnerCountryField = ({ partnership, supportedCountryCodes, className, ...rest }) => {
    const isInternationalPaymentsEnabled = useSelector(currentCompanySettingsIsInternationalPaymentsEnabledSelector);
    const { isCustomer, isVendor } = Object(partnership);
    const isCountryEditable = (isVendor ||
        (!isCustomer && !isVendor)) &&
        isInternationalPaymentsEnabled;
    const partnerType = getCustomerOrVendorTextByPartnership(partnership);
    const lockedInputReason = `You can only invite US-based ${partnerType}s for now.`;
    return (React.createElement("div", { className: clsx('form-control flex flex-col', className) },
        React.createElement(CountryField, { className: "w-full", countryCodes: supportedCountryCodes, formName: formNamesPartnership.CREATE, isEnabled: Boolean(isCountryEditable), isRequired: true, name: createPartnershipFormFields.PARTNER_COUNTRY_CODE, partnership: partnership, placeholder: "Enter vendor's country", reason: lockedInputReason, validate: [requiredValidator, vendorCountryAndAddressCountryMatchValidator], ...rest }),
        isCountryEditable && React.createElement(MissingCountryHint, { className: "mt-3" })));
};
export default PartnerCountryField;
