import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import { clearFundingAccountManualAddress, closeCreateManualAddressModal } from 'actions/createManualAddress';

import { formNamesExternal } from 'constants/forms';

import CreateManualAddressFundingAccount from 'modules/address/createManualAddressFundingAccount/CreateManualAddressFundingAccount';

import { currentCompanySelector } from 'selectors/currentCompanySelectors';
import {
  fundingAccountAddressManualErrorsSelector,
  fundingAccountAddressManualIsSubmittingSelector,
  fundingAccountAddressManualLastSubmittedSelector,
} from 'selectors/fundingSelectors';
import { modalSelector } from 'selectors/modalsSelector';

import submitFundingAccountAddressManual from 'thunks/submitFundingAccountAddressManual';

const createManualAddressForm = reduxForm({
  enableReinitialize: true,
  form: formNamesExternal.CREATE_MANUAL_ADDRESS,
})(CreateManualAddressFundingAccount);

const mapStateToProps = (state) => {
  const modal = modalSelector(state, 'createManualAddress');
  return {
    currentCompany: currentCompanySelector(state),
    errors: fundingAccountAddressManualErrorsSelector(state),
    initialValues: {
      address: modal.address || {},
      ui: {
        showCustomizePrintedCheckToggle: true,
      },
    },
    isSubmitting: fundingAccountAddressManualIsSubmittingSelector(state),
    lastSubmitted: fundingAccountAddressManualLastSubmittedSelector(state),
    modal,
  };
};

const mapDispatchToProps = {
  onClearManualAddressFundingAccount: clearFundingAccountManualAddress,
  onCloseModal: closeCreateManualAddressModal,
  onSubmitFundingAccountAddressManual: submitFundingAccountAddressManual,
};

export default connect(mapStateToProps, mapDispatchToProps)(createManualAddressForm);
