import { TruncatableText } from '@routable/components';
import { Clickable, Popover, Spinner } from '@routable/gross-ds';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { poMatchTypeModel } from 'hooks/purchaseOrders/purchaseOrder.models';
import { ExternalLink } from 'components';
import { deCamelCaseObjectKeys } from 'helpers/utility';
export const InvoiceListPoReceiptCell = ({ isFetchingPoData, isOnPayablesTable, linkVariant = 'default', rowData, }) => {
    const rowDataReceipts = rowData?.receipts;
    const receipts = useMemo(() => (rowDataReceipts || []).map(deCamelCaseObjectKeys), [rowDataReceipts]);
    if (isFetchingPoData) {
        return (React.createElement("div", { className: "flex gap-3 items-center" },
            React.createElement(Spinner, { size: "xxs" }),
            React.createElement("span", { className: "text-xs text-black" }, "Loading PO")));
    }
    const isMatchType3way = rowData?.poMatchType === poMatchTypeModel.Enum['3-way'];
    return (React.createElement("div", { className: clsx('text-xs', {
            'text-grey-50': receipts.length === 0 && isOnPayablesTable,
            'text-black': receipts.length === 0 && !isOnPayablesTable,
        }) },
        receipts.length === 0 && (isMatchType3way ? 'Missing' : 'None'),
        receipts.length === 1 && (React.createElement(ExternalLink, { className: "flex", href: receipts[0].ledger_link, onClick: (evt) => evt.stopPropagation(), variant: linkVariant },
            React.createElement(TruncatableText, { position: "top", text: receipts[0].receipt_number || 'View receipt', tooltipClassName: "!max-w-[calc(100%-12px)]" }))),
        receipts.length > 1 && (React.createElement(Popover, { content: React.createElement(Clickable, { className: "text-xs", onClick: (evt) => {
                    evt.preventDefault();
                }, variant: linkVariant }, `${receipts.length} receipts`), keepOpen: true, placement: "top" },
            React.createElement("div", { className: "py-4 px-5 flex flex-col gap-3" },
                React.createElement("span", { className: "text-xs text-black font-medium leading-[22px]" }, `${receipts.length} linked receipts`),
                receipts.map((receipt) => (React.createElement(ExternalLink, { className: "!text-xs", href: receipt.ledger_link, key: receipt.ledger_link, variant: linkVariant }, receipt.receipt_number || 'View receipt'))))))));
};
