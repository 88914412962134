import { lineItemModel, offsetPaginationModel } from '@routable/shared';
import { z } from 'zod';
import { NETSUITE } from 'constants/integration';
import { parseCurrency } from 'helpers/numbers';
export const purchaseOrderReceiptModel = z.object({
    ledger_link: z.string(),
    ledger_ref: z.string(),
    receipt_number: z.string(),
});
export const purchaseOrderModel = z.object({
    amount: z.coerce.number(),
    created: z.string(),
    currencyCode: z.string(),
    isBillable: z.boolean(),
    ledgerLink: z.string(),
    ledgerRef: z.string(),
    purchaseOrderNumber: z.string().nullish(),
    status: z.enum(['Open', 'Closed', 'Unapproved']),
    statusDescription: z.string().nullish(),
    statusLedger: z.string().nullish(),
    receipts: z.array(purchaseOrderReceiptModel).nullish(),
});
export const purchaseOrderOptionModel = purchaseOrderModel.transform((purchaseOrder) => ({
    currencyCode: purchaseOrder.currencyCode,
    description: `Total amount: ${parseCurrency(purchaseOrder.amount, { symbol: '' })} ${purchaseOrder.currencyCode} Created: ${purchaseOrder.created}`,
    isDisabled: Boolean(purchaseOrder.statusDescription),
    key: purchaseOrder.ledgerRef,
    label: purchaseOrder.purchaseOrderNumber || `${NETSUITE} ID: ${purchaseOrder.ledgerRef}`,
    ledgerLink: purchaseOrder.ledgerLink,
    rightAddons: null,
    status: purchaseOrder.status,
    statusLedger: purchaseOrder.statusLedger,
    tooltip: purchaseOrder.statusDescription,
    value: purchaseOrder?.ledgerRef,
}));
export const poMatchTypeOptions = z.enum(['2-way', '3-way', 'non-po']);
export const poMatchTypeModel = poMatchTypeOptions.exclude(['non-po']);
export const poDiscrepancyTypeModel = z.enum(['missing_receipt_only']);
export const purchaseOrdersByBillSchema = z.object({
    data: z.object({
        bills: z
            .object({
            ledgerRef: z.string(),
            purchaseOrders: z.array(purchaseOrderModel),
        })
            .array(),
    }),
});
export const purchaseOrderSchema = z.object({
    data: z.object({
        purchaseOrders: purchaseOrderModel.array(),
    }),
    pagination: offsetPaginationModel,
});
export const singlePurchaseOrderModel = z.object({
    data: purchaseOrderModel.extend({
        lineItems: lineItemModel.array(),
    }),
});
export const prefilledItemSchema = z.object({
    data: z.object({
        attributes: z.object({
            line_items: z.array(lineItemModel.extend({
                receipts: z.array(purchaseOrderReceiptModel).nullish(),
            })),
        }),
    }),
});
