import { featureFlag, useFeatureFlag } from '@routable/feature-configs';
import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useUnmount } from 'react-use';
import { change, Field } from 'redux-form';
import { internationalBankValidatorsReset } from 'actions/funding';
import { ReduxFormFieldRenderInput } from 'components';
import { colors } from 'constants/styles';
import { requiredValidator } from 'helpers/fieldValidation';
import { snakeCaseFundingFieldsToExternalUserCase } from 'helpers/funding';
import { InternationalBeneficiaryValidationContext } from 'modules/fundingAccount/components/AddInternationalPartnerFundingAccountForm/components/InternationalBeneficiaryValidation';
import { IsLoadingInline } from 'modules/isLoading';
import { useInternationalBankValidator } from '../../hooks';
import {} from './ManuallyAddBankAccountField.types';
const ManuallyAddBankAccountField = ({ fieldInfo, formName, isInternational }) => {
    const dispatch = useDispatch();
    const fieldName = fieldInfo.field;
    const placeholder = snakeCaseFundingFieldsToExternalUserCase(fieldName);
    const enableInternationalBankAccountValidation = useFeatureFlag(featureFlag.Enum.global_only_international_bank_account_validation);
    const { bankNameNode, fieldValidator, isValidating, onBlur } = useInternationalBankValidator(fieldName, isInternational);
    const { triggerValidation, validationErrors, validationInfo } = useContext(InternationalBeneficiaryValidationContext);
    const fieldValidationInfo = validationInfo?.[fieldName];
    useUnmount(() => {
        dispatch(internationalBankValidatorsReset({ fieldName }));
        dispatch(change(formName, fieldName, undefined));
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(Field, { addon: isValidating ? React.createElement(IsLoadingInline, { color: colors.colorDarkSilverHex }) : null, className: "margin-top--m-large", component: ReduxFormFieldRenderInput, hasErrors: validationErrors?.[fieldName], isRequired: true, name: fieldName, onBlur: (e) => {
                if (enableInternationalBankAccountValidation && isInternational) {
                    return triggerValidation();
                }
                return onBlur(e);
            }, placeholder: placeholder, validate: [requiredValidator, fieldValidator] }),
        !!fieldValidationInfo && (React.createElement("div", { className: "mt-2" },
            React.createElement("span", { className: "text-sm text-black" }, fieldValidationInfo))),
        bankNameNode));
};
export default ManuallyAddBankAccountField;
