export const formNamesAddenda = {
  ADDENDA_RECORD: 'addendaRecordForm',
};

export const formNamesAuth = {
  ACCEPT_MEMBERSHIP_INVITE: 'acceptMembershipInviteForm',
  CHANGE_PASSWORD: 'changePassword',
  LOGIN: 'authLoginForm',
  RESET_PASSWORD: 'authResetPasswordForm',
  RESET_PASSWORD_COMPLETE: 'authResetPasswordCompleteForm',
};

export const formNamesAuthV2 = {
  ACCEPT_MEMBERSHIP_INVITE: 'acceptMembershipInviteFormV2',
  CHANGE_PASSWORD: 'changePasswordV2',
  LOGIN: 'authLoginFormV2',
  NAMESPACE: 'authNamespace',
  RESET_PASSWORD: 'authResetPasswordFormV2',
  RESET_PASSWORD_COMPLETE: 'authResetPasswordCompleteFormV2',
};

export const formNamesBalance = {
  TRANSACTION: 'balanceTransaction',
};

export const formNamesBulkActions = {
  BULK_ACTIONS: 'bulkActions',
  IMPORT_ITEMS: 'importItems',
};

export const formNamesContact = {
  CREATE: 'createContact',
  UPDATE: 'updateContact',
};

/**
 * !!NOTE!! [DEV-15462]
 * This is currently a duplicate constant. Until it is removed per the above ticket,
 * changes made to it also need to be made in packages/shared.
 */
export const formNamesItem = {
  CREATE_ITEM: 'createItem',
};

export const formNamesExistingItem = {
  APPROVE: 'existingItemApprove',
  ASSOCIATE_ITEM_MEMBER: 'associateItemMember',
  CANCEL: 'existingItemCancel',
  CANCEL_PAYMENT: 'existingPaymentCancel',
  FINALIZE: 'existingItemFinalize',
  MARK_AS_PAID: 'existingItemMarkAsPaid',
  SELECT_FUNDING_ACCOUNT: 'existingItemSelectFundingAccount',
};

export const formNamesExternal = {
  COLLECT_TAX_INFO: 'ext_collectTaxInfo',
  CREATE_MANUAL_ADDRESS: 'ext_createManualAddress',
  PARTNER_COMPANY_CONTACT: 'ext_partnerCompanyContact',
  PARTNER_COMPANY_GENERAL: 'ext_partnerCompanyGeneral',
  PARTNER_COMPANY_PAYMENT_METHOD: 'ext_partnerCompanyPaymentMethod',
  TAX_E_DELIVERY_CONSENT: 'ext_taxEDeliveryConsent',
  TERM_OF_SERVICE: 'ext_termsOfService',
  UPDATE_PAYMENT_METHOD_INTERNATIONAL: 'ext_updatePaymentMethodInternational',
  UPDATE_PAYMENT_METHOD: 'ext_updatePaymentMethod',
  VENDOR_COUNTRY: 'ext_vendorCountry',
};

export const formNamesFunding = {
  ADD_PARTNER_INTERNATIONAL_FUNDING_ACCOUNT: 'addPartnerInternationalFundingAccount',
  ADD_MANUAL_BANK: 'addManualBank',
  ADD_PARTNER_FUNDING_ACCOUNT: 'addPartnerFundingAccount',
  VERIFY_FUNDING_ACCOUNT: 'verifyFundingAccountForm',
};

export const formNamesIntegrations = {
  INTEGRATION_CONNECT: 'integrationConnect',
  INTEGRATION_SYNC_OPTIONS: 'integrationSyncOptions',
};

export const formNamesCompanySettings = {
  INTERNATIONAL_PAYMENTS: 'internationalPayments',
};

export const formNamesMatchClearingAccounts = {
  LIST: 'matchClearingAccountListForm',
  MODAL: 'matchClearingAccountForm',
};

export const formNamesMatchPartnership = {
  LEDGER_TO_PLATFORM: 'mergeLedgerPartnershipToPlatformForm',
  PLATFORM_TO_LEDGER: 'matchPlatformPartnershipToLedgerForm',
  UI: 'ledgerPartnershipMatchingForm',
};

export const formNamesMembership = {
  EDIT_TEAM_MEMBER: 'editTeamMemberForm',
  INVITE_TEAMMATE: 'inviteTeammate',
};

export const formNamesPartnership = {
  CREATE: 'createPartnershipForm',
  SEND_UPDATE_PAYMENT_LINK: 'sendUpdatePaymentLinkForm',
  UPDATE_PAYMENT_METHOD: 'updatePartnershipPaymentMethodForm',
  ADD_OR_UPDATE_VENDOR_REGISTERED_ADDRESS: 'addOrUpdateVendorRegisteredAddress',
  EDIT_GENERAL_INFO: 'editGeneralInfo',
};

export const formNamesSettings = {
  BRAND: 'settingsCompanyBrand',
  COMPANY_REP_LIST: 'settingsCompanyRepList',
  EMAIL_FORWARDS: 'emailForwardsForm',
  INFO: 'settingsCompanyInfo',
  LEGAL_BUSINESS: 'settingsCompanyLegalBusinessInfo',
  LEGAL_TOGGLE: 'settingsCompanyLegalInfoToggle',
  NOTIFICATION_SETTINGS: 'notificationSettingsForm',
  PAYABLE_APPROVAL_SETTINGS: 'settingsApprovalSettings',
  PAYABLE_CONFIGURATIONS: 'settingsPayableConfigurations',
  READ_API_TOKEN: 'readApiTokenForm',
  USER_PROFILE: 'userProfile',
  USER_REPRESENTATIVE: 'settingsUserRepresentativeForm',
  VENDOR_LIST_FILTER: 'vendorListFilter',
};

export const formNamesSignup = {
  CONVERT_EXTERNAL: 'signupConvertToRegistered',
  CREATE_ACCOUNT: 'signupCreateAccount',
  CREATE_ACCOUNT_COMPANY: 'signupCreateAccountCompany',
  CREATE_ACCOUNT_USER: 'signupCreateAccountUser',
  SELECT_NAMESPACE: 'signupSelectNamespace',
};

export const formNamesSSO = {
  SETUP_EDIT_SSO: 'setupEditSSO',
};

export const formNamesTags = {
  EXISTING_ITEM_TAGS: 'existingItemTags',
};

export const formNamesWebhooks = {
  CREATE_SUBSCRIPTION: 'webhookSubscriptionCreate',
};
