import debounce from 'es6-promise-debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, getFormValues } from 'redux-form';
import './SelectWorkspace.module.scss';
import { getCurrentCompanyRequest } from 'actions/currentCompany';
import { validateNamespace } from 'actions/signUpFlow';
import { ButtonV2, FormFieldLabel, IconNames, ReduxFormFieldRenderInput } from 'components';
import InputValueHighlighter from 'components/input/InputValueHighlighter';
import { ButtonSize } from 'constants/button';
import { Environment } from 'constants/env';
import { formNamesSignup } from 'constants/forms';
import { ENVIRONMENT_DOMAINS } from 'constants/routes';
import { formStyles, sizes } from 'constants/styles';
import { minCharacterLengthValidator, requiredValidator, subdomainValidator } from 'helpers/fieldValidation';
import { currentCompanyNamespaceSelector } from 'selectors/currentCompanySelectors';
import { isNamespaceValidSelector } from 'selectors/signupSelectors';
import { Intent } from '../../../constants/ui';
import SignUpFormFooter from '../components/SignUpFormFooter';
import SignUpFormHeader from '../components/SignUpFormHeader';
import { vanityClassname, pClassname, pClassnameWithMargin, sectionHeaderClassname, } from '../constants/relevantClassnames';
import NamespaceAvailability from './components/WorkspaceAvailability';
import { VALIDATION_REGEX } from './constants/regex';
import { submit } from './helpers/submit';
const minLengthValidator = minCharacterLengthValidator(4);
const formSelector = getFormValues(formNamesSignup.SELECT_NAMESPACE);
const SelectWorkspace = ({ handleSubmit, invalid, ...props }) => {
    const { isSubmitting } = props;
    const dispatch = useDispatch();
    const [isUserTyping, setIsUserTyping] = useState(false);
    const formValues = useSelector(formSelector);
    const namespace = formValues?.company?.namespace || '';
    const isNamespaceAvailable = useSelector(isNamespaceValidSelector);
    const currentCompanyNamespace = useSelector(currentCompanyNamespaceSelector);
    useEffect(() => {
        dispatch(getCurrentCompanyRequest());
    }, [dispatch]);
    useEffect(() => {
        if (currentCompanyNamespace?.length) {
            dispatch(validateNamespace(namespace));
        }
    }, [currentCompanyNamespace, dispatch, namespace]);
    const handleChange = (event) => {
        dispatch(validateNamespace(event.target.value));
        setIsUserTyping(false);
    };
    const debouncedChange = useCallback(debounce(handleChange, 500), []);
    const setTypingChange = (event) => {
        setIsUserTyping(true);
        debouncedChange(event);
    };
    const isDisabled = invalid || !namespace || !isNamespaceAvailable;
    return (React.createElement("div", { className: "submodule-container" },
        React.createElement("div", { className: "form-container" },
            React.createElement(SignUpFormHeader, { hasSignInOption: false }),
            React.createElement("form", { className: "form", id: formNamesSignup.SELECT_NAMESPACE, onSubmit: handleSubmit((values) => submit(values, props)) },
                React.createElement("h2", null, "Next, name your workspace"),
                React.createElement(FormFieldLabel, { className: sectionHeaderClassname }, "Workspace name"),
                React.createElement("p", { className: pClassname }, "You will use this unique name to log into your Routable account."),
                React.createElement("p", { className: pClassnameWithMargin }, "A default workspace name has been created for you based on your company name."),
                React.createElement("p", { className: pClassnameWithMargin }, "You can change it now or at a later time."),
                React.createElement("div", { className: "form-control", id: "namespace-entry-container" },
                    React.createElement(Field, { className: `${formStyles.field.xl.full} namespace`, component: ReduxFormFieldRenderInput, dataFullStory: true, dataTestId: "namespace", id: "password-entry-field", isDisabled: Boolean(isSubmitting), name: "company.namespace", onChange: setTypingChange, placeholder: "Workspace name", type: "text", validate: [requiredValidator, minLengthValidator, subdomainValidator] },
                        React.createElement(InputValueHighlighter, { regex: VALIDATION_REGEX, value: namespace })),
                    React.createElement("div", { className: "vanity-container" },
                        React.createElement("span", { className: vanityClassname }, `.${ENVIRONMENT_DOMAINS[Environment.PRODUCTION].brandedWorkspaces}`))),
                namespace && !isUserTyping && !formValues.isSubmitting && !invalid && React.createElement(NamespaceAvailability, null),
                React.createElement("div", { className: "form-control remove-margin-bottom", id: "button-control" },
                    React.createElement(ButtonV2, { className: "display--table-cell", dataTestId: "saveAndContinueButton", htmlFor: formNamesSignup.SELECT_NAMESPACE, intent: Intent.PRIMARY, isDisabled: isDisabled, isLoading: Boolean(isSubmitting), rightIconClassName: "margin-left--m", rightIconName: IconNames.ARROW_RIGHT, rightIconSize: sizes.iconSizes.SMALL, size: ButtonSize.MEDIUM, type: "submit" }, "Save and continue"))),
            React.createElement(SignUpFormFooter, null))));
};
export default SelectWorkspace;
