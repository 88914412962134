import { ArrowRight, Flash } from '@carbon/icons-react';
import { Button, useAddToast } from '@routable/gross-ds';
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 } from 'uuid';
import { closeConnectBankTokenModal, openConnectBankTokenModal, updateConnectBankTokenModal, } from 'actions/connectBank';
import { connectBankTokenModalSelector } from 'modules/connectBank/global/ConnectBankContainer';
import PlaidLink from 'modules/plaidLink';
import { fundingAccountBankTokenIsSubmittingSelector } from 'selectors/fundingSelectors';
import createFundingAccountLinkToken from 'thunks/createFundingAccountLinkToken';
import submitFundingAccountBankToken from 'thunks/submitFundingAccountBankToken';
export const ConnectBankAccountViaPlaid = ({ createCheckSource, skipSuccessToast }) => {
    const addToast = useAddToast();
    const dispatch = useDispatch();
    const connectBankTokenModal = useSelector(connectBankTokenModalSelector);
    const isSubmitting = useSelector(fundingAccountBankTokenIsSubmittingSelector);
    const plaidSession = useRef(v4());
    const handleCreateFundingAccountLinkToken = (successCallback, errorCallback) => {
        dispatch(createFundingAccountLinkToken(successCallback, errorCallback));
    };
    const handleCloseBankTokenModal = () => {
        dispatch(closeConnectBankTokenModal());
    };
    const handleSubmitFundingTokenAccount = (publicToken, { account_id, institution, link_session_id }) => {
        dispatch(submitFundingAccountBankToken({
            data: { type: 'FundingAccount' },
            meta: {
                accountId: account_id,
                createCheckSource,
                plaidInstitutionId: institution.institution_id,
                plaidInstitutionName: institution.name,
                plaidLinkSessionId: link_session_id,
                publicToken,
            },
        }, (res) => {
            if (!skipSuccessToast) {
                addToast({ type: 'success', message: 'Bank account added', id: 'plaid-account-toast-success' });
            }
            return res;
        }, (err) => {
            addToast({ type: 'critical', message: 'Failed to add bank account', id: 'plaid-account-toast-danger' });
            return err;
        }));
        handleCloseBankTokenModal();
    };
    const openPlaidLink = () => {
        if (!connectBankTokenModal.open) {
            dispatch(updateConnectBankTokenModal({ plaidLinkToken: null }));
            dispatch(openConnectBankTokenModal({ plaidSessionId: plaidSession.current }));
        }
    };
    return (React.createElement("div", null,
        React.createElement(Button, { className: "!w-full h-auto group gap-4", "data-testid": "btn--connect-bank-account", loading: isSubmitting, onPress: () => openPlaidLink(), variant: "outline" },
            React.createElement(Flash, null),
            React.createElement("span", { className: "inline-flex flex-col flex-1 items-start" },
                React.createElement("span", { className: "font-semibold" }, "Connect your bank account"),
                React.createElement("span", { className: "text-grey-60 group-hover:text-blue-40" }, "Instant verification")),
            React.createElement(ArrowRight, null)),
        React.createElement(PlaidLink, { createFundingAccountLinkToken: handleCreateFundingAccountLinkToken, onSuccess: handleSubmitFundingTokenAccount, sessionId: plaidSession.current })));
};
