import dayjs from 'dayjs';
import { createSelector } from 'reselect';
import { getItemEarApprovals } from 'ducks/itemEarApproval';
import * as extHelpers from 'helpers/external';
import { getItemAttachmentsFromMap } from 'helpers/itemAttachments';
import { isItemKindPayable, isItemKindReceivable, isItemStatusNew } from 'helpers/items';
import * as queryHelpers from 'helpers/queryParams';
import { sortObjectArray } from 'helpers/sort';
import { allValues } from 'helpers/utility';
import { attachmentsSelector } from 'selectors/attachmentsSelectors';
import { idSelector, propsSelector } from 'selectors/globalSelectors';
import { CREATE_ITEM_TABS, DASHBOARD } from '../constants/routes';
import { locationSelector } from './routerSelectors';
const getItemsState = (state) => state.items;
const getAnnotationState = (state) => state.annotations;
const getAnnotationItemsState = (state) => state.annotationLineItems;
export const itemIdSelector = createSelector([idSelector, locationSelector], (id, location) => {
    const isNewBillPath = location.pathname.includes(`${DASHBOARD}/${CREATE_ITEM_TABS.CREATE_BILL}`);
    const itemId = queryHelpers.getQueryParamValueFromUrl(isNewBillPath ? 'id' : 'item_id', location?.search);
    return (typeof id === 'string' && id) || itemId;
});
export const propsItemSelector = createSelector([propsSelector], (props) => props.item);
export const isFetchingSingleItemSelector = createSelector([getItemsState], (itemsState) => itemsState.singleItemRequests.isFetchingSingleItem);
export const selectUseBillActiveItem = createSelector([getItemsState, itemIdSelector], (items, id) => ({
    ...(items?.singleItemRequests.statusById[id] || {
        isLoading: false,
        isError: false,
    }),
    data: items.singleItemRequests.byId[id] ?? null,
}));
export const selectUseBillActiveItemKind = createSelector([selectUseBillActiveItem], (item) => item?.data?.kind);
export const isDeletingItemSelector = createSelector([getItemsState], (items) => items.isDeleting);
export const isFetchingItemSelector = createSelector([getItemsState], (items) => items.isFetching);
export const itemsSubmitSelector = createSelector([getItemsState], (items) => items.submit);
export const isSubmittingItemSelector = createSelector([itemsSubmitSelector], (submit) => submit.isSubmitting);
export const lastSubmittedItemSelector = createSelector([itemsSubmitSelector], (submit) => submit.lastSubmitted);
export const isUpdatingItemSelector = createSelector([getItemsState], (items) => items.isUpdating);
export const itemsSelector = createSelector([getItemsState], (items) => items?.byId);
export const itemsAllIdsSelector = createSelector([getItemsState], (items) => items.allIds);
export const itemsArraySelector = createSelector([itemsSelector, getItemEarApprovals], (byId, approvals) => allValues(byId).map((item) => ({
    ...item,
    approval: approvals?.approvals[item.id],
})));
export const itemsErrorsSelector = createSelector([getItemsState], (items) => items.errors);
export const itemsPaginationSelector = createSelector([getItemsState], (items) => items.pagination);
export const itemPaginationCountSelector = createSelector([itemsPaginationSelector], (pagination) => pagination.count);
export const itemPaginationCurrentSelector = createSelector([itemsPaginationSelector], (pagination) => pagination.current);
export const itemSelector = createSelector([itemsSelector, itemIdSelector], (items, itemId) => items?.[itemId]);
export const itemMembersForItemSelector = createSelector([itemSelector], (item) => item.itemMembers);
export const partnershipItemsSelector = createSelector([itemsSelector, idSelector], (items, partnershipId) => sortObjectArray(allValues(items).filter((item) => item.partnership === partnershipId), 'created', { comparisonFunc: dayjs }));
export const partnershipPayableItemsSelector = createSelector([partnershipItemsSelector], (partnershipItems) => partnershipItems.filter(isItemKindPayable));
export const partnershipReceivableItemsSelector = createSelector([partnershipItemsSelector], (partnershipItems) => partnershipItems.filter(isItemKindReceivable));
export const partnershipNewReceivableItemsSelector = createSelector([partnershipItemsSelector], (partnershipItems) => partnershipItems.filter((item) => isItemKindReceivable(item) && isItemStatusNew(item)));
export const partnershipFilteredItemsSelector = createSelector([partnershipPayableItemsSelector, partnershipReceivableItemsSelector], (payables, receivables) => {
    const itemId = queryHelpers.getQueryParam('item_id');
    const partnershipItems = extHelpers.isExternalPathPay() ? payables : receivables;
    return partnershipItems.filter((item) => !itemId || item.id !== itemId);
});
export const createPartnershipFilteredItemsSelector = (itemId) => createSelector([partnershipPayableItemsSelector, partnershipReceivableItemsSelector], (payables, receivables) => {
    const partnershipItems = extHelpers.isExternalPathPay() ? payables : receivables;
    return partnershipItems.filter((item) => !itemId || item.id !== itemId);
});
export const itemAttachmentsSelector = createSelector([itemSelector, attachmentsSelector], getItemAttachmentsFromMap);
export const itemUploadBillsSelector = createSelector([getItemsState], (items) => items.uploadBills);
export const itemSearchSelector = createSelector([getItemsState], (items) => items.search);
export const annotationsAllIdsSelector = createSelector([getAnnotationState], (items) => items.allIds);
export const annotationsByIdsSelector = createSelector([getAnnotationState], (items) => items?.byId);
export const annotationLineItemsByIdSelector = createSelector([getAnnotationItemsState], (items) => items?.byId);
export const consolidatedLineItemsSelector = createSelector([itemSelector], (item) => item?.fieldProvenanceMap?.consolidatedLineItems);
