import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { reduxFormInputPropType } from 'components/commonProps';
import { ArrowRenderer, ClearRenderer } from 'components/form';
import { SelectFieldV2, selectComponents } from 'components/selectV2';
import { CreateOptionPosition } from 'components/selectV2/utils/constants';

import { sizes } from 'constants/styles';

import { returnFullOptionObjectAsValueForSelects } from 'helpers/selects';
import { allValues, callIfIsFn, callWithArgsIfIsFn } from 'helpers/utility';

import { TagMultiValueLabel, TagMultiValueRemove, TagMultiValueContainer } from './components';

/**
 * Multi-selection (react-select) component that displays selected values as tags.
 * @param {Object} props
 * @param {boolean} [props.backspaceRemovesValue] - When a user types the backspace, does this remove the value?
 * @param {boolean} [props.closeMenuOnSelect] - Should the menu be closed when a selection is made?}
 * @param {string} [props.className] - className to apply to the Select component
 * @param {Object} props.components - Provide other components to override subcomponents within the Select
 * @param {string} [props.createOptionPosition='last'] - Where should the option to create a new resource be placed in
 * @param {boolean} [props.hasErrors=false] - No idea why this is being passed in and not used so adding it in below.
 * the list of options, first or last?
 * @param {*} [props.defaultValue] - Default value for the Select
 * @param {string|string[]} [props.errors] - Field errors
 * @param {Function} [props.getOptionLabel] - Getter to determine the label for the option object
 * @param {Function} [props.getOptionValue] - Getter to determine the value for the option object
 * @param {boolean} [props.hideSelectedOptions] - Should options that have already been selected be hidden from the
 * dropdown?
 * @param {boolean} [props.isCreatable] - Should we show an option to create a new resource in the dropdown?
 * @param {boolean} [props.isSearchable] - Should you be able to search to filter the options?
 * @param {string} props.label - Label for the Select
 * @param {StringMaybe} [props.name] - Form name for the Select
 * @param {function} [props.onChange] - Callback which handles what should happen when the selection changes
 * @param {function} [props.onClosePopover] - Callback to close the popover, if provided
 * @param {function} [props.onCreate] - Callback which handles what should happen when a user clicks the "create a new
 * {resource}" option at the bottom or top of the select
 * @param {Array<TagMultiSelectOption>} [props.options=[]] - Options to display in the Select dropdown
 * @param {Object|function} [props.setRef] - ref for parent component to pass down into Select to get access to the
 * onClose method in the base Select component
 * @param {Object} [props.styles={}] - Extra styles to apply to the Select
 * @param {Object[]|null} [props.value] - A list of selected options
 * @param {string} [props.valueKey='value'] - Which key holds the value on the option object
 * @return {StatelessComponent}
 */
export const TagMultiSelect = ({
  backspaceRemovesValue,
  className,
  components,
  createOptionPosition,
  dataFullStory,
  hideSelectedOptions,
  isCreatable,
  isSearchable,
  input,
  label,
  onClosePopover,
  onCreate,
  options,
  parseValue,
  setRef,
  styles,
  hasErrors,
  isOpen,
  ...rest
}) => {
  const { name } = input;

  return (
    <SelectFieldV2
      {...rest}
      backspaceRemovesValue={backspaceRemovesValue}
      className={clsx('tag-multi-select select-alt', className)}
      components={{
        ClearIndicator: ClearRenderer,
        DropdownIndicator: ArrowRenderer,
        Option: selectComponents.VerboseOption,
        MultiValueContainer: TagMultiValueContainer,
        MultiValueLabel: TagMultiValueLabel,
        MultiValueRemove: TagMultiValueRemove,
        ...components,
      }}
      createOptionPosition={createOptionPosition}
      dataFullStory={dataFullStory}
      hasErrors={hasErrors}
      hideSelectedOptions={hideSelectedOptions}
      idPrefix={name}
      input={input}
      isClearable
      isCreatable={isCreatable}
      isMulti
      isOpen={isOpen}
      isSearchable={isSearchable}
      label={label}
      onCreate={(values) => {
        callWithArgsIfIsFn(onCreate, values);
        callIfIsFn(onClosePopover);
      }}
      options={options}
      parseValue={parseValue}
      ref={setRef}
      // strip or alter styles from some of the built-in react-select components
      // (keep all of their functionality, but remove aesthetic)
      styles={{
        indicatorSeparator: () => ({ display: 'none' }),
        input: (provided) => ({
          ...provided,
          marginTop: '0',
          maxHeight: sizes.tagHeight,
        }),
        multiValueRemove: () => ({}),
        ...styles,
      }}
    />
  );
};

TagMultiSelect.propTypes = {
  autoFocus: PropTypes.bool,
  backspaceRemovesValue: PropTypes.bool,
  className: PropTypes.string,
  components: PropTypes.shape({
    MultiValue: PropTypes.elementType,
    MultiValueLabel: PropTypes.elementType,
    MultiValueRemove: PropTypes.elementType,
    Option: PropTypes.elementType,
    MultiValueContainer: PropTypes.elementType,
  }),
  closeMenuOnSelect: PropTypes.bool,
  createOptionPosition: PropTypes.oneOf(allValues(CreateOptionPosition)),
  dataFullStory: PropTypes.bool,
  defaultValue: PropTypes.shape(),
  hasErrors: PropTypes.bool,
  hideSelectedOptions: PropTypes.bool,
  isCreatable: PropTypes.bool,
  isRequired: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isOpen: PropTypes.bool,
  input: reduxFormInputPropType.isRequired,
  label: PropTypes.string.isRequired,
  onClosePopover: PropTypes.func,
  onCreate: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape()),
  parseValue: PropTypes.func,
  placeholder: PropTypes.string,
  setRef: PropTypes.oneOfType([PropTypes.shape(), PropTypes.func]),
  styles: PropTypes.shape({ selectWrapper: PropTypes.shape({}) }),
};

TagMultiSelect.defaultProps = {
  autoFocus: undefined,
  backspaceRemovesValue: undefined,
  className: undefined,
  // By default, react-select will close the menu by default when a selection is made. For TagMultiSelect, closing after
  // each selection is counterproductive
  // eslint-disable-next-line routable/default-props-prefer-undefined
  closeMenuOnSelect: undefined,
  components: {},
  createOptionPosition: CreateOptionPosition.LAST,
  dataFullStory: undefined,
  defaultValue: undefined,
  hideSelectedOptions: true,
  hasErrors: undefined,
  isOpen: undefined,
  isCreatable: undefined,
  isRequired: undefined,
  isSearchable: undefined,
  onClosePopover: undefined,
  onCreate: undefined,
  options: [],
  parseValue: returnFullOptionObjectAsValueForSelects,
  placeholder: undefined,
  setRef: undefined,
  styles: {},
};

export default TagMultiSelect;
