import { Clickable } from '@routable/gross-ds';
import React from 'react';
import { ITEM_DETAILS_FILTERS } from 'enums/routes';
import { makeSimilarUrl } from 'helpers/urls';
import {} from '../../ContactRequiredToAllowSelectPaymentMethodHint.types';
export const FinalizePaymentHintTextComponent = ({ history, onCloseExistingItemSidePanel, }) => {
    const handleClick = () => {
        history.push(makeSimilarUrl(ITEM_DETAILS_FILTERS.PARTNER));
        onCloseExistingItemSidePanel();
    };
    return (React.createElement("span", { className: "contact-required-to-select-payment-hint" },
        "To allow a vendor to select their payment method, please",
        ' ',
        React.createElement(Clickable, { className: "text-blue-40 hover:no-underline hover:cursor-pointer hover:text-blue-50 visited:text-blue-40", onClick: handleClick }, "add a contact to this payment"),
        "."));
};
