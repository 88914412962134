import {} from '@routable/shared';
import { apiJsonApiCall, convertToSnakeCase } from '@routable/framework';
import { partnershipRequestPatchApiResponseModel } from '../models/partnershipRequest.model';
export const partnershipRequestsService = {
    updateExternalVendorCountry: async (partnershipId, countryCode) => apiJsonApiCall({
        url: `/partnership_requests/${partnershipId}/country/`,
        method: 'patch',
        body: convertToSnakeCase({
            data: {
                type: 'PartnershipRequest',
                id: partnershipId,
                attributes: { countryCode },
            },
        }),
        responseModel: partnershipRequestPatchApiResponseModel,
    }),
};
