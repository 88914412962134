import { useQueryPagination } from '@routable/framework';
import { queryContext } from '@routable/shared';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { itemEarApprovalsRoutine } from 'ducks/itemEarApproval';
import { fetchItemsRoutine } from 'actions/routines/item';
import { PAGE_SIZE } from 'constants/persistence';
import { TABLE_DEFAULT_PAGE_SIZE } from 'constants/tables';
import { ItemStatuses } from 'enums/items';
import { usePersistentValue } from 'hooks/usePersistentValue';
import { getItemsById } from 'reducers/itemsReducer/itemsByIdReducer';
import { isFetchingItemSelector, itemsSelector } from 'selectors/itemsSelectors';
import { itemsFiltersModel } from '../items.models';
import { itemsService } from '../items.service';
export const useItemsQuery = ({ params, tableName, }) => {
    const dispatch = useDispatch();
    const isFetching = useSelector(isFetchingItemSelector);
    const itemsById = useSelector(itemsSelector);
    const [pageSizeFromStorage, persistPageSize] = usePersistentValue({
        id: `${tableName}${PAGE_SIZE}`,
        defaultValue: TABLE_DEFAULT_PAGE_SIZE,
        useMembershipId: false,
    });
    const [pagination, setPagination] = useState({
        page: params?.page ?? 1,
        pageSize: pageSizeFromStorage,
    });
    const { page, pageSize } = pagination;
    const sort = params?.sort;
    const onSetPagination = (pag) => {
        if (pagination.page !== pag.page || pagination.pageSize !== pag.pageSize) {
            setPagination(pag);
            persistPageSize(pag.pageSize);
        }
    };
    const { kind, ...uiFilters } = params.filters;
    const parsedInitialFilters = itemsFiltersModel.safeParse(uiFilters);
    const parsedFilters = parsedInitialFilters.success ? parsedInitialFilters.data : {};
    const filters = Object.fromEntries(Object.entries(parsedFilters).filter(([, v]) => v === false || !!v));
    const results = useQuery({
        context: queryContext,
        queryFn: async () => itemsService.getAll({
            ...filters,
            kind,
            sort,
            page: {
                number: page,
                size: pageSize,
            },
        }),
        queryKey: ['items', kind, filters, sort, page, pageSize],
    });
    useEffect(() => {
        if (results.isFetching) {
            dispatch(fetchItemsRoutine.request());
        }
    }, [dispatch, results.isFetching]);
    useEffect(() => {
        if (results.data) {
            if (filters.status?.includes?.(ItemStatuses.NEEDS_APPROVAL)) {
                dispatch(itemEarApprovalsRoutine(Object.keys(results.data?.item || {})));
            }
            const items = getItemsById(results.data.item, itemsById, { payload: results.data });
            dispatch(fetchItemsRoutine.fulfill(items));
            dispatch(fetchItemsRoutine.success(results.data));
        }
    }, [dispatch, results.data]);
    useEffect(() => {
        if (results.isError) {
            dispatch(fetchItemsRoutine.failure());
        }
    }, [dispatch, results.isError]);
    const paginationData = useQueryPagination({
        page,
        pageSize,
        setPagination: onSetPagination,
        totalRecords: results.data?.meta?.pagination?.count,
    }, [`items-${tableName}-pagination`, ...Object.values(filters)]);
    return {
        ...results,
        isFetching: results.isFetching || isFetching,
        ...paginationData,
    };
};
