import { PaymentDeliveryMethodType } from '@routable/shared';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { ItemKinds } from 'enums/items';
import { hasLength } from 'helpers/utility';
import { useSelectorWithProps } from 'hooks';
import { getPaymentMethodsAccepted } from 'modules/createPartnerCompany/helpers/paymentMethods';
import { AddBankAccountManuallyButton } from 'modules/fundingAccount';
import { itemFromQuerySelector } from 'queries/itemRouterSelectors';
import { partnershipFromQuerySelector } from 'queries/partnershipCompoundSelectors';
import { GetPaidViaPayPal } from '../../../GetPaidViaPayPal';
import { AcceptPendingPaymentsCheckboxField, UpdatePaymentMethodFundingAccounts, UpdatePaymentFlowFormLabel, } from './components';
import {} from './UpdatePaymentMethodInternationalLayout.types';
const UpdatePaymentMethodInternationalLayout = ({ fundingAccounts, isUpdatePaymentMethodFlow, }) => {
    const location = useLocation();
    const item = useSelectorWithProps(itemFromQuerySelector, { location });
    const partnership = useSelectorWithProps(partnershipFromQuerySelector, { location });
    const paymentDeliveryMethodsAccepted = getPaymentMethodsAccepted(partnership, ItemKinds.RECEIVABLE, item);
    const hasFundingAccounts = hasLength(fundingAccounts);
    const acceptPaypal = paymentDeliveryMethodsAccepted.includes(PaymentDeliveryMethodType.PAYPAL);
    if (isUpdatePaymentMethodFlow && hasFundingAccounts) {
        return (React.createElement(React.Fragment, null,
            React.createElement(UpdatePaymentFlowFormLabel, null),
            React.createElement("div", { className: "flex flex-col gap-3" },
                React.createElement(AddBankAccountManuallyButton, null),
                acceptPaypal && React.createElement(GetPaidViaPayPal, null)),
            React.createElement(UpdatePaymentFlowFormLabel, { className: "mt-8", labelText: "Select preferred payment method" }),
            React.createElement(UpdatePaymentMethodFundingAccounts, { fundingAccounts: fundingAccounts }),
            React.createElement(AcceptPendingPaymentsCheckboxField, null)));
    }
    if (hasFundingAccounts) {
        return (React.createElement(React.Fragment, null,
            React.createElement(UpdatePaymentMethodFundingAccounts, { fundingAccounts: fundingAccounts }),
            React.createElement(AcceptPendingPaymentsCheckboxField, null),
            React.createElement(UpdatePaymentFlowFormLabel, { className: "mt-8" }),
            React.createElement("div", { className: "flex flex-col gap-3" },
                React.createElement(AddBankAccountManuallyButton, null),
                acceptPaypal && React.createElement(GetPaidViaPayPal, null))));
    }
    return (React.createElement("div", { className: "flex flex-col gap-3" },
        React.createElement(AddBankAccountManuallyButton, null),
        acceptPaypal && React.createElement(GetPaidViaPayPal, null)));
};
export default UpdatePaymentMethodInternationalLayout;
