import { apiCall, convertToSnakeCase } from '@routable/framework';
import { validateInternationalBeneficiaryApiResponse, validateInternationalBeneficiaryErrorApiResponse, } from '../models';
export const internationalBeneficiaryService = {
    validateBeneficiary: (data) => apiCall({
        method: 'post',
        url: 'funding_accounts/verify/',
        body: convertToSnakeCase(data),
        responseModel: validateInternationalBeneficiaryApiResponse,
        errorResponseModel: validateInternationalBeneficiaryErrorApiResponse,
    }),
};
