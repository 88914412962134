import { z } from 'zod';
export const internationalBankFieldCategory = z.enum(['bank', 'bank_extra', 'beneficiary']);
export const internationalBeneficiaryFieldName = z.enum([
    'country_of_nationality',
    'in_fcra',
    'mx_curp',
    'mx_rfc',
    'phone_number',
    'residential_status',
    'ar_cuil',
    'ar_cuit',
    'br_cnpj',
    'br_cpf',
    'cl_rut',
    'co_cc',
    'co_nit',
    'bank_account_owner_name',
    'cr_cpf',
    'cr_cpj',
    'email',
    'gt_cui',
    'gt_nit',
    'hn_id',
    'hn_rtn',
    'kz_bin',
    'kz_bnf',
    'kz_iin',
    'kr_brn',
    'kr_rrn',
    'pk_cnic',
    'pk_ntn',
    'ro_cnp',
    'ro_cui',
]);
export const internationalBeneficiaryFieldModel = z.object({
    category: z.literal(internationalBankFieldCategory.Enum.beneficiary),
    field: internationalBeneficiaryFieldName,
});
const internationalBankFieldName = z.enum([
    'aba',
    'account_number',
    'bank_code',
    'bic_swift',
    'branch_code',
    'bsb_code',
    'clabe',
    'cnaps',
    'iban',
    'ifsc',
    'sort_code',
]);
export const internationalBankCategoryFieldModel = z.object({
    category: z.literal(internationalBankFieldCategory.Enum.bank),
    field: internationalBankFieldName,
});
const internationalBankExtraFieldName = z.enum(['account_type']);
export const internationalBankExtraFieldModel = z.object({
    category: z.literal(internationalBankFieldCategory.Enum.bank_extra),
    field: internationalBankExtraFieldName,
});
export const internationalBankFieldModel = z.discriminatedUnion('category', [
    internationalBankCategoryFieldModel,
    internationalBankExtraFieldModel,
    internationalBeneficiaryFieldModel,
]);
export const internationalBankFieldsApiResponseModel = z.object({
    data: z.array(internationalBankFieldModel),
});
