import { DateFormats, DayjsPrecisionValues } from '@routable/shared';
import dayjs from 'dayjs';

import { DateScheduledTypes, TimeFrames, TimeUnit, TimeZoneId } from 'constants/temporal';

import * as math from './math';

/**
 * Get the current local date.
 * @return {import('dayjs').Dayjs}
 */
export const getCurrentDateLocal = () => dayjs().local();

/**
 * Get formatted date in local time
 * @param {Date|string|number} date
 * @param {string} format
 * @return {string}
 */
export const getDate = (date, format = DateFormats.SLASH_DATE_SHORT) => {
  if (!date) {
    return null;
  }

  if (format === 'calendar') {
    return dayjs(date).calendar();
  }

  return dayjs(date).format(format);
};

/**
 * Get formatted object date.
 * @param {object} obj
 * @param {string} dateKey
 * @param {string} format
 * @return {string}
 */
export const getObjDate = (obj, dateKey, format = DateFormats.SLASH_DATE_SHORT) => {
  if (!obj || !dateKey || !obj[dateKey]) {
    return null;
  }

  const desiredDate = obj[dateKey];

  return getDate(desiredDate, format);
};

/**
 * Get a date in a from now form
 * @param {Object} obj - Object containing date(s)
 * @param {string} dateKey - Date key within the object
 * @return {string}
 */
export const getObjDateFromNow = (obj, dateKey) => dayjs(obj[dateKey]).fromNow();

/**
 * Get a number of hours, days, months, years, etc. from now to a date object.
 * @param {object} dateObj
 * @return {string}
 */
export const getDateFromNow = (dateObj) => dayjs(dateObj).fromNow();

/**
 * Detect if a date object is between two other dates.
 * @param {object} date
 * @param {object} start
 * @param {object} end
 * @return {string}
 */
export const isDateBetween = (date, start, end) => dayjs(date) >= dayjs(start) && dayjs(date) <= dayjs(end);

/**
 * Detect if the given date is today (match on year, month, and day).
 * @param {string|Object} date
 * @return {boolean}
 */
export const isDateToday = (date) => dayjs(date).isSame(dayjs(), DayjsPrecisionValues.DAY);

/**
 * Returns true if given date is before as the date that we compare to
 * @param {string | import('dayjs').Dayjs | Date} date - Date string | Dayjs | Date
 * @param {string | import('dayjs').Dayjs | Date} compareDate - Date (string | Dayjs | Date) to compare
 * @param {import('@routable/shared').DayjsPrecisionValues)} [precision=day] - Sets the precision parameter for comparison.
 * @return {boolean}
 */
export const isDateBefore = (date, compareDate, precision = DayjsPrecisionValues.DAY) =>
  dayjs(date).isBefore(compareDate, precision);

/**
 * Returns true if given date is before or the same as date that we compare to
 * @param {string} date - Date string
 * @param {string} compareDate - Date string to compare
 * @param {import('@routable/shared').DayjsPrecisionValues)} [precision=day] - Sets the precision parameter for comparison.
 * @return {boolean}
 */
export const isDateBeforeOrEqual = (date, compareDate, precision = DayjsPrecisionValues.DAY) =>
  dayjs(date).isSameOrBefore(compareDate, precision);

/**
 * getDateAndTime
 * given a timestamp returns the date and time formatted as: Oct 15th 2020 at 3:18 pm
 * @param {string} timestamp
 * @return {string}
 */
export const getDateAndTime = (timestamp) => dayjs(timestamp).format(DateFormats.LOCAL_DATE_ORDINAL_SHORT_WITH_TIME);

export const getStartEndDatesForTimeFrame = (timeFrame) => {
  let timeframe = timeFrame;
  // Allowed timeframes
  const allowedTimeframes = [TimeFrames.TODAY, TimeFrames.YESTERDAY, TimeFrames.CURRENT_WEEK, TimeFrames.CURRENT_MONTH];

  // set the timeframe (ensuring it's allowed - if not, use the default)
  timeframe = allowedTimeframes.includes(timeframe) ? timeframe : false;

  // init return vars
  let start;
  let end;

  // Define the start and end date given the timeframe selected
  switch (timeframe) {
    case TimeFrames.TODAY:
      // Start and end date are the edges of today in local time
      start = dayjs().startOf(DayjsPrecisionValues.DAY);
      end = dayjs().endOf(DayjsPrecisionValues.DAY);
      break;

    case TimeFrames.YESTERDAY:
      // Start and end date are the edges of yesterday in local time
      start = dayjs().subtract(1, 'days').startOf(DayjsPrecisionValues.DAY);
      end = dayjs().endOf(DayjsPrecisionValues.DAY);
      break;

    case TimeFrames.CURRENT_WEEK:
      // Start and end date are the edges of the week in local time
      start = dayjs().subtract(7, 'days').startOf(DayjsPrecisionValues.DAY);
      end = dayjs().endOf(DayjsPrecisionValues.DAY);
      break;

    case TimeFrames.CURRENT_MONTH:
      // Start and end date are the edges of the week in local time
      start = dayjs().subtract(30, 'days').startOf(DayjsPrecisionValues.DAY);
      end = dayjs().endOf(DayjsPrecisionValues.DAY);
      break;

    default:
      break;
  }

  return { start: start.toISOString(), end: end.toISOString() };
};

/**
 * !!NOTE!! [DEV-15462]
 * This is currently a duplicate function. Until it is removed per the above
 * ticket, changes made to it also need to be made in packages/shared.
 *
 * Format a date/dayjs object as a string date
 * @param {object} dateObj
 * @param {string} format
 * @return {string}
 */
export const formatDate = (dateObj, format = DateFormats.FULL_NUMERIC_YEAR_MONTH_DAY) => dayjs(dateObj).format(format);

/**
 * !!NOTE!! [DEV-15462]
 * This is currently a duplicate function. Until it is removed per the above
 * ticket, changes made to it also need to be made in packages/shared.
 *
 * Returns a date string in the given format
 * @param {string | Date} dateStr
 * @param {string} format
 * @return {string}
 */
export const formatDateString = (dateStr, format = DateFormats.FULL_NUMERIC_YEAR_MONTH_DAY) =>
  formatDate(dayjs(dateStr), format);

/**
 * !!NOTE!! [DEV-15462]
 * This is currently a duplicate function. Until it is removed per the above
 * ticket, changes made to it also need to be made in packages/shared.
 *
 * Returns a date string in the given format, as long as the string provided produces a valid dayjs object.
 * @param {string} dateStr
 * @param {string} format
 * @return {StringMaybe}
 */
export const formatValidDateString = (dateStr, format = DateFormats.FULL_NUMERIC_YEAR_MONTH_DAY) => {
  const dayjsDate = dayjs(dateStr);

  if (dayjsDate.isValid()) {
    return formatDate(dayjsDate, format);
  }

  return undefined;
};

/**
 * Get a date in a from now form
 * @param {Object} a - Dayjs object (base)
 * @param {Object} b - Dayjs object to subtract (past date will give positive, future negative)
 * @param {String} unit - Unit of time to return diff in, defaults to 'seconds'
 * @return {number}
 */
export const getDiff = (a, b, unit = 'seconds') => a.diff(b, unit);

/**
 * Return an age in years from a dayjs date object
 * @param {object} birthDateObj
 * @return {number}
 */
export const getAge = (birthDateObj) => getDiff(dayjs(), birthDateObj, 'years');

/**
 * Check if given date occurs after the time right now. Useful in checking if a time cutoff has occurred.
 * @example
 * // currently 2020-01-09T01:57:37Z
 * const cancelNormal = dayjs(2020-01-09T01:57:38.492326Z);
 * isAfter(cancelNormal); // true
 *
 * // currently 2020-01-09T01:57:39Z
 * isAfter(cancelNormal); // false
 * @param {import('dayjs').Dayjs|string} time
 * @returns {boolean} - True if time is later than the current time
 */
export const isAfterNow = (time) => {
  const now = dayjs().tz(TimeZoneId.UTC);
  const cutoffTime = dayjs(time).tz(TimeZoneId.UTC);

  return cutoffTime.isAfter(now);
};

/**
 * Check whether the current date scheduled type is today
 * @param dateScheduledType
 * @return {boolean}
 * @deprecated This helper is deprecated, please refer to helpers/temporal
 */
export const isDateScheduledTypeToday = (dateScheduledType) => dateScheduledType === DateScheduledTypes.TODAY;

/**
 * Convenience object for to/from time unit conversions.
 * @example
 * // using 'to' property
 * const secondsToMillis = convertTime.seconds.to.millis(11);
 * > 11000
 *
 * @example
 * // using 'from' property
 * const millisFromSeconds = convertTime.millis.from.seconds(11);
 * > 11000
 *
 * @type {Object}
 */
export const convertTime = {
  millis: {
    to: {
      seconds: math.millisToSec,
      minutes: math.millisToMin,
      hours: math.millisToHours,
    },
    from: {
      seconds: math.secToMillis,
      minutes: math.minToMillis,
      hours: math.hoursToMillis,
    },
  },
  seconds: {
    to: {
      millis: math.secToMillis,
      minutes: math.secToMin,
      hours: math.secToHours,
    },
    from: {
      millis: math.millisToSec,
      minutes: math.minToSec,
      hours: math.hoursToSec,
    },
  },
  minutes: {
    to: {
      millis: math.minToMillis,
      seconds: math.minToSec,
      hours: math.minToHours,
    },
    from: {
      millis: math.millisToMin,
      seconds: math.secToMin,
      hours: math.hoursToMin,
    },
  },
  hours: {
    to: {
      millis: math.hoursToMillis,
      seconds: math.hoursToSec,
      minutes: math.hoursToMin,
    },
    from: {
      millis: math.millisToHours,
      seconds: math.secToHours,
      minutes: math.minToHours,
    },
  },
};

/**
 * For the given time string, e.g. '21:30:00', returns the specified unit of time
 * as an integer.
 *
 * @example
 * const secondsComponent = getTimeComponentFromString('21:30:22', TimeUnit.SECONDS);
 * > 22
 *
 * @param {string} timeStr
 * @param {TimeUnitName} unit
 * @return {number}
 */
export const getTimeComponentFromString = (timeStr = '', unit = TimeUnit.SECONDS) => {
  let componentIndex;

  switch (unit) {
    case TimeUnit.HOURS:
      componentIndex = 0;
      break;

    case TimeUnit.MINUTES:
      componentIndex = 1;
      break;

    case TimeUnit.SECONDS:
      componentIndex = 2;
      break;

    default:
      return 0;
  }

  const time = timeStr.split(':')[componentIndex];

  if (time) {
    return parseInt(time, 10);
  }

  return 0;
};

/**
 * If provided value exists, returns a dayjs object, otherwise returns the falsy value.
 * @param {T} value
 * @param {String} [format]
 * @return {import('dayjs').Dayjs|T}
 * @template T
 */
export const getDayjsObjectIfValue = (value, format) => (value ? dayjs(value, format) : value);

/**
 * !!NOTE!! [DEV-15462]
 * This is currently a duplicate function. Until it is removed per the above
 * ticket, changes made to it also need to be made in packages/shared.
 *
 * If fromDateInitializer is given (which can be any value usable as the initializer for a date),
 * returns it as an ISO formatted date string.
 * If no argument is provided, returns Date.now() as an ISO formatted date string.
 * @param {string|number|Date} [fromDateInitializer]
 * @return {string}
 */
export const toIsoString = (fromDateInitializer) => {
  const initDate = fromDateInitializer || Date.now();
  return new Date(initDate).toISOString();
};

/**
 * If fromDateInitializer is given (which can be any value usable as the initializer for a date),
 * returns it as an ISO formatted date string that is valid for filenames. (YYYYMMDDTHHmmss)
 * If no argument is provided, returns Date.now() formatted date string.
 * @param {string|number|Date} [fromDateInitializer]
 * @returns {string}
 */
export const getISOFilenameFormatString = (fromDateInitializer) => {
  const initDate = fromDateInitializer || Date.now();
  return dayjs(initDate).format('YYYYMMDDTHHmmss');
};

/**
 * When creating an item with some ledgers, we have a time-only field. This is an equality matcher to see if the value
 * we have is from one of those fields.
 *
 * @param {*} [value='']
 * @returns {boolean}
 */
export const isTimeOnlyValue = (value = '') =>
  typeof value === 'string' &&
  // matches '11:12:42'
  Boolean(value.match(/\d{2}:\d{2}:\d{2}/)) &&
  // but not '2021-10-22T11:12:42'
  !value.match(/\S+\d{2}:\d{2}:\d{2}/);

/**
 * Just checks to see if the string passed in is a valid date or not.
 *
 * @param {*} [value]
 * @returns {boolean}
 */
export const isValidDate = (dateString) => dayjs(dateString).isValid();

export const getDateStringFromDays = (numOfdays = 1, now = dayjs()) =>
  now.add(numOfdays, 'd').format(DateFormats.FULL_NUMERIC_YEAR_MONTH_DAY);

/**
 * Given a date string will return number days since today
 * @param {string} dateStr Date string in YYYY-MM-DD format
 * @returns {number}
 */
export function daysFromToday(dateStr, today = new Date()) {
  const date = new Date(dateStr);
  const oneDay = 24 * 60 * 60 * 1000; // One day in milliseconds

  // Get the difference in days between the two dates
  const diffDays = Math.ceil((date.getTime() - today.getTime()) / oneDay);

  return diffDays;
}
