import { internationalBeneficiaryFieldName } from '@routable/fund-routes';
import React from 'react';
import { BeneficiaryInputField } from '../BeneficiaryInputField';
import { BeneficiaryPhoneNumberField } from '../BeneficiaryPhoneNumberField';
import { BeneficiarySelectField } from '../BeneficiarySelectField';
import { NationalityField } from '../NationalityField';
import { NonGovermentOrganizationFields } from '../NonGovermentOrganizationFields';
import { ResidentialStatusField } from '../ResidentialStatusField';
import { kzBnfFieldOptions } from './InternationalPartnerFundingAccountBeneficiaryDetails.constants';
export const InternationalPartnerFundingAccountBeneficiaryDetails = ({ defaultValues, fields, formName, labels, }) => {
    return (React.createElement("div", { className: "flex flex-col gap-5 mt-5" },
        fields.country_of_nationality && (React.createElement(NationalityField, { defaultValue: defaultValues?.country_of_nationality, form: formName })),
        fields.bank_account_owner_name && (React.createElement(BeneficiaryInputField, { field: internationalBeneficiaryFieldName.Enum.bank_account_owner_name, form: formName, placeholder: "Bank account owner name" })),
        fields.email && (React.createElement(BeneficiaryInputField, { field: internationalBeneficiaryFieldName.Enum.email, form: formName, placeholder: "Email Address" })),
        fields.phone_number && (React.createElement(BeneficiaryPhoneNumberField, { defaultCountry: defaultValues?.phone_number, form: formName })),
        fields.in_fcra && React.createElement(NonGovermentOrganizationFields, { form: formName, label: labels?.in_fcra }),
        fields.residential_status && React.createElement(ResidentialStatusField, { form: formName }),
        fields.mx_curp && (React.createElement(BeneficiaryInputField, { field: internationalBeneficiaryFieldName.Enum.mx_curp, form: formName, placeholder: "CURP (Clave \u00DAnica de Registro de Poblaci\u00F3n)" })),
        fields.mx_rfc && (React.createElement(BeneficiaryInputField, { field: internationalBeneficiaryFieldName.Enum.mx_rfc, form: formName, placeholder: "RFC (Registro Federal de Contribuyentes)" })),
        fields.ar_cuil && (React.createElement(BeneficiaryInputField, { field: internationalBeneficiaryFieldName.Enum.ar_cuil, form: formName, placeholder: "CUIL (C\u00F3digo \u00DAnico de Identificaci\u00F3n Laboral)" })),
        fields.ar_cuit && (React.createElement(BeneficiaryInputField, { field: internationalBeneficiaryFieldName.Enum.ar_cuit, form: formName, placeholder: "CUIT (C\u00F3digo \u00DAnico de Identificaci\u00F3n Tributaria)" })),
        fields.br_cnpj && (React.createElement(BeneficiaryInputField, { field: internationalBeneficiaryFieldName.Enum.br_cnpj, form: formName, placeholder: "CNPJ (Cadastro Nacional da Pessoa Jur\u00EDdica)" })),
        fields.br_cpf && (React.createElement(BeneficiaryInputField, { field: internationalBeneficiaryFieldName.Enum.br_cpf, form: formName, placeholder: "CPF (Cadastro de Pessoas F\u00EDsicas)" })),
        fields.cl_rut && (React.createElement(BeneficiaryInputField, { field: internationalBeneficiaryFieldName.Enum.cl_rut, form: formName, placeholder: "RUT (Rol \u00DAnico Tributario)" })),
        fields.co_cc && (React.createElement(BeneficiaryInputField, { field: internationalBeneficiaryFieldName.Enum.co_cc, form: formName, placeholder: "CC (C\u00E9dula de Ciudadan\u00EDa)" })),
        fields.co_nit && (React.createElement(BeneficiaryInputField, { field: internationalBeneficiaryFieldName.Enum.co_nit, form: formName, placeholder: "NIT (N\u00FAmero de Identificaci\u00F3n Tributaria)" })),
        fields.cr_cpf && (React.createElement(BeneficiaryInputField, { field: internationalBeneficiaryFieldName.Enum.cr_cpf, form: formName, placeholder: "CPF (C\u00E9dula de Persona F\u00EDsica)" })),
        fields.cr_cpj && (React.createElement(BeneficiaryInputField, { field: internationalBeneficiaryFieldName.Enum.cr_cpj, form: formName, placeholder: "CPJ (C\u00E9dula de Persona Jur\u00EDdica)" })),
        fields.gt_cui && (React.createElement(BeneficiaryInputField, { field: internationalBeneficiaryFieldName.Enum.gt_cui, form: formName, placeholder: "CUI (C\u00F3digo \u00DAnico de Identificaci\u00F3n)" })),
        fields.gt_nit && (React.createElement(BeneficiaryInputField, { field: internationalBeneficiaryFieldName.Enum.gt_nit, form: formName, placeholder: "NIT (N\u00FAmero de Identificaci\u00F3n Tributaria)" })),
        fields.hn_id && (React.createElement(BeneficiaryInputField, { field: internationalBeneficiaryFieldName.Enum.hn_id, form: formName, placeholder: "ID (Tarjeta de Identidad)" })),
        fields.hn_rtn && (React.createElement(BeneficiaryInputField, { field: internationalBeneficiaryFieldName.Enum.hn_rtn, form: formName, placeholder: "RTN (Registro Tributario Nacional)" })),
        fields.kz_bin && (React.createElement(BeneficiaryInputField, { field: internationalBeneficiaryFieldName.Enum.kz_bin, form: formName, placeholder: "BIN (Biznes-identifikatsionnyy nomer)" })),
        fields.kz_bnf && (React.createElement(BeneficiarySelectField, { field: internationalBeneficiaryFieldName.Enum.kz_bnf, form: formName, label: "BNF (Benefitsiarnyy nomer)", options: kzBnfFieldOptions, placeholder: "Select BNF (Benefitsiarnyy nomer)" })),
        fields.kz_iin && (React.createElement(BeneficiaryInputField, { field: internationalBeneficiaryFieldName.Enum.kz_iin, form: formName, placeholder: "IIN (Individualnyy identifikatsionnyy nomer)" })),
        fields.kr_brn && (React.createElement(BeneficiaryInputField, { field: internationalBeneficiaryFieldName.Enum.kr_brn, form: formName, placeholder: "BRN (Business Registration Number)" })),
        fields.kr_rrn && (React.createElement(BeneficiaryInputField, { field: internationalBeneficiaryFieldName.Enum.kr_rrn, form: formName, placeholder: "RRN (Resident Registration Number)" })),
        fields.pk_cnic && (React.createElement(BeneficiaryInputField, { field: internationalBeneficiaryFieldName.Enum.pk_cnic, form: formName, placeholder: "CNIC (Computerized National Identity Card)" })),
        fields.pk_ntn && (React.createElement(BeneficiaryInputField, { field: internationalBeneficiaryFieldName.Enum.pk_ntn, form: formName, placeholder: "NTN (National Tax Number)" })),
        fields.ro_cnp && (React.createElement(BeneficiaryInputField, { field: internationalBeneficiaryFieldName.Enum.ro_cnp, form: formName, placeholder: "CNP (Cod Numeric Personal)" })),
        fields.ro_cui && (React.createElement(BeneficiaryInputField, { field: internationalBeneficiaryFieldName.Enum.ro_cui, form: formName, placeholder: "CUI (Cod Unic de Inregistrare)" }))));
};
