import React from 'react';
import { FormControl } from 'components/form';
import { Hint, HintTitle } from 'components/hint';
import { Intent } from 'constants/ui';
import { PartnerSelfManagedTooltip } from './PartnerSelfManagedTooltip';
export const PartnerSelfManagedHint = ({ partnerName }) => (React.createElement(FormControl, { className: "!mb-0 mt-7 self-stretch" },
    React.createElement("div", { className: "flex flex-1", "data-testid": "partner-self-managed-hint" },
        React.createElement(Hint, { "aria-label": `Approvals are not available for this payable because ${partnerName} is self-managed`, intent: Intent.WARNING, multiline: true, role: "note" },
            React.createElement(HintTitle, null,
                "Approvals are not available for this payable because ",
                partnerName,
                " is",
                ' ',
                React.createElement(PartnerSelfManagedTooltip, null, "self-managed"))))));
