import styled from 'styled-components';
import { Container as ComboBoxContainer } from '../../../Combobox/combobox.component.styled';
import { TableCell } from '../../../Table/table.styled';
export const StyledTableCell = styled(TableCell) `
  position: relative;
  align-items: center;
  align-self: stretch;
  display: flex;
  height: ${(props) => (props.isOnDetailsPage ? '56px' : '40px')};
  border-bottom: 1px solid var(--color-grey20);

  &:not(:last-child) {
    border-right: ${(props) => (props.isOnDetailsPage ? 'none' : '1px solid var(--color-grey20)')};
  }

  .column-content {
    align-items: center;
  }

  ${ComboBoxContainer} {
    padding: 0;
  }
  /* Remove hover and focus from children */
  *:hover,
  *:focus,
  *:focus-within {
    box-shadow: none;
  }

  &:focus-within::before {
    border: 1px solid blue;
    bottom: 0;
    content: '';
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
  }

  &:has(.form-error-container) {
    border: 1px solid var(--color-red40) !important;

    &::after {
      /* Show the thicker bottom border as a background to prevent offsetting content */
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 4px; /* bottom border height */
      background-color: var(--color-red00);
    }
  }
  .form-error-container {
    pointer-events: none;

    /* centering at the bottom of container */
    text-align: center;
    width: fit-content;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    /* styling */
    background: var(--color-red00);
    padding: 2px 4px;

    p {
      color: var(--color-red60);
      font-weight: 500;
    }
  }

  &:has(.ledger-form-field.disabled) {
    background-color: var(--color-grey05);
    color: var(--neutral-black);
    pointer-events: none;
  }

  &:has(textarea) {
    padding: 0;
  }
`;
export const RowContainer = styled.div `
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  z-index: 1;
  min-width: 100%;
  overflow: hidden;

  .table-cell.discrepancy {
    border: 1px solid var(--color-gold40);
    border-radius: 0.8125rem;
  }

  .ledger-form-field.input-container .placeholder {
    height: 24px;
    top: calc(50% - 12px + 8px);
    width: calc(100% - 24px);
    left: 0px;
    right: unset;
  }
  .ledger-form-field.input-container {
    justify-content: center;
  }

  .menu-button {
    border: none;
  }

  textarea:has(${StyledTableCell}) {
    padding: 0;
    background: red;
  }
`;
export const TableRow = styled.div `
  position: relative;
  display: flex;
  width: auto;
  min-width: 100%;
  flex-direction: row;
  background-color: rgba(255, 255, 255, 1);
  z-index: 1;
  box-sizing: border-box;
`;
