import { Clickable, InlineMessage } from '@routable/gross-ds';
import React from 'react';
import { CustomerServiceHelper } from '../CustomerService';
export const MissingCountryHint = ({ className }) => {
    return (React.createElement(InlineMessage, { className: className, intent: "info" },
        "Missing a country?",
        ' ',
        React.createElement(Clickable, { onClick: () => {
                CustomerServiceHelper.show();
                CustomerServiceHelper.setConversationTags(['missing-country']);
            } }, "Contact us.")));
};
