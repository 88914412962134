export const getTooltipText = ({ companyIntegrationSettings, currencyCode, countryName, ledger, purchaseOrderCurrency, }) => {
    if (companyIntegrationSettings.enableBaseCurrencyMode && currencyCode !== ledger.baseCurrencyCode) {
        return `As per your settings, this bill will be coded in ${currencyCode}, but it will sync to ${ledger.name} in ${ledger.baseCurrencyCode}.`;
    }
    if (purchaseOrderCurrency) {
        return `You can't change your bill currency because the linked PO is in ${purchaseOrderCurrency}.`;
    }
    const vendorCountry = countryName ? `Vendors in ${countryName}` : 'Vendor';
    return `${vendorCountry} can only be paid in ${currencyCode}.`;
};
