import React from 'react';
import InputField from 'components/input';
import { getFieldErrors } from 'helpers/errors';
export const CustomDatePickerInput = (props) => {
    const { errors, id, input, meta, name, onBlur, ...rest } = props;
    let fieldErrors;
    let hasErrors;
    let inputName;
    let handleOnBlur;
    if (input) {
        inputName = input.name;
        fieldErrors = (meta.touched || meta.submitFailed) && meta.error;
        hasErrors = !!fieldErrors;
        handleOnBlur = input.onBlur;
    }
    else {
        inputName = name;
        fieldErrors = errors;
        hasErrors = !!getFieldErrors(errors, inputName);
        handleOnBlur = onBlur;
    }
    return (React.createElement(InputField, { errors: fieldErrors, hasErrors: hasErrors, id: id, name: inputName, onBlur: handleOnBlur, ...rest }));
};
CustomDatePickerInput.defaultProps = {
    errors: {},
    name: '',
    onBlur: () => { },
    onClick: () => { },
    placeholder: '',
    value: '',
};
