import { ArrowRight, Building } from '@carbon/icons-react';
import { Button } from '@routable/gross-ds';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeConnectBankManualModal, openConnectBankManualModal } from 'actions/connectBank';
import { Modal } from 'components';
import ConnectBankManual from 'modules/connectBank/connectBankManual/ConnectBankManual';
import { connectBankManualModalSelector } from 'modules/connectBank/global/ConnectBankContainer';
import { fundingAccountBankManualIsSubmittingSelector } from 'selectors/fundingSelectors';
const AddBankAccountManually = () => {
    const dispatch = useDispatch();
    const connectBankManualModal = useSelector(connectBankManualModalSelector);
    const isSubmitting = useSelector(fundingAccountBankManualIsSubmittingSelector);
    const onOpenManualAccountModal = () => {
        dispatch(openConnectBankManualModal());
    };
    const onCloseManualAccountModal = () => {
        dispatch(closeConnectBankManualModal());
    };
    return (React.createElement("div", null,
        React.createElement(Button, { className: "!w-full h-auto group gap-4", "data-testid": "btn--add-bank-account-manually", loading: isSubmitting, onPress: () => onOpenManualAccountModal(), variant: "outline" },
            React.createElement(Building, null),
            React.createElement("span", { className: "inline-flex flex-col flex-1 items-start" },
                React.createElement("span", { className: "font-semibold" }, "Add bank account manually"),
                React.createElement("span", { className: "text-grey-60 group-hover:text-blue-40" }, "2-3 days verification")),
            React.createElement(ArrowRight, null)),
        React.createElement(Modal, { body: React.createElement(ConnectBankManual, null), hasInlineFooter: true, modalHeader: "Add a bank account", modalState: connectBankManualModal.open, onCloseModal: onCloseManualAccountModal })));
};
export default AddBankAccountManually;
