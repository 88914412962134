import React from 'react';
import { components } from 'react-select';
const { MenuList: ReactSelectMenuList } = components;
export const MenuList = (props) => {
    const { children, selectProps } = props;
    const { MenuListFooter } = selectProps.components;
    return (React.createElement(ReactSelectMenuList, { ...props },
        children,
        children.length && MenuListFooter));
};
