import { SettingsAdjust } from '@carbon/icons-react';
import { featureFlag, useFeatureFlag } from '@routable/feature-configs';
import { Popover, Checkbox, Button, Tooltip } from '@routable/gross-ds';
import React, { useEffect, useState, useMemo } from 'react';
import { useOverlayTriggerState } from 'react-stately';
import { pullHeaderConfigFromIndexDb } from './TableColumnSelect.helpers';
import {} from './TableColumnSelect.types';
import { RoutableEvents, useEvent } from '@routable/framework';
const EXCLUDE_COLUMN_IDS = {
    checkbox: true,
};
export const TableColumnSelect = ({ tableName }) => {
    const state = useOverlayTriggerState({});
    const [checkboxesState, setCheckboxesState] = useState({});
    const syncHeaderConfigFromIndexDb = async () => {
        const checkboxesStateFromIndexDB = await pullHeaderConfigFromIndexDb(tableName);
        if (checkboxesStateFromIndexDB) {
            setCheckboxesState(checkboxesStateFromIndexDB);
        }
    };
    useEffect(() => {
        syncHeaderConfigFromIndexDb();
    }, []);
    useEvent(`columnselect:${tableName}:columns:updated`, () => {
        syncHeaderConfigFromIndexDb();
    });
    const handleSubmit = () => {
        RoutableEvents.Publish(`columnselect:${tableName}:columns:update`, checkboxesState);
        state.close();
    };
    const handleCheckboxToggle = (id) => () => setCheckboxesState({ ...checkboxesState, [id]: { ...checkboxesState[id], checked: !checkboxesState[id].checked } });
    const checkboxesToRender = useMemo(() => Object.values(checkboxesState).filter(({ id }) => !EXCLUDE_COLUMN_IDS[id]), [checkboxesState]);
    const isLastChecked = useMemo(() => checkboxesToRender.reduce((count, checkbox) => count + (checkbox.checked ? 1 : 0), 0) === 1, [checkboxesToRender]);
    const isColumnPickerEnabled = useFeatureFlag(featureFlag.Enum.column_picker);
    if (!isColumnPickerEnabled) {
        return null;
    }
    return (React.createElement("div", null,
        React.createElement(Popover, { placement: "bottom right", keepOpen: true, state: state, content: React.createElement(Tooltip, { "data-testid": "column-select-picker", tooltip: "Customize columns", variant: "dark" },
                React.createElement(Button, { className: "ml-4", "data-testid": "column-settings", intent: "primary", variant: "outline", size: "small" },
                    React.createElement(SettingsAdjust, null))) },
            React.createElement("div", { className: "p-5 pr-9 flex flex-col gap-3 text-grey-70" }, checkboxesToRender.map(({ id, label, checked }) => (React.createElement(Checkbox, { checked: checked, "data-testid": `columns-config-checkbox-${id}`, key: id, label: label, name: id, disabled: isLastChecked && checked, onChange: handleCheckboxToggle(id) })))),
            React.createElement("div", { className: "p-5 text-right border-t border-t-grey-10" },
                React.createElement(Button, { "data-testid": "columns-config-apply", intent: "primary", variant: "outline", size: "small", onPress: handleSubmit }, "Apply")))));
};
