import React from 'react';
import { useDispatchTableEvent } from '../../hooks';
import { TableCellWrapper } from '../../table.styled';
import { RowSelect } from '../rowselects';
import {} from './TableSelectCell.types';
export const TableSelectCell = ({ isDisabled, tableName, testId = 'table-cell--select-single', value, }) => {
    const changeSingleSelection = useDispatchTableEvent({
        tableName: tableName,
        event: 'selected:update:single',
    });
    const handleOnChange = (e) => {
        e.preventDefault();
        changeSingleSelection([value]);
    };
    return (React.createElement(TableCellWrapper, { className: "cursor-pointer", "data-role": "checkbox", role: "checkbox", "data-testid": testId, onClick: handleOnChange },
        React.createElement(RowSelect, { isDisabled: isDisabled, value: value, shouldTriggerOnChange: false })));
};
