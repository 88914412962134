import { DateElement } from '@routable/shared';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { DatePickerForReduxForm } from 'components/reduxForm';

import { DateFormats } from 'constants/date';
import * as propTypes from 'constants/propTypes';
import { PopperPlacements } from 'constants/ui';

import { getDayjsObjectIfValue } from 'helpers/date';
import { normalizeDate } from 'helpers/fieldNormalizers';
import { oneOfValuesFromObject } from 'helpers/propTypes';

import { useGetBlockFieldProps } from 'modules/table/hooks';

/**
 * Renders a date picker field for date values to be rendered in a block section
 * @param {ComponentProps} props
 * @param {DateElement[]} props.dateElements
 * @param {Object} props.formValues
 * @param {string} props.initialValue
 * @param {string} props.name
 * @param {string} props.placeholder
 * @param {Function} props.validate
 * @returns {StatelessComponent}
 */
const DateBlockField = ({
  dateElements,
  dateFormat,
  formValues,
  getIsDisabled,
  getIsRequired,
  initialValue,
  name,
  placeholder,
  timeFormat,
  validate,
}) => {
  const { isDisabled, isRequired, validators } = useGetBlockFieldProps({
    formValues,
    getIsDisabled,
    getIsRequired,
    validate,
  });

  const showTimeSelect = dateElements.includes(DateElement.time);
  const showTimeSelectOnly = !dateElements.includes(DateElement.date);

  const formatDate = showTimeSelect ? dateFormat : undefined;
  const format = showTimeSelectOnly ? `${DateFormats.FULL_NUMERIC_MONTH_DAY_YEAR_SLASHED} ${dateFormat}` : formatDate;

  return (
    <Field
      component={DatePickerForReduxForm}
      dateFormat={dateFormat}
      format={(value) => getDayjsObjectIfValue(value, format)}
      isDisabled={isDisabled}
      isRequired={isRequired}
      name={name}
      normalize={(value) => (value ? normalizeDate(value, formatDate, format) : initialValue)}
      placeholderText={placeholder}
      props={{
        popperModifiers: { flip: { enabled: false } },
        popperPlacement: PopperPlacements.BOTTOM,
      }}
      showTimeSelect={showTimeSelect}
      showTimeSelectOnly={showTimeSelectOnly}
      timeFormat={timeFormat}
      validate={validators}
    />
  );
};

DateBlockField.propTypes = {
  ...propTypes.baseFieldSharedPropTypes,
  dateElements: PropTypes.arrayOf(oneOfValuesFromObject(DateElement)).isRequired,
  dateFormat: PropTypes.string,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  placeholder: PropTypes.string.isRequired,
  timeFormat: PropTypes.string,
};

DateBlockField.defaultProps = {
  ...propTypes.baseFieldSharedDefaultProps,
  dateFormat: DateFormats.SHORT_ALPHA_MONTH_FULL_YEAR,
  initialValue: undefined,
  timeFormat: undefined,
};

export default React.memo(DateBlockField);
