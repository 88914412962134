import { createdDateFilterModel, createMultiOptionFilterModel, dateModel, paginationModel, dateOrAmountFilterFromQueryParamsTransformer, dateFilterToQueryParamsTransformer, stringValueFilterModel, stringFilterToQueryParamsTransformer, stringFilterFromQueryParamsTransformer, uuidModel, amountFilterToQueryParamsTransformer, amountFilterModel, } from '@routable/shared';
import { taxInfoStatusFilter } from '@routable/taxes';
import _omit from 'lodash/omit';
import _omitBy from 'lodash/omitBy';
import { z } from 'zod';
import { poMatchTypeOptions } from 'hooks/purchaseOrders/purchaseOrder.models';
import { ItemStatuses } from 'enums/items';
export const itemStatusFilterModel = z.enum([
    ItemStatuses.CANCELED,
    ItemStatuses.COMPLETED,
    ItemStatuses.COMPLIANCE_HOLD,
    ItemStatuses.CREATED,
    ItemStatuses.EXTERNALLY_PAID,
    ItemStatuses.FAILED,
    ItemStatuses.INITIATED,
    ItemStatuses.ISSUE,
    ItemStatuses.NEEDS_APPROVAL,
    ItemStatuses.NEW,
    ItemStatuses.OCR,
    ItemStatuses.PENDING,
    ItemStatuses.PO_DISCREPANCY,
    ItemStatuses.PROCESSING,
    ItemStatuses.READY_TO_SEND,
    ItemStatuses.SCHEDULED,
]);
export const itemApiResponseModel = z.object({
    company: z.object({}).passthrough(),
    fundingAccount: z.object({}).passthrough(),
    fundingInfoBank: z.object({}).passthrough(),
    item: z.object({}).passthrough(),
    itemMember: z.object({}).passthrough(),
    membership: z.object({}).passthrough(),
    partnership: z.object({}).passthrough(),
    partnershipMember: z.object({}).passthrough(),
    thread: z.object({}).passthrough(),
    user: z.object({}).passthrough(),
});
export const itemsApiResponseModel = z.object({
    company: z.object({}).passthrough(),
    fundingAccount: z.object({}).passthrough(),
    item: z.object({}).passthrough(),
    itemAttachment: z.object({}).passthrough(),
    links: z.object({}).passthrough(),
    membership: z.object({}).passthrough(),
    meta: z.object({ pagination: paginationModel }).passthrough(),
    partnership: z.object({}).passthrough(),
    user: z.object({}).passthrough(),
});
export const itemsFiltersBaseModel = z.object({
    amountDue: amountFilterModel.nullish(),
    approverId: createMultiOptionFilterModel(uuidModel),
    approver_status: z.literal('requested').nullish(),
    createdDate: createdDateFilterModel.nullish(),
    csv_upload: z.string().nullish(),
    dateDue: createdDateFilterModel.nullish(),
    fundingAccount: uuidModel.nullish(),
    invoiceNumbers: stringValueFilterModel.nullish(),
    my_approvals: z.boolean().nullish(),
    payment: z.string().nullish(),
    poMatchType: createMultiOptionFilterModel(poMatchTypeOptions).nullish(),
    po_discrepancy_type: z.literal('missing_receipt_only').nullish(),
    search: z.string().nullish(),
    sort: z.string().nullish(),
    status: createMultiOptionFilterModel(itemStatusFilterModel),
    tax_info_status: createMultiOptionFilterModel(taxInfoStatusFilter),
});
export const itemsFiltersModel = itemsFiltersBaseModel
    .transform(amountFilterToQueryParamsTransformer(z.literal('amountDue'), 'amount_due'))
    .transform(({ approverId, ...rest }) => {
    if (!approverId) {
        return _omit(rest, ['approver_status']);
    }
    return {
        ...rest,
        approver_id: approverId,
        approver_status: 'requested',
    };
})
    .transform(dateFilterToQueryParamsTransformer(z.literal('createdDate'), 'created__date'))
    .transform(dateFilterToQueryParamsTransformer(z.literal('dateDue'), 'date_due'))
    .transform(({ fundingAccount, ...rest }) => {
    if (!fundingAccount) {
        return rest;
    }
    return {
        ...rest,
        funding_account: fundingAccount,
    };
})
    .transform(stringFilterToQueryParamsTransformer(z.literal('invoiceNumbers'), 'invoice_numbers'))
    .transform(({ poMatchType, ...rest }) => {
    if (!poMatchType) {
        return rest;
    }
    return {
        ...rest,
        po_match_type: poMatchType,
    };
});
export const itemsQueryParamsFiltersModel = itemsFiltersBaseModel
    .extend({
    amount_due__gte: z.string().nullish(),
    amount_due__lte: z.string().nullish(),
    amount_due: z.string().nullish(),
    approver_id: z.string().nullish(),
    approver_status: z.literal('requested').nullish(),
    created__date__gte: dateModel.nullish(),
    created__date__lte: dateModel.nullish(),
    created__date: dateModel.nullish(),
    csv_upload: z.string().nullish(),
    date_due__gte: dateModel.nullish(),
    date_due__lte: dateModel.nullish(),
    date_due: dateModel.nullish(),
    funding_account: uuidModel.nullish(),
    invoice_numbers__icontains: z.string().nullish(),
    invoice_numbers__iendswith: z.string().nullish(),
    invoice_numbers__iexact: z.string().nullish(),
    invoice_numbers__istartswith: z.string().nullish(),
    my_approvals: z.boolean().nullish(),
    payment: z.string().nullish(),
    po_match_type: z.string().nullish(),
    po_discrepancy_type: z.literal('missing_receipt_only').nullish(),
    search: z.string().nullish(),
    sort: z.string().nullish(),
    status: z.string().nullish(),
})
    .strip()
    .omit({ search: true, sort: true })
    .transform(dateOrAmountFilterFromQueryParamsTransformer('amountDue', 'amount_due'))
    .transform(({ approver_id, ...rest }) => {
    if (!approver_id) {
        return _omit(rest, ['approver_status']);
    }
    return {
        ...rest,
        approver_id,
    };
})
    .transform(dateOrAmountFilterFromQueryParamsTransformer('createdDate', 'created__date'))
    .transform(dateOrAmountFilterFromQueryParamsTransformer('dateDue', 'date_due'))
    .transform(({ funding_account, ...rest }) => {
    if (!funding_account) {
        return _omit(rest, 'funding_account');
    }
    return {
        ...rest,
        fundingAccount: funding_account,
    };
})
    .transform(stringFilterFromQueryParamsTransformer('invoiceNumbers', 'invoice_numbers'))
    .transform((filters) => {
    const multiValueFilters = _omitBy({
        approverId: filters.approver_id?.split(','),
        poMatchType: filters.po_match_type?.split(','),
        status: filters.status?.split(','),
        tax_info_status: filters.tax_info_status?.split(','),
    }, (val) => val === undefined);
    return { ...filters, ...multiValueFilters };
});
