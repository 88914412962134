import { useCurrencyAmountFormatter } from '@routable/shared';
import clsx from 'clsx';
import React from 'react';
import { ItemCurrencyCodeKey } from 'enums/items';
const ShowAmountCell = ({ amountProps, children, currencyCodeKey = ItemCurrencyCodeKey.GENERAL, dataKey, rowData, }) => {
    const { formatCurrencyAmount } = useCurrencyAmountFormatter();
    return (React.createElement("div", { className: "w-full flex items-center justify-end inline-flex gap-4", "data-testid": "show-item-amount-cell" },
        children,
        React.createElement("span", { className: clsx(amountProps?.amountClassName, 'text-black text-xs') }, formatCurrencyAmount({
            amount: rowData[dataKey],
            currency: rowData.predictedCurrencyCode ?? rowData[currencyCodeKey],
        }))));
};
export default ShowAmountCell;
