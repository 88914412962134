import _get from 'lodash/get';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getDefaultLineItem, getLineItemsPathFromStyle, isLineItemSimilarToDefault } from 'helpers/lineItems';
import { createItemsTableViewModelManagerForKindSelector } from 'queries/tableCompoundSelectors';
export const useGetTableFieldProps = ({ formValues, getIsDisabled: { getIsDisabled }, getIsRequired: { getIsRequired }, fieldPath, rowIdx, validate: validateFns, }) => {
    const rowValues = _get(formValues, fieldPath);
    const viewModelManager = useSelector(createItemsTableViewModelManagerForKindSelector);
    const isSimilarToDefaultLineItem = isLineItemSimilarToDefault(rowValues, getDefaultLineItem(viewModelManager, getLineItemsPathFromStyle(rowValues?.style)));
    const isRequired = Boolean(getIsRequired(formValues, rowValues));
    const validators = useMemo(() => {
        if (isSimilarToDefaultLineItem || formValues.ui.blockDetailsSections) {
            return () => undefined;
        }
        return isRequired ? validateFns : validateFns?.slice?.(1);
    }, [formValues.ui.blockDetailsSections, isRequired, isSimilarToDefaultLineItem, validateFns]);
    const isDisabled = useMemo(() => getIsDisabled(formValues, rowIdx), [formValues, getIsDisabled, rowIdx]);
    return {
        isDisabled,
        isRequired,
        validators,
    };
};
