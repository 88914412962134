import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { fundingAccountFundingSourcesFromFundingAccountPropSelector } from 'queries/fundingCompoundSelectors';
import { partnershipFundingAccountForFundingAccountPropSelector } from 'queries/partnershipCompoundSelectors';
import { fundingInfoAddressForFundingAccountPropSelector, fundingInfoBankAccountForFundingAccountPropSelector, fundingInfoInternationalForFundingAccountPropSelector, fundingInfoPayPalDirectForFundingAccountPropSelector, } from 'selectors/fundingSelectors';
import UpdatePaymentMethodFundingAccount from './UpdatePaymentMethodFundingAccount';
import {} from './UpdatePaymentMethodFundingAccount.types';
export const mapStateToProps = createStructuredSelector({
    fundingInfoAddress: fundingInfoAddressForFundingAccountPropSelector,
    fundingInfoBankAccount: fundingInfoBankAccountForFundingAccountPropSelector,
    fundingInfoInternational: fundingInfoInternationalForFundingAccountPropSelector,
    fundingInfoPayPalDirect: fundingInfoPayPalDirectForFundingAccountPropSelector,
    fundingSources: fundingAccountFundingSourcesFromFundingAccountPropSelector,
    partnershipFundingAccount: partnershipFundingAccountForFundingAccountPropSelector,
});
export default connect(mapStateToProps)(UpdatePaymentMethodFundingAccount);
