export const bankAccountFields = {
  ACCOUNT_NICKNAME: 'bankAccountNickName',
  ACCOUNT_NUMBER: 'bankAccountNumber',
  ACCOUNT_TYPE: 'bankAccountType',
  BANK_IDENTIFIER_CODE: 'bankIdentifierCode',
  CONFIRM_ACCOUNT_NUMBER: 'confirmBankAccountNumber',
  ROUTING_NUMBER: 'bankRoutingNumber',
};

export const bulkActionsFormFields = {
  UI_HAS_SUBMITTED: 'ui.hasSubmitted',
  UI_SHOW_CONFIRM: 'ui.showConfirm',
};

export const bulkImportItemsFormFields = {
  ID: 'form.id',
  KIND: 'form.kind',
  UI_SHOW_CONFIRM: 'ui.showConfirm',
  UI_STEP: 'ui.step',
  ITEM_APPROVERS: 'fields.itemApprovers',
};

/**
 * !!NOTE!! [DEV-15462]
 * This is currently a duplicate constant. Until it is removed per the above ticket,
 * changes made to it also need to be made in packages/shared.
 */
export const commonFormFields = {
  CURRENCY_CODE: 'currencyCode',
  FUNDING_ACCOUNT: 'fundingAccount',
  FUNDING_PAYMENT_DELIVERY_METHOD: 'funding.paymentDeliveryMethod',
  META_PARTNERSHIP_MEMBERS_BANK: 'meta.partnershipMembers',
  PARTNER_COMPANY_TYPE: 'partner.companyType',
  PARTNER_COUNTRY: 'partner.country',
  PARTNER_NAME: 'partner.name',
  PARTNER_LEDGER_NAME: 'partner.ledgerName',
  PARTNERSHIP_MEMBERS: 'partnershipMembers',
  NAME: 'name',
};

export const createCompanyFields = {
  COMPANY_ACCOUNTING_SOFTWARE: 'accountingSoftware',
  COMPANY_NAME: 'name',
  COMPANY_SIZE: 'companySize',
  SIGNUP_CODE: 'signupCode',
  USER_EMAIL: 'email',
  USER_FIRST_NAME: 'firstName',
  USER_LAST_NAME: 'lastName',
  USER_PASSWORD: 'password',
  USER_TITLE: 'title',
};

export const integrationSyncOptionsFields = {
  GL_ACCOUNT: 'glAccount',
  GL_ACCOUNT_NAME: 'glAccountName',
  GL_ENABLED: 'glEnabled',
  GL_SYNC: 'glSync',
  MODE: 'mode',
  PARTNERSHIPS_SYNC: 'partnershipsSync',
  PAYMENT_TYPE: 'paymentType',
};

export const signUpCreateAccountFields = {
  COMPANY_NAME: 'company.name',
  USER_EMAIL: 'user.email',
  USER_FIRST_NAME: 'user.firstName',
  USER_LAST_NAME: 'user.lastName',
  USER_PASSWORD: 'user.password',
};

/**
 * @deprecated - use the one from packages/shared
 * !!NOTE!! [DEV-15462]
 * This is currently a duplicate constant. Until it is removed per the above ticket,
 * changes made to it also need to be made in packages/shared.
 */
export const createItemFormFields = {
  ADDRESS: 'address',
  ADDRESS_COUNTRY: 'address.country',
  ADDRESS_CITY: 'address.city',
  ADDRESS_POSTALCODE: 'address.postalcode',
  ADDRESS_PRINT_COMPANY: 'address.printCompany',
  ADDRESS_PRINT_NAME: 'address.printName',
  ADDRESS_STATE: 'address.state',
  ADDRESS_STREET_ADDRESS: 'address.streetAddress',
  ADDRESS_STREET_ADDRESS_UNIT: 'address.streetAddressUnit',
  APPROVERS: 'approvers',
  COUNTRY_CODE: 'countryCode',
  FUNDING_PAYMENT_DELIVERY_METHOD: commonFormFields.FUNDING_PAYMENT_DELIVERY_METHOD,
  FUNDING_PROVIDER_MEMO: 'item.fundingProviderMemo',
  FUNDING_ACH_ACCOUNT_MEMO: 'fundingAccountMemoForACH',
  ITEM_ATTACHMENTS: 'item.attachments',
  ITEM_BILLS: 'item.bills',
  ITEM_CURRENCY_CODE_RECEIVER: 'item.currencyCodeReceiver',
  ITEM_DATE_DUE: 'item.dateDue',
  ITEM_FUNDING_ACCOUNT_ID: 'item.fundingAccount.id',
  ITEM_IS_CONSOLIDATED: 'item.isConsolidated',
  ITEM_ID: 'item.id',
  ITEM_KIND: 'item.kind',
  ITEM_MEMBERS: 'itemMembers',
  ITEM_PARTNER_RECEIVABLE_ACCOUNT: 'item.partnerReceivableAccount',
  ITEM_PAYMENT_DELIVERY_METHOD: 'item.paymentDeliveryMethod',
  ITEM_PAYMENT_DELIVERY_METHODS_ACCEPTED: 'item.paymentDeliveryMethodsAccepted',
  ITEM_PAYMENT_METHOD_OPTIONS: 'item.paymentMethodOptions',
  ITEM_PAYMENT_DELIVERY_OPTION: 'item.paymentDeliveryOption',
  ITEM_SWIFT_CHARGE_OPTION: 'item.swiftChargeOption',
  ITEM_PURPOSE_CODE: 'item.purposeCode',
  MAIN_GENERAL_BILL_CURRENCY: 'main.general.bill_currency',
  PARTNER_COUNTRY_CODE: 'countryCode',
  PARTNER_NAME: 'partner.name',
  PARTNER_GOVERNMENT_ID: 'partner.governmentId',
  PARTNERSHIP: 'partnership',
  PARTNERSHIP_MEMBERS: 'partnershipMembers',
  PARTNERSHIP_TYPE: 'partnershipType',
  PURCHASE_ORDER: 'item.purchaseOrder',
  SHOW_MARK_AS_PAID: 'showMarkAsPaid',
  UI_BLOCK_DETAILS_SECTIONS: 'ui.blockDetailsSections',
  UI_FILTERS: 'ui.filters',
  UI_IS_BILL_VIEW: 'ui.isBillView',
  UI_IS_PURCHASE_ORDER_FETCHED: 'ui.isPoFetched',
  UI_IS_ITEM_EDIT: 'ui.isItemEdit',
  UI_IS_PURPOSE_CODE_REQUIRED: 'ui.isPurposeCodeRequired',
  UI_SELECTED_COMPANY: 'ui.selectedCompany',
  UI_SELECTED_COMPANY_NAME: 'ui.selectedCompany.companyName',
  UI_COUNTRY_CODE_PARTNER: 'ui.selectedCompany.countryCodePartner',
  UI_SHOW_INVOICE_DISCOUNT: 'ui.showInvoiceDiscount',
  UI_SHOW_INVOICE_GENERATOR: 'ui.showInvoiceGenerator',
  UI_SKIP_PURCHASE_ORDER: 'ui.skipPurchaseOrder',
  UI_WARNINGS_DISMISSED: 'ui.warningsDismissed',
  UI_PARTNERSHIP_MEMBER_CREATED: 'ui.partnershipMemberCreated',
};

// This is a copy of createItemFormFields while we refactor the create item form to be more generic for create, edit,
// and OCR usage cases.
export const itemFormFields = {
  APPROVERS: 'approvers',
  EXTERNAL_REF: 'externalRef',
  FUNDING_PAYMENT_DELIVERY_METHOD: commonFormFields.FUNDING_PAYMENT_DELIVERY_METHOD,
  FUNDING_PROVIDER_MEMO: 'item.fundingProviderMemo',
  ITEM_BILLS: 'item.bills',
  ITEM_DATE_DUE: 'item.dateDue',
  ITEM_FUNDING_ACCOUNT_ID: 'item.fundingAccount.id',
  ITEM: 'item',
  ITEM_ID: 'item.id',
  ITEM_KIND: 'item.kind',
  ITEM_MEMBERS: 'itemMembers',
  ITEM_PARTNER_RECEIVABLE_ACCOUNT: 'item.partnerReceivableAccount',
  ITEM_PAYMENT_DELIVERY_METHOD: 'item.paymentDeliveryMethod',
  ITEM_PAYMENT_DELIVERY_OPTION: 'item.paymentDeliveryOption',
  ITEM_SWIFT_CHARGE_OPTION: 'item.swiftChargeOption',
  LEDGER_INVOICE_REFS: 'ledgerInvoiceRefs',
  META_APPROVERS: 'meta.approvers',
  META_APPROVERS_HASH: 'meta.approvers.hash',
  META_APPROVERS_MANUAL_APPROVER: 'meta.approvers.manualApprover',
  META_APPROVERS_MANUAL_APPROVER_ID: 'meta.approvers.manualApprover.id',
  META_APPROVERS_MANUAL_PLACEMENT: 'meta.approvers.manualApprover.placement',
  META_MESSAGE: 'meta.message',
  PARTNER_NAME: 'partner.name',
  PARTNERSHIP: 'partnership',
  PARTNERSHIP_MEMBERS: 'partnershipMembers',
  PARTNERSHIP_TYPE: 'partnershipType',
  SHOW_MARK_AS_PAID: 'showMarkAsPaid',
  UI_SELECTED_COMPANY: 'ui.selectedCompany',
  UI_SHOW_EXTERNAL_REF: 'ui.showExternalRef',
  UI_SHOW_INVOICE_DISCOUNT: 'ui.showInvoiceDiscount',
  UI_SHOW_INVOICE_GENERATOR: 'ui.showInvoiceGenerator',
  UI_WARNINGS_DISMISSED: 'ui.warningsDismissed',
};

export const createPartnershipFormFields = {
  ADDRESS: 'address',
  FUNDING_PAYMENT_DELIVERY_METHOD: commonFormFields.FUNDING_PAYMENT_DELIVERY_METHOD,
  INVITE_MESSAGE: 'inviteMessage',
  META_KNOWN_PARTNERSHIP_IDS: 'meta.knownPartnershipIds',
  PARTNER_COUNTRY_CODE: 'countryCode',
  PARTNER_LEDGER_CURRENCY_CODE: 'ledgerCurrencyCode',
  PARTNER: 'partner',
  PARTNERSHIP_MEMBERS: commonFormFields.PARTNERSHIP_MEMBERS,
  PARTNERSHIP_TYPE: 'partnershipType',
  PARTNERSHIP: 'partnership',
  UI: 'ui',
  UI_FORM_SUBMITTED: 'ui.formSubmitted',
};

/**
 * @enum {string}
 */
export const inviteTeamMemberFormFields = {
  EMAIL: 'form.email',
  FIRST_NAME: 'form.firstName',
  LAST_NAME: 'form.lastName',
  META_WARNINGS_EMAIL: 'meta.warnings.form.email',
  META_WARNINGS_EMAIL_WARNING_TYPE: 'meta.warnings.form.email.warningType',
  ROLE_ID: 'form.role.id',
  TITLE: 'form.title',
};

/**
 * @enum {string}
 */
export const editUserSettingsFormFields = {
  EMAIL: 'form.email',
  FIRST_NAME: 'form.user.firstName',
  LAST_NAME: 'form.user.lastName',
  ROLE_ID: 'form.role.id',
  TITLE: 'form.title',
};

export const partnershipMemberContactFormFields = {
  DEFAULT_GENERAL: 'defaultGeneral',
  DEFAULT_ITEM: 'defaultItem',
  EMAIL: 'email',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  META: 'meta',
  META_KNOWN_PARTNERSHIP_IDS: 'meta.knownPartnershipIds',
  META_WARNINGS: 'meta.warnings',
  META_WARNINGS_EMAIL: 'meta.warnings.email',
  ACCESS_ITEM: 'accessItem',
  PHONE_NUMBER: 'phoneNumber',
  UI_EDIT_EMAIL: 'ui.editEmail',
  UI_EDIT_FIRST: 'ui.editFirstName',
  UI_EDIT_LAST: 'ui.editLastName',
  UI_REQUIRE_NAME_FIELDS: 'ui.requireNameFields',
};

/**
 * When setting up field names for the first and last legal name in individual contact form, we want to attach those properties
 * to the "first" partnership member (i.e. index 0). In case of any errors, the BE will return an
 * error message referring to the first partnership member, so we need to match that naming here.
 * @enum {string}
 */
export const individualPartnershipContactFormFields = {
  EMAIL: `partnershipMembers[0].${partnershipMemberContactFormFields.EMAIL}`,
  FIRST_NAME: `partnershipMembers[0].${partnershipMemberContactFormFields.FIRST_NAME}`,
  LAST_NAME: `partnershipMembers[0].${partnershipMemberContactFormFields.LAST_NAME}`,
  PHONE_NUMBER: `partnershipMembers[0].${partnershipMemberContactFormFields.PHONE_NUMBER}`,
};

export const partnerFundingAccountFormFields = {
  ADDRESS: 'form.address',
  APPLY_REGISTERED_ADDRESS: 'form.applyRegisteredAddress',
  APPLY_TO_NOT_SET: 'form.applyToNotSet',
  APPLY_TO_PENDING: 'form.applyToPending',
  BANK_ACCOUNT: 'form.bankAccount',
  BANK_ACCOUNT_ROUTING_NUMBER: `form.bankAccount.${bankAccountFields.ROUTING_NUMBER}`,
  BANK_INSTITUTION_NAME: 'ui.bankInstitutionName',
  REPLACE_FUNDING_ACCOUNTS: 'form.replaceFundingAccounts',
  SET_PRIMARY: 'form.setPrimary',
  SHOULD_DISABLE_SET_PRIMARY: 'ui.shouldDisableSetPrimary',
  SHOULD_SHOW_SET_AS_PRIMARY_TOGGLE: 'ui.shouldShowSetAsPrimaryToggle',
  SHOW_CUSTOMIZE_PRINTED_CHECK_SECTION: 'ui.showCustomizePrintedCheckSection',
  SHOULD_SHOW_REPLACE_FUNDING_ACCOUNTS: 'ui.shouldShowReplaceFundingAccounts',
};

export const ACCOUNT_ID_FIELD = 'accountID';
export const ADDENDA_RECORD_FIELD = 'addendaRecord';
export const FORM_FIELD_PREFIX = 'form';
export const SUBSIDIARY_ID_FIELD = 'subsidiaryID';

export const netSuiteIntegrationFormFields = {
  CONSUMER_KEY: `${FORM_FIELD_PREFIX}.consumerKey`,
  CONSUMER_SECRET: `${FORM_FIELD_PREFIX}.consumerSecret`,
  TOKEN_ID: `${FORM_FIELD_PREFIX}.tokenID`,
  TOKEN_SECRET: `${FORM_FIELD_PREFIX}.tokenSecret`,
  ACCOUNT_ID: `${FORM_FIELD_PREFIX}.${ACCOUNT_ID_FIELD}`,
  SUBSIDIARY_ID: `${FORM_FIELD_PREFIX}.${SUBSIDIARY_ID_FIELD}`,
};

export const FieldsWithAuxiliaryErrors = [
  createItemFormFields.PARTNERSHIP_MEMBERS,
  bulkImportItemsFormFields.ITEM_APPROVERS,
];

export const UI = 'ui';
export const FORM_UI = 'formUI';

export const DEFAULT_RECEIVABLE = 'defaultReceivable';

export const updatePaymentMethodFormFields = {
  ACCEPT_FUTURE_PENDING_PAYMENTS: `${DEFAULT_RECEIVABLE}.acceptFuturePendingPayments`,
  FUNDING_ACCOUNT: `${DEFAULT_RECEIVABLE}.fundingAccount`,
  PAYMENT_DELIVERY_METHOD: `${DEFAULT_RECEIVABLE}.paymentDeliveryMethod`,
};

/**
 * DeactivateTeamMemberModal isn't really a form, but we do need a constant to get the membership from
 * state.
 * @enum {string}
 */
export const DeactivateTeamMemberModalField = {
  ERRORS: 'errors',
  ERROR_ACKNOWLEDGE: 'acknowledge',
  ERROR_TEXT: 'errorText',
  MEMBERSHIP: 'membership',
};

/**
 * Form fields used in external on-boarding flow
 * @enum {string}
 */
export const externalFormFields = {
  MEMBERSHIP_PHONE_NUMBER: 'membership.phoneNumber',
  MEMBERSHIP_USER_FIRST_NAME: 'membership.user.firstName',
  MEMBERSHIP_USER_LAST_NAME: 'membership.user.lastName',
};

/**
 * Form fields for external partner company general form
 * @enum {string}
 */
export const externalCompanyGeneralFields = {
  COMPANY_COMPANY_TYPE: 'company.companyType',
  COMPANY_INFO: 'company.info',
  COMPANY_MAILING_ADDRESS: 'company.mailingAddress',
  COMPANY_META_TOS_AGREE: 'company.meta.tosAgree',
  COMPANY_NAME: 'company.name',
  FORM_UI_DISPLAY_INPUT_FOR_TIN: 'formUI.displayInputForTin',
};

export const existingItemFinalizeFormFields = {
  FORM_CURRENCY_CODE_RECEIVER: 'form.currencyCodeReceiver',
  FORM_CURRENCY_CODE: 'form.currencyCode',
  FORM_ITEM_FUNDING_ACCOUNT: 'form.fundingAccount',
  FORM_PAYMENT_DELIVERY_OPTION: 'form.paymentDeliveryOption',
  UI_IS_INTERNATIONAL: 'ui.isInternational',
  /**
   * TODO: gradually add more fields like
   * form.partnerReceivableAccount
   * form.paymentDeliveryMethod
   * and other usages like these ^ under src/modules/dashboard/existingItem
   */
};

export const existingItemCancelFormFields = {
  FORM_META_SKIP_LEDGER: 'form.meta.skip_ledger',
};

export const existingItemMarkAsPaidFormFields = {
  FORM_FUNDING_ACCOUNT_ID: 'form.fundingAccount.id',
  FORM_META_SKIP_LEDGER: 'form.meta.skip_ledger',
};

export const existingPaymentCancelFormFields = {
  FORM_META_SKIP_LEDGER: 'form.meta.skipLedger',
};

/**
 * Form fields used on edit partnership general info
 * @enum {string}
 */
export const editCompanyGeneralInfoFormFields = {
  NAME: 'displayName',
  EXTERNAL_REF: 'externalRef',
  GOVERNMENT_ID: 'governmentId',
  LEGAL_NAME: 'name',
  LEGAL_FIRST_NAME: 'legalFirstName',
  LEGAL_LAST_NAME: 'legalLastName',
  LEDGER_NAME_CUSTOMER: 'ledgerNameCustomer',
  LEDGER_NAME_VENDOR: 'ledgerNameVendor',
};

/**
 * Form fields used in the Payable Approval Settings form.
 * @enum {string}
 */
export const payableApprovalSettingsFormFields = {
  ALLOW_APPROVAL_BY_EMAIL: 'allowApprovalByEmail',
  ALLOW_CUSTOMIZE_APPROVAL_REQUIREMENTS: 'allowCustomizeApprovalRequirements',
  DISMISS_APPROVALS_ON_CHANGE: 'dismissApprovalsOnChange',
  ENFORCE_FOR_APPROVERS: 'enforceForApprovers',
  LIMIT_APPROVER_ITEM_VIEW: 'limitApproverItemView',
};

/**
 * Form fields used on company settings forms
 * @enum {string}
 */
export const companySettingsFormFields = {
  ADDITIONAL_GLOBAL_CURRENCY_EUR: 'additionalGlobalCurrencyEur',
  ADDITIONAL_GLOBAL_CURRENCY_GBP: 'additionalGlobalCurrencyGbp',
};
