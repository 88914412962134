import { PaymentDeliveryMethodType } from '@routable/shared';

import { LedgerApplicationTypes } from 'constants/ledger';

import { ItemPaymentTerms } from 'enums/items';

/**
 * @enum {string}
 */
export const ItemFormActions = {
  CREATE: 'create',
  EDIT: 'edt',
};

/**
 * @enum {string}
 */
export const ExistingItemActions = {
  APPROVE: 'approve',
  APPROVE_DISCREPANCY: 'approve_discrepancy',
  CANCEL: 'cancel',
  CANCEL_PAYMENT: 'cancel_payment',
  EDIT: 'edit',
  PAY: 'pay',
  RECEIVE: 'receive',
  MARK_AS_PAID: 'externally_paid',
  SEND: 'send',
};

export const ExistingItemActionsTitles = {
  [ExistingItemActions.PAY]: 'Pay',
  [ExistingItemActions.APPROVE]: 'Approve',
  [ExistingItemActions.CANCEL]: 'Cancel',
  [ExistingItemActions.CANCEL_PAYMENT]: 'Cancel',
  [ExistingItemActions.RECEIVE]: 'Accept',
  [ExistingItemActions.SEND]: 'Send',
  [ExistingItemActions.MARK_AS_PAID]: 'Mark as paid',
};

export const ItemApprovalTypes = {
  ALL: 'all',
  ANY: 'any',
};

/**
 * @enum {string}
 */
export const ItemKinds = {
  PAYABLE: 'payable',
  RECEIVABLE: 'receivable',
};

export const ItemKindsText = {
  [ItemKinds.PAYABLE]: 'Payment',
  [ItemKinds.RECEIVABLE]: 'Request',
};

/**
 * Translate payable => payment and receivable => invoice.
 * @enum {string}
 */
export const PaymentOrInvoiceByItemKindText = {
  [ItemKinds.PAYABLE]: 'payment',
  [ItemKinds.RECEIVABLE]: 'invoice',
};

/**
 * Backup text to use when no item kind is given
 * @see {getPaymentOrInvoiceOrInformalText}
 * @type {string}
 */
export const INFORMAL = 'payment/invoice';

export const ExternallyPaidPaymentMethods = {
  ACH: 'ach',
  CHECK: 'check',
  CREDIT_CARD: 'credit_card',
  DEBIT_CARD: 'debit_card',
  PAYMENT_PLATFORM: 'payment_platform',
  PAYPAL: 'paypal',
  WIRE_DOMESTIC: 'wire_domestic',
  WIRE_INTERNATIONAL: 'wire_international',
  OTHER: 'other',
};

export const ItemPaymentMethodsText = {
  [PaymentDeliveryMethodType.ACH]: 'Bank Transfer',
  [PaymentDeliveryMethodType.ANY]: 'allow vendor to select their payment method',
  [PaymentDeliveryMethodType.CHECK]: 'check',
  [PaymentDeliveryMethodType.INTERNATIONAL]: 'Bank Transfer',
  [ExternallyPaidPaymentMethods.ACH]: 'Bank Transfer',
  [ExternallyPaidPaymentMethods.CHECK]: 'check',
  [ExternallyPaidPaymentMethods.CREDIT_CARD]: 'credit card',
  [ExternallyPaidPaymentMethods.DEBIT_CARD]: 'debit card',
  [ExternallyPaidPaymentMethods.PAYMENT_PLATFORM]: 'another payment platform',
  [ExternallyPaidPaymentMethods.PAYPAL]: 'PayPal',
  [ExternallyPaidPaymentMethods.WIRE_DOMESTIC]: 'wire (Domestic)',
  [ExternallyPaidPaymentMethods.WIRE_INTERNATIONAL]: 'wire (International)',
  [ExternallyPaidPaymentMethods.OTHER]: 'other',
};

export const ItemPaymentDeliveryOptionType = {
  INTERNATIONAL_ACH: 'international_ach',
  SWIFT: 'international_swift',
  VENDORS_CHOICE: 'vendors_choice',
};

export const ItemPaymentDeliveryOptionText = {
  [ItemPaymentDeliveryOptionType.INTERNATIONAL_ACH]: 'International ACH',
  [ItemPaymentDeliveryOptionType.SWIFT]: 'SWIFT (International transfer)',
};

export const ItemPaymentTermsText = {
  [ItemPaymentTerms.NET30]: 'NET 30',
  [ItemPaymentTerms.NET60]: 'NET 60',
  [ItemPaymentTerms.UPON_RECEIPT]: 'Upon Receipt',
  [ItemPaymentTerms.SEE_INVOICE]: 'See invoice for terms',
};

export const ItemSourceTypes = {
  NETSUITE: LedgerApplicationTypes.NETSUITE,
  PLATFORM: 'platform',
  QBO: LedgerApplicationTypes.QBO,
  UNKNOWN: 'unknown',
  XERO: LedgerApplicationTypes.XERO,
};

/**
 * Represent item current payment status
 * @enum {string}
 */
export const ItemPaymentStatusText = {
  DUE: 'due',
  PAID: 'paid',
  SENT: 'sent',
};

/**
 * Represents item partner
 * @enum {string}
 */
export const ItemPartnerLabel = {
  PAYOR: 'payor',
  PAYEE: 'payee',
};

/**
 * Display strings for item properties.
 * @enum {string}
 */
export const ItemPropertyDisplay = {
  BILL_NUMBER: 'Bill No.',
  REFERENCE: 'Reference',
};
