import React from 'react';
import { updateSyncErrors } from 'redux-form';

import { changeSaveStatus, changeView } from 'actions/signUpFlow';
import { showErrorUi } from 'actions/ui';

import { SwalWrapperV2 } from 'components';
import { showSuccessIndicator } from 'components/form/helpers/indicators';

import { ButtonSize } from 'constants/button';
import { RepresentativeIDTypes } from 'constants/document';

import { cleanFieldsOnSubmitSuccess } from 'helpers/formChanges';
import { getCurrentMembershipId } from 'helpers/localStorage';
import { showSwal } from 'helpers/swal';

import {
  addIdsToFormRelationships,
  handleDisableIdNumberEdit,
  handleRemoveAttachments,
} from 'modules/dashboard/settings/user/representative/helpers/forms';

import { viewSteps } from './viewSteps';

// TODO: ts this file

export const onSubmitRepresentativeSuccess = (parsedResponse, dispatch, props) => {
  // for the prompt-on-navigation behavior to behave in an expected way,
  // it's important that we cleanse the fields here (called by the reduxForm
  // instance's onSuccess handler), and not within the submit function,
  // due to how we go about destroying this form as a modal-over-modal
  cleanFieldsOnSubmitSuccess(props.values, dispatch, props, {
    keepDirty: false,
    keepSubmitSucceeded: true,
    keepValues: false,
  });
  handleRemoveAttachments();
  handleDisableIdNumberEdit(parsedResponse);
  addIdsToFormRelationships(parsedResponse);
  showSuccessIndicator('Your business representative info was saved!');
};

export const submitRepresentativeForm = async (payload, dispatch, props) => {
  const { onSubmitRepresentativeForm } = props;

  dispatch(changeSaveStatus.saving());

  let responseData = null;
  const successCallback = (parsedResponse) => {
    responseData = parsedResponse;
    return parsedResponse;
  };

  // Construct update payload based on verification state
  const updatePayload = { id: payload.id, companyType: payload.companyType };

  const isPassport = payload.representativeDocumentType === RepresentativeIDTypes.PASSPORT_NUMBER;
  const personalInfo = payload.personalInfo
    ? Object.keys(payload.personalInfo).reduce((prev, key) => {
        if ((isPassport && key === 'ssn') || (!isPassport && key === 'passportNumber')) {
          return prev;
        }

        return {
          ...prev,
          // check using typeof {var} !== undefined instead of !{var} to include 'false' values in the payload
          ...(typeof payload.personalInfo?.[key] !== 'undefined' && {
            [key]: payload.personalInfo[key],
          }),
        };
      }, {})
    : {};

  const hasPersonalInfo = Object.values(personalInfo).some((value) => Boolean(value));
  const hasPhoneNumber = payload.phoneNumber && Object.values(payload.phoneNumber).some((value) => Boolean(value));
  const hasAddress = payload.address && Object.values(payload.address).some((value) => Boolean(value));

  // doing this way so we only add in the payload info that are filled
  Object.assign(updatePayload, {
    ...(hasAddress && { address: payload.address }),
    ...(hasPersonalInfo && { personalInfo }),
    ...(hasPhoneNumber && { phoneNumber: payload.phoneNumber }),
    ...(payload.title && { title: payload.title }),
    ...(payload.agreeIdentity && { agreeIdentity: payload.agreeIdentity }),
    ...(payload.agreeBusinessRepresentative && {
      agreeBusinessRepresentative: payload.agreeBusinessRepresentative,
    }),
    ...(payload.representativeDocumentType === RepresentativeIDTypes.PASSPORT_NUMBER && {
      hasPassportNumber: true,
      ssn: false,
    }),
    ...(payload.representativeDocumentType === RepresentativeIDTypes.SOCIAL_SECURITY_NUMBER && {
      hasSsn: true,
      hasPassportNumber: false,
    }),
  });

  // If personalInfo.isCompanyBeneficiary=false, set the percentOfOwnership value to null as it shouldn't be
  // a part of the payload
  if (!updatePayload.personalInfo?.isCompanyBeneficiary && updatePayload.personalInfo?.percentOfOwnership) {
    updatePayload.personalInfo.percentOfOwnership = null;
  }

  const submissionErrors = await onSubmitRepresentativeForm(updatePayload, getCurrentMembershipId(), successCallback);

  if (submissionErrors?.errors) {
    // Handle non-field errors differently than the rest of the fields errors by
    // showing error dialog
    if (submissionErrors.errors.fields?.personalInfo?.nonFieldErrors) {
      showSwal({
        Content: (
          <SwalWrapperV2
            rightButtonProps={{ children: 'Close', size: ButtonSize.MEDIUM }}
            subtitle={submissionErrors.errors.fields.personalInfo.nonFieldErrors[0]}
            title="Something went wrong"
          />
        ),
      });
    } else {
      dispatch(showErrorUi('Something went wrong.'));
      dispatch(updateSyncErrors(props.form, submissionErrors.errors.fields));
    }
    dispatch(changeSaveStatus.notSaved());
    return null;
  }

  const next = viewSteps[props.activeView]?.next?.path;
  dispatch(changeView(next));

  dispatch(changeSaveStatus.saved());

  // Need to return the response data to be picked up by onSubmitRepresentativeSuccess
  return responseData;
};

export default submitRepresentativeForm;
