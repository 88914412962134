import { formNamesItem } from '@routable/shared';
import React from 'react';
import { useSelector } from 'react-redux';
import { useAnnotations } from 'helpers/ocr';
import SummarizeLineItems from 'modules/dashboard/createItems/invoiceGenerator/components/InvoiceGeneratorView/SummarizeLineItems';
import { createItemsTableViewModelManagerForKindSelector } from 'queries/tableCompoundSelectors';
import { createFieldValueSelector } from 'selectors/createFormSelectors';
import { createItemFormItemIdSelector } from 'selectors/forms';
const isForcedConsolidatedLineItemSelector = createFieldValueSelector(formNamesItem.CREATE_ITEM, 'ui.isForcedConsolidatedLineItem');
export const DataFedSummarizeTransfer = ({ sectionType }) => {
    const itemId = useSelector(createItemFormItemIdSelector);
    const { annotationData, isOcrEnabled, isInboxBillView } = useAnnotations(itemId);
    const isForcedConsolidatedLineItem = useSelector(isForcedConsolidatedLineItemSelector);
    const lineItems = useSelector(createFieldValueSelector(formNamesItem.CREATE_ITEM, 'line_items'));
    const viewModelManager = useSelector(createItemsTableViewModelManagerForKindSelector);
    if (!(isOcrEnabled && isInboxBillView)) {
        return null;
    }
    return (React.createElement("div", { className: "flex items-center" },
        React.createElement(SummarizeLineItems, { isForcedConsolidatedLineItem: isForcedConsolidatedLineItem, lineItems: lineItems, ocrAnnotation: annotationData, sectionType: sectionType, viewModelManager: viewModelManager })));
};
