import { Select, Tag, TextInput } from '@routable/gross-ds';
import { stringOperatorModel, stringOperatorOptions } from '@routable/shared';
import _set from 'lodash/set';
import React, {} from 'react';
import {} from './TextMatchFilter.types';
export const TextMatchFilter = ({ onChange, label = 'Text match filter', value = { operator: stringOperatorModel.Enum.EXACT, value: '' }, }) => {
    const handleOperatorChange = (operatorValue) => {
        onChange(_set({ ...value }, 'operator', operatorValue));
    };
    const handleValueChange = (evt) => {
        onChange(_set({ ...value }, 'value', evt.target.value));
    };
    const operatorSelectOptions = Object.entries(stringOperatorOptions).map(([key, optLabel]) => ({
        label: optLabel,
        value: key,
    }));
    return (React.createElement("div", { className: "flex flex-wrap gap-3 items-center" },
        React.createElement(Select, { "aria-label": `${label} - operator`, name: "operator", onChange: handleOperatorChange, options: operatorSelectOptions, value: value.operator }),
        React.createElement(TextInput, { containerClassName: "flex-1", "aria-label": label, name: "value", onChange: handleValueChange, placeholder: "Search by invoice number...", value: value.value })));
};
const Tags = ({ value, onRemove, size }) => (React.createElement(React.Fragment, null,
    stringOperatorOptions[value.operator],
    React.createElement(Tag, { "data-testid": "tag:text-match-filter", intent: "primary", size: size, onRemove: onRemove }, value.value)));
TextMatchFilter.Tags = Tags;
