import { createItemFormFields, formNamesItem } from '@routable/shared';
import React from 'react';
import { useDispatch } from 'react-redux';
import { change, Field, touch } from 'redux-form';
import { SelectFieldV2 } from 'components/selectV2';
import { usePartnershipPaymentOptions, usePrevious } from 'hooks';
import { isCreateItemVendorReceivingCurrencyEnabled } from 'modules/dashboard/createItems/helpers/allTheCurrenciesLogic';
import { MissingCurrencyFooter } from 'modules/dashboard/vendorReceivingCurrency/components';
import CurrencyCodeBlockFieldTooltipText from './components/CurrencyCodeBlockFieldTooltipText';
import {} from './CurrencyCodeBlockField.types';
import { getCurrencyOptionsWithAnnotationData } from './helpers';
const customValidation = (validCurrencies) => (value) => {
    if (value && !validCurrencies?.find((currency) => currency.value === value)) {
        return [`${value} is not supported for this company`];
    }
    return undefined;
};
const CurrencyCodeBlockField = ({ companyIntegrationSettings, formValues, getIsDisabled, isItemEdit, ledger, partnership, ...rest }) => {
    const dispatch = useDispatch();
    const billCurrencyFormValue = formValues?.main?.general?.bill_currency;
    const annotationBillCurrency = formValues?.main?.general?.annotation_bill_currency;
    const purchaseOrderCurrency = formValues?.item?.purchaseOrder?.currencyCode;
    const { currencyCodesBillOptions, defaultBillCurrency, defaultCurrencyCodeReceiverByBillCurrency, isFetching, hasData, } = usePartnershipPaymentOptions({
        partnershipId: partnership?.id,
    });
    const currencyOptions = getCurrencyOptionsWithAnnotationData(currencyCodesBillOptions, annotationBillCurrency, purchaseOrderCurrency);
    const setDefaultReceivingCurrency = (billCurrency) => {
        if (isCreateItemVendorReceivingCurrencyEnabled()) {
            dispatch(change(formNamesItem.CREATE_ITEM, createItemFormFields.ITEM_CURRENCY_CODE_RECEIVER, defaultCurrencyCodeReceiverByBillCurrency[billCurrency]));
        }
    };
    React.useEffect(() => {
        if (isItemEdit || annotationBillCurrency || purchaseOrderCurrency) {
            return;
        }
        if (defaultBillCurrency) {
            dispatch(change(formNamesItem.CREATE_ITEM, createItemFormFields.MAIN_GENERAL_BILL_CURRENCY, defaultBillCurrency));
            setDefaultReceivingCurrency(defaultBillCurrency);
        }
    }, [defaultBillCurrency]);
    const prevIsFetching = usePrevious(isFetching);
    const onlyOneOptionAvailable = currencyOptions?.length === 1;
    const isDisabled = getIsDisabled.getIsDisabled(formValues);
    React.useEffect(() => {
        if (prevIsFetching && !isFetching && hasData) {
            if (purchaseOrderCurrency) {
                dispatch(change(formNamesItem.CREATE_ITEM, createItemFormFields.MAIN_GENERAL_BILL_CURRENCY, purchaseOrderCurrency));
                dispatch(touch(formNamesItem.CREATE_ITEM, rest.name));
                if (customValidation(currencyCodesBillOptions)(purchaseOrderCurrency) === undefined) {
                    setDefaultReceivingCurrency(purchaseOrderCurrency);
                }
            }
            else if (annotationBillCurrency) {
                if (customValidation(currencyCodesBillOptions)(annotationBillCurrency) !== undefined) {
                    dispatch(touch(formNamesItem.CREATE_ITEM, rest.name));
                }
                setDefaultReceivingCurrency(billCurrencyFormValue);
            }
        }
    }, [
        dispatch,
        prevIsFetching,
        isFetching,
        rest.name,
        billCurrencyFormValue,
        annotationBillCurrency,
        purchaseOrderCurrency,
    ]);
    return (React.createElement(Field, { component: SelectFieldV2, options: currencyOptions, ...rest, footer: React.createElement(MissingCurrencyFooter, null), isDisabled: isDisabled || isFetching || onlyOneOptionAvailable, isLoading: isFetching, isLocked: onlyOneOptionAvailable, lockedTooltipProps: {
            title: (React.createElement(CurrencyCodeBlockFieldTooltipText, { companyIntegrationSettings: companyIntegrationSettings, countryCodePartner: partnership?.countryCodePartner, currencyCodesBillOptions: currencyOptions, ledger: ledger, onlyOneOptionAvailable: onlyOneOptionAvailable, purchaseOrderCurrency: purchaseOrderCurrency })),
        }, onChange: (newBillCurrency) => {
            setDefaultReceivingCurrency(newBillCurrency);
        }, validate: [customValidation(currencyCodesBillOptions)] }));
};
export default React.memo(CurrencyCodeBlockField);
