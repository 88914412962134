import PropTypes from 'prop-types';
import React from 'react';

import { EscButton } from 'components/EscButton';

import './SidePanelNav.scss';

const SidePanelNav = ({ headerText, onClose }) => (
  <div className="panel-nav">
    <h3 className="panel-tab panel-tab--selected">{headerText}</h3>
    <EscButton onPress={onClose} />
  </div>
);

SidePanelNav.propTypes = {
  headerText: PropTypes.string,
  onClose: PropTypes.func,
};

SidePanelNav.defaultProps = {
  headerText: 'Confirmation',
  onClose: undefined,
};

export default SidePanelNav;
