import React from 'react';
import { Text } from 'components';
import { typography } from 'constants/styles';
import { AccountBeneficiaryInfo } from 'modules/beneficiary';
import { ManuallyAddBankAccountField } from 'modules/connectBank/ManuallyAddBankAccountSideSheet/components';
import { AccountTypeField } from 'modules/fundingAccount/components/AccountTypeField';
const InternationalPartnerFundingAccountBankDetails = ({ availableCurrencies, bankExtraFields, company, country, fields, formName, partnership, preSelectedCurrencyCode, }) => (React.createElement("div", { className: "international-partner-funding-account__bank-details" },
    React.createElement(Text.Semibold, { className: "mt-5", color: typography.TextColor.GREY_XX_DARK, weight: typography.TextWeight.LEVEL_600 }, "Account holder details"),
    React.createElement(AccountBeneficiaryInfo, { addMissingCurrencyFooter: true, availableCurrencies: availableCurrencies, company: company, formName: formName, partnership: partnership, selectedCurrencyCode: preSelectedCurrencyCode }),
    React.createElement("div", { className: "mt-5" },
        React.createElement(Text.Semibold, { className: "mt-5", color: typography.TextColor.GREY_XX_DARK, weight: typography.TextWeight.LEVEL_600 }, "Bank account details")),
    React.createElement("div", null,
        React.createElement(Text.Regular, { color: typography.TextColor.GREY_XX_DARK }, `This bank account must be located in ${country}.`)),
    bankExtraFields?.account_type && (React.createElement(AccountTypeField, { className: "mt-5", fieldName: bankExtraFields.account_type.field, form: formName })),
    fields?.map((fieldInfo) => (React.createElement(ManuallyAddBankAccountField, { fieldInfo: fieldInfo, formName: formName, isInternational: true, key: fieldInfo.field })))));
export default InternationalPartnerFundingAccountBankDetails;
