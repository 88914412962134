import { apiCall } from '@routable/framework';
import { dwollaBusinessCategoriesApiResponseModel, dwollaBusinessClassificationApiResponseModel } from '../models';
export const fundDataService = {
    listDwollaBusinessCategories: async () => apiCall({
        headers: { Accept: 'application/json' },
        method: 'get',
        url: '/data/funding/dwolla_business_categories/',
        responseModel: dwollaBusinessCategoriesApiResponseModel,
    }),
    listDwollaBusinessClassifications: async () => apiCall({
        headers: { Accept: 'application/json' },
        method: 'get',
        url: '/data/funding/dwolla_business_classification/',
        responseModel: dwollaBusinessClassificationApiResponseModel,
    }),
};
