import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { changeView } from 'actions/signUpFlow';

import { ButtonV2, IconNames } from 'components';

import { ButtonAppearance, ButtonSize } from 'constants/button';
import { colors } from 'constants/styles';

import { getSaveStatus } from 'selectors/signupSelectors';

import SkipStepModal from './components/SkipStepModal/SkipStepModal';
import { Main, BackButton, Title, Subtitle, Footer, Header, SkipButton } from './FlowStepperMain.styles';
import { triggerSkipStep } from './helpers/skipStep';

/**
 * FlowStepperMain handles common UI & UX in the "main" section of the sign up flow
 * @param {Object} props
 * @param {function} [props.onBack]    // onBack: undefined | { path?: string, content?: string, handleNext?: fn }
 * @param {function} [props.onSubmit]  // submit form for verification
 * @param {function} [props.onNext]    // onNext: undefined | { path, content, handler }
 * @param {function} [props.onSkip]    // onSkip: undefined | { path, content, handler }
 * @param {object} [props.title]       // title: undefined | { content }
 * @param {object} [props.subTitle]    // subTitle: undefined | { content }
 * @param {JSX.Element} props.fields
 * @param {boolean} props.invalid
 * @param {string} [props.containerWidth]
 * @param {boolean} [props.submit]
 * @returns {JSX.Element}
 */
const FlowStepperMain = ({
  onBack,
  onSubmit,
  onNext,
  onSkip,
  title,
  subTitle,
  fields,
  invalid,
  containerWidth,
  submit = true,
}) => {
  // in case  we want to 'save' on each 'next' we can simply hook it up here
  const dispatch = useDispatch();
  const saveStatus = useSelector(getSaveStatus);

  const handleNext = () => {
    if (!submit) {
      dispatch(changeView(onNext.path));
    }
  };

  const handleBack = () => {
    if (onBack?.handleBack) {
      onBack.handleBack();
    } else {
      triggerSkipStep(dispatch, saveStatus, onBack.path);
    }
  };

  const handleSkip = () => {
    if (onSkip?.handleSkip) {
      onSkip.handleSkip();
    } else {
      triggerSkipStep(dispatch, saveStatus, onSkip.path);
    }
  };

  return (
    <Main>
      <Header containerWidth={containerWidth}>
        {onBack && (
          <BackButton
            appearance={ButtonAppearance.BORDERLESS}
            color={colors.colorGreyXDarkHex}
            dataTestId="back--button"
            leftIconClassName="margin-right--xm"
            leftIconColor={colors.colorGreyXDarkHex}
            leftIconName={IconNames.ARROW_LEFT}
            onClick={handleBack}
            size={ButtonSize.MEDIUM}
            type="button"
          >
            {onBack.content || 'Back'}
          </BackButton>
        )}
        {title && <Title>{title.content}</Title>}
        {subTitle && <Subtitle>{subTitle.content}</Subtitle>}
      </Header>
      <div>{fields}</div>
      <Footer containerWidth={containerWidth}>
        <SkipStepModal invalid={invalid} />
        {onNext && (
          <ButtonV2
            dataTestId="next--button"
            isDisabled={invalid || onNext.isLoading}
            isLoading={onNext.isLoading}
            onClick={handleNext}
            rightIconClassName="margin-left--xm"
            rightIconColor={colors.colorWhiteHex}
            rightIconName={IconNames.ARROW_RIGHT}
            size={ButtonSize.MEDIUM}
            type={submit ? 'submit' : 'button'}
          >
            {onNext.content || 'Save and continue'}
          </ButtonV2>
        )}
        {onSubmit && (
          <ButtonV2
            dataTestId="submit--button"
            isDisabled={invalid}
            onClick={onSubmit}
            rightIconClassName="margin-left--xm"
            rightIconColor={colors.colorWhiteHex}
            rightIconName={IconNames.ARROW_RIGHT}
            size={ButtonSize.MEDIUM}
            type="button"
          >
            Submit for verification
          </ButtonV2>
        )}
        {onSkip && (
          <SkipButton
            appearance={ButtonAppearance.BORDERLESS}
            color={colors.colorGreyXXDarkHex}
            dataTestId="skip--button"
            onClick={handleSkip}
            size={ButtonSize.MEDIUM}
            type="button"
          >
            {onSkip.content || 'Skip for now'}
          </SkipButton>
        )}
      </Footer>
    </Main>
  );
};

export default FlowStepperMain;
