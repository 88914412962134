import { governmentIdType } from '@routable/companies-management';

import { RepresentativeIDTypes, DocumentTypes } from 'constants/document';
import { TinTypes } from 'constants/taxes';

import { valueOrEmptyString, valueOrUndefined } from 'helpers/forms';
import { isEqual, valueOrDefault } from 'helpers/utility';

import { getInitialAddressValues } from 'modules/address/helpers/initialValues';

import { fields } from '../fields';

import { canBusinessUseTinTypeSSN } from './business';
import { getMailingAddressFields } from './getMailingAddressFields';

/**
 * Helper to get the  initial values for the companyInfo sub-object
 * @param companyInfo
 * @returns {{legalName: *, dwollaBusinessClassification: *, dwollaBusinessCategory: *, companyType: *, tinType: *, tin: *, dbaName: *, businessType: *}}
 */
export const getInitialCompanyInfoValues = (companyInfo = {}) => ({
  businessDescription: valueOrUndefined(companyInfo.businessDescription),
  businessPhoneNumber: valueOrUndefined(companyInfo.businessPhoneNumber),
  businessType: valueOrEmptyString(companyInfo.businessType),
  businessUrl: valueOrUndefined(companyInfo.businessUrl),
  dbaName: valueOrEmptyString(companyInfo.dbaName),
  dwollaBusinessCategory: valueOrEmptyString(companyInfo.dwollaBusinessCategory),
  dwollaBusinessClassification: valueOrEmptyString(companyInfo.dwollaBusinessClassification),
  hasTin: valueOrUndefined(companyInfo.hasTin),
  id: valueOrUndefined(companyInfo.id),
  legalName: valueOrEmptyString(companyInfo.legalName),
  purposeOfPayments: valueOrUndefined(companyInfo.purposeOfPayments),
  stateOfIncorporation: valueOrEmptyString(companyInfo.stateOfIncorporation),
  tin: valueOrUndefined(companyInfo.tin),
  tinType: valueOrDefault(companyInfo.tinType, TinTypes.EIN),
});

/**
 * Helper to get the initial values for the BusinessLegalInfo form
 * @param params
 * @returns {{documents: [], name: (string), physicalAddress: {hideImportMailingAddress: boolean, country, city: string, streetAddress: string, streetAddressUnit: string, postalcode: string, state: string}, id: (string), info: {legalName: *, dwollaBusinessClassification: *, dwollaBusinessCategory: *, companyType: *, tinType: *, tin: *, dbaName: *, businessType: *}}}
 */
export const getBusinessLegalInfoInitialState = (params) => {
  const { currentCompany, currentCompanyInfo, physicalAddress, mailingAddress = {}, documents } = params;

  // compare addresses without Address.id
  // eslint-disable-next-line
  const { id: physicalAddressId, ...physicalAddressFields } = physicalAddress || {};

  // Mailing address comes with extra properties that will always differ from physical address
  // This destructuring compares only the relevant fields
  const mailingAddressFields = getMailingAddressFields(physicalAddressFields, mailingAddress);

  const sameAddresses = isEqual(physicalAddressFields, mailingAddressFields);

  return {
    companyType: currentCompany.companyType,
    documents,
    currentCompanyId: currentCompany.id,
    info: getInitialCompanyInfoValues(currentCompanyInfo),
    name: currentCompany.name,
    [fields.sameAddresses]: sameAddresses,
    physicalAddress: {
      country: 'US',
      ...physicalAddress,
    },
    mailingAddress: {
      // if there is a currentCompany.mailing address in state, use that
      // otherwise, default to using the physical address as: fields.sameAddresses default to true
      ...(mailingAddress || physicalAddress),
    },
  };
};

export const getInitialUIValues = (currentMembershipPersonalInfo = {}, representativeDocumentType, companyInfo) => {
  const { hasPassportNumber, hasSsn } = currentMembershipPersonalInfo;
  const hasSavedDocumentNumber = hasPassportNumber || hasSsn;
  const savedDocumentType = hasSavedDocumentNumber && representativeDocumentType;
  return {
    hasSavedDocumentNumber,
    savedDocumentType,
    hasPassportNumber,
    hasSsn,
    businessWithoutEIN:
      canBusinessUseTinTypeSSN(companyInfo?.businessType) && companyInfo?.tinType === governmentIdType.Enum.ssn,
  };
};

export const getRepresentativeInitialState = ({
  currentCompanyInfo,
  currentMembership,
  currentMembershipAddress,
  currentMembershipPersonalInfo,
  documentsOwned,
  currentUser,
}) => {
  const currentMembershipInfo = {
    ...currentMembershipPersonalInfo,
    legalFirstName: currentMembershipPersonalInfo?.legalFirstName || currentUser?.firstName,
    legalLastName: currentMembershipPersonalInfo?.legalLastName || currentUser?.lastName,
  };
  const representativeDocumentType = currentMembershipPersonalInfo?.hasPassportNumber
    ? RepresentativeIDTypes.PASSPORT_NUMBER
    : RepresentativeIDTypes.SOCIAL_SECURITY_NUMBER;
  return {
    address: getInitialAddressValues({ address: currentMembershipAddress }),
    documentsOwned,
    id: currentMembership?.id,
    personalInfo: currentMembershipInfo,
    phoneNumber: currentMembership?.phoneNumber || {},
    title: currentMembership?.title,
    ui: getInitialUIValues(currentMembershipPersonalInfo, representativeDocumentType, currentCompanyInfo),
    agreeBusinessRepresentative: currentMembership?.agreeBusinessRepresentative,
    agreeIdentity: currentMembership?.agreeIdentity,
    representativeDocumentType,
    documentUploadType: documentsOwned?.[0]?.documentType || DocumentTypes.DRIVERS_LICENSE,
  };
};
