import { mapCurrencyCodeToSelectOption } from 'helpers/currency';
export const getCurrencyOptionsWithAnnotationData = (currencyCodesBillOptions, annotationBillCurrency, purchaseOrderCurrency) => {
    if (purchaseOrderCurrency) {
        return [mapCurrencyCodeToSelectOption(purchaseOrderCurrency)];
    }
    if (!annotationBillCurrency) {
        return currencyCodesBillOptions;
    }
    if (currencyCodesBillOptions.find((currency) => currency.value === annotationBillCurrency)) {
        return currencyCodesBillOptions;
    }
    return [...currencyCodesBillOptions, mapCurrencyCodeToSelectOption(annotationBillCurrency)];
};
