import { Tooltip } from '@routable/gross-ds';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { changeView } from 'actions/signUpFlow';

import { GuideStatuses } from 'constants/guide';

import { getMembershipFullName, isMembershipCurrentMembership } from 'helpers/memberships';

import { useSelectorWithProps } from 'hooks';

import GuideSectionStatus from 'modules/dashboard/guide/global/presenters/GuideSectionStatus';

import { memberWithUserInfoSelector } from 'selectors/currentCompanySelectors';
import { rejectedMembershipDocumentsByMembershipSelector } from 'selectors/documentsSelectors';
import { allMembershipsWithFailedBeneficiaryStatus } from 'selectors/membershipsSelector';

import { views } from '../../constants';

/*
This component handles a set of cases with slightly different color highlights, copy, tooltips & enabled/disabled states
based on:
- if the representative for this component is the current user or not
- if that rep has submitted all info or still has some info to filled
*/

const RepresentativeInformation = ({ membership, notStarted }) => {
  const dispatch = useDispatch();
  const member = useSelectorWithProps(memberWithUserInfoSelector, membership.id);
  const failedBeneficiaries = useSelector(allMembershipsWithFailedBeneficiaryStatus) || [];
  const failedDocumentsForThisMember = useSelectorWithProps(
    rejectedMembershipDocumentsByMembershipSelector,
    membership.id,
  );

  const isCurrentUser = isMembershipCurrentMembership(membership);
  const memberIsAFailedBeneficiary = failedBeneficiaries?.some((rep) => rep.id === membership.id);
  const fullName = getMembershipFullName(member);
  const bizRepMemberHasFailedKYC = memberIsAFailedBeneficiary || failedDocumentsForThisMember?.length;

  const onClick = () => {
    dispatch(changeView(views.businessRepresentative));
  };

  const error = bizRepMemberHasFailedKYC
    ? {
        description: '',
        title: `${isCurrentUser ? "You'll" : `${fullName} will`} need to provide more information.`,
      }
    : undefined;

  let title;
  if (notStarted) {
    // if the current user is the first user going through this flow, it's possible they will not have any
    // business reps yet (ie. they have not yet agreed to the 2 checkboxes in the Representative step)
    // in this case, this component should just ask them to start:
    title = 'Complete your business representative information';
  } else {
    if (bizRepMemberHasFailedKYC) {
      title = 'Your representative information';
    } else if (membership.hasAllPersonalInfo) {
      // member has completed all info
      title = `${
        isCurrentUser ? 'You completed your' : `${fullName} has completed their`
      } business representative information`;
    } else {
      // member has not completed all their info
      title = `${
        isCurrentUser ? 'Complete your' : `${fullName} still needs to complete their`
      } business representative information`;
    }
  }

  return (
    <Tooltip
      data-testid="representative-information--link--tooltip"
      position="top"
      tooltip={isCurrentUser ? null : "You can't view someone else's representative information."}
    >
      <GuideSectionStatus
        callToAction={notStarted ? 'Add +' : 'Update'}
        description={undefined}
        error={error}
        isDisabled={!isCurrentUser}
        isRenderedOutsideGuideList={false}
        onClick={onClick}
        sectionURL={window.location.pathname}
        showCtaWhenComplete
        status={notStarted || !membership.hasAllPersonalInfo ? GuideStatuses.INCOMPLETE : GuideStatuses.COMPLETE}
        title={title}
      />
    </Tooltip>
  );
};

export default RepresentativeInformation;
