import { queryContext } from '@routable/shared';
import { useQuery } from '@tanstack/react-query';
import { annotationService } from './annotation.service';
export const useAnnotationPoll = (annotationId, refetchInterval = 1000) => useQuery({
    queryKey: ['ocr', 'annotations', annotationId],
    queryFn: () => annotationService.get({ annotationId }),
    enabled: Boolean(annotationId),
    context: queryContext,
    refetchIntervalInBackground: true,
    cacheTime: 0,
    refetchInterval: (annotation) => (annotation?.status === 'done' ? false : refetchInterval),
});
