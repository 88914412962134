import dayjs from 'dayjs';
import _omitBy from 'lodash/omitBy';
import { z } from 'zod';
export const operatorModel = z.enum(['BETWEEN', 'GTE', 'LTE', 'EQ']);
export const stringOperatorModel = z.enum(['EXACT', 'CONTAINS', 'STARTS_WITH', 'ENDS_WITH']);
export const operatorOptions = {
    GTE: 'is greater or equal to',
    EQ: 'is equal to',
    LTE: 'is less or equal to',
    BETWEEN: 'is between',
};
export const stringOperatorOptions = {
    EXACT: 'exactly matches',
    CONTAINS: 'contains',
    STARTS_WITH: 'starts with',
    ENDS_WITH: 'ends with',
};
export const operatorSymbols = {
    GTE: '≥',
    LTE: '≤',
    BETWEEN: '<->',
    EQ: '=',
};
export const dateModel = z
    .string()
    .or(z.date())
    .transform((str) => dayjs(str).toDate())
    .refine((date) => !isNaN(date.valueOf()), { message: 'Not a valid date' });
export const createdDateFilterModel = z.discriminatedUnion('operator', [
    z.object({
        operator: z.literal(operatorModel.Enum.BETWEEN),
        value: z.tuple([dateModel, dateModel]),
    }),
    z.object({ operator: z.literal(operatorModel.Enum.GTE), value: dateModel }),
    z.object({ operator: z.literal(operatorModel.Enum.LTE), value: dateModel }),
    z.object({ operator: z.literal(operatorModel.Enum.EQ), value: dateModel }),
]);
export const amountFilterModel = z.discriminatedUnion('operator', [
    z.object({
        operator: z.literal(operatorModel.Enum.BETWEEN),
        value: z.tuple([z.string(), z.string()]),
    }),
    z.object({ operator: z.literal(operatorModel.Enum.GTE), value: z.string() }),
    z.object({ operator: z.literal(operatorModel.Enum.LTE), value: z.string() }),
    z.object({ operator: z.literal(operatorModel.Enum.EQ), value: z.string() }),
]);
export const stringValueFilterModel = z.object({
    operator: stringOperatorModel,
    value: z.string(),
});
export const amountFilterToQueryParamsTransformer = (filterName, paramName) => ({ [filterName.value]: amount, ...filters }) => {
    const parsedData = amountFilterModel.safeParse(amount);
    if (!amount || !parsedData.success) {
        return filters;
    }
    const amountData = parsedData.data;
    const ParamMap = {
        [operatorModel.Enum.EQ]: paramName,
        [operatorModel.Enum.LTE]: `${paramName}__lte`,
        [operatorModel.Enum.GTE]: `${paramName}__gte`,
    };
    if (amountData.operator === operatorModel.Enum.BETWEEN) {
        const [gteValue, lteValue] = amountData.value;
        return {
            ...filters,
            [ParamMap.LTE]: lteValue.replace(',', ''),
            [ParamMap.GTE]: gteValue.replace(',', ''),
        };
    }
    const key = ParamMap[amountData.operator];
    const value = amountData.value.replace(',', '');
    return {
        [key]: value,
        ...filters,
    };
};
export const dateFilterToQueryParamsTransformer = (filterName, paramName) => ({ [filterName.value]: createdDate, ...filters }) => {
    const parsedCreatedDate = createdDateFilterModel.safeParse(createdDate);
    if (!createdDate || !parsedCreatedDate.success) {
        return filters;
    }
    const createdDateData = parsedCreatedDate.data;
    const CreatedAtParamMap = {
        [operatorModel.Enum.EQ]: paramName,
        [operatorModel.Enum.LTE]: `${paramName}__lte`,
        [operatorModel.Enum.GTE]: `${paramName}__gte`,
    };
    const format = 'YYYY-MM-DD';
    if (createdDateData.operator === operatorModel.Enum.BETWEEN) {
        const [gteDate, lteDate] = createdDateData.value;
        return {
            ...filters,
            [CreatedAtParamMap.LTE]: dayjs(lteDate).format(format),
            [CreatedAtParamMap.GTE]: dayjs(gteDate).format(format),
        };
    }
    const createdAtParamName = CreatedAtParamMap[createdDateData.operator];
    const dateValue = dayjs(createdDateData.value).format(format);
    return {
        [createdAtParamName]: dateValue,
        ...filters,
    };
};
export const dateOrAmountFilterFromQueryParamsTransformer = (filterName, paramName) => ({ [paramName]: dateOrAmount, [`${paramName}__gte`]: dateOrAmount__gte, [`${paramName}__lte`]: dateOrAmount__lte, ...rest }) => {
    if (!dateOrAmount && !dateOrAmount__gte && !dateOrAmount__lte) {
        delete rest[filterName];
        return rest;
    }
    if (dateOrAmount) {
        return {
            ...rest,
            [filterName]: { operator: operatorModel.Enum.EQ, value: dateOrAmount },
        };
    }
    if (dateOrAmount__gte && !dateOrAmount__lte) {
        return {
            ...rest,
            [filterName]: {
                operator: operatorModel.Enum.GTE,
                value: dateOrAmount__gte,
            },
        };
    }
    if (!dateOrAmount__gte && dateOrAmount__lte) {
        return {
            ...rest,
            [filterName]: {
                operator: operatorModel.Enum.LTE,
                value: dateOrAmount__lte,
            },
        };
    }
    return {
        ...rest,
        [filterName]: {
            operator: operatorModel.Enum.BETWEEN,
            value: [dateOrAmount__gte, dateOrAmount__lte],
        },
    };
};
export const stringFilterToQueryParamsTransformer = (filterName, paramName) => ({ [filterName.value]: stringFilter, ...filters }) => {
    const parsedData = stringValueFilterModel.safeParse(stringFilter);
    if (!stringFilter || !parsedData.success) {
        return filters;
    }
    const valueData = parsedData.data;
    const ParamMap = {
        [stringOperatorModel.Enum.EXACT]: `${paramName}__iexact`,
        [stringOperatorModel.Enum.CONTAINS]: `${paramName}__icontains`,
        [stringOperatorModel.Enum.STARTS_WITH]: `${paramName}__istartswith`,
        [stringOperatorModel.Enum.ENDS_WITH]: `${paramName}__iendswith`,
    };
    return {
        [ParamMap[valueData.operator]]: valueData.value,
        ...filters,
    };
};
export const stringFilterFromQueryParamsTransformer = (filterName, paramName) => ({ [`${paramName}__iexact`]: stringValueExact, [`${paramName}__icontains`]: stringValueContains, [`${paramName}__istartswith`]: stringValueStartsWith, [`${paramName}__iendswith`]: stringValueEndsWith, ...rest }) => {
    if (!stringValueExact && !stringValueContains && !stringValueStartsWith && !stringValueEndsWith) {
        delete rest[filterName];
        return rest;
    }
    if (stringValueExact) {
        return {
            ...rest,
            [filterName]: { operator: stringOperatorModel.Enum.EXACT, value: stringValueExact },
        };
    }
    if (stringValueContains) {
        return {
            ...rest,
            [filterName]: { operator: stringOperatorModel.Enum.CONTAINS, value: stringValueContains },
        };
    }
    if (stringValueStartsWith) {
        return {
            ...rest,
            [filterName]: { operator: stringOperatorModel.Enum.STARTS_WITH, value: stringValueStartsWith },
        };
    }
    return {
        ...rest,
        [filterName]: { operator: stringOperatorModel.Enum.ENDS_WITH, value: stringValueEndsWith },
    };
};
export const createMultiValueFiltersFromQueryParamsTransformer = (...filtersToTransform) => (filters) => {
    const filterCandidates = filtersToTransform.reduce((acc, val) => {
        return { ...acc, [val]: filters[val]?.split(',') };
    }, {});
    const multiValueFilters = _omitBy(filterCandidates, (val) => val === undefined);
    return { ...filters, ...multiValueFilters };
};
const joinIfArrayElseSingleValue = (val) => (Array.isArray(val) ? val.join(',') : val);
export const createMultiOptionFilterModel = (enumModel) => enumModel.or(z.array(enumModel)).nullish().transform(joinIfArrayElseSingleValue);
