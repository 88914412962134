import { z } from 'zod';
export const dwollaBusinessCategoryModel = z.object({
    id: z.string().uuid(),
    value: z.string().uuid(),
    text: z.string(),
});
export const dwollaBusinessClassificationModel = z.object({
    id: z.string().uuid(),
    value: z.string().uuid(),
    text: z.string(),
});
export const dwollaBusinessCategoriesApiResponseModel = z.array(dwollaBusinessCategoryModel);
export const dwollaBusinessClassificationApiResponseModel = z.record(z.string().uuid(), z.array(dwollaBusinessClassificationModel));
