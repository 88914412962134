import { z } from 'zod';
import { baseId, uuidModel } from './common.model';
export const getJsonApiRelationshipModel = (type, dataModel) => z.object({
    data: dataModel,
    type: z.literal(type),
});
export const getJsonApiModel = (model) => baseId.and(z.object({ attributes: model }));
export const getJsonApiModelRecord = (model) => z.record(z.string(), model);
export const createReferenceModel = (type, idModel = uuidModel) => z.object({
    data: z.object({ type: z.literal(type), id: idModel }).nullable(),
});
export const createMultiReferenceModel = (type, idModel = uuidModel) => z.object({
    meta: z.object({ count: z.number() }),
    data: z.array(z.object({ type: z.literal(type), id: idModel }).nullable()),
});
