import { RecentlyCompletedHint } from 'components';

import {
  AcceptItemComponentTypes,
  AcceptPartnershipComponentTypes,
  CommonExternalComponentTypes,
  UpdatePaymentComponentTypes,
} from 'constants/ui';

import ExternalCurrencyTypeAnnouncementHint from 'modules/external/ExternalCurrencyTypeAnnouncementHint';
import { ExternalItemsListWrapper } from 'modules/external/externalItemsListV2';
import { ExternalPaymentsList } from 'modules/external/externalPaymentsList';
import { ExternalReceiptSingleItem } from 'modules/external/externalReceiptV2/ExternalReceiptSingleItem';
import {
  CancelledStepHint,
  CompletedStepHint,
  FailedPaymentStepHint,
  IncompleteStepHint,
  ProcessingStepHint,
} from 'modules/external/externalStepHint';
import {
  UpdatePaymentMethodFlowForm,
  UpdatePaymentMethodFormWithCheckboxAndHints,
} from 'modules/external/externalUpdatePaymentMethod';
import { ExternalVendorCountryForm } from 'modules/external/ExternalVendorCountryForm';
import {
  PendingIllustrationSvg,
  PendingRegisterIllustrationSvg,
  RegisterIllustrationSvg,
  SuccessIllustrationSvg,
  SuccessRegisterIllustrationSvg,
} from 'modules/external/illustrations';
import MicroDepositsNeedVerificationWithoutConnectHint from 'modules/external/MicroDepositsNeedVerificationWithoutConnectHint';
import MicroDepositsNotYetReadyHint from 'modules/external/MicroDepositsNotYetReadyHint';
import MicroDepositsReadyHint from 'modules/external/MicroDepositsReadyHint';
import PartnerCompanyContactForm from 'modules/external/PartnerCompanyContactForm';
import PartnershipCompanyGeneralFormContainer from 'modules/external/PartnershipCompanyGeneralForm';
import { CurrentPaymentMethodHint, ItemPaymentMethodHint } from 'modules/external/paymentMethodHints';
import PaymentMethodSameHint from 'modules/external/PaymentMethodSameHint';
import { SubstituteW8Form, SubstituteW9Form } from 'modules/external/taxForms';
import TermsOfServiceHint from 'modules/external/TermsOfServiceHint';

import * as mockImageComponents from './mockImageComponents';

const ComponentTypes = {
  ...AcceptItemComponentTypes,
  ...AcceptPartnershipComponentTypes,
  ...CommonExternalComponentTypes,
  ...UpdatePaymentComponentTypes,
};

/**
 * External components map
 * @see withExternalComponents
 * @enum {FunctionComponent}
 */
export const componentsMap = {
  [ComponentTypes.AlreadyCompletedSideCard]: mockImageComponents.AlreadyCompletedSideCard,
  [ComponentTypes.CancelledStepHint]: CancelledStepHint,
  [ComponentTypes.CompletedStepHint]: CompletedStepHint,
  [ComponentTypes.CurrentPaymentMethod]: CurrentPaymentMethodHint,
  [ComponentTypes.ExternalCurrencyTypeAnnouncementHint]: ExternalCurrencyTypeAnnouncementHint,
  [ComponentTypes.ExternalItemsList]: ExternalItemsListWrapper,
  [ComponentTypes.ExternalPaymentsList]: ExternalPaymentsList,
  [ComponentTypes.ExternalReceiptPayment]: ExternalReceiptSingleItem,
  [ComponentTypes.ExternalReceiptSingleItem]: ExternalReceiptSingleItem,
  [ComponentTypes.ExternalVendorCountryForm]: ExternalVendorCountryForm,
  [ComponentTypes.FailedPaymentStepHint]: FailedPaymentStepHint,
  [ComponentTypes.IncompleteStepHint]: IncompleteStepHint,
  [ComponentTypes.ItemPaymentMethod]: ItemPaymentMethodHint,
  [ComponentTypes.MicroDepositsNeedVerificationWithoutConnectHint]: MicroDepositsNeedVerificationWithoutConnectHint,
  [ComponentTypes.MicroDepositsNotReadyHint]: MicroDepositsNotYetReadyHint,
  [ComponentTypes.MicroDepositsReadyHint]: MicroDepositsReadyHint,
  [ComponentTypes.NoLongerNeeded]: mockImageComponents.NoLongerNeeded,
  [ComponentTypes.PartnerCompanyGeneralForm]: PartnershipCompanyGeneralFormContainer,
  [ComponentTypes.PartnerCompanyContactForm]: PartnerCompanyContactForm,
  [ComponentTypes.PaymentMethodSameHint]: PaymentMethodSameHint,
  [ComponentTypes.PendingIllustrationSvg]: PendingIllustrationSvg,
  [ComponentTypes.PendingPaymentMethod]: CurrentPaymentMethodHint,
  [ComponentTypes.PendingPaymentsToAcceptList]: mockImageComponents.PendingPaymentsToAcceptList,
  [ComponentTypes.PendingRegisterIllustrationSvg]: PendingRegisterIllustrationSvg,
  [ComponentTypes.ProcessingStepHint]: ProcessingStepHint,
  [ComponentTypes.RecentlyCompletedHint]: RecentlyCompletedHint,
  [ComponentTypes.RegisterIllustrationSvg]: RegisterIllustrationSvg,
  [ComponentTypes.SubstituteW8Form]: SubstituteW8Form,
  [ComponentTypes.SubstituteW9Form]: SubstituteW9Form,
  [ComponentTypes.SuccessRegisterIllustrationSvg]: SuccessRegisterIllustrationSvg,
  [ComponentTypes.SuccessIllustrationSvg]: SuccessIllustrationSvg,
  [ComponentTypes.TermsOfServiceHint]: TermsOfServiceHint,
  [ComponentTypes.UnauthorizedSvg]: mockImageComponents.UnauthorizedSvg,
  [ComponentTypes.UpdatedPaymentMethod]: CurrentPaymentMethodHint,
  [ComponentTypes.UpdatePaymentMethodFlowForm]: UpdatePaymentMethodFlowForm,
  [ComponentTypes.UpdatePaymentMethodFormWithCheckboxAndHints]: UpdatePaymentMethodFormWithCheckboxAndHints,
};
