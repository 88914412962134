import { ArrowRight, Book } from '@carbon/icons-react';
import { Button } from '@routable/gross-ds';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openCreateManualAddressModal } from 'actions/createManualAddress';
import CreateManualAddressFundingAccountContainer from 'modules/address/createManualAddressFundingAccount/CreateManualAddressFundingAccountContainer';
import { currentCompanySelector } from 'selectors/currentCompanySelectors';
export const AddCheckAddress = () => {
    const currentCompany = useSelector(currentCompanySelector);
    const dispatch = useDispatch();
    return (React.createElement("div", null,
        React.createElement(CreateManualAddressFundingAccountContainer, null),
        React.createElement(Button, { className: "!w-full h-auto group gap-4", "data-testid": "btn--add-check-address", onPress: () => dispatch(openCreateManualAddressModal({ printCompany: currentCompany.name })), variant: "outline" },
            React.createElement(Book, null),
            React.createElement("span", { className: "inline-flex flex-col flex-1 items-start" },
                React.createElement("span", { className: "font-semibold" }, "Check"),
                React.createElement("span", { className: "text-grey-60 group-hover:text-blue-40" }, "1-2 days verification")),
            React.createElement(ArrowRight, null))));
};
