import { InformationFilled } from '@carbon/icons-react';
import { TruncatableText } from '@routable/components';
import { Tooltip } from '@routable/gross-ds';
import clsx from 'clsx';
import React from 'react';
import { FlexCol, FlexRow } from 'components/layout';
const RadioTitle = ({ isChecked, optionText, subText, tooltipProps }) => {
    if (!optionText) {
        return null;
    }
    return (React.createElement(FlexRow, { className: "radio--title" },
        React.createElement(FlexCol, { className: "pl-4 w-full" },
            React.createElement("span", { className: clsx('radio--optiontext text-sm bold', {
                    'text-black': isChecked,
                    'text-grey-70': !isChecked,
                }) }, optionText),
            subText && (React.createElement(TruncatableText, { className: "radio--subtext text-grey-70 text-[13px]", position: "top", text: subText, variant: "dark" }))),
        tooltipProps?.title && (React.createElement("div", { className: "radio--tooltip" },
            React.createElement(Tooltip, { "data-testid": "radio-icon--tooltip", tooltip: tooltipProps.title, variant: "light" },
                React.createElement(InformationFilled, { className: "w-6 h-6 text-grey-50 flex-shrink-0", title: "information" }))))));
};
export default RadioTitle;
