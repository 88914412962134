import { bindWorker } from './threading/thread.service.js';
import { Auth0Client } from './helpers/auth.helper.js';
const activeThreads = [];
const setActiveThread = (key, threadObj) => {
    const threadIdx = activeThreads.findIndex((item) => {
        return item.key === key;
    });
    if (threadIdx < 0) {
        const activeWorker = bindWorker(threadObj);
        activeThreads.push({
            key: key,
            thread: activeWorker,
        });
    }
};
const getActiveThread = (key) => {
    const threadIdx = activeThreads.findIndex((item) => {
        return item.key === key;
    });
    if (threadIdx < 0) {
        return null;
    }
    return activeThreads[threadIdx];
};
setActiveThread('routable.thread.loader', new Worker(new URL(`${location.origin}/static/assets/threads/thread.loader.js`)));
const ThreadInterface = getActiveThread('routable.thread.loader').thread;
const globalWatchEvents = async () => {
    ThreadInterface.RoutableEvents.Subscribe('routable:storage:error', (event) => {
    });
    ThreadInterface.RoutableEvents.Subscribe('routable:storage:open', (event) => {
    });
};
const startThreads = async (options) => {
    try {
        globalWatchEvents();
        ThreadInterface.RoutableStorage.openDatabase(options.database.dbName, options.database.version, options.database.tables ? options.database.tables : []).then((res) => {
            if (res.Error) {
                ThreadInterface.RoutableEvents.Publish('routable:storage:error', res);
            }
            else {
                ThreadInterface.RoutableEvents.Publish('routable:storage:open', {
                    database: 'open',
                });
            }
        });
    }
    catch (ex) {
        console.log('***START ERROR->', ex.message);
    }
    return true;
};
const parseQueryString = (queryString) => {
    const params = {};
    if (!queryString || queryString === '?') {
        return params;
    }
    const queryWithoutQuestionMark = queryString.substring(1);
    const queries = queryWithoutQuestionMark.split('&');
    queries.forEach((query) => {
        const [key, value] = query.split('=').map(decodeURIComponent);
        params[key] = value || '';
    });
    return params;
};
const getAuthenticationToken = async () => {
    try {
        const membershipsRaw = localStorage.getItem('memberships');
        if (membershipsRaw) {
            const memberships = JSON.parse(membershipsRaw);
            const currentMembershipId = localStorage.getItem('currentMembershipId') || '';
            const membership = memberships[currentMembershipId];
            const token = membership?.authToken;
            if (token) {
                return `Token ${token}`;
            }
            if (Auth0Client.isAuthenticated) {
                try {
                    const auth0Token = await Auth0Client.getAccessTokenSilently();
                    if (auth0Token) {
                        return `JWT_AUTH ${auth0Token}`;
                    }
                    return '';
                }
                catch {
                    return '';
                }
            }
        }
        else {
            const qs = parseQueryString(window.location.search);
            if (qs.token) {
                return `JWT ${qs.token}`;
            }
        }
        return '';
    }
    catch {
        return '';
    }
};
const getRequestHeaders = (headers) => {
    const membershipsData = JSON.parse(localStorage.getItem('memberships') ?? '{}') || {};
    const currentMembershipId = localStorage.getItem('currentMembershipId') || '';
    const companyId = membershipsData?.[currentMembershipId]?.companyId;
    return {
        ...headers,
        'fe-version': process.env.REACT_APP_VERSION ?? '',
        'X-Location': window.location.href,
        'X-Company-Id': companyId,
    };
};
export let RoutableEvents = ThreadInterface.RoutableEvents;
export const __setRoutableEvents__ = (routableEvents) => {
    ThreadInterface.RoutableEvents = routableEvents;
    RoutableEvents = routableEvents;
};
export const RoutableCalculations = ThreadInterface.RoutableCalculations;
export const RoutableIntervals = ThreadInterface.RoutableIntervals;
export const RoutableStorage = ThreadInterface.RoutableStorage;
export const RoutableNetwork = {
    baseUrl: process.env.API_URL,
    getAuthenticationToken: getAuthenticationToken,
    get: (options) => {
        return new Promise(async (resolve, reject) => {
            const threadOptions = {
                ...options,
                ...{
                    authToken: await getAuthenticationToken(),
                    baseUrl: RoutableNetwork.baseUrl,
                    _id: crypto.randomUUID().replaceAll('-', ''),
                },
                defaultHeaders: getRequestHeaders(options.headers),
            };
            ThreadInterface._routableNetwork
                .get(threadOptions)
                .then((response) => {
                if (response.error) {
                    reject(response);
                }
                else {
                    resolve(response);
                }
            })
                .catch((err) => {
                reject(err);
            });
        });
    },
    post: (options) => {
        return new Promise(async (resolve, reject) => {
            const threadOptions = {
                ...options,
                ...{
                    authToken: await getAuthenticationToken(),
                    baseUrl: RoutableNetwork.baseUrl,
                    _id: crypto.randomUUID().replaceAll('-', ''),
                },
                defaultHeaders: getRequestHeaders(),
            };
            ThreadInterface._routableNetwork
                .post(threadOptions)
                .then((response) => {
                if (response.error) {
                    reject(response);
                }
                else {
                    resolve(response);
                }
            })
                .catch((err) => {
                reject(err);
            });
        });
    },
    put: (options) => {
        return new Promise(async (resolve, reject) => {
            const threadOptions = {
                ...options,
                ...{
                    authToken: await getAuthenticationToken(),
                    baseUrl: RoutableNetwork.baseUrl,
                    _id: crypto.randomUUID().replaceAll('-', ''),
                },
                defaultHeaders: getRequestHeaders(),
            };
            ThreadInterface._routableNetwork
                .put(threadOptions)
                .then((response) => {
                if (response.error) {
                    reject(response);
                }
                else {
                    resolve(response);
                }
            })
                .catch((err) => {
                reject(err);
            });
        });
    },
    delete: (options) => {
        return new Promise(async (resolve, reject) => {
            const threadOptions = {
                ...options,
                ...{
                    authToken: await getAuthenticationToken(),
                    baseUrl: RoutableNetwork.baseUrl,
                    _id: crypto.randomUUID().replaceAll('-', ''),
                },
                defaultHeaders: getRequestHeaders(),
            };
            ThreadInterface._routableNetwork
                .delete(threadOptions)
                .then((response) => {
                if (response.error) {
                    reject(response);
                }
                else {
                    resolve(response);
                }
            })
                .catch((err) => {
                reject(err);
            });
        });
    },
    patch: (options) => {
        return new Promise(async (resolve, reject) => {
            const threadOptions = {
                ...options,
                ...{
                    authToken: await getAuthenticationToken(),
                    baseUrl: RoutableNetwork.baseUrl,
                    _id: crypto.randomUUID().replaceAll('-', ''),
                },
                defaultHeaders: getRequestHeaders(),
            };
            ThreadInterface._routableNetwork
                .patch(threadOptions)
                .then((response) => {
                if (response.error) {
                    reject(response);
                }
                else {
                    resolve(response);
                }
            })
                .catch((err) => {
                reject(err);
            });
        });
    },
};
export const Loader = async (options) => {
    await startThreads(options);
};
