import { Add, ArrowUpRight, InformationFilled } from '@carbon/icons-react';
import { Button, Hint } from '@routable/gross-ds';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field } from 'redux-form';
import { verifyFundingAccountModalOpen } from 'actions/funding';
import { updatePaymentMethodFormFields } from 'constants/formFields';
import { formNamesExternal } from 'constants/forms';
import { PLATFORM_EMAILS } from 'constants/platform';
import { isFundingSourceInvalidMicroDepositsProcessed } from 'helpers/funding';
import { getMailtoUrl } from 'helpers/urls';
import { useFieldValue, useSelectorWithProps } from 'hooks';
import { PaymentMethodManage } from 'modules/fundingAccount/PaymentMethod';
import { isFormFundingInternalAndInvalidOrPendingSelector } from 'queries/updatePaymentFormSelectors';
import { companySelector } from 'selectors/companiesSelectors';
import ConvertFundingAccountHint from './ConvertFundingAccountHint';
import {} from './UpdatePaymentMethodFundingAccount.types';
const UpdatePaymentMethodFundingAccount = ({ fundingAccount, fundingInfoBankAccount, fundingSources, partnership, ...rest }) => {
    const dispatch = useDispatch();
    const partnerCompany = useSelectorWithProps(companySelector, partnership?.partner);
    const selectedFundingAccount = useFieldValue(formNamesExternal.UPDATE_PAYMENT_METHOD, updatePaymentMethodFormFields.FUNDING_ACCOUNT);
    const isSelected = selectedFundingAccount === fundingAccount.id;
    const isFormFundingAccountInvalidOrPending = useSelector(isFormFundingInternalAndInvalidOrPendingSelector);
    const fundingSource = fundingSources.find((source) => fundingAccount.fundingSources.includes(source.id));
    const showConvertExternalAccountHint = isSelected && fundingAccount.isExternal;
    const showMicroDepositsHint = isSelected && isFormFundingAccountInvalidOrPending;
    const showMicroDepositsActions = fundingSource && isFundingSourceInvalidMicroDepositsProcessed(fundingSource);
    const handleOpenVerifyMicroDepositsModal = () => {
        dispatch(verifyFundingAccountModalOpen(fundingAccount.id, fundingSource.id));
    };
    return (React.createElement("div", { className: "mb-5" },
        React.createElement(Field, { component: PaymentMethodManage, disableManageActions: true, fundingAccount: fundingAccount, fundingInfoBankAccount: fundingInfoBankAccount, fundingSources: fundingSources, isSelectable: true, key: fundingAccount.id, name: updatePaymentMethodFormFields.FUNDING_ACCOUNT, partnership: partnership, shouldTreatInvalidStatusAsPendingVerification: true, type: "radio", value: fundingAccount.id, ...rest }),
        showConvertExternalAccountHint && (React.createElement(ConvertFundingAccountHint, { fundingAccount: fundingAccount, fundingInfoBankAccount: fundingInfoBankAccount, partnerName: partnerCompany?.name })),
        showMicroDepositsHint && (React.createElement(Hint, { className: "mt-3", title: showMicroDepositsActions
                ? 'Your bank account should have two micro-deposits available'
                : 'Your bank account will soon receive micro-deposits for verification', type: "info" },
            React.createElement("div", { className: "flex flex-col gap-4" },
                React.createElement("span", null,
                    "You will receive two small amounts deposited to your bank account (ending in",
                    ' ',
                    fundingInfoBankAccount?.accountNumber,
                    ") within the next 1-2 business days. The deposit descriptions begin with \"Routable\", and each one is less than $1.00."),
                showMicroDepositsActions && (React.createElement("div", { className: "flex items-center gap-5" },
                    React.createElement(Button, { "data-testid": "btn--open-verify-micro-deposits-modal", onPress: handleOpenVerifyMicroDepositsModal, size: "small" },
                        React.createElement(Add, null),
                        "Enter deposits to verify"),
                    React.createElement("a", { className: "!text-black inline-flex items-center text-xs underline gap-2", href: getMailtoUrl(PLATFORM_EMAILS.CHAT, {
                            subject: `I didn't receive micro deposits for my bank account ending in ${fundingInfoBankAccount.accountNumber}`,
                        }), rel: "noreferrer", target: "_blank" },
                        React.createElement(InformationFilled, { className: "fill-blue-40" }),
                        React.createElement("span", { className: "inline-flex items-center gap-1" },
                            "Didn't receive deposit amounts?",
                            React.createElement(ArrowUpRight, { className: "!fill-black", size: 12 }))))))))));
};
export default UpdatePaymentMethodFundingAccount;
