import React, {} from 'react';
import {} from 'types/quickswitch';
import { MINIMUM_COUNT_FOR_SEARCHBAR } from './constants';
import QuickswitchErrorModal from './QuickswitchErrorModal';
import QuickswitchModal, {} from './QuickswitchModal';
const Quickswitch = ({ children, totalNumberOfMemberships = 0, fetchMembershipList, openModal, isErrorModalOpen = false, ...modalProps }) => {
    const handleClick = () => {
        fetchMembershipList();
        openModal();
    };
    if (totalNumberOfMemberships <= 1) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("button", { className: "justify-between quickswitch-button w-full", onClick: handleClick, type: "button" }, children),
        React.createElement(QuickswitchModal, { showSearchBar: totalNumberOfMemberships > MINIMUM_COUNT_FOR_SEARCHBAR, ...modalProps }),
        React.createElement(QuickswitchErrorModal, { isModalOpen: isErrorModalOpen })));
};
export default Quickswitch;
