import PropTypes from 'prop-types';
import React from 'react';

import { fullCountryNameLookup } from 'constants/i18n';

const AccountAddress = ({
  attentionLine,
  streetAddress,
  streetAddressUnit,
  city,
  state,
  postalcode,
  printCompany,
  printName,
  country,
}) => {
  let streetAddressLineText = streetAddress;
  if (streetAddressUnit) {
    streetAddressLineText += `, ${streetAddressUnit}`;
  }

  return (
    <div className="flex flex-col gap-1 text-sm text-black">
      {!!attentionLine && <span>{attentionLine}</span>}

      {!!printName && <span>{printName}</span>}

      {!!printCompany && <span>{printCompany}</span>}

      <span>{streetAddressLineText}</span>

      <span>{`${city}, ${state} ${postalcode}`}</span>

      <span>{fullCountryNameLookup[country]}</span>
    </div>
  );
};

AccountAddress.propTypes = {
  attentionLine: PropTypes.string,
  streetAddress: PropTypes.string.isRequired,
  streetAddressUnit: PropTypes.string,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  postalcode: PropTypes.string.isRequired,
  printCompany: PropTypes.string,
  printName: PropTypes.string,
  country: PropTypes.string.isRequired,
};

AccountAddress.defaultProps = {
  printCompany: undefined,
  printName: undefined,
  streetAddressUnit: undefined,
};

export default AccountAddress;
