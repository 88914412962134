import { PaymentDeliveryMethodType } from '@routable/shared';
import React from 'react';
import { useLocation } from 'react-router-dom';
import {} from 'constants/item';
import { useSelectorWithProps } from 'hooks';
import { getPaymentMethodsAccepted } from 'modules/createPartnerCompany/helpers/paymentMethods';
import { itemFromQuerySelector } from 'queries/itemRouterSelectors';
import { partnershipFromQuerySelector } from 'queries/partnershipCompoundSelectors';
import { GetPaidViaPayPal } from '../../../GetPaidViaPayPal';
import { AddBankAccountManually, AddCheckAddress, ConnectBankAccountViaPlaid } from './components';
export const AddFundingAccountOptions = ({ itemKind }) => {
    const location = useLocation();
    const item = useSelectorWithProps(itemFromQuerySelector, { location });
    const partnership = useSelectorWithProps(partnershipFromQuerySelector, { location });
    const paymentDeliveryMethodsAccepted = getPaymentMethodsAccepted(partnership, itemKind, item);
    const acceptACH = paymentDeliveryMethodsAccepted.includes(PaymentDeliveryMethodType.ACH);
    const acceptCheck = paymentDeliveryMethodsAccepted.includes(PaymentDeliveryMethodType.CHECK);
    const acceptPaypal = paymentDeliveryMethodsAccepted.includes(PaymentDeliveryMethodType.PAYPAL);
    return (React.createElement("div", { className: "flex flex-col gap-3" },
        acceptACH && (React.createElement(React.Fragment, null,
            React.createElement(ConnectBankAccountViaPlaid, null),
            React.createElement(AddBankAccountManually, null))),
        acceptCheck && React.createElement(AddCheckAddress, null),
        acceptPaypal && React.createElement(GetPaidViaPayPal, null)));
};
