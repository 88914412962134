export const DocumentStatuses = {
  PENDING: 'uploaded',
  VERIFIED: 'verified',
  REJECTED: 'rejected',
};

export const DocumentTypes = {
  IDENTIFICATION_CARD: 'id_card',
  DRIVERS_LICENSE: 'license',
  PASSPORT: 'passport',
  OTHER: 'other',
};

export const RepresentativeIDTypes = {
  SOCIAL_SECURITY_NUMBER: 'social_security_number',
  PASSPORT_NUMBER: 'passport_number',
};

export const DocumentAcceptImageString = '.jpg, .jpeg, .png';
export const DocumentAcceptString = `.pdf, .csv, .xlsx, ${DocumentAcceptImageString}`;
export const DocumentAcceptAttachmentString = `.csv, .docx, .txt, .xlsx, ${DocumentAcceptString}`;

export const AcceptFilesBillAttachments = `.docx, ${DocumentAcceptString}`;
