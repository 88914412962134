import { z } from 'zod';
import { currencyCodeModel } from './currency.model';
export var PaymentDeliveryOptionType;
(function (PaymentDeliveryOptionType) {
    PaymentDeliveryOptionType["ACH_EXPEDITED"] = "ach_expedited";
    PaymentDeliveryOptionType["ACH_NEXT_DAY"] = "ach_next_day";
    PaymentDeliveryOptionType["ACH_SAME_DAY"] = "ach_same_day";
    PaymentDeliveryOptionType["ACH_STANDARD"] = "ach_standard";
    PaymentDeliveryOptionType["CHECK_EXPEDITED"] = "check_expedited";
    PaymentDeliveryOptionType["CHECK_INTERNATIONAL"] = "check_international";
    PaymentDeliveryOptionType["CHECK_PRIORITY"] = "check_priority";
    PaymentDeliveryOptionType["CHECK_STANDARD"] = "check_standard";
    PaymentDeliveryOptionType["INTERNATIONAL_ACH"] = "international_ach";
    PaymentDeliveryOptionType["INTERNATIONAL_SWIFT"] = "international_swift";
    PaymentDeliveryOptionType["INTERNATIONAL_SWIFT_OUR_TIER_2"] = "international_swift_our_tier_2";
    PaymentDeliveryOptionType["INTERNATIONAL_SWIFT_SHA_TIER_2"] = "international_swift_sha_tier_2";
    PaymentDeliveryOptionType["RTP"] = "real_time_payment";
})(PaymentDeliveryOptionType || (PaymentDeliveryOptionType = {}));
export var PaymentDeliveryMethodType;
(function (PaymentDeliveryMethodType) {
    PaymentDeliveryMethodType["ACH"] = "ach";
    PaymentDeliveryMethodType["ANY"] = "any";
    PaymentDeliveryMethodType["CHECK"] = "check";
    PaymentDeliveryMethodType["INTERNATIONAL"] = "international";
    PaymentDeliveryMethodType["PAYPAL"] = "paypal";
})(PaymentDeliveryMethodType || (PaymentDeliveryMethodType = {}));
export const currencyModel = z.object({
    value: z.string().regex(/([\d,.]+)|(NaN)/gi, {
        message: 'can only contain numbers and , / . keys',
    }),
    currency: currencyCodeModel,
});
export const paymentDeliveryOptionTypeModel = z.nativeEnum(PaymentDeliveryOptionType);
export const paymentDeliveryMethodTypeModel = z.nativeEnum(PaymentDeliveryMethodType);
