import { InformationFilled } from '@carbon/icons-react';
import { Button, Hint } from '@routable/gross-ds';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isFundingAccountCheckAddress, isFundingAccountPayPalDirect } from 'helpers/funding';
import helpDocs from 'helpers/helpDocs';
import {} from 'interfaces/fundingAccount';
import { fundingAccountsIsConvertingSelector } from 'selectors/fundingSelectors';
import submitConvertFundingAccountFromExternal from 'thunks/submitConvertFundingAccountFromExternal';
const ConvertFundingAccountHint = ({ fundingAccount, fundingInfoBankAccount, partnerName }) => {
    const dispatch = useDispatch();
    const isConvertingExternalFundingAccount = useSelector(fundingAccountsIsConvertingSelector);
    const handleSubmitExternalFundingAccount = () => {
        dispatch(submitConvertFundingAccountFromExternal(fundingAccount.id));
    };
    const fundingAccountNumber = fundingInfoBankAccount?.accountNumber;
    const { title, description, fundingAccountType } = useMemo(() => {
        if (isFundingAccountCheckAddress(fundingAccount)) {
            return {
                fundingAccountType: 'check address',
                description: `This check address was added by ${partnerName} on your behalf. Please confirm you would
          like to use this check address for future payments.`,
                title: `This check address has been previously added`,
            };
        }
        if (isFundingAccountPayPalDirect(fundingAccount)) {
            return {
                fundingAccountType: 'paypal e-mail',
                description: `This paypal e-mail was added by ${partnerName} on your behalf. Please confirm you would
          like to use this paypal e-mail for future payments.`,
                title: `This paypal e-mail has been previously added`,
            };
        }
        return {
            fundingAccountType: 'bank account',
            description: `This bank account was added by ${partnerName} on your behalf. Please confirm you would 
        like to use this bank account for future payments, and complete our micro-deposit verification
        process.`,
            title: `This bank account (${fundingAccountNumber}) has been previously added`,
        };
    }, [fundingAccount, fundingAccountNumber, partnerName]);
    return (React.createElement(Hint, { className: "mt-3", title: title, type: "info" },
        React.createElement("div", { className: "flex flex-col gap-4" },
            React.createElement("span", null, description),
            React.createElement("div", { className: "flex items-center gap-5" },
                React.createElement(Button, { "data-testid": "btn--convert-external-funding-account", intent: "primary", loading: isConvertingExternalFundingAccount, onPress: handleSubmitExternalFundingAccount, size: "small" },
                    "Use this ",
                    fundingAccountType),
                React.createElement("a", { className: "inline-flex items-center text-xs gap-2 text-black hover:text-blue-40 underline", href: helpDocs.WHY_WAS_MY_PAYMENT_METHOD_PREVIOUSLY_ADDED, rel: "noreferrer", target: "_blank" },
                    React.createElement(InformationFilled, { className: "fill-blue-40" }),
                    React.createElement("span", { className: "inline-flex items-center gap-1" },
                        "Why is this ",
                        fundingAccountType,
                        " already added?"))))));
};
export default ConvertFundingAccountHint;
