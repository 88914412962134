import { useDwollaBusinessCategories, useDwollaBusinessClassification } from '@routable/fund-routes';
import { Tooltip } from '@routable/gross-ds';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';

import { IconNames, SelectFieldV2 } from 'components';
import CannotUpdateFieldHint from 'components/hintTypes/CannotUpdateFieldHint';

import { colors } from 'constants/styles';

import { requiredValidator } from 'helpers/fieldValidation';
import { sortObjectArray } from 'helpers/sort';

import { useFieldValue } from 'hooks';

import { containerWidthMap } from 'modules/signup-v3/constants/container';

import {
  currentCompanyVerificationUpdateAllowedSelector,
  isUpdatingCurrentCompanySelector,
} from 'selectors/currentCompanySelectors';

import { signupFlowForm } from '../constants';
import { getViewToFieldsMap } from '../fields';
import FlowStepperMain from '../FlowStepperMain';
import { viewSteps } from '../helpers/viewSteps';

import { Container, FieldContainer, TooltipContainer, InputIcon } from './BusinessCategory.styles';

const BusinessField = ({
  businessCategories = [],
  businessClassifications = {},
  name,
  id,
  companyCategory,
  isDisabled,
}) => {
  const businessClassificationOptions = businessClassifications[companyCategory] || [];
  const businessFieldProps = {
    businessCategory: {
      options: sortObjectArray(businessCategories, 'text'),
      label: 'Category',
      disabled: false,
    },
    businessClassification: {
      options: sortObjectArray(businessClassificationOptions, 'text'),
      label: 'Classification',
      disabled: !businessClassificationOptions.length,
    },
  };

  return (
    <FieldContainer disabled={businessFieldProps[id].disabled}>
      <Field
        component={SelectFieldV2}
        dataTestId={businessFieldProps[id].label}
        isDisabled={businessFieldProps[id].disabled || isDisabled}
        isLocked={isDisabled}
        isRequired
        label={businessFieldProps[id].label}
        name={name}
        options={businessFieldProps[id].options}
        type="text"
        validate={requiredValidator}
      />
      {businessFieldProps[id].disabled && (
        <TooltipContainer>
          <Tooltip
            position="right"
            tooltip="The classification options depend on the category you selected above. Select a category first."
          >
            <InputIcon color={colors.colorGreyDarkHex} icon={IconNames.LOCK} size={14} />
          </Tooltip>
        </TooltipContainer>
      )}
    </FieldContainer>
  );
};

export const BusinessCategory = ({ activeView, invalid }) => {
  const updatedAllowed = useSelector(currentCompanyVerificationUpdateAllowedSelector);
  const isUpdating = useSelector(isUpdatingCurrentCompanySelector);
  const companyCategory = useFieldValue(signupFlowForm, 'info.dwollaBusinessCategory');
  const viewToFieldsMap = useMemo(() => getViewToFieldsMap(), []);

  const { data: businessCategories } = useDwollaBusinessCategories();
  const { data: businessClassifications } = useDwollaBusinessClassification();

  return (
    <FlowStepperMain
      containerWidth={containerWidthMap.large}
      fields={
        <Container>
          {!updatedAllowed && <CannotUpdateFieldHint />}
          {viewToFieldsMap[activeView]?.map(({ name, ...props }) => (
            <BusinessField
              businessCategories={businessCategories}
              businessClassifications={businessClassifications}
              companyCategory={companyCategory}
              name={name}
              {...props}
              isDisabled={!updatedAllowed}
              key={name}
            />
          ))}
        </Container>
      }
      invalid={invalid || !updatedAllowed}
      onBack={viewSteps[activeView].previous}
      onNext={{
        ...viewSteps[activeView].next,
        isLoading: isUpdating,
      }}
      onSkip={updatedAllowed && viewSteps[activeView].skip}
      subTitle={{
        content: "If you can't find a perfect match, select the option that describes your business best.",
      }}
      title={{ content: "What's your business category and classification?" }}
    />
  );
};

export default BusinessCategory;
