import {} from '@routable/types';
import React, { useMemo } from 'react';
import { FieldArray } from 'redux-form';
import clsx from 'clsx';
import { getHeaderOrBodyCellProps, } from '../../../TablematicTable/tablematic.table.types';
import { fixCellWidths } from '../../helpers/tablematic.table.helpers';
import { RowContainer, StyledTableCell, TableRow } from './tablematic.table.rows.styled';
const RenderComponent = React.memo(({ CustomRowComponent, fieldProps, getTableSectionFieldSize, headersConfigById, horizontalScrollGroupRef, isOnDetailsPage, rowIdx, section, }) => {
    if (CustomRowComponent) {
        return (React.createElement(TableRow, { key: `table-row-${rowIdx + 1}`, "data-testid": "tablematic-row" },
            React.createElement(CustomRowComponent, { CellComponent: StyledTableCell, fieldProps: fieldProps, fixCellWidths: fixCellWidths, getHeaderOrBodyCellProps: getHeaderOrBodyCellProps, getTableSectionFieldSize: getTableSectionFieldSize, headersConfigById: headersConfigById, isOnDetailsPage: isOnDetailsPage, rowIdx: rowIdx, section: section })));
    }
    return (React.createElement(TableRow, { key: `table-row-${rowIdx + 1}`, "data-testid": "tablematic-row" }, Object.values(headersConfigById).map((cell, cellIdx) => {
        const headerConfig = headersConfigById[cell.id];
        if (!headerConfig?.isVisible) {
            return null;
        }
        const { Cell } = cell;
        const fieldSize = getTableSectionFieldSize(fieldProps[cell.id]);
        const bodyCellProps = fixCellWidths(getHeaderOrBodyCellProps(fieldSize));
        const cellClassNames = clsx(fieldProps[cell.id]?.className, 'use-scrollbars');
        return (React.createElement(StyledTableCell, { key: `table-cell-${cellIdx + 1}`, ...cell.getCellProps?.(), ...bodyCellProps, isOnDetailsPage: isOnDetailsPage },
            React.createElement(Cell, { ...fieldProps[cell.id], className: cellClassNames, getIsDisabled: fieldProps[cell.id].getIsDisabled, horizontalScrollGroupRef: horizontalScrollGroupRef, id: fieldProps[cell.id].getId(rowIdx), isOnDetailsPage: isOnDetailsPage, name: fieldProps[cell.id].getName(rowIdx), rowIdx: rowIdx, section: section, validate: fieldProps[cell.id].validate })));
    })));
});
const InternalTableRows = ({ CustomRowComponent, fieldProps, getAreTableRowFieldValuesEqualToInitial, getTableSectionFieldSize, headersConfigById, horizontalScrollGroupRef, innerWidth, isOnDetailsPage, rowContainerRef, rowDataLength, section, }) => {
    const dataRows = useMemo(() => Array.from({ length: rowDataLength }), [rowDataLength]);
    const isAccount = section.sectionPath.includes('_account');
    const billLineItemsSectionPath = isAccount ? 'line_items_account' : 'line_items_item';
    let sectionPath = section.sectionPath;
    if (section.id !== billLineItemsSectionPath) {
        sectionPath = isAccount
            ? 'line_items.line_items_account_block.line_items_account'
            : 'line_items.line_items_item_block.line_items_item';
    }
    return (React.createElement(RowContainer, { className: "tablematic-table-body w-full", key: `${section.id}.rows`, ref: rowContainerRef, style: { width: innerWidth } },
        isOnDetailsPage && (React.createElement(React.Fragment, null, dataRows.map((_, idx) => (React.createElement(RenderComponent, { CustomRowComponent: CustomRowComponent, fieldProps: fieldProps, getAreTableRowFieldValuesEqualToInitial: getAreTableRowFieldValuesEqualToInitial, getTableSectionFieldSize: getTableSectionFieldSize, headersConfigById: headersConfigById, horizontalScrollGroupRef: horizontalScrollGroupRef, isOnDetailsPage: isOnDetailsPage, rowIdx: idx, key: `${section.id}-${idx}`, section: section }))))),
        !isOnDetailsPage && (React.createElement(FieldArray, { name: sectionPath, component: ({ fields }) => (React.createElement(React.Fragment, null, fields.map((_field, rowIdx) => (React.createElement(RenderComponent, { CustomRowComponent: CustomRowComponent, fieldProps: fieldProps, getAreTableRowFieldValuesEqualToInitial: getAreTableRowFieldValuesEqualToInitial, getTableSectionFieldSize: getTableSectionFieldSize, headersConfigById: headersConfigById, horizontalScrollGroupRef: horizontalScrollGroupRef, isOnDetailsPage: isOnDetailsPage, key: `${section.id}-${rowIdx}`, rowIdx: rowIdx, section: section }))))) }))));
};
export const TablematicTableRows = React.memo(InternalTableRows);
