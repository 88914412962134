import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Field, getFormValues } from 'redux-form';

import {
  DatePickerForReduxForm,
  ReduxFormFieldRenderNumericInput,
  ReduxFormFieldRenderInput,
  InternationalPhoneInput,
  Checkbox,
  SelectFieldV2,
} from 'components';
import CannotUpdateFieldHint from 'components/hintTypes/CannotUpdateFieldHint';

import { NumericSuffix } from 'constants/numeric';

import { formatFieldDate } from 'helpers/fieldFormatters';
import { normalizeDate, numberOnly } from 'helpers/fieldNormalizers';
import {
  dateValidator,
  maxAgeValidator,
  minAgeValidator,
  requiredValidator,
  phoneNumberLengthValidator,
  phoneNumberRequiredValidator,
  internationalPhoneNumberValidator,
  ownershipPercentageValidator,
} from 'helpers/fieldValidation';

import { useCountries } from 'hooks';

import Accordion from 'modules/signup-v3/components/Accordion';
import { containerWidthMap } from 'modules/signup-v3/constants/container';

import {
  isUpdatingMembershipSelector,
  currentMembershipVerificationUpdateAllowedSelector,
} from 'selectors/membershipsSelector';

import { signupFlowForm } from '../constants';
import { howRoutableUseThisInformation } from '../constants/accordion';
import { getViewToFieldsMap } from '../fields';
import FlowStepperMain from '../FlowStepperMain';
import { viewSteps } from '../helpers/viewSteps';

import { FieldWrapper, FieldsContainer } from './PersonalInformation.styled';

const minAge18Validator = minAgeValidator(18);
const maxAge120Validator = maxAgeValidator(120);
const formatDate = formatFieldDate();
const formValueSelector = getFormValues(signupFlowForm);

const fields = {
  title: {
    component: ReduxFormFieldRenderInput,
    dataTestId: 'company-title-field',
    isRequired: true,
    placeholder: 'Your title in the company',
    type: 'text',
    validate: [requiredValidator],
  },
  isCompanyBeneficiary: {
    component: Checkbox,
    dataTestId: 'company-beneficiary-checkbox-field',
    type: 'checkbox',
    flex: true,
  },
  legalFirstName: {
    component: ReduxFormFieldRenderInput,
    dataTestId: 'first-name-field',
    isRequired: true,
    placeholder: 'First name',
    type: 'text',
    validate: [requiredValidator],
  },
  legalLastName: {
    component: ReduxFormFieldRenderInput,
    dataTestId: 'last-name-field',
    isRequired: true,
    placeholder: 'Last name',
    type: 'text',
    validate: [requiredValidator],
  },
  dateOfBirth: {
    component: DatePickerForReduxForm,
    dataTestId: 'date-of-birth-field',
    format: formatDate,
    isRequired: true,
    normalize: (value) => normalizeDate(value),
    openToDate: new Date('1990/09/28'),
    placeholderText: 'Date of birth (mm/dd/yyyy)',
    scrollableYearDropdown: true,
    showYearDropdown: true,
    validate: [maxAge120Validator, minAge18Validator, dateValidator, requiredValidator],
  },
  phoneNumber: {
    component: InternationalPhoneInput,
    dataTestId: 'phone-number-field',
    isRequired: true,
    placeholder: 'Phone number',
    type: 'text',
    validate: [phoneNumberRequiredValidator, phoneNumberLengthValidator, internationalPhoneNumberValidator],
  },
  countryOfBirth: {
    component: SelectFieldV2,
    dataTestId: 'country-of-birth-field',
    isRequired: true,
    label: 'Country of birth',
    validate: [requiredValidator],
  },
  percentOfOwnership: {
    component: ReduxFormFieldRenderNumericInput,
    dataTestId: 'percent-of-ownership-field',
    decimalScale: 0,
    isRequired: true,
    label: 'Percent of ownership',
    normalize: numberOnly,
    placeholder: '0',
    suffix: NumericSuffix.PERCENT,
    thousandSeparator: false,
    type: 'text',
    validate: [requiredValidator, ownershipPercentageValidator],
  },
};

export const PersonalInformationMain = ({ activeView, invalid }) => {
  const updatedAllowed = useSelector(currentMembershipVerificationUpdateAllowedSelector);

  const { countriesAsOptions } = useCountries();
  const formValues = useSelector(formValueSelector);
  const { isCompanyBeneficiary } = formValues?.personalInfo || {};

  const isUpdating = useSelector(isUpdatingMembershipSelector);
  const viewToFieldsMap = useMemo(() => getViewToFieldsMap(), []);

  const fieldsActiveView = viewToFieldsMap[activeView].reduce(
    (prev, currentField) => ({
      ...prev,
      [currentField.id]: {
        ...currentField,
      },
    }),
    {},
  );

  return (
    <FlowStepperMain
      containerWidth={containerWidthMap.small}
      fields={
        <>
          <FieldsContainer>
            {!updatedAllowed && <CannotUpdateFieldHint />}
            <FieldWrapper>
              <Field
                isDisabled={!updatedAllowed}
                isLocked={!updatedAllowed}
                name={fieldsActiveView.title.name}
                {...fields.title}
              />
            </FieldWrapper>
            <FieldWrapper flex>
              <FieldWrapper grow>
                <Field
                  dataFullStory
                  isDisabled={!updatedAllowed}
                  isLocked={!updatedAllowed}
                  name={fieldsActiveView.legalFirstName.name}
                  {...fields.legalFirstName}
                />
              </FieldWrapper>
              <FieldWrapper grow>
                <Field
                  dataFullStory
                  isDisabled={!updatedAllowed}
                  isLocked={!updatedAllowed}
                  name={fieldsActiveView.legalLastName.name}
                  {...fields.legalLastName}
                />
              </FieldWrapper>
            </FieldWrapper>
            <FieldWrapper flex>
              <FieldWrapper grow>
                <Field
                  dataFullStory
                  isDisabled={!updatedAllowed}
                  isLocked={!updatedAllowed}
                  name={fieldsActiveView.phoneNumber.name}
                  {...fields.phoneNumber}
                />
              </FieldWrapper>
              <FieldWrapper grow>
                <Field
                  isDisabled={!updatedAllowed}
                  isLocked={!updatedAllowed}
                  name={fieldsActiveView.dateOfBirth.name}
                  {...fields.dateOfBirth}
                />
              </FieldWrapper>
            </FieldWrapper>
            <FieldWrapper>
              <Field
                name={fieldsActiveView.countryOfBirth.name}
                options={countriesAsOptions}
                {...fields.countryOfBirth}
              />
            </FieldWrapper>
            <FieldWrapper flex>
              <FieldWrapper grow>
                <Field
                  isDisabled={!updatedAllowed}
                  isLocked={!updatedAllowed}
                  name={fieldsActiveView.isCompanyBeneficiary.name}
                  {...fields.isCompanyBeneficiary}
                  className="w-full border border-grey-20 px-5 py-3 rounded-md [&_.checkbox--label]:!items-center [&_.checkbox--label]:gap-2"
                  content={
                    <div className="flex flex-col">
                      <span className="text-grey-70 font-medium">I am a beneficial owner</span>
                      <span>An individual with 25% or more ownership</span>
                    </div>
                  }
                />
              </FieldWrapper>
              {isCompanyBeneficiary && (
                <FieldWrapper maxWidth="160px">
                  <Field name={fieldsActiveView.percentOfOwnership.name} {...fields.percentOfOwnership} />
                </FieldWrapper>
              )}
            </FieldWrapper>
          </FieldsContainer>
          <Accordion {...howRoutableUseThisInformation} containerWidth={containerWidthMap.small} />
        </>
      }
      invalid={invalid || !updatedAllowed}
      onBack={viewSteps[activeView].previous}
      onNext={{
        ...viewSteps[activeView].next,
        isLoading: isUpdating,
      }}
      onSkip={updatedAllowed && viewSteps[activeView].skip}
      subTitle={{
        content:
          "Routable is required to collect this information in order to combat financial crime. Don't worry — only you will be able to view your information.",
      }}
      title={{ content: 'Tell us a bit more about yourself' }}
    />
  );
};

export default PersonalInformationMain;
