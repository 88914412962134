import { featureFlag, featureFlagsQueryKey } from '@routable/feature-configs';
import { queryClient } from '@routable/shared';
import { ItemKinds, ItemStatuses } from 'enums/items';
import { getItemStatusByFilter } from 'helpers/items';
import { getQueryParamsFromUrl } from 'helpers/queryParams';
import { getDashboardURLParams, isTabInbox, isTabPayables } from 'helpers/urls';
import {} from 'interfaces/itemFilters';
import {} from 'interfaces/itemsRequest/ItemsRequest';
import {} from 'interfaces/table.types';
import { getItemKindByTab } from 'modules/dashboard/itemList/helpers';
import { payloadToCamelCase } from 'services/api/formatHelpers';
export const itemsRequestDataSelector = (state, params) => {
    const method = 'GET';
    const { tab, filter } = getDashboardURLParams();
    const { page, url } = params;
    const featureFlags = queryClient.getQueryData(featureFlagsQueryKey);
    const payablesFilterDefault = featureFlags?.find((flagData) => flagData.featureFlag === featureFlag.Enum.payables_filter_default);
    const isPayablesStatusFilterEnabled = featureFlags?.find((flagData) => flagData.featureFlag === featureFlag.Enum.payables_status_filter);
    let status = getItemStatusByFilter(filter, isPayablesStatusFilterEnabled);
    let kind = getItemKindByTab(tab);
    const isPayables = payablesFilterDefault ? isTabPayables(tab) || isTabInbox(tab) : isTabPayables(tab);
    let filters = isPayables ? state.table.payablesTable.filters || [] : state.table.receivablesTable.filters || [];
    const sort = isTabPayables(tab) ? state.table.payablesTable.sort || '' : state.table.receivablesTable.sort || '';
    const queryParams = payloadToCamelCase(getQueryParamsFromUrl());
    if (isTabInbox(tab)) {
        filters = payablesFilterDefault ? filters : [];
        kind = ItemKinds.PAYABLE;
        status = [ItemStatuses.CREATED, ItemStatuses.OCR];
    }
    const { pagination } = state.items;
    return {
        ...queryParams,
        method,
        filter,
        filters,
        kind,
        pagination: {
            ...pagination,
            pageSize: page?.size || pagination.pageSize,
            page: page?.number || 1,
        },
        sort,
        status,
        url,
    };
};
