import { z } from 'zod';
export const featureFlag = z.enum([
    'approve_and_next',
    'column_picker',
    'crossborder_multiple_providers_enabled',
    'custom_roles_enabled',
    'global_only_international_bank_account_validation',
    'inbox_side_by_side',
    'ocr_embedded_mode',
    'payables_filter_default',
    'payables_status_filter',
    'global_only_convert_csv_to_pdf',
]);
export const featureFlagsApiResponseModel = z.array(z.object({ featureFlag }));
export const featureFlagsMapModel = z.record(featureFlag, z.boolean());
