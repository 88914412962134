import merge from 'deepmerge';
import _isObject from 'lodash/isObject';

import { fetchExternalPartnershipRequestRoutine, submitFundingStateRoutine } from 'actions/routines/external';

const arrayMergeStrategy = (currentSteps, newSteps) => {
  // if it's the first time, just return whatever values we got
  if (currentSteps.length === 0) {
    return newSteps;
  }

  const mergedValues = [...currentSteps];

  // This is meant to keep previous step on the meta array.
  // Useful for External vendor country form, as this step is removed from api response once we add a country
  // but we still need to see the step on the UI
  mergedValues.forEach((currentStep, index) => {
    // Don't do anything if array doesn't not contain `stepName` key
    if (!_isObject(currentStep) || !currentStep.stepName) {
      return;
    }

    // If new step exists on the first version, update it
    const updatedStep = newSteps.find((step) => step.stepName === currentStep.stepName);
    if (updatedStep) {
      mergedValues[index] = updatedStep;
    }
  });

  return mergedValues;
};

const metaReducer = (state = {}, action) => {
  switch (action.type) {
    case fetchExternalPartnershipRequestRoutine.SUCCESS:
      return merge(state, action.payload.meta, {
        arrayMerge: arrayMergeStrategy,
      });

    case submitFundingStateRoutine.SUCCESS:
      return action.payload.meta;

    default:
      return state;
  }
};

export default metaReducer;
