import { Hint } from '@routable/gross-ds';
import clsx from 'clsx';
import React from 'react';
import { LinkNewPage } from 'components';
import { CONTACT_SUPPORT_LOWERCASE_LABEL } from 'constants/labels';
import { PLATFORM_EMAILS } from 'constants/platform';
import { getMailtoUrl } from 'helpers/urls';
const MaxPaymentMethodsReachedHint = ({ className }) => (React.createElement(Hint, { className: clsx('w-full', className), title: "You've reached the maximum amount of payment methods", type: "critical" },
    "Please ",
    React.createElement(LinkNewPage, { href: getMailtoUrl(PLATFORM_EMAILS.CHAT) }, CONTACT_SUPPORT_LOWERCASE_LABEL),
    " to remove an existing account."));
export default MaxPaymentMethodsReachedHint;
