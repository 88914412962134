import clsx from 'clsx';
import React from 'react';
import { isFundingAccountEligibleForRTP } from 'helpers/funding';
import { useToggle } from 'hooks';
import { PaymentMethodBase, PaymentMethodDetails, PaymentMethodHeader, PaymentMethodStatus } from '..';
import { PaymentMethodVariant } from '../../enums';
import { getPaymentMethodHeader, getPaymentMethodIcon, getPaymentMethodStatus } from '../../helpers';
import { PaymentMethodManageActions } from './components';
const PaymentMethodManage = ({ className, creator, disableManageActions, fundingAccount, fundingInfoAddress, fundingInfoBankAccount, fundingInfoInternational, fundingInfoPayPalDirect, fundingSources, input, isExpanded: isExpandedOverride, isSelectable, isSubmitting, isPartnerAccountInDashboard, onDelete, onPrimary, onUpdate, partnership, partnershipFundingAccount, showFundingAccountId, shouldTreatInvalidStatusAsPendingVerification, }) => {
    const [isExpanded, toggleExpanded] = useToggle(isExpandedOverride);
    const { subtitle, title } = getPaymentMethodHeader({
        fundingAccount,
        fundingInfoAddress,
        fundingInfoBankAccount,
        fundingInfoInternational,
        fundingInfoPayPalDirect,
    });
    const status = getPaymentMethodStatus({
        fundingAccount,
        fundingSources,
        isPartnerAccountInDashboard,
        partnershipFundingAccount,
        shouldTreatInvalidStatusAsPendingVerification,
    });
    const isRTPEnabled = isFundingAccountEligibleForRTP(fundingAccount);
    return (React.createElement(PaymentMethodBase, { className: className, input: input, variant: PaymentMethodVariant.MANAGE },
        React.createElement(PaymentMethodHeader, { currencyCode: fundingAccount.currencyCode, fundingAccountId: fundingAccount.id, fundingIcon: getPaymentMethodIcon(fundingAccount), input: input, isExpanded: isExpanded, isRTPEnabled: isRTPEnabled, isSelectable: isSelectable, onToggleExpand: toggleExpanded, status: status, statusEl: !fundingAccount.isExternal && (React.createElement("div", { className: "margin-left--xm" },
                React.createElement(PaymentMethodStatus, { currencyCode: fundingAccount.currencyCode, status: status }))), subtitle: subtitle, title: title }),
        React.createElement("div", { className: clsx('payment_method__expandable', { expanded: isExpanded }) },
            React.createElement(PaymentMethodDetails, { creator: creator, fundingAccount: fundingAccount, fundingInfoAddress: fundingInfoAddress, fundingInfoBankAccount: fundingInfoBankAccount, fundingInfoInternational: fundingInfoInternational, isPartnerAccountInDashboard: isPartnerAccountInDashboard, partnership: partnership, partnershipFundingAccount: partnershipFundingAccount, showFundingAccountId: showFundingAccountId }),
            !disableManageActions && (React.createElement(PaymentMethodManageActions, { currencyCode: fundingAccount.currencyCode, isSubmitting: isSubmitting, onDelete: onDelete, onPrimary: onPrimary, onUpdate: onUpdate, status: status })))));
};
export default PaymentMethodManage;
