import { createSelector } from 'reselect';

import SelectApproverText from 'components/selectTypes/MutuallyExclusiveSelects/components/SelectApproverText';

import { DeactivateTeamMemberModalField } from 'constants/formFields';
import { modalNameDeactivateTeamMember } from 'constants/modals';

import { filterApprovalLevelsByKindAndAmount, mapApprovalLevelConfig } from 'helpers/approvals';
import { findOneMembershipById } from 'helpers/memberships';
import { isPaymentDeliveryMethodAch } from 'helpers/paymentMethods';

import { selectedInvoicesAmountsSelector } from 'queries/createItemFormSelectors';
import { creatingNewInvoiceSelector } from 'queries/createItemFormValueCompoundSelectors';
import { fundingAccountsAllForAchProviderSelector } from 'queries/fundingCompoundSelectors';
import { createItemsTableConstrainedMetaTotalSelector } from 'queries/tableCompoundSelectors';

import { companyMailingAddressSelector } from 'selectors/addressesSelectors';
import {
  currentCompanyIdSelector,
  currentCompanyItemApprovalLevelsSortedSelector,
  currentCompanyMembersSelector,
  currentCompanyItemApprovalSettingsSelector,
  currentCompanyMembersWithUserInfoAllValuesSelector,
  currentCompanyMaxBankAccountsSettingsSelector,
  isFetchingCurrentCompanySelector,
} from 'selectors/currentCompanySelectors';
import { externalDefaultReceivableSelector } from 'selectors/forms';
import { allStateSelector } from 'selectors/globalSelectors';
import { currentMembershipSelector, isFetchingMembershipSelector } from 'selectors/membershipsSelector';
import { createModalValueSelector } from 'selectors/modalsSelector';
import { itemPropSelector } from 'selectors/propSelectors';

export const currentCompanyMailingAddressSelector = createSelector(
  [allStateSelector, currentCompanyIdSelector],
  (state, currentCompanyId) => companyMailingAddressSelector(state, currentCompanyId),
);

/**
 * Maps itemApprovalLevels and adds missing attributes
 * @type {StandardSelector}
 * @param {ReduxState} state - Redux state
 * @returns {ApprovalSelectConfig[]}
 */
export const currentCompanyItemApprovalLevelsMappedSelector = createSelector(
  [
    creatingNewInvoiceSelector,
    currentCompanyItemApprovalLevelsSortedSelector,
    currentCompanyMembersSelector,
    currentCompanyItemApprovalSettingsSelector,
    currentMembershipSelector,
  ],
  (creatingNewInvoice, approvalLevels, members, approvalSettings, currentMembership) =>
    mapApprovalLevelConfig({
      approvalLevels,
      creatingNewInvoice,
      currentUserID: currentMembership.id,
      members,
      approvalSettings,
      TextComponent: SelectApproverText,
    }),
);

/**
 * Filters mapped itemApprovalLevels and returns only the levels to be used for a given item based on its amount
 * @type {StandardSelector}
 * @param {ReduxState} state - Redux state
 * @param {ComponentProps} ownProps
 * @param {ComponentProps} ownProps.item
 * @returns {ApprovalSelectConfig[]}
 */
export const currentCompanyItemApprovalLevelsFilteredSelector = createSelector(
  [
    currentCompanyItemApprovalLevelsMappedSelector,
    itemPropSelector,
    createItemsTableConstrainedMetaTotalSelector,
    selectedInvoicesAmountsSelector,
  ],
  (approvalLevels, item = {}, itemAmount, selectedInvoicesAmounts) =>
    filterApprovalLevelsByKindAndAmount({
      approvalLevels,
      item,
      itemAmount,
      selectedInvoicesAmounts,
    }),
);

/**
 * Get the current membership ID we're thinking about disabling from the modal state.
 * @type {StandardSelector}
 * @param {ReduxState}
 * @returns {StringMaybe}
 */
export const deactivateTeamMemberModalMembershipId = createModalValueSelector({
  field: DeactivateTeamMemberModalField.MEMBERSHIP,
  modalName: modalNameDeactivateTeamMember,
});

/**
 * Get the current membership we're thinking about disabling for use in the confirmation modal.
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {MembershipWithUserData}
 */
export const currentCompanyModalDeactivateOneMemberSelector = createSelector(
  [currentCompanyMembersWithUserInfoAllValuesSelector, deactivateTeamMemberModalMembershipId],
  findOneMembershipById,
);

/**
 * Returns true if current company has reached it's max bank accounts
 * limit
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {boolean}
 */
export const currentCompanyHasReachedMaxBankAccountsLimitSelector = createSelector(
  [fundingAccountsAllForAchProviderSelector, currentCompanyMaxBankAccountsSettingsSelector],
  (achFundingAccounts, maxBankAccounts) => achFundingAccounts.length >= maxBankAccounts,
);

/**
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {boolean}
 */
export const isCurrentCompanyMaxBankAccountsReachedHintVisibleSelector = createSelector(
  [currentCompanyHasReachedMaxBankAccountsLimitSelector, externalDefaultReceivableSelector],
  (hasReachedMaxBankAccounts, defaultReceivable) => {
    // If currently selected payment delivery method is not ACH, we don't
    // want to show the hint (since the hint is only in connection to bank accounts).
    if (!isPaymentDeliveryMethodAch(defaultReceivable?.paymentDeliveryMethod)) {
      return false;
    }

    // If payment delivery method is ACH, return the output of
    // currentCompanyHasReachedMaxBankAccountsLimit selector
    return hasReachedMaxBankAccounts;
  },
);

/**
 * Returns whether the currentCompany or the membership isFetching
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {boolean}
 */
export const isFetchingCurrentCompanyOrMembership = createSelector(
  [isFetchingCurrentCompanySelector, isFetchingMembershipSelector],
  (isFetchingCurrentCompany, isFetchingMembership) => isFetchingCurrentCompany || isFetchingMembership,
);
