import { z } from 'zod';
export const fundingInfoPayPalDirectAttributesModel = z.object({
    paypalEmail: z.string(),
});
export const fundingInfoPayPalDirectModel = z.object({
    type: z.literal('FundingInfoPayPalDirect'),
    id: z.string().uuid(),
    attributes: fundingInfoPayPalDirectAttributesModel,
});
export const fundingInfoBankAccountType = z.enum(['checking', 'savings']);
export const fundingInfoBankAttributesModel = z.object({
    accountNumber: z.string(),
    accountType: fundingInfoBankAccountType,
    institutionName: z.string(),
    isVirtualAccountNumber: z.boolean(),
    logo: z.string().url(),
    primaryColor: z.string(),
    routingNumber: z.string(),
});
export const fundingInfoBankModel = z.object({
    type: z.literal('FundingInfoBank'),
    id: z.string().uuid(),
    attributes: fundingInfoBankAttributesModel,
});
