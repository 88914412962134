import { Email } from '@carbon/icons-react';
import { Clickable, InlineMessage } from '@routable/gross-ds';
import pluralize from 'pluralize';
import React from 'react';
import { useSelector } from 'react-redux';
import { createItemFormFields } from 'constants/formFields';
import { ItemKinds, PaymentOrInvoiceByItemKindText } from 'constants/item';
import { PartnershipTypes } from 'constants/partnership';
import { getMembershipNameOrEmailOrPhoneNumber } from 'helpers/memberships';
import { capitalize } from 'helpers/stringHelpers';
import { hasLength } from 'helpers/utility';
import { useMountedValue } from 'hooks';
import { getItemKindFromLocationSelector } from 'selectors/routerSelectors';
import {} from './GroupedContactSelectsSharedWrapper.types';
const GroupedContactSelectsSharedWrapper = ({ children, className, fieldName, formUI, isCollapsed, selectedContacts, setIsCollapsed, showLabel = true, }) => {
    const { formSubmitted } = formUI;
    const isReceivable = useSelector(getItemKindFromLocationSelector) === 'receivable';
    const isOnCreateItemForm = fieldName === createItemFormFields.ITEM_MEMBERS;
    const hasContactsOnInitialRender = useMountedValue(hasLength(selectedContacts));
    const areSelectsCollapsed = isCollapsed && isOnCreateItemForm && hasContactsOnInitialRender;
    if (formSubmitted) {
        return null;
    }
    const itemKindText = PaymentOrInvoiceByItemKindText[isReceivable ? ItemKinds.RECEIVABLE : ItemKinds.PAYABLE];
    if (areSelectsCollapsed) {
        const [firstContact] = selectedContacts;
        const numOfOtherContacts = selectedContacts.length - 1;
        const suffixText = numOfOtherContacts > 0 ? ` and ${numOfOtherContacts} ${pluralize('other', numOfOtherContacts)}` : '';
        const partnershipTypeText = isReceivable ? PartnershipTypes.CUSTOMER : PartnershipTypes.VENDOR;
        const firstContactText = getMembershipNameOrEmailOrPhoneNumber(firstContact);
        return (React.createElement(InlineMessage, { customIcon: React.createElement(Email, { className: "flex-shrink-0 w-5 h-5 text-grey-70" }) },
            `${capitalize(itemKindText)} notification will be sent to: ${firstContactText}${suffixText}. `,
            React.createElement(Clickable, { onClick: () => setIsCollapsed(false), variant: "black" }, `Edit ${partnershipTypeText} contacts`)));
    }
    return (React.createElement("div", { className: className },
        showLabel && (React.createElement("span", { className: "text-sm text-black font-medium leading-5 block mb-3 mt-4" },
            "Send ",
            itemKindText,
            " notification to")),
        children));
};
export default GroupedContactSelectsSharedWrapper;
