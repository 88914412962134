import { queryContext } from '@routable/shared';
import { useQuery } from '@tanstack/react-query';
import {} from '../../models';
import { featureConfigsService } from '../../services';
export const featureSettingsQueryKey = ['featureConfigs', 'featureSettings'];
export const useFeatureSettingsQuery = () => {
    return useQuery({
        context: queryContext,
        queryKey: featureSettingsQueryKey,
        queryFn: async () => featureConfigsService.listFeatureSettings(),
        staleTime: Infinity,
        select: (data) => {
            if (!data) {
                return {};
            }
            return data.reduce((acc, val) => {
                acc[val.featureSetting] = val.value;
                return acc;
            }, {});
        },
    });
};
