import React from 'react';
import { externalItemToStatusToText, getItemStatusText, getStatusToConsider, itemStatusToVariant, } from 'helpers/status';
import ExternalBlocksSection from 'modules/external/ExternalBlocksSection';
import ExternalReceiptItemStatus from '../ExternalReceiptItemStatus';
import { ExternalReceiptPaymentHeader, ExternalReceiptPaymentItemSummary, ExternalReceiptPaymentPaginator, ExternalReceiptPaymentViewDetails, } from './components';
import {} from './ExternalReceiptPayment.types';
import './ExternalReceiptPayment.scss';
const ExternalReceiptPayment = ({ amount, attachments, currencyCode, canNextPage, canPreviousPage, item, payment, onGoToPage, onViewBills, onViewLineItems, pageCount, pageIndex, }) => {
    const status = payment ? payment.status : getStatusToConsider(item);
    const text = getItemStatusText(item, status);
    const showViewDetailsButton = !!item?.lineItems?.length;
    return (React.createElement(ExternalBlocksSection, { className: "external-receipt-payment" },
        React.createElement(ExternalReceiptPaymentHeader, { amount: amount, currencyCode: currencyCode, itemKind: item?.kind }),
        React.createElement(ExternalReceiptItemStatus, { status: itemStatusToVariant[status], statusText: externalItemToStatusToText[status], text: text }),
        payment ? (React.createElement(ExternalReceiptPaymentPaginator, { canNextPage: canNextPage, canPrevPage: canPreviousPage, onGoToPage: onGoToPage, onViewAll: () => onViewBills(payment), page: pageIndex, pages: pageCount })) : (showViewDetailsButton && React.createElement(ExternalReceiptPaymentViewDetails, { onClick: () => onViewLineItems(item) })),
        React.createElement(ExternalReceiptPaymentItemSummary, { attachments: attachments, item: item })));
};
export default ExternalReceiptPayment;
