import { useEffect, useState } from 'react';
import { useOverlayTriggerState } from 'react-stately';
import { RoutableStorage, useEventSync } from '@routable/framework';
const pullHeaderConfigFromIndexDb = async (tableName, headersConfig) => {
    const columnConfigRecord = await RoutableStorage.get('routableTableColumns', [
        {
            indexName: 'tableId',
            condition: '==',
            value: tableName,
        },
    ]);
    const columnConfig = columnConfigRecord.data;
    const config = Object.fromEntries(headersConfig.map(({ id }) => [id, true]));
    if (columnConfig) {
        for (const key in columnConfig) {
            config[key] = columnConfig[key];
        }
    }
    return config;
};
const pushHeaderConfigToIndexDb = async (tableName, columnConfig) => {
    await RoutableStorage.put('routableTableColumns', `table:${tableName}:columns:updated`, tableName, {
        tableId: tableName,
        timestamp: new Date(),
        data: columnConfig,
    });
};
const toById = (config) => config.reduce((byId, conf) => {
    byId[conf.id] = conf;
    return byId;
}, {});
export const useTablematicTableColumnSelect = ({ headersConfig, tableName, updateCondition, }) => {
    const state = useOverlayTriggerState({});
    const [newHeadersConfig, setnewHeadersConfig] = useState(headersConfig);
    const [newHeadersConfigById, setnewHeadersConfigById] = useState(toById(headersConfig));
    const [checkboxesState, setCheckboxesState] = useState({});
    const setHeadersConfig = (latestCheckboxesState) => {
        const newConf = headersConfig.map((header) => ({
            ...header,
            isVisible: latestCheckboxesState[header.id],
        }));
        setnewHeadersConfig(newConf);
        setnewHeadersConfigById(toById(newConf));
    };
    const pullHeaderConfigFromIndexDbAndSetAsync = async () => {
        const checkboxesStateFromIndexDB = await pullHeaderConfigFromIndexDb(tableName, headersConfig);
        setCheckboxesState(checkboxesStateFromIndexDB);
        setHeadersConfig(checkboxesStateFromIndexDB);
    };
    useEffect(() => {
        pullHeaderConfigFromIndexDbAndSetAsync();
    }, []);
    useEffect(() => {
        setHeadersConfig(checkboxesState);
    }, [updateCondition]);
    const handleSubmit = () => {
        setHeadersConfig(checkboxesState);
        pushHeaderConfigToIndexDb(tableName, checkboxesState);
        state.close();
    };
    const handleCheckboxToggle = (id) => () => setCheckboxesState({ ...checkboxesState, [id]: !checkboxesState[id] });
    useEventSync('columnselect:update:column', ({ id, value }) => {
        setCheckboxesState({ ...checkboxesState, [id]: value });
    });
    return {
        columnSelectProps: {
            state,
            headersConfig,
            checkboxesState,
            handleCheckboxToggle,
            handleSubmit,
        },
        headersConfig: newHeadersConfig,
        headersConfigById: newHeadersConfigById,
    };
};
