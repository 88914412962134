import { createReducer } from '@reduxjs/toolkit';
import { setVendorCountryFormIsSubmittingState, setVendorCountryFormIsValidState, setW8FormIsSubmittingState, setW8FormIsValidState, setW9FormIsSubmittingState, setW9FormIsValidState, } from 'actions/partnershipRequests';
const initialValues = {
    isValid: false,
    isSubmitting: false,
};
const initialState = {
    vendorCountry: initialValues,
    w8: initialValues,
    w9: initialValues,
};
const partnershipRequestFormReducer = createReducer(initialState, (builder) => builder
    .addCase(setW8FormIsSubmittingState, (draft, { payload }) => {
    draft.w8.isSubmitting = payload;
})
    .addCase(setW8FormIsValidState, (draft, { payload }) => {
    draft.w8.isValid = payload;
})
    .addCase(setW9FormIsSubmittingState, (draft, { payload }) => {
    draft.w9.isSubmitting = payload;
})
    .addCase(setW9FormIsValidState, (draft, { payload }) => {
    draft.w9.isValid = payload;
})
    .addCase(setVendorCountryFormIsSubmittingState, (draft, { payload }) => {
    draft.vendorCountry.isSubmitting = payload;
})
    .addCase(setVendorCountryFormIsValidState, (draft, { payload }) => {
    draft.vendorCountry.isValid = payload;
})
    .addDefaultCase((state) => state));
export { initialState };
export default partnershipRequestFormReducer;
