import {} from '@routable/framework';
import { Menu, Tooltip } from '@routable/gross-ds';
import _cloneDeep from 'lodash/cloneDeep';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { LineItemStyles } from 'constants/lineItems';
import { getInitialAnnotationLineItems } from 'helpers/ocr';
import { lineItemsSectionsSelector } from 'modules/dashboard/createItems/createItemForm/components/CreateItemForm/lineItemsSectionsSelector';
import { useTransferLineItems } from 'modules/table/hooks';
import { useAnnotationLineItems } from './useAnnotationLineItems';
import { useSummarizeLineItemsToggle } from './useSummarizeLineItemsToggle';
export const SummarizeLineItems = ({ isForcedConsolidatedLineItem, lineItems, ocrAnnotation, sectionType, viewModelManager, }) => {
    const [unsummarizedLineItems, setUnsummarizedLineItems] = useState({});
    const { annotationCount, annotationItems } = useAnnotationLineItems({});
    const { lineItemsAccountSection, lineItemsItemSection } = useSelector(lineItemsSectionsSelector);
    const lineItemsAccountDisplayName = lineItemsAccountSection?.display;
    const lineItemsItemDisplayName = lineItemsItemSection?.display;
    const hasBothSections = Boolean(lineItemsItemDisplayName && lineItemsAccountDisplayName);
    const activeTabIsItem = sectionType === LineItemStyles.ITEM;
    useEffect(() => {
        let updatedAnnotationItems = annotationItems;
        if (lineItemsAccountSection) {
            updatedAnnotationItems = annotationItems.map((lineItem) => ({ ...lineItem, style: LineItemStyles.ACCOUNT }));
        }
        const originalLineItems = getInitialAnnotationLineItems(_cloneDeep(viewModelManager?.initialValues?.line_items || {}), updatedAnnotationItems);
        setUnsummarizedLineItems(originalLineItems);
    }, [annotationItems, lineItemsAccountSection, viewModelManager.initialValues.line_items]);
    const { disabledTooltipText, onHandleSummarize, summarizeOrSeparateText } = useSummarizeLineItemsToggle({
        activeTabIsItem,
        annotationCount,
        isForcedConsolidatedLineItem,
        ocrAnnotation,
        unsummarizedLineItems,
    });
    const { hasTransferrableLineItems, transferText, transferLineItems } = useTransferLineItems({
        activeTabIsItem,
        viewModelManager,
    });
    const isSummarizeVisible = !(hasBothSections && activeTabIsItem) &&
        ((ocrAnnotation?.lineItems || [])?.length > 1 || isForcedConsolidatedLineItem);
    const isTransferVisible = hasBothSections && (ocrAnnotation?.lineItems || [])?.length > 0;
    if (!isSummarizeVisible && !isTransferVisible) {
        return null;
    }
    return (React.createElement("div", { className: "flex items-center gap-3 relative w-full" },
        React.createElement("div", { className: "flex items-end cursor-pointer gap-3" },
            React.createElement(Menu, { buttonVariant: "floating", "data-testid": "summarize-line-items-action-menu", intent: "secondary", label: "Actions", placement: "bottom right", size: "small" },
                isSummarizeVisible && (React.createElement(Menu.Item, { disabled: Boolean(disabledTooltipText), key: "summarize-line-items", onAction: onHandleSummarize, textValue: "Summarize" },
                    React.createElement(Tooltip, { "data-testid": "summarize-line-items-tooltip", tooltip: disabledTooltipText, variant: "light" },
                        React.createElement("div", { className: "flex items-center" },
                            React.createElement("span", { className: "pl-4" }, summarizeOrSeparateText))))),
                isTransferVisible && (React.createElement(Menu.Item, { disabled: !hasTransferrableLineItems, key: "transfer-line-items", onAction: () => transferLineItems(lineItems), textValue: transferText },
                    React.createElement("div", { className: "flex items-center" },
                        React.createElement("span", { className: "pl-4" }, transferText))))))));
};
export default SummarizeLineItems;
