import {} from '@routable/framework';
import React, { useMemo, useRef } from 'react';
import { TablematicTableHeader, useTablematicTableColumnSelect } from './components';
import { TablematicTableRows } from './components/rows/tablematic.table.rows.component';
import { tablematicMinFieldSizeMap } from './helpers/tablematic.table.helpers';
import { TableSection, TableSectionWrap } from './tablematic.table.styled';
import { usePreventOverscrollLeft } from '../Table/hooks/usePreventOverscrollLeft.hook';
import { useTablematicStickyScroll } from '../Table/hooks/useTablematicStickyScroll.hook';
import clsx from 'clsx';
export const TablematicTableSection = React.memo(({ getAreTableRowFieldValuesEqualToInitial, getTableSectionFieldSize, is3WayMatching, isOnDetailsPage, isPayable, sectionRef, tableSection, rowDataLength, }) => {
    const tableProps = tableSection.tableProps;
    const subSectionId = tableSection.id;
    const scrollableRef = useRef(null);
    const { headerContainerRef, rowContainerRef, innerWidth } = useTablematicStickyScroll();
    usePreventOverscrollLeft(scrollableRef);
    const currentHeadersConfigForUseTablematicTable = useMemo(() => tableProps.headers
        .filter((header) => is3WayMatching || header.id !== 'receipt')
        .map(({ id, isVisible, Cell, Header }) => ({
        Cell,
        id,
        Header,
        isVisible,
    })), [tableProps.headers, is3WayMatching]);
    const { headersConfig, headersConfigById, columnSelectProps } = useTablematicTableColumnSelect({
        headersConfig: currentHeadersConfigForUseTablematicTable,
        tableName: subSectionId,
        updateCondition: is3WayMatching,
    });
    let widthStyle = {};
    if (subSectionId !== 'line_items_item' && subSectionId !== 'line_items_account') {
        const maxWidthSectionSize = (window.innerWidth - 48) / 2;
        const sectionSize = headersConfig
            .filter(({ isVisible }) => isVisible)
            .reduce((prev, { id }) => {
            const fieldSize = getTableSectionFieldSize(tableSection.fieldProps[id]);
            return prev + tablematicMinFieldSizeMap[fieldSize];
        }, 0);
        const widthSize = tableProps.sectionWidth || (sectionSize >= maxWidthSectionSize ? maxWidthSectionSize : sectionSize + 2);
        widthStyle = sectionSize ? { flex: `0 0 ${tableSection.sectionWidth || widthSize}px` } : {};
    }
    const CustomSectionHeaderComponent = tableSection.CustomSectionHeaderComponent;
    const CustomRowComponent = tableSection.CustomRowComponent;
    return (React.createElement(TableSection, { ref: sectionRef, key: `${subSectionId}.section`, style: { ...tableSection.sectionStyle, ...widthStyle }, className: `tablematic-table table-section table-section-${subSectionId} use-scrollbars horizontal-scroll-group` },
        CustomSectionHeaderComponent && isPayable && (React.createElement("div", null,
            React.createElement(CustomSectionHeaderComponent, { columnSelectProps: columnSelectProps, fieldProps: tableSection.fieldProps, getTableSectionFieldSize: getTableSectionFieldSize, isOnDetailsPage: isOnDetailsPage, is3WayMatching: is3WayMatching, section: tableProps, subSectionId: subSectionId }))),
        React.createElement(TableSectionWrap, { ref: scrollableRef, className: clsx('use-scrollbars horizontal-scroll-group', {
                '!overflow-x-hidden': ['line_items_item_line_status', 'line_items_item_line_actions'].includes(subSectionId),
            }) },
            React.createElement(TablematicTableHeader, { headerContainerRef: headerContainerRef, fieldProps: tableSection.fieldProps, getTableSectionFieldSize: getTableSectionFieldSize, headersConfigById: headersConfigById, horizontalScrollGroupRef: sectionRef, isOnDetailsPage: isOnDetailsPage, section: tableProps, subSectionId: subSectionId }),
            React.createElement(TablematicTableRows, { CustomRowComponent: CustomRowComponent, fieldProps: tableSection.fieldProps, getAreTableRowFieldValuesEqualToInitial: getAreTableRowFieldValuesEqualToInitial, getTableSectionFieldSize: getTableSectionFieldSize, headersConfigById: headersConfigById, horizontalScrollGroupRef: sectionRef, innerWidth: innerWidth, isOnDetailsPage: isOnDetailsPage, rowContainerRef: rowContainerRef, rowDataLength: rowDataLength, section: tableSection }))));
});
