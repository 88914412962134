export const kzBnfFieldOptions = [
    { label: 'Government (Central Administration Bodies)', value: '11' },
    { label: 'Regional and local administration bodies', value: '12' },
    { label: 'National bank and Central Banks', value: '13' },
    { label: 'Monetary and depositary corporations (local banks)', value: '14' },
    { label: "Non-depositary finance corp.'s (org.'s performing some bank activities)", value: '15' },
    { label: 'State non-finance corporations', value: '16' },
    { label: 'Private non-finance corporations', value: '17' },
    { label: 'Not-for-profit organizations', value: '18' },
    { label: 'Individuals, self-employed population', value: '19' },
    { label: 'Government of other countries (Central Administration Bodies)', value: '21' },
    { label: 'Regional and local administration bodies of other countries', value: '22' },
    { label: 'Central Banks of other banks', value: '23' },
    { label: 'Monetary and depositary corporations (non-resident banks)', value: '24' },
    { label: 'Non-depositary finance corporations — non-residents', value: '25' },
    { label: 'State non-finance corporations — non-residents', value: '26' },
    { label: 'Private non-finance corporations — non-residents', value: '27' },
    { label: 'Not-for-profit organizations — non-residents', value: '28' },
    { label: 'Non-resident individuals, self-employed population', value: '29' },
];
