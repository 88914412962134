import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { fetchExternalPartnershipRequestRoutine } from 'actions/routines/external';
import { fetchSupportedCountriesRoutine } from 'actions/routines/funding';
import { fetchPermissionsRoutine } from 'actions/routines/permissions';
import { fetchRolesRoutine } from 'actions/routines/roles';

import { currentWorkflowStepSelector } from 'queries/currentWorkflowStepSelector';
import { isExternalFlowLoading } from 'queries/externalWorkflowSelectors';
import {
  partnershipPartnerCompanyBrandFromPartnershipRequestSelector,
  partnershipPartnerCompanyFromPartnershipRequestSelector,
} from 'queries/partnershipCompoundSelectors';

import { externalOnboardingStepSelector } from 'selectors/externalOnboardingSelectors';
import { partnershipFundingStateCurrentHasDateCompletedSelector } from 'selectors/partnershipFundingStatesSelectors';
import {
  partnershipRequestConfirmationRedirectUrlSelector,
  partnershipRequestFromLocationSelector,
  partnershipRequestStepsForCurrentUserSelector,
} from 'selectors/partnershipRequestSelectors';

import ExternalRouteWrapper from './ExternalRouteWrapper';

export const mapStateToProps = createStructuredSelector({
  activeStepIndex: externalOnboardingStepSelector,
  brand: partnershipPartnerCompanyBrandFromPartnershipRequestSelector,
  company: partnershipPartnerCompanyFromPartnershipRequestSelector,
  currentStep: currentWorkflowStepSelector,
  isLoading: isExternalFlowLoading,
  partnershipFundingStateHasDateCompleted: partnershipFundingStateCurrentHasDateCompletedSelector,
  partnershipRequest: partnershipRequestFromLocationSelector,
  redirectUrl: partnershipRequestConfirmationRedirectUrlSelector,
  steps: partnershipRequestStepsForCurrentUserSelector,
});

export const mapDispatchToProps = {
  fetchExternalPartnershipDetails: fetchExternalPartnershipRequestRoutine,
  onFetchPermissionsAndPermissionGroups: fetchPermissionsRoutine,
  onFetchRoles: fetchRolesRoutine,
  onFetchSupportedCountries: fetchSupportedCountriesRoutine,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExternalRouteWrapper);
