import { queryContext } from '@routable/shared';
import { useQuery } from '@tanstack/react-query';
import {} from '../../models';
import { featureConfigsService } from '../../services';
export const featureFlagsQueryKey = ['featureConfigs', 'featureFlags'];
export const useFeatureFlagsQuery = () => {
    return useQuery({
        context: queryContext,
        queryKey: featureFlagsQueryKey,
        queryFn: async () => featureConfigsService.listFeatureFlags(),
        staleTime: Infinity,
        select: (data) => {
            if (!data) {
                return {};
            }
            return data
                .map((featureFlagData) => featureFlagData.featureFlag)
                .reduce((acc, val) => {
                if (val) {
                    acc[val] = true;
                }
                return acc;
            }, {});
        },
    });
};
