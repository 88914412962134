import { PaymentDeliveryMethodType } from '@routable/shared';
import PropTypes from 'prop-types';
import React from 'react';

import { partnerFundingAccountFormFields } from 'constants/formFields';
import { countryListOptions } from 'constants/i18n';

import { isItemPaymentDeliveryMethodCheck } from 'helpers/items';

import AddressReduxForm from 'modules/address/presenters/AddressReduxForm';
import ConnectBankManualForm from 'modules/connectBank/connectBankManual/components/ConnectBankManualForm';

/**
 * Renders form fields for AddPartnerFundingAccount form in side panel
 * @param formName
 * @param paymentDeliveryMethod
 * @return {FunctionComponent}
 */
const AddPartnerFundingAccountFormFields = ({ formName, paymentDeliveryMethod }) => {
  const isCheckPaymentMethod = isItemPaymentDeliveryMethodCheck({
    paymentDeliveryMethod,
  });

  if (isCheckPaymentMethod) {
    return (
      <AddressReduxForm
        addressPath={partnerFundingAccountFormFields.ADDRESS}
        countryOptions={countryListOptions.US_AND_CANADA}
        formName={formName}
      />
    );
  }

  return (
    <ConnectBankManualForm
      formName={formName}
      formPath={partnerFundingAccountFormFields.BANK_ACCOUNT}
      isPartnerAccount
    />
  );
};

AddPartnerFundingAccountFormFields.propTypes = {
  formName: PropTypes.string.isRequired,
  paymentDeliveryMethod: PropTypes.oneOf(Object.values(PaymentDeliveryMethodType)),
};

AddPartnerFundingAccountFormFields.defaultProps = {
  paymentDeliveryMethod: PaymentDeliveryMethodType.ACH,
};

export default AddPartnerFundingAccountFormFields;
