import { PaymentDeliveryMethodType } from '@routable/shared';
import _find from 'lodash/find';
import _groupBy from 'lodash/groupBy';
import { change } from 'redux-form';

import { openAddPartnerFundingAccountOptions } from 'actions/ui';

import { getFlattenedOptions, isApplyToNotSetOptionActive } from 'components/selectTypes/MultiCheckSelect/helpers';
import { CommonSelectOptions } from 'components/selectV2/utils/constants';

import { partnerFundingAccountFormFields } from 'constants/formFields';
import { FundingAccountTypes } from 'constants/funding';
import { texts } from 'constants/partnerFundingAccount';

import { getAddressAsString } from 'helpers/addressHelpers';
import { onSubmitFailReduxForm } from 'helpers/errors';
import { getFundingInfoAddressForPartnershipFundingAccount } from 'helpers/funding';
import { isItemPaymentDeliveryMethodCheck } from 'helpers/items';
import { isArrayLengthEqual, isEqual } from 'helpers/utility';

import { getEmptyFundingAddressValues } from 'modules/address/helpers/initialValues';

export const onPartnerFundingAccountOptionsChange = (formName, values, dispatch, props, prevValues) => {
  const { form, ui } = values;
  const { form: prevForm } = prevValues;

  if (!prevForm) {
    return;
  }

  const fieldName = partnerFundingAccountFormFields.APPLY_TO_NOT_SET;
  const { replaceFundingAccounts } = form;
  const { applyToNotSet: prevApplyToNotSet } = prevForm;

  const showingReplaceFundingAccounts = ui?.shouldShowReplaceFundingAccounts;
  const applyToNotSetOptionChecked = replaceFundingAccounts && isApplyToNotSetOptionActive(replaceFundingAccounts);

  // If we are showing funding account and want to replace one or many of them - unsure to update the checkbox
  // field accordingly
  if (showingReplaceFundingAccounts && applyToNotSetOptionChecked !== prevApplyToNotSet) {
    dispatch(change(formName, fieldName, applyToNotSetOptionChecked));
  } else if (!showingReplaceFundingAccounts && prevApplyToNotSet) {
    dispatch(change(formName, fieldName, false));
  }
};

/**
 * Gets the appropriate modal header text for the given payment method
 * @param {string} paymentDeliveryMethod
 * @return {string}
 */
export const getSidePanelHeaderTextForPaymentMethod = (paymentDeliveryMethod) => {
  if (isItemPaymentDeliveryMethodCheck({ paymentDeliveryMethod })) {
    return 'Add a vendor address';
  }

  return 'Add a vendor bank account';
};

/**
 * Gets the appropriate submit button text content for the given payment method
 * @param {string} paymentDeliveryMethod
 * @return {string}
 */
export const getSubmitButtonText = (paymentDeliveryMethod) => {
  if (isItemPaymentDeliveryMethodCheck({ paymentDeliveryMethod })) {
    return 'Add address';
  }

  return 'Add bank account';
};

/**
 * Gets the appropriate success message text for the given payment method
 * @param {string} paymentDeliveryMethod
 * @return {string}
 */
export const getSuccessMessageForAddedPartnerFundingAccount = (paymentDeliveryMethod) => {
  if (isItemPaymentDeliveryMethodCheck({ paymentDeliveryMethod })) {
    return 'Vendor address added successfully!';
  }

  return 'Vendor bank account added successfully!';
};

/**
 * Builds an array of partnership funding accounts, grouped by payment method
 * (select-all and not-set are in their own groups, for display purposes).
 * @param {Object[]} partnershipFundingAccounts
 * @param {Object} options
 * @returns {Object[]}
 */
export const getPartnershipFundingAccountSelectionOptions = (partnershipFundingAccounts = [], options = {}) => {
  const { fundingAccountsById, fundingInfoAddressesById, partnership } = options;

  const accountsByMethod = _groupBy(partnershipFundingAccounts, 'paymentDeliveryMethod');

  const selectAllOption = {
    ...CommonSelectOptions.SELECT_ALL,
    valueLabel: 'Any existing payment methods',
  };

  const notSetOption = {
    ...CommonSelectOptions.NOT_SET,
    label: `Payables waiting for ${partnership?.name} to select a payment method`,
  };

  // note: giving each group an arbitrary string value prevents a unique key
  // error from occurring in react-select internals
  const groupedOptions = [
    {
      label: null,
      options: [selectAllOption],
      value: 'select-all-group',
    },
    {
      label: null,
      options: [notSetOption],
      value: 'not-set-group',
    },
  ];

  if (accountsByMethod[PaymentDeliveryMethodType.ACH]) {
    groupedOptions.push({
      label: texts.SELECT_GROUP_HEADING_BANK_ACCOUNTS,
      options: accountsByMethod[PaymentDeliveryMethodType.ACH].map((account) => ({
        label: account.name,
        value: account.id,
      })),
      value: PaymentDeliveryMethodType.ACH,
    });
  }

  if (accountsByMethod[PaymentDeliveryMethodType.CHECK]) {
    groupedOptions.push({
      label: texts.SELECT_GROUP_HEADING_ADDRESS,
      options: accountsByMethod[PaymentDeliveryMethodType.CHECK].map((account) => {
        const label = getAddressAsString(
          getFundingInfoAddressForPartnershipFundingAccount(account, fundingAccountsById, fundingInfoAddressesById),
        );

        return {
          label,
          value: account.id,
        };
      }),
      value: PaymentDeliveryMethodType.CHECK,
    });
  }

  return groupedOptions;
};

/**
 * Gets the initial values to use for the add partner funding account form
 * @param {Object} params
 * @param {Object} params.partnership
 * @param {Object[]} params.partnershipFundingAccounts
 * @param {Boolean} params.isInternational
 * @param {Object} params.options
 * @param {Object} params.isModal
 * @return {Object}
 */
export const getInitialAddPartnerFundingAccountValues = ({
  partnership,
  partnershipFundingAccounts,
  isInternational = false,
  options = {},
  isModal,
  showCustomizePrintedCheckToggle = false,
}) => {
  const initiallySelectedGroupedOptions = getPartnershipFundingAccountSelectionOptions(
    partnershipFundingAccounts,
    options,
  );
  const ungrouped = getFlattenedOptions(initiallySelectedGroupedOptions);

  // if this is the first payment method added for a given vendor,
  // we'll auto-select "set as primary method" and disable that toggle
  const hasNoExistingFundingAccounts = isArrayLengthEqual(partnershipFundingAccounts, 0);

  const result = {
    form: {
      address: getEmptyFundingAddressValues({ partnerName: partnership?.name }),
      applyToNotSet: false,
      applyToPending: false,
      bankAccount: {
        bankAccountNumber: '',
        bankAccountType: FundingAccountTypes.CHECKING,
        bankRoutingNumber: '',
        confirmBankAccountNumber: '',
      },
      replaceFundingAccounts: ungrouped,
      setPrimary: hasNoExistingFundingAccounts,
    },
    ui: {
      bankInstitutionName: '',
      shouldDisableSetPrimary: hasNoExistingFundingAccounts,
      showCustomizePrintedCheckToggle,
      shouldShowReplaceFundingAccounts: false,
      shouldShowSetAsPrimaryToggle: true,
      closeInternationalBankModalOnSuccess: isModal,
    },
  };

  if (isInternational) {
    delete result.form.address;
    delete result.form.bankAccount;
    delete result.ui.bankInstitutionName;
    delete result.ui.showCustomizePrintedCheckToggle;

    result.partner = {
      country: partnership.countryCodePartner,
    };
  }

  return result;
};

/**
 * Returns the submission payload for adding a partnership funding account.
 * @param {Object} formValues
 * @param {Object} options
 * @returns {Object}
 */
export const getAddPartnershipFundingAccountPayload = (formValues, options = {}) => {
  const {
    form: { address, applyToNotSet, applyToPending, bankAccount, replaceFundingAccounts, setPrimary },
    ui,
  } = formValues;

  const { shouldShowReplaceFundingAccounts } = ui;

  const { partnershipFundingAccounts, paymentDeliveryMethod } = options;

  const payload = {
    applyToNotSet,
    applyToPending,
    setPrimary,
  };

  if (shouldShowReplaceFundingAccounts) {
    const formattedReplaceAccounts = [];

    replaceFundingAccounts.forEach((option) => {
      const partnerFundingAccount = _find(partnershipFundingAccounts, (account) => isEqual(account.id, option.value));

      if (partnerFundingAccount) {
        formattedReplaceAccounts.push({
          type: 'FundingAccount',
          id: partnerFundingAccount.fundingAccount,
        });
      }
    });

    payload.replaceFundingAccounts = formattedReplaceAccounts;
  }

  if (isItemPaymentDeliveryMethodCheck({ paymentDeliveryMethod })) {
    return { ...payload, ...address };
  }

  return { ...payload, ...bankAccount };
};

/**
 * Process form errors if submitting failed.
 * Passed directly to reduxForm({ onSubmitFail }), which is why there is no argument destructuring.
 * @param {Object} errors
 * @param {Function} dispatch
 * @param {*} submitError
 * @param {*} props
 */
export const onSubmitFailAddPartnerFundingAccountForm = (errors, dispatch, submitError, props) => {
  onSubmitFailReduxForm(errors, dispatch, submitError, props);

  if (errors?.form?.replaceFundingAccounts) {
    dispatch(openAddPartnerFundingAccountOptions());
  }
};
