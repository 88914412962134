import { StatusIndicator } from '@routable/gross-ds';
import { DateFormats } from '@routable/shared';
import React from 'react';

import ExternalLink from 'components/ExternalLink';
import { DocumentRelation } from 'components/relation';
import TransactionFees from 'components/transactionDetails/TransactionFees';

import { ItemPaymentMethodsText } from 'constants/item';
import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';

import { getCityStateAndPostal } from 'helpers/addressHelpers';
import { formatting, getBillingDataForBillingCode } from 'helpers/billing';
import { formatCurrencyUSDExplicit } from 'helpers/currencyFormatter';
import { formatDateString } from 'helpers/date';
import { getFileDeepLink } from 'helpers/deepLinks';
import { getFundingAccountMask } from 'helpers/funding';
import { getRemittanceInfoOrAddendaRecordText } from 'helpers/fundingProviderMemo';
import {
  isInternationalItem,
  isItemCreatorCurrentCompany,
  isItemKindPayable,
  isItemStatusFailedOrIssue,
} from 'helpers/items';
import { getVendorOrCustomerTitleFromItemKind } from 'helpers/partnerships';
import { isPaymentDeliveryOptionInternationalAch } from 'helpers/paymentDeliveryOption';
import { isPaymentDeliveryOptionRTP, isPaymentMethodDeliveryCheck } from 'helpers/paymentMethods';
import { capitalize } from 'helpers/stringHelpers';
import { transactionHelpers } from 'helpers/transactions';
import { getDashboardURLParams } from 'helpers/urls';

import { getTransactionStatus } from 'modules/billDetails/components/BillDetailsPayment/utils';

import { payloadToUnderscore } from 'services/api/formatHelpers';

import GeneratePaymentConfirmation from '../GeneratePaymentConfirmation';

/**
 * Derives the title to use for the transaction details disclosure list header.
 * @param {Transaction} transaction
 * @param {Object} params
 * @return {string}
 */
export const getDetailsTitleForTransaction = (transaction, params = {}) => {
  const { transactionNumber = 1, totalTransactions = 1 } = params;

  const { created, paymentDeliveryMethod } = transaction;

  let methodText = '';

  if (paymentDeliveryMethod) {
    methodText += `${capitalize(ItemPaymentMethodsText[paymentDeliveryMethod])}: `;
  }

  const dateText = formatDateString(created, DateFormats.SHORT_MONTH_SHORT_DATE_FULL_YEAR);

  return `${methodText}${dateText} (${transactionNumber} of ${totalTransactions})`;
};

/**
 * Derives the data items to use for the transaction details, related to amount and payment.
 * @param {Transaction} transaction
 * @param {Object} params
 * @return {Object[]}
 */
export const getDetailsDataForPaymentInfo = (transaction = {}, params = {}) => {
  const { billingDataByCode, payment, item } = params;

  const { amount, billingCode, paymentDeliveryMethod, paymentDeliveryOption } = transaction;

  const billingData = getBillingDataForBillingCode(billingDataByCode, billingCode);

  const data = [
    {
      key: 'amount',
      label: 'Amount',
      value: formatCurrencyUSDExplicit(amount),
    },
  ];

  if (payment) {
    const { filter, section, tab } = getDashboardURLParams();

    data.push({
      value: `Batch payment (${payment.itemCount} bills) ↗`,
      label: 'Bills',
      url: `/dashboard/${section}/${tab}/${filter}?payment=${payment.id}`,
      target: '_blank',
      key: 'payment-id-link',
    });
  }

  const deliveryMethod = paymentDeliveryMethod || item.paymentDeliveryMethod;
  if (deliveryMethod) {
    data.push({
      key: 'paymentDeliveryMethod',
      label: 'Payment method',
      value: capitalize(ItemPaymentMethodsText[deliveryMethod]),
    });
  }

  if (paymentDeliveryOption && billingData) {
    const optionTitle = formatting.getFormattedBillingCodeDisplayTitle(billingData, paymentDeliveryOption);

    data.push({
      key: 'paymentDeliveryOption',
      label: 'Delivery option',
      value: optionTitle,
    });
  }

  return data;
};

/**
 * Derives the data items to use for the transaction details, related to transaction checks.
 * @param {Transaction} transaction
 * @param {Object} params
 * @return {Object[]}
 */
export const getDetailsDataForTransactionCheck = (transaction, params = {}) => {
  const { onDownloadFile, transactionCheck } = params;

  const data = [];

  if (transactionCheck) {
    const { checkCarrier, checkExpirationDate, checkNumber, checkTrackingNumber, checkTrackingUrl, checkImageUrl } =
      transactionCheck;

    if (checkNumber) {
      data.push({
        key: 'checkNumber',
        label: 'Check number',
        value: checkNumber,
      });
    }

    if (checkExpirationDate) {
      data.push({
        key: 'checkExpirationDate',
        label: 'Check Expiration Date',
        value: formatDateString(checkExpirationDate, DateFormats.LOCAL_DATE_SHORT),
      });
    }

    if (checkTrackingNumber) {
      let value;
      if (checkTrackingUrl) {
        value = (
          <React.Fragment>
            {`${checkCarrier}: `}
            <ExternalLink href={checkTrackingUrl} variant="black">
              {checkTrackingNumber}
            </ExternalLink>
          </React.Fragment>
        );
      } else {
        value = `${checkCarrier}: ${checkTrackingNumber}`;
      }

      data.push({
        key: 'checkTrackingNumber',
        label: 'Check tracking no.',
        value,
      });
    }

    if (checkImageUrl) {
      const fileName = `Check${checkNumber}.pdf`;
      const deepLinkUrl = getFileDeepLink(checkImageUrl, fileName, {
        viewerTitle: 'Check image',
      });

      data.push({
        key: 'checkImage',
        label: 'Check image',
        value: (
          <DocumentRelation
            fileName={fileName}
            fileUrl={deepLinkUrl}
            onDownload={() => onDownloadFile(checkImageUrl, fileName)}
          />
        ),
      });
    }
  }

  return data;
};

/**
 * Derives the data items to use for the transaction details, related to transaction trace and ach.
 * @param {Transaction} transaction
 * @param {Object} params
 * @return {Object[]}
 */
export const getDetailsDataForTransactionTraceAndAch = (transaction, params = {}) => {
  const { item, transactionAch } = params;
  const partnerType = getVendorOrCustomerTitleFromItemKind(item.kind);

  const data = [];

  if (transactionAch) {
    const { destinationAchId, destinationTraceId, originationAchId, originationTraceId } = transactionAch;

    if (item.fundingProviderMemo) {
      const isDeliveryOptionRTP = isPaymentDeliveryOptionRTP(item.paymentDeliveryOption);

      data.push({
        key: 'fundingProviderMemo',
        label: capitalize(getRemittanceInfoOrAddendaRecordText(isDeliveryOptionRTP)),
        value: item.fundingProviderMemo,
      });
    }

    const addToData = (key, label, value) => {
      if (value) {
        data.push({
          key,
          label,
          value,
        });
      }
    };

    if (isItemKindPayable(item)) {
      addToData('originationTraceId', `Trace ID to share with my bank`, originationTraceId);
      addToData('originationAchId', `Individual ACH ID on my bank statement`, originationAchId);
      addToData('destinationTraceId', `Trace ID to share with ${partnerType}`, destinationTraceId);
      if (!isPaymentDeliveryOptionInternationalAch(transaction.paymentDeliveryOption)) {
        addToData('destinationAchId', `Individual ACH ID on ${partnerType} bank statement`, destinationAchId);
      }
    } else {
      addToData('destinationTraceId', `Trace ID to share with my bank`, destinationTraceId);
      addToData('destinationAchId', `Individual ACH ID on my bank statement`, destinationAchId);
      addToData('originationTraceId', `Trace ID to share with ${partnerType}`, originationTraceId);
      addToData('originationAchId', `Individual ACH ID on ${partnerType} bank statement`, originationAchId);
    }
  }

  return data;
};

/**
 * Derives the data items to use for the transaction details, related to the payment destination source.
 * @param {Object} params
 * @return {Object[]}
 */
export const getDetailsDataForPaymentSource = (params = {}) => {
  const {
    company,
    fundingAccountId,
    fundingAccountsById,
    fundingInfoAddressesById,
    fundingInfoBankAccountsById,
    isDestinationSource,
    itemKey,
    labelText,
    paymentDeliveryMethod,
  } = params;

  const source = fundingAccountsById[fundingAccountId];
  const data = [];

  if (source) {
    let value = source.name;

    if (isDestinationSource && isPaymentMethodDeliveryCheck(paymentDeliveryMethod)) {
      const address = fundingInfoAddressesById[source.address];

      if (address) {
        value = [
          address.printName,
          address.printCompany,
          address.streetAddress,
          getCityStateAndPostal(address),
          address.country,
        ];
      }
    } else if (!value && source.bank) {
      const bank = fundingInfoBankAccountsById[source.bank];

      if (bank) {
        const mask = getFundingAccountMask(bank.accountNumber);
        const name = bank.institutionName;
        value = `${name} ${mask}`;
      }
    }

    data.push({
      key: itemKey,
      label: `${labelText} (${company.name})`,
      value,
    });
  }

  return data;
};

/**
 * Derives the data items to use for the transaction details, related to the payment destination source.
 * @param {Transaction} transaction
 * @param {Object} params
 * @return {Object[]}
 */
export const getDetailsDataForPaymentDestination = (transaction, params = {}) => {
  const { companiesById, destinationCompany, item } = params;
  const { destinationFundingAccount, paymentDeliveryMethod } = transaction || {};

  const isFailedAndInternational = isItemStatusFailedOrIssue(item) && isInternationalItem(item);

  // If we have a failed international item, we want to reference to the original company
  // to which the item was sent, and not the one listed in the transaction, since the failed
  // transaction might be comming from the CurrencyCloud, in which case the displayed
  // data is wrong
  const company = isFailedAndInternational ? companiesById[item.partner] : destinationCompany;
  // If we have a failed international item, we want to reference to the original funding
  // account to which the item was sent, and not the one listed in the transaction, since the
  // failed transaction might be comming from the CurrencyCloud, in which case the displayed
  // data is wrong
  const fundingAccountId = isFailedAndInternational ? item.partnerFundingAccount : destinationFundingAccount;

  const sourceDataParams = {
    ...params,
    company,
    fundingAccountId,
    isDestinationSource: true,
    itemKey: 'paymentDestination',
    labelText: 'Payment destination',
    paymentDeliveryMethod: paymentDeliveryMethod || item.paymentDeliveryMethod,
  };

  return getDetailsDataForPaymentSource(sourceDataParams);
};

/**
 * Derives the data items to use for the transaction details, related to the payment origination source.
 * @param {Transaction} transaction
 * @param {Object} params
 * @return {Object[]}
 */
export const getDetailsDataForPaymentOrigination = (transaction, params = {}) => {
  const { item, originationCompany } = params;
  const { originationFundingAccount, paymentDeliveryMethod } = transaction;

  const isFailedAndInternational = isItemStatusFailedOrIssue(item) && isInternationalItem(item);

  // If we have a failed international item, we want to reference to the original funding
  // account from which the item was sent, and not the one listed in the transaction, since the
  // failed transaction might be comming from the CurrencyCloud, in which case the displayed
  // data is wrong
  const fundingAccountId = isFailedAndInternational ? item.fundingAccount : originationFundingAccount;

  const sourceDataParams = {
    ...params,
    company: originationCompany,
    fundingAccountId,
    itemKey: 'paymentOrigination',
    labelText: 'Payment source',
    paymentDeliveryMethod,
  };

  return getDetailsDataForPaymentSource(sourceDataParams);
};

/**
 * Derives the data items to use for the transaction details, related to the transaction status.
 * @param {Transaction} transaction
 * @return {Object[]}
 */
export const getDetailsDataForStatus = (transaction) => {
  const { providerFailureCode, providerFailureDescription } = transaction;

  const data = [];

  const { variant, text } = getTransactionStatus(transaction);

  data.push({
    key: 'providerStatus',
    label: 'Transfer status',
    value: <StatusIndicator status={variant}>{text}</StatusIndicator>,
  });

  if (
    transactionHelpers.shouldTransactionDisplayIssueStatus(transaction) ||
    transactionHelpers.isTransactionFailed(transaction)
  ) {
    data.push({
      key: 'failureReason',
      label: 'Failure reason',
      value: `${providerFailureCode}: ${providerFailureDescription}`,
    });
  }

  return data;
};

/**
 * Derives the data items to use for the transaction details, related to transaction fees.
 * @param {Transaction} transaction
 * @param {Object} params
 * @return {Object[]}
 */
export const getDetailsDataForFees = (transaction, params = {}) => {
  const { billingDataByCode } = params;

  const { fees } = transaction;

  const feesByCode = payloadToUnderscore(fees, {
    includeNumbers: true,
  });

  return [
    {
      component: TransactionFees,
      data: {
        billingDataByCode,
        feesByCode,
      },
      key: 'transactionFees',
      label: `${PLATFORM_DISPLAY_SHORT_NAME} fees`,
    },
  ];
};

/**
 * Conditionally show generate payment confirmation or download/open button UI
 * @param {Transaction} transaction
 * @param {Object} params
 * @return {Object[]}
 */
export const getDetailsDataForPaymentConfirmation = (transaction, params = {}) => {
  const { item, onDownloadFile, transactionPaymentConfirmation } = params;

  // Payment confirmation is only available to payables created by the current company
  if (!isItemKindPayable(item)) {
    return [];
  }

  if (!isItemCreatorCurrentCompany(item)) {
    return [];
  }

  if (transactionPaymentConfirmation) {
    // In this case we have a payment confirmation and therefore want to show the download button
    const { filename: fileName, file: url } = transactionPaymentConfirmation;

    return [
      {
        key: 'paymentConfirmation',
        label: 'Payment confirmation',
        value: (
          <DocumentRelation
            fileName={fileName}
            fileUrl={getFileDeepLink(url, fileName, {
              viewerTitle: 'Payment confirmation',
            })}
            onDownload={() => onDownloadFile(url, fileName)}
          />
        ),
      },
    ];
  }

  // In this case there's no payment confirmation and we want to show the button to generate it
  return [
    {
      key: 'paymentConfirmation',
      label: 'Payment confirmation',
      value: <GeneratePaymentConfirmation transaction={transaction} {...params} />,
    },
  ];
};

/**
 * Returns the items to display in the transaction details disclosure list.
 *
 * Note: There's a race condition on loading some pages that, once in a while,
 * results in a company not existing in state prior to when the transactions load in
 * (just order of requests completing). If we don't have the relevant company for one
 * of these data sections yet, we'll filter that section here, and render it in a few
 * milliseconds when the response comes back.
 *
 * @param {Transaction} transaction
 * @param {Object} params
 * @return {Object[]}
 */
export const getDetailsDataForTransaction = (transaction, params = {}) => {
  const hasOriginationCompany = Boolean(params.originationCompany);
  const hasDestinationCompany = Boolean(params.destinationCompany);

  const paymentOriginData = hasOriginationCompany ? getDetailsDataForPaymentOrigination(transaction, params) : [];
  const paymentDestData = hasDestinationCompany ? getDetailsDataForPaymentDestination(transaction, params) : [];
  const transactionTraceAndAchData = hasDestinationCompany
    ? getDetailsDataForTransactionTraceAndAch(transaction, params)
    : [];

  return [
    ...getDetailsDataForPaymentInfo(transaction, params),
    ...getDetailsDataForPaymentConfirmation(transaction, params),
    ...paymentOriginData,
    ...paymentDestData,
    ...getDetailsDataForStatus(transaction),
    ...transactionTraceAndAchData,
    ...getDetailsDataForTransactionCheck(transaction, params),
    ...getDetailsDataForFees(transaction, params),
  ];
};
