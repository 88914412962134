import { ArrowRight } from '@carbon/icons-react';
import { Button } from '@routable/gross-ds';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Icon, {} from 'components/icon';
import { formNamesFunding } from 'constants/forms';
import { openManuallyAddBankAccountSidePanel } from 'helpers/sidePanels';
import { useSelectorWithProps } from 'hooks';
import { partnershipFromQuerySelector } from 'queries/partnershipCompoundSelectors';
const AddBankAccountManuallyButton = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const partnership = useSelectorWithProps(partnershipFromQuerySelector, { location });
    const handleClick = () => {
        dispatch(openManuallyAddBankAccountSidePanel({
            formContext: formNamesFunding.ADD_MANUAL_BANK,
            partnershipId: partnership.id,
        }));
    };
    return (React.createElement(Button, { className: "!w-full h-auto group gap-4", "data-testid": "btn--add-international-bank-account", onPress: handleClick, variant: "outline" },
        React.createElement(Icon, { icon: 'icon-ic-bank', isCustomIcon: true }),
        React.createElement("span", { className: "inline-flex flex-col flex-1 items-start" },
            React.createElement("span", { className: "font-semibold" }, "Add bank account"),
            React.createElement("span", { className: "text-grey-60 group-hover:text-blue-40" }, "Add account information")),
        React.createElement(ArrowRight, null)));
};
export default AddBankAccountManuallyButton;
