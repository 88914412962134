import { reset } from 'redux-form';
import { changeView, toggleSkipStepModal, setNextView } from 'actions/signUpFlow';
import { signupFlowForm, SAVE_STATUSES } from '../constants';
export const triggerSkipStep = (dispatch, saveStatus, onSkipPath) => {
    if (saveStatus === SAVE_STATUSES.notSaved) {
        dispatch(setNextView(onSkipPath));
        dispatch(toggleSkipStepModal());
        return;
    }
    dispatch(reset(signupFlowForm));
    dispatch(changeView(onSkipPath));
};
