import indefinite from 'indefinite';
import React from 'react';
import { ExternalLink } from 'components';
import * as constants from 'constants/fundingProviderMemo';
import { formatCurrencyUSD } from 'helpers/currencyFormatter';
import {} from '../interfaces/billing';
import { isBillingCodeAchAddenda } from './billing/base';
import {} from './fundingProviderMemo.types';
import helpDocs from './helpDocs';
import { multiplyFloat } from './math';
export const getFundingProviderMemoBillingCode = (isDeliveryOptionRTP, billingDataAchAddenda, billingDataRtpRemittance) => {
    if (isDeliveryOptionRTP) {
        return billingDataRtpRemittance;
    }
    return billingDataAchAddenda;
};
export const getFundingProviderMemoAddedOrUpdatedText = (currentString, isDeliveryOptionRTP) => {
    if (currentString) {
        return isDeliveryOptionRTP
            ? constants.REMITTANCE_INFO_UPDATED_TOAST_MESSAGE
            : constants.ADDENDA_UPDATED_TOAST_MESSAGE;
    }
    return isDeliveryOptionRTP ? constants.REMITTANCE_INFO_ADDED_TOAST_MESSAGE : constants.ADDENDA_ADDED_TOAST_MESSAGE;
};
export const getFundingProviderMemoConfirmDeleteSwalText = (isDeliveryOptionRTP) => isDeliveryOptionRTP ? constants.REMITTANCE_INFO_CONFIRM_DELETE_SWAL_TEXT : constants.ADDENDA_CONFIRM_DELETE_SWAL_TEXT;
export const getFundingProviderMemoDeleteSuccessMessage = (isDeliveryOptionRTP) => isDeliveryOptionRTP ? constants.REMITTANCE_INFO_DELETED_TOAST_MESSAGE : constants.ADDENDA_DELETED_TOAST_MESSAGE;
export const getRemittanceInfoOrAddendaRecordText = (isDeliveryOptionRTP, isPlural) => isDeliveryOptionRTP ? 'remittance info' : `addenda record${isPlural ? 's' : ''}`;
export const getFundingProviderMemoMaxCharLimit = (isDeliveryOptionRTP) => isDeliveryOptionRTP ? constants.REMITTANCE_INFO_MAX_CHAR_LIMIT : constants.ADDENDA_MAX_CHAR_LIMIT;
export const getFundingProviderMemoModalButtonText = (addendaRecord, isDeliveryOptionRTP) => {
    if (isDeliveryOptionRTP) {
        return addendaRecord
            ? constants.UPDATE_REMITTANCE_INFO_RECORD_MESSAGE
            : constants.ADD_REMITTANCE_INFO_RECORD_MESSAGE;
    }
    return addendaRecord ? constants.UPDATE_ADDENDA_RECORD_MESSAGE : constants.ADD_ADDENDA_RECORD_MESSAGE;
};
export const getFundingProviderMemoModalTitle = (addendaRecord, isDeliveryOptionRTP) => {
    if (isDeliveryOptionRTP) {
        return addendaRecord ? constants.EDIT_REMITTANCE_INFO_RECORD_MESSAGE : constants.ADD_REMITTANCE_INFO_RECORD_MESSAGE;
    }
    return addendaRecord ? constants.EDIT_ADDENDA_RECORD_MESSAGE : constants.ADD_ADDENDA_RECORD_MESSAGE;
};
export const getFundingProviderMemoTransactionFeeText = (billingData) => {
    const recordType = isBillingCodeAchAddenda(billingData.code) ? 'addenda record' : 'remittance info';
    const { minAmount, multiplier } = billingData;
    const bps = multiplyFloat(multiplier, 10000);
    const baseText = `Adding ${indefinite(recordType)} will cost`;
    if (bps === 0) {
        return `${baseText} ${formatCurrencyUSD(0)} per transaction.`;
    }
    if (!minAmount || parseFloat(minAmount) <= 0) {
        return `${baseText} an additional ${bps} basis points per transaction.`;
    }
    return `${baseText} an additional ${bps} basis points per transaction, with a minimum charge of ${formatCurrencyUSD(minAmount)}.`;
};
export const getAddFundingProviderMemoTooltipText = ({ areMultipleBillsSelected, isCompanyTypePersonal, isDeliveryOptionRTP, }) => {
    if (areMultipleBillsSelected) {
        return isDeliveryOptionRTP
            ? constants.REMITTANCE_INFO_MULTIPLE_PAYMENTS_TEXT
            : constants.ADDENDA_MULTIPLE_PAYMENTS_TEXT;
    }
    if (isCompanyTypePersonal && !isDeliveryOptionRTP) {
        return constants.ADDENDA_COMPANY_TYPE_PERSONAL_TEXT;
    }
    return (React.createElement(React.Fragment, null,
        isDeliveryOptionRTP ? 'A remittance info' : 'An ACH addenda',
        " is a description field added to a transaction that will show on the bank statement. The description helps you and your vendor better identify the purpose of this payment.",
        React.createElement(ExternalLink, { className: "display--block margin-top--m bold primary", href: helpDocs.ADDENDA_RECORDS },
            "Learn more about ",
            getRemittanceInfoOrAddendaRecordText(isDeliveryOptionRTP, true),
            ' ')));
};
