import { fetchSingleItemSuccess, fetchSingleItemRequest } from 'actions/item';
import { fetchItemsRoutine } from 'actions/routines/item';
import {} from 'interfaces/routableApiResponse';
import { getItemsById } from 'reducers/itemsReducer/itemsByIdReducer';
import { routableApi, RoutableTags } from './routableApi';
import {} from './routableApi.types';
const extendedItemApi = routableApi.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        getItemById: builder.query({
            query: ({ id }) => ({ url: `/items/${id}/` }),
            providesTags: [RoutableTags.Items],
            async onCacheEntryAdded(queryArgs, api) {
                api.dispatch(fetchSingleItemRequest(queryArgs?.id));
                const payload = await api.cacheDataLoaded;
                api.dispatch(fetchSingleItemSuccess(payload.data));
            },
        }),
        getItemList: builder.query({
            query: ({ params }) => ({ url: `/items/`, params }),
            providesTags: [RoutableTags.Items],
            async onCacheEntryAdded(_response, api) {
                const payload = await api.cacheDataLoaded;
                const items = getItemsById(payload.data.item, {}, { payload: payload.data });
                api.dispatch(fetchItemsRoutine.fulfill(items));
                api.dispatch(fetchItemsRoutine.success(payload.data));
            },
        }),
    }),
});
export const { useGetItemByIdQuery, useGetItemListQuery, useLazyGetItemByIdQuery, useLazyGetItemListQuery } = extendedItemApi;
