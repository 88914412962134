import { RoutableStorage } from '@routable/framework';
import {} from '../../table.extended.types';
import {} from './TableColumnSelect.types';
export const pullHeaderConfigFromIndexDb = async (tableName, headersConfig) => {
    const columnsConfigRecord = await RoutableStorage.get('routableTableColumns', [
        {
            indexName: 'tableId',
            condition: '==',
            value: tableName,
        },
    ]);
    const columnsConfig = columnsConfigRecord.data;
    if (headersConfig) {
        const config = Object.fromEntries(headersConfig.map(({ columnId, caption }) => [columnId, { id: columnId, label: caption, checked: true }]));
        if (columnsConfig) {
            for (const key in columnsConfig) {
                if (key in config) {
                    config[key].checked = columnsConfig[key].checked;
                }
            }
        }
        return config;
    }
    return columnsConfig;
};
export const pushHeaderConfigToIndexDb = async (tableName, columnConfig) => {
    await RoutableStorage.put('routableTableColumns', `table:${tableName}:columns:updated`, tableName, {
        tableId: tableName,
        timestamp: new Date(),
        data: columnConfig,
    });
};
