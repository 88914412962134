import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useEffectOnce, useSearchParam, useToggle } from 'react-use';
import { useLazyGetItemByIdQuery, useLazyGetItemListQuery } from 'ducks/routableApi';
import { ItemFilterUrlParams } from 'enums/itemFilters';
import { getObjectsByIdWithRelationships } from 'helpers/reducer';
import { allValues } from 'helpers/utility';
import { usePayment, useSelectorWithProps } from 'hooks';
import {} from 'interfaces/countries';
import {} from 'interfaces/item';
import {} from 'interfaces/jsonapi';
import { partnershipCountryCodeFromCurrentPartnershipRequestSelector } from 'queries/partnershipCompoundSelectors';
import { itemAttachmentsSelector } from 'selectors/itemsSelectors';
import {} from 'types/UseApi';
import { ExternalReceiptPaymentFullPageModal, ExternalReceiptPaymentLineItemsModal, } from './components/ExternalReceiptPayment/components';
import ExternalReceiptSingleItem from './ExternalReceiptSingleItem';
const getItems = (itemId, itemDataFromUrl, activePaymentId, paymentDataFromUrl) => {
    let items = [];
    if (itemId && itemDataFromUrl?.item) {
        const itemsById = getObjectsByIdWithRelationships(itemDataFromUrl.item);
        items = allValues(itemsById);
    }
    else if (activePaymentId && paymentDataFromUrl?.item) {
        const itemsById = getObjectsByIdWithRelationships(paymentDataFromUrl.item);
        items = allValues(itemsById);
    }
    return items;
};
export const ExternalReceiptSingleItemContainer = () => {
    const [billModalOpen, setBillModalOpen] = useToggle(false);
    const [activeItem, setActiveItem] = useState();
    const partnershipCountryCode = useSelector(partnershipCountryCodeFromCurrentPartnershipRequestSelector);
    const itemId = useSearchParam('item_id');
    const [triggerGetItemById, { data: itemDataFromUrl }] = useLazyGetItemByIdQuery();
    const activePaymentId = useSearchParam(ItemFilterUrlParams.FILTER_PAYMENT_ID_URL_PARAM);
    const { data: activePayment } = usePayment({ initialPaymentId: activePaymentId });
    const [triggerGetItemList, { data: paymentDataFromUrl, originalArgs: getItemListQueryArgs, isFetching }] = useLazyGetItemListQuery();
    const goToPage = (destinationPage) => {
        switch (destinationPage) {
            case 'PREV':
                triggerGetItemList({
                    params: {
                        ...getItemListQueryArgs.params,
                        page: {
                            size: 1,
                            number: getItemListQueryArgs.params.page.number - 1,
                        },
                    },
                });
                break;
            case 'NEXT':
                triggerGetItemList({
                    params: {
                        ...getItemListQueryArgs.params,
                        page: {
                            size: 1,
                            number: getItemListQueryArgs.params.page.number + 1,
                        },
                    },
                });
                break;
            default:
                triggerGetItemList({
                    params: {
                        ...getItemListQueryArgs.params,
                        page: {
                            size: 1,
                            number: destinationPage,
                        },
                    },
                });
        }
    };
    useEffectOnce(() => {
        if (itemId) {
            triggerGetItemById({ id: itemId });
        }
        if (activePaymentId) {
            triggerGetItemList({
                params: {
                    page: {
                        size: 1,
                        number: 1,
                    },
                    payment: activePaymentId,
                },
            });
        }
    });
    const items = getItems(itemId, itemDataFromUrl, activePaymentId, paymentDataFromUrl);
    const pagination = paymentDataFromUrl?.meta?.pagination ?? {
        page: 1,
        pageSize: 1,
    };
    const item = items.length ? items[0] : undefined;
    const attachments = useSelectorWithProps(itemAttachmentsSelector, item?.id);
    if (!item) {
        return null;
    }
    const enableLineItems = item?.lineItems?.length > 0;
    return (React.createElement(React.Fragment, null,
        React.createElement(ExternalReceiptSingleItem, { attachments: attachments, canNextPage: !isFetching && pagination.page < pagination.pages, canPreviousPage: !isFetching && pagination.page > 1, enableLineItems: enableLineItems, item: item, onGoToPage: goToPage, onViewBills: (viewBillPayment) => setBillModalOpen(!!viewBillPayment?.id), onViewLineItems: setActiveItem, pageCount: pagination.pages, pageIndex: pagination.page, partnershipCountryCode: partnershipCountryCode, payment: activePayment }),
        activePayment && (React.createElement(ExternalReceiptPaymentFullPageModal, { modalState: billModalOpen, onCloseModal: () => setBillModalOpen(), onSetActiveItem: setActiveItem, payment: activePayment })),
        React.createElement(ExternalReceiptPaymentLineItemsModal, { item: activeItem, modalState: !!activeItem, onCloseModal: () => setActiveItem(undefined) })));
};
export default ExternalReceiptSingleItemContainer;
