import { Hint } from '@routable/gross-ds';
import React from 'react';
import { LinkNewPage } from 'components';
import { CONTACT_SUPPORT_LOWERCASE_LABEL } from 'constants/labels';
import { PLATFORM_EMAILS } from 'constants/platform';
import { getMailtoUrl } from 'helpers/urls';
const UnableToUpdatePaymentMethodHint = ({ className }) => (React.createElement(Hint, { className: className, title: "Sorry, we are unable to process your request.", type: "critical" },
    "Please refresh this page. If the issue persists, please",
    ' ',
    React.createElement(LinkNewPage, { href: getMailtoUrl(PLATFORM_EMAILS.CHAT) }, CONTACT_SUPPORT_LOWERCASE_LABEL),
    "."));
export default UnableToUpdatePaymentMethodHint;
