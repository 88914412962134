import {
  isItemKindPayable,
  isItemKindReceivable,
  isItemStatusNew,
  isReceivableCreatorPartnerCompany,
} from 'helpers/items';

export const getItemAmountPaidPrefix = (item) => {
  if (isItemKindPayable(item)) {
    return 'Amount paid (by me)';
  }

  if (isItemKindReceivable(item)) {
    return 'Amount paid (by customer)';
  }

  return 'Amount paid';
};

export const getItemAmountDuePrefix = (item) => {
  if (isReceivableCreatorPartnerCompany(item) && isItemStatusNew(item)) {
    return 'Amount sent (by customer)';
  }

  return 'Amount due';
};

/**
 * Determines whether the given amount is negative.
 * @param {number} amount
 * @return {boolean}
 */
export const isAmountNegative = (amount) => amount < 0;
