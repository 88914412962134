import {} from 'history';
import { FetchService } from 'services';
import { BASE_WEBSITE_URL } from 'services/api';
export const navigateToEmbeddedUrl = async (url, itemId, isItemEdit, kind, history) => {
    const cancelUrlPath = `/ocr/training/cancel_url/${itemId}/${isItemEdit}/${kind}`;
    const config = {
        endpoint: url,
        method: 'POST',
        requireAuth: true,
        payload: {
            cancel_url: `${BASE_WEBSITE_URL}${cancelUrlPath}`,
            return_url: `${BASE_WEBSITE_URL}/ocr/training/return_url/${itemId}/${isItemEdit}/${kind}`,
        },
        accept: 'application/json',
        overrideHeaders: {
            'Content-Type': 'application/json',
        },
    };
    try {
        const result = await FetchService.request(config);
        if (result && result.originalData && result.originalData.url) {
            history.replace(cancelUrlPath);
            window.location.href = result.originalData.url;
            return true;
        }
        throw new Error('Invalid Embedded URL');
    }
    catch (err) {
        throw new Error(err.message);
    }
};
