import { RoutableEvents } from '@routable/framework';
import { queryClient } from '@routable/shared';
import { all, call, fork, join, put, race, select, spawn, take, takeLatest } from 'redux-saga/effects';

import { itemEarApprovalsRoutine } from 'ducks/itemEarApproval/itemEarApproval';

import { handleRequestErrors } from 'actions/errors';
import * as actions from 'actions/item';
import { fetchSingleItemRequest } from 'actions/item';
import { goBackIfPreviousState } from 'actions/navigation';
import { fetchItemsRoutine } from 'actions/routines/item';
import { deleteInboxItemRoutine } from 'actions/routines/item/delete';
import { deselectAllRows } from 'actions/tables';
import * as threadActions from 'actions/thread';
import * as uiActions from 'actions/ui';

import { TOGGLE_WARNING_MODAL_PROMPT } from 'constants/events';

import { confirmAlert } from 'helpers/confirmAlert';
import { parseCaughtError, parseErrorResponse } from 'helpers/errors';
import { trackEvent, TrackEventName } from 'helpers/eventTracking';
import * as fileHelpers from 'helpers/fileHelpers';
import { getQueryParam } from 'helpers/queryParams';

import { getItemKindByTab } from 'modules/dashboard/itemList/helpers';
import { persistFilterListToURL } from 'modules/itemFilters/persistToUrl';
import { sortParamToUrl } from 'modules/itemSort/sortParamToUrl';

import { itemsRequestDataSelector } from 'selectors/itemsRequestSelectors';

import FetchService from 'services/fetch';

import { CANCEL_SET_EXPORT_FILE_ID } from 'types/export';
import * as types from 'types/item';
import * as tableTypes from 'types/tables';
import * as threadTypes from 'types/thread';

import * as api from './api';
import { makeItemsRequest } from './itemsRequestApi';
import * as strings from './strings';

/**
 * Handle fetching all items
 */
export function* fetchItems(action) {
  let errorData = {};

  yield put(fetchItemsRoutine.request());

  try {
    const { payload: params = {} } = action;

    const request = yield select(itemsRequestDataSelector, params);
    const response = yield call(makeItemsRequest, request);

    if (response.ok) {
      yield put(fetchItemsRoutine.success(response.data));
      // We need to get approvals when on the approval tab and using EAR
      if (request.filter?.endsWith('approval')) {
        yield put(itemEarApprovalsRoutine(Object.keys(response?.data?.item || {})));
      }

      yield put(fetchItemsRoutine.fulfill());
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  yield put(handleRequestErrors(fetchItemsRoutine.failure, errorData));
}

/**
 * Handle fetching all items.
 * If the response is 202, it's async and downloaded in the background.
 * When the response is 200, it's sync and the file is downloaded in the browser.
 * For async files, stores the export file id.
 * @param {ReduxAction} action fetchItemsExportSuccess or fetchItemsExportFailure
 * @return {IterableIterator<*>}
 */
export function* fetchItemsExport(action) {
  let errorData = {};

  try {
    const {
      payload: { params },
    } = action;

    const requestOptions = fileHelpers.getFileDownloadRequestOptions(params.accept);

    const response = yield call(api.fetchItems, params, requestOptions);
    const kind = getItemKindByTab(params.tab);

    // case: async 202
    if (FetchService.isResponseAccepted(response)) {
      const exportFileId = yield new Promise((resolve) => {
        response.data.text().then((text) => {
          resolve(text.trim().split('\n')[1]);
        });
      });
      yield put({
        type: CANCEL_SET_EXPORT_FILE_ID,
        payload: { exportFileId },
      });
      yield put(actions.fetchItemsExportSuccess());
      yield call(trackEvent, TrackEventName.CSV_EXPORT_CLICKED, {
        exportType: kind,
        downloadType: 'background',
      });
      return;
    }

    // case: sync anything between 200 and 300
    if (FetchService.isResponseOK(response)) {
      const fileName = fileHelpers.getExportZipFilename(response, `${kind}s`);
      fileHelpers.downloadFile(response.data, fileName);
      yield put(actions.fetchItemsExportSuccess());
      yield call(trackEvent, TrackEventName.CSV_EXPORT_CLICKED, {
        exportType: kind,
        downloadType: 'immediate',
      });
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  yield put(handleRequestErrors(actions.fetchItemsExportFailure, errorData));
}

/**
 * Handle fetching a specific item.
 * @param {ReduxAction} action
 * @return {IterableIterator<*>}
 */
export function* fetchSingleItem(action) {
  let errorData = {};
  const { itemId } = action?.payload ?? {};
  try {
    const {
      payload: { itemId, options },
    } = action;
    const { queryParams } = options;

    const response = yield call(api.fetchSingleItem, itemId, queryParams, options);

    if (response.ok) {
      yield put(actions.fetchSingleItemSuccess(response.data));

      return response;
    }

    errorData = parseErrorResponse(response, { itemId });
  } catch (error) {
    errorData = parseCaughtError(error, { itemId });
  }

  yield put(handleRequestErrors(actions.fetchSingleItemFailure, errorData));
  return undefined;
}

/**
 * Using query params, emits an action initiating fetchSingleItem saga and waits till saga is done.
 */
export function* fetchSingleItemFromQueryParam() {
  const itemId = getQueryParam('item_id');

  if (itemId) {
    yield put(fetchSingleItemRequest(itemId));
    yield race([take(types.FETCH_ITEM_FAILURE), take(types.FETCH_ITEM_SUCCESS)]);
  }
}

/**
 * Handle fetching a thread for an item.
 * @return {IterableIterator<*>}
 */
export function* fetchThread(action) {
  let errorData = {};

  try {
    const {
      payload: { threadId },
    } = action;

    const response = yield call(api.fetchThread, threadId);

    if (response.ok) {
      yield put(threadActions.fetchThreadSuccess(response.data));
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  yield put(handleRequestErrors(threadActions.fetchThreadFailure, errorData));
}

/**
 * Handle fetching an item and its thread.
 * @return {IterableIterator<*>}
 */
export function* fetchItemAndThread(action) {
  const fetchItemTask = yield fork(fetchSingleItem, action);

  yield join(fetchItemTask);

  const response = fetchItemTask.result();

  if (response) {
    const threadId = response.originalData.data.relationships.thread.data.id;
    yield put(threadActions.fetchThreadRequest(threadId));
  }
}

/**
 * Handle deleting a bill from inbox items.
 * @return {IterableIterator<*>}
 */
export function* deleteInboxItem(action) {
  let errorData = {};

  RoutableEvents.Publish(TOGGLE_WARNING_MODAL_PROMPT, { showPrompt: false });
  try {
    const {
      payload: { itemId },
    } = action;

    const isAgreed = yield call(confirmAlert, strings.getDeleteInboxItemConfirmAlertMessage());

    if (!isAgreed) {
      RoutableEvents.Publish(TOGGLE_WARNING_MODAL_PROMPT, { showPrompt: true });
      return;
    }

    yield put(deleteInboxItemRoutine.request());
    const response = yield call(api.deleteInboxItem, itemId);

    if (response.ok) {
      // invalidate query to trigger refetching of items
      queryClient.invalidateQueries(['items']);

      yield all([
        put(deleteInboxItemRoutine.success({ id: itemId })),
        // add success toast
        put(
          uiActions.showSuccessUi(strings.getDeleteInboxItemSuccessMessage(), {
            id: 'delete-item',
          }),
        ),
        // as our delete action can only be called from modal at present, always exit modal
        put(goBackIfPreviousState()),
      ]);

      RoutableEvents.Publish(TOGGLE_WARNING_MODAL_PROMPT, { showPrompt: true });
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  RoutableEvents.Publish(TOGGLE_WARNING_MODAL_PROMPT, { showPrompt: true });
  yield put(handleRequestErrors(deleteInboxItemRoutine.failure, errorData));
}

export function* applyFilters(action) {
  const {
    payload: { filters, ...options },
  } = action;

  persistFilterListToURL(filters);
  yield all([put(deselectAllRows()), put(fetchItemsRoutine.trigger(options))]);
}

// This conditionally calls other actions for re-fetching items as we only need this mechanism for the inbox table. In payables/receivables, we are already
// listening to update in location and dispatch actions to fetch items.
// eslint-disable-next-line require-yield
export function* applySort(action) {
  const {
    payload: { sortParam, shouldRefetch },
  } = action;

  sortParamToUrl(sortParam);

  if (shouldRefetch) {
    yield all([put(deselectAllRows()), put(fetchItemsRoutine.trigger())]);
  }
}

/**
 * Listens for redux actions related to items.
 * @return {IterableIterator<*>}
 */
export function* watch() {
  yield takeLatest(fetchItemsRoutine.TRIGGER, fetchItems);
  yield takeLatest(types.FETCH_ITEM_AND_THREAD_REQUEST, fetchItemAndThread);
  yield takeLatest([tableTypes.APPLY_PAYABLES_TABLE_SORT, tableTypes.APPLY_RECEIVABLES_TABLE_SORT], applySort);

  while (true) {
    const action = yield take([
      deleteInboxItemRoutine.TRIGGER,
      types.FETCH_ITEM_REQUEST,
      threadTypes.FETCH_THREAD_REQUEST,
      types.FETCH_ITEMS_EXPORT_REQUEST,
      tableTypes.APPLY_PAYABLES_TABLE_FILTERS,
      tableTypes.APPLY_RECEIVABLES_TABLE_FILTERS,
    ]);

    switch (action.type) {
      case deleteInboxItemRoutine.TRIGGER:
        yield spawn(deleteInboxItem, action);
        break;

      case tableTypes.APPLY_PAYABLES_TABLE_FILTERS:
      case tableTypes.APPLY_RECEIVABLES_TABLE_FILTERS:
        yield spawn(applyFilters, action);
        break;

      case types.FETCH_ITEM_REQUEST:
        yield spawn(fetchSingleItem, action);
        break;

      case threadTypes.FETCH_THREAD_REQUEST:
        yield spawn(fetchThread, action);
        break;

      case types.FETCH_ITEMS_EXPORT_REQUEST:
        yield spawn(fetchItemsExport, action);
        break;

      default:
        yield null;
    }
  }
}

/**
 * Root item saga.
 * @return {IterableIterator<*>}
 */
export default function* item() {
  yield watch();
}
