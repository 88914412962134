import PropTypes from 'prop-types';
import React from 'react';
import Script from 'react-load-script';

import * as connectBankHelpers from 'modules/connectBank/global/helpers/connect';
import { PlaidLinkCDNURL } from 'modules/plaidLink/constants';

import { createLoadPlaidLinkHandler, removeAllPlaidFramesFromDOM, updatePlaidLinkStatusToLoading } from './helpers';

/**
 * Load Plaid API as a component. On load, and other events, update the Plaid modal state.
 * @param {Object} props
 * @returns {JSX.Element}
 */
const PlaidLink = (props) => {
  const {
    createFundingAccountLinkToken,
    connectBankTokenModal: { hasErrors, open: modalOpen, plaidLinkLoaded, plaidSessionId, plaidLinkToken },
    institution,
    onUpdateTokenAccountModal,
    sessionId,
  } = props;

  const [linkHandler, setLinkHandler] = React.useState(null);

  // componentDidUpdate
  React.useEffect(() => {
    // Ensure the modal is open
    if (!modalOpen || !plaidLinkToken) {
      return;
    }

    // Ensure this is the right plaid session
    if (sessionId !== plaidSessionId) {
      return;
    }

    linkHandler.open(institution);
  }, [institution, linkHandler, modalOpen, plaidLinkToken, plaidSessionId, sessionId]);

  // Remove Plaid Iframes created in the dom on unMount
  React.useEffect(() => () => removeAllPlaidFramesFromDOM(), []);

  if (!plaidLinkToken && !hasErrors) {
    connectBankHelpers.createLinkToken(createFundingAccountLinkToken, onUpdateTokenAccountModal);
  }

  if (!plaidLinkToken) {
    return null;
  }

  const handleCreate = updatePlaidLinkStatusToLoading(plaidLinkLoaded, onUpdateTokenAccountModal);

  const handleLoad = createLoadPlaidLinkHandler({ plaidLinkToken, props, setLinkHandler });

  return <Script onCreate={handleCreate} onLoad={handleLoad} url={PlaidLinkCDNURL} />;
};

PlaidLink.propTypes = {
  /* props used in helpers */
  /* eslint-disable react/no-unused-prop-types */
  createFundingAccountLinkToken: PropTypes.func.isRequired,
  connectBankTokenModal: PropTypes.shape().isRequired,

  // Open link to a specific institution, for a more custom solution
  institution: PropTypes.string,

  // Set to true to launch Link with longtail institution support enabled.
  // Longtail institutions are only available with the Connect product.
  longtail: PropTypes.bool,

  onCloseTokenAccountModal: PropTypes.func.isRequired,
  onUpdateTokenAccountModal: PropTypes.func.isRequired,

  // A function that is called when a user has successfully onboarded their
  // account. The function should expect two arguments, the public_key and a
  // metadata object
  onSuccess: PropTypes.func.isRequired,

  // Set to true to launch Link with the 'Select Account' pane enabled.
  // Allows users to select an individual account once they've authenticated
  selectAccount: PropTypes.bool,
  sessionId: PropTypes.string.isRequired,

  // Specify a webhook to associate with a user.
  webhook: PropTypes.string,
  /* eslint-enable react/no-unused-prop-types */
};

PlaidLink.defaultProps = {
  institution: undefined,
  longtail: false, // eslint-disable-line routable/default-props-prefer-undefined
  onSuccess: undefined,
  selectAccount: true,
  webhook: undefined,
};

export default PlaidLink;
