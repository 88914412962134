import { countryCodeModel, uuidModel } from '@routable/shared';
import { z } from 'zod';
export const partnershipRequestAttributesModel = z.object({
    countryCode: countryCodeModel,
});
export const partnershipRequestModel = z.object({
    type: z.literal('PartnershipRequest'),
    id: uuidModel,
    attributes: partnershipRequestAttributesModel,
});
export const partnershipRequestPatchApiResponseModel = z.object({
    partnershipRequest: z.record(uuidModel, partnershipRequestModel),
});
