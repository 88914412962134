import { Clickable } from '@routable/gross-ds';
import React from 'react';
import { CustomerServiceHelper } from 'modules/customerSupport';
export const MissingCurrencyFooter = () => (React.createElement("div", { className: "border-t border-grey-20 p-4 text-black text-xs" },
    "Missing currency?",
    ' ',
    React.createElement(Clickable, { onClick: () => {
            CustomerServiceHelper.show();
            CustomerServiceHelper.setConversationTags(['missing-currency']);
        } }, "Contact us")));
