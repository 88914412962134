import { createSelector } from 'reselect';

import { UserTypeToInternationalFundingBusinessType } from 'constants/funding';
import { sidePanelNameContact, sidePanelNameEditCompanyGeneralInfo } from 'constants/sidePanels';

import { splitPersonalCompanyNameToFirstLast } from 'helpers/company';
import { getCountryNameFromCode } from 'helpers/countries';
import { getCurrencyNameFromCode } from 'helpers/currency';
import {
  getPartnershipPartnerCompany,
  getPartnershipPrimaryReceivableFundingAccount,
  getPartnershipCurrencyCodeCompany,
  isInternationalPartnership,
} from 'helpers/partnerships';

import { formPartnershipSelector } from 'queries/createItemFormSelectors';

import { addressesSelector } from 'selectors/addressesSelectors';
import { brandsSelector } from 'selectors/brandSelectors';
import { companiesSelector, companyInfoByIdSelector } from 'selectors/companiesSelectors';
import { byCountrySelector } from 'selectors/countriesSelectors';
import { byCurrencySelector } from 'selectors/currenciesSelectors';
import { isFetchingCurrentCompanySelector } from 'selectors/currentCompanySelectors';
import {
  fundingAccountIdFromFundingAccountPropSelector,
  fundingAccountsByIdSelector,
  fundingAccountsIsFetchingSelector,
} from 'selectors/fundingSelectors';
import { allStateSelector, idSelector } from 'selectors/globalSelectors';
import { ledgerPartnershipsSelector, matchingPartnershipsSelector } from 'selectors/ledgerInfoSelectors';
import { modalMatchPlatformPartnershipSelector, modalMergeLedgerPartnershipSelector } from 'selectors/modalsSelector';
import { partnershipRequestFromLocationSelector } from 'selectors/partnershipRequestSelectors';
import {
  allPartnershipReceivableFundingAccountsSelector,
  isFetchingPartnershipSelector,
  partnershipSelector,
  partnershipsByIdSelector,
  partnershipFundingAccountsAllValuesSelector,
} from 'selectors/partnershipsSelectors';
import { isFetchingPermissionsSelector } from 'selectors/permissionsSelectors';
import {
  derivePartnershipIdFromPropsSelector,
  idPropSelector,
  itemPartnershipPropSelector,
  itemPartnershipRowPropSelector,
  partnershipIdPropSelector,
  partnershipPropSelector,
  rowDataPartnerPropSelector,
  rowDataPartnershipPropSelector,
} from 'selectors/propSelectors';
import { isFetchingRolesSelector } from 'selectors/rolesSelectors';
import { partnershipIdQuerySelector } from 'selectors/routerSelectors';
import { createSidePanelSelector } from 'selectors/sidePanelsSelector';

const contactSidePanelSelector = createSidePanelSelector(sidePanelNameContact);
const editCompanyGeneralInfoSidePanelSelector = createSidePanelSelector(sidePanelNameEditCompanyGeneralInfo);

/**
 * Selects the partnership partner company
 * @param {object} state - Redux state
 * @param {string} partnershipId - Partnership id
 */
export const partnershipPartnerCompanySelector = createSelector(
  [companiesSelector, partnershipSelector],
  getPartnershipPartnerCompany,
);

/**
 * Selects the partnership partner company with the partnershipId passed in via ownProps
 * @param {ReduxState} state
 * @param {ComponentProps} ownProps
 * @param {Partnership} ownProps.partnership
 * @returns {Company} partnerCompany
 */
export const partnershipPartnerCompanyFromPropsSelector = createSelector(
  [companiesSelector, partnershipPropSelector],
  getPartnershipPartnerCompany,
);

/**
 * Selects the companyType of partnership partner company with the partnershipId passed in via ownProps
 * @param {ReduxState} state
 * @param {ComponentProps} ownProps
 * @param {Partnership} ownProps.partnership
 * @returns {UserType} companyType
 */
export const partnershipPartnerCompanyTypeFromPropsSelector = createSelector(
  [partnershipPartnerCompanyFromPropsSelector],
  (company) => company?.companyType,
);

/**
 * Returns true if fetching funding accounts/current company or partnerships, otherwise returns false
 * @param {object} state - Redux state
 */
export const isFetchingPartnerUpdateFundingAccountRequirementsSelector = createSelector(
  [
    fundingAccountsIsFetchingSelector,
    isFetchingCurrentCompanySelector,
    isFetchingPartnershipSelector,
    isFetchingPermissionsSelector,
    isFetchingRolesSelector,
  ],
  (...selectors) => selectors.some(Boolean),
);

/**
 * Selects the partnership partner member
 * @type {StandardSelector}
 * @param {Object} state
 * @param {Object=location} props
 * @return {ObjectMaybe}
 */
export const partnershipFromQuerySelector = createSelector(
  [partnershipsByIdSelector, partnershipIdQuerySelector],
  (partnerships, partnershipId) => partnerships[partnershipId],
);

/**
 * Selects the country code that belongs to the partnership selected from the query param
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {ComponentProps} props
 * @param {Location} props.location
 * @returns {CountryCode}
 */
export const partnershipCountryCodeFromQuerySelector = createSelector(
  [partnershipFromQuerySelector],
  (partnership) => partnership?.countryCodeCompany,
);

/**
 * Selects the country name that belongs to the partnership selected from the query param
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {ComponentProps} props
 * @param {Location} props.location
 * @returns {CountryName}
 */
export const partnershipCountryNameFromQuerySelector = createSelector(
  [byCountrySelector, partnershipCountryCodeFromQuerySelector],
  getCountryNameFromCode,
);

/**
 * Selects the Partnership from state when an item is passed to ownProps.
 * @function
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {ComponentProps} ownProps
 * @param {Item} ownProps.item
 * @param {Item.partnership} ownProps.item.partnership - An Id for the Item's Partnership
 * @returns {Partnership} - Full Partnership from state
 */
export const partnershipFromItemPropSelector = createSelector(
  [partnershipsByIdSelector, itemPartnershipPropSelector],
  (partnerships, partnershipId) => partnerships[partnershipId],
);

/**
 * Selects the Partnership from state when an item is passed to ownProps.
 * @function
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {ComponentProps} ownProps
 * @param {Item} ownProps.rowData
 * @param {Item.partnership} ownProps.item.partnership - An Id for the Item's Partnership
 * @returns {?Partnership} - Full Partnership from state
 */
export const partnershipFromRowDataPropSelector = createSelector(
  [partnershipsByIdSelector, rowDataPartnershipPropSelector],
  (partnerships, partnershipId) => partnerships?.[partnershipId],
);

/**
 * Selects the Partnership from state when table row with item data is passed to ownProps
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {ComponentProps} ownProps
 * @param {Object} ownProps.row
 * @param {Item} ownProps.row.original
 * @param {Item.partnership} ownProps.row.original.partnership
 * @returns {Partnership}
 */
export const partnershipFromRowPropSelector = createSelector(
  [partnershipsByIdSelector, itemPartnershipRowPropSelector],
  (partnerships, partnershipId) => partnerships[partnershipId],
);

/**
 * Selects the Partnership name from state when table row with item data is passed to ownProps
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {ComponentProps} ownProps
 * @param {Object} ownProps.row
 * @param {Item} ownProps.row.original
 * @param {Item.partnership} ownProps.row.original.partnership
 * @returns {Partnership.name}
 */
export const partnershipNameFromRowPropSelector = createSelector(
  [partnershipFromRowPropSelector],
  (partnership) => partnership?.name,
);

/**
 * Selects the partnership primary receivable funding account or returns undefined using the partnership or
 * partnershipId passed to the component's ownProps.
 * @function
 * @param {ReduxState} state
 * @param {ComponentProps} ownProps
 * @returns {FundingAccount|undefined}
 */
export const partnershipPrimaryReceivableFundingAccountFromPropsSelector = createSelector(
  [allPartnershipReceivableFundingAccountsSelector, derivePartnershipIdFromPropsSelector],
  getPartnershipPrimaryReceivableFundingAccount,
);

/**
 * Selects the Partnership from state when an id is passed to ownProps.
 * @function
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {ComponentProps} ownProps
 * @param {Id} ownProps.id
 * @returns {Partnership} - Full Partnership from state
 */
export const partnershipFromIdPropSelector = createSelector(
  [partnershipsByIdSelector, idPropSelector],
  (partnerships, partnershipId) => partnerships[partnershipId],
);

/**
 * Selects the Partnership from state when an partnershipId is passed to ownProps.
 * @function
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {ComponentProps} ownProps
 * @param {Id} ownProps.partnershipId
 * @returns {Partnership} - Full Partnership from state
 */
export const partnershipFromPartnershipIdPropSelector = createSelector(
  [partnershipsByIdSelector, partnershipIdPropSelector],
  (partnerships, partnershipId) => partnerships[partnershipId],
);

/**
 * Gets the partnership data using the partnership ID in sidePanels.contact.
 * @function
 * @param {ReduxState} state
 * @param {ComponentProps} props
 * @returns {ObjectMaybe}
 */
export const partnershipFromContactSidePanelDataSelector = createSelector(
  [contactSidePanelSelector, partnershipsByIdSelector],
  (sidePanel, partnershipsById) => partnershipsById[sidePanel.partnershipId],
);

/**
 * Gets the partnership ID from the current partnership request
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {ComponentProps} props
 * @param {Location} props.location
 * @returns {Partnership.id}
 */
export const partnershipIdFromCurrentPartnershipRequestSelector = createSelector(
  [partnershipRequestFromLocationSelector],
  (partnershipRequest) => partnershipRequest?.partnership,
);

/**
 * Gets the partnership from the current partnership request
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {ComponentProps} props
 * @param {Location} props.location
 * @returns {Partnership}
 */
export const partnershipFromCurrentPartnershipRequestSelector = createSelector(
  [allStateSelector, partnershipIdFromCurrentPartnershipRequestSelector],
  partnershipSelector,
);

/**
 * Selects the partnership from current partnership request and determines if the partnership
 * is international
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {ComponentProps} props
 * @param {Location} props.location
 * @returns {Boolean}
 */
export const isPartnershipInternationalFromCurrentPartnershipRequestSelector = createSelector(
  [partnershipFromCurrentPartnershipRequestSelector],
  isInternationalPartnership,
);

/**
 * Selects the partnership from current partnership request and returns partnership currency code
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {ComponentProps} props
 * @param {Location} props.location
 * @returns {CurrencyCode}
 */
export const partnershipCurrencyCodeCompanyFromCurrentPartnershipRequestSelector = createSelector(
  [partnershipFromCurrentPartnershipRequestSelector],
  getPartnershipCurrencyCodeCompany,
);

/**
 * Selects the partnership from current partnership request and returns partnership currency name
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {ComponentProps} props
 * @param {Location} props.location
 * @returns {string}
 */
export const partnershipCurrencyNameFromCurrentPartnershipRequestSelector = createSelector(
  [byCurrencySelector, partnershipCurrencyCodeCompanyFromCurrentPartnershipRequestSelector],
  getCurrencyNameFromCode,
);

/**
 * Selects the partnership from current partnership request and determines if the partnership
 * currency code is a non-usd currency code
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {ComponentProps} props
 * @param {Location} props.location
 * @returns {Boolean}
 */
export const isInternationalPartnershipSelector = createSelector(
  [partnershipFromCurrentPartnershipRequestSelector],
  isInternationalPartnership,
);

/**
 * Gets the partnership's country code from the current partnership request
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {ComponentProps} props
 * @param {Location} props.location
 * @returns {CountryCode|undefined}
 */
export const partnershipCountryCodeFromCurrentPartnershipRequestSelector = createSelector(
  [partnershipFromCurrentPartnershipRequestSelector],
  (partnership) => partnership?.countryCodeCompany,
);

/**
 * Selects Partnership PartnerCompany from the PartnershipRequest used in the external flow
 * @type {StandardSelector}
 * @returns {Partnership|undefined}
 */
export const partnershipPartnerCompanyFromPartnershipRequestSelector = createSelector(
  [companiesSelector, partnershipFromCurrentPartnershipRequestSelector],
  getPartnershipPartnerCompany,
);

/**
 * Selects Partnership PartnerCompany's brand from the PartnershipRequest used in the external flow
 * @type {StandardSelector}
 * @returns {Brand|undefined}
 */
export const partnershipPartnerCompanyBrandFromPartnershipRequestSelector = createSelector(
  [partnershipPartnerCompanyFromPartnershipRequestSelector, brandsSelector],
  (partner, brandsById) => brandsById[partner?.brand],
);

/**
 * Selects Partnership PartnerCompany's InternationalFundingBusinessType from the current PartnershipRequest
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {InternationalFundingBusinessType}
 */
export const partnershipPartnerInternationalBusinessTypeFromPartnershipRequestSelector = createSelector(
  [partnershipPartnerCompanyFromPartnershipRequestSelector],
  (partner) => UserTypeToInternationalFundingBusinessType[partner?.companyType],
);

/**
 * Selects full Partnership object from state for selected partnership in create item form
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Partnership}
 */
export const partnershipFromCreateItemFormSelector = createSelector(
  [partnershipsByIdSelector, formPartnershipSelector],
  (partnershipsById, partnership) => partnershipsById[partnership?.id],
);

/**
 * Selects partnership funding accounts from passed partnership component prop
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {ComponentProps} props
 * @returns {PartnershipFundingAccount[]}
 */
export const partnershipFundingAccountsForPartnershipPropSelector = createSelector(
  [partnershipFundingAccountsAllValuesSelector, partnershipPropSelector],
  (partnershipsFundingAccounts = [], partnership) =>
    partnershipsFundingAccounts.filter(
      (partnershipFundingAccount) => partnershipFundingAccount.partnership === partnership.id,
    ),
);

/**
 * Selects partnership funding accounts from passed partnership component prop
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {ComponentProps} props
 * @returns {PartnershipFundingAccount[]}
 */
export const partnershipFundingAccountsForPartnershipIdFromCreateItemFormSelector = createSelector(
  [fundingAccountsByIdSelector, partnershipFundingAccountsAllValuesSelector, formPartnershipSelector],
  (fundingAccountsById, partnershipsFundingAccounts = [], partnership) =>
    partnershipsFundingAccounts.reduce((arr, partnershipFundingAccount) => {
      if (partnershipFundingAccount.partnership !== partnership?.id) {
        return arr;
      }

      return [
        ...arr,
        {
          ...partnershipFundingAccount,
          processingChannels: fundingAccountsById?.[partnershipFundingAccount.fundingAccount]?.processingChannels,
        },
      ];
    }, []),
);

/**
 * Selects partnership funding accounts from RTS partnership component prop
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {string} partnershipId
 * @returns {PartnershipFundingAccount[]}
 */
export const partnershipFundingAccountsForPartnershipIdFromFinalizeItemFormSelector = createSelector(
  [fundingAccountsByIdSelector, partnershipFundingAccountsAllValuesSelector, idSelector],
  (fundingAccountsById, partnershipsFundingAccounts = [], partnershipId) =>
    partnershipsFundingAccounts.reduce((arr, partnershipFundingAccount) => {
      if (partnershipFundingAccount.partnership !== partnershipId) {
        return arr;
      }

      return [
        ...arr,
        {
          ...partnershipFundingAccount,
          processingChannels: fundingAccountsById?.[partnershipFundingAccount.fundingAccount]?.processingChannels,
        },
      ];
    }, []),
);

/**
 * Selects partnership funding account from passed partnership and funding account prop
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {Object} props
 * @param {Partnership} props.partnership
 * @param {FundingAccount} props.fundingAccount
 * @returns {PartnershipFundingAccount|undefined}
 */
export const partnershipFundingAccountForFundingAccountPropSelector = createSelector(
  [partnershipFundingAccountsForPartnershipPropSelector, fundingAccountIdFromFundingAccountPropSelector],
  (partnershipFundingAccounts = [], fundingAccountId) =>
    partnershipFundingAccounts.find(
      (partnershipFundingAccount) => partnershipFundingAccount.fundingAccount === fundingAccountId,
    ),
);

/**
 * Selects partnership's partner company mailing address from rowData.partner prop
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {Object} props
 * @param {Object} props.rowData
 * @param {Id} props.rowData.partner
 * @returns {MailingAddress}
 */
export const partnershipPartnerAddressForRowDataPartnerPropSelector = createSelector(
  [addressesSelector, companiesSelector, rowDataPartnerPropSelector],
  (addresses, companies, partner) => {
    const company = companies[partner];
    return addresses[company?.mailingAddress];
  },
);

/**
 * Selects partnership's currency code vendor from partnership create item form
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {CurrencyCode}
 */
export const createItemFormPartnershipCurrencyCodeVendorSelector = createSelector(
  [formPartnershipSelector, partnershipsByIdSelector],
  (partnership, partnerships) => partnerships[partnership?.id]?.currencyCodeVendor,
);

/**
 * Gets initial values from partnership state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Object} initialValues
 * @returns {String|undefined} initialValues.externalRef
 * @returns {String|undefined} initialValues.displayName
 * @returns {String|undefined} initialValues.legalFirstName
 * @returns {String|undefined} initialValues.legalLastName
 * @returns {String|undefined} initialValues.ledgerNameCustomer
 * @returns {String|undefined} initialValues.ledgerNameVendor
 * @returns {String|undefined} initialValues.name
 * @returns {Object} initialValues.partner
 * @returns {String|undefined} initialValues.partner.name
 */
export const editGeneralInfoInitialValuesSelector = createSelector(
  [companiesSelector, companyInfoByIdSelector, editCompanyGeneralInfoSidePanelSelector, partnershipsByIdSelector],
  (companies, companyInfo, sidePanel, partnerships) => {
    const partnership = partnerships[sidePanel?.partnershipId] || {};
    const displayName = partnership.name;
    const company = companies[partnership.partner] || {};
    const legalName = company.name || displayName;
    const { hasTin, tin, tinType } = companyInfo[company.info] || {};

    const { legalFirstName, legalLastName } = splitPersonalCompanyNameToFirstLast(legalName);

    const extraProps = hasTin
      ? {
          governmentId: {
            tin,
            tinType,
          },
        }
      : {};

    return {
      externalRef: partnership.externalRef,
      displayName,
      legalFirstName,
      legalLastName,
      ledgerNameCustomer: partnership.ledgerNameCustomer,
      ledgerNameVendor: partnership.ledgerNameVendor,
      name: legalName,
      ...extraProps,
    };
  },
);

/**
 * Selects the partnershipId property of the Edit General Info side sheet state (if
 * it exists)
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {string|undefined} partnershipId, if it exists
 */
export const editGeneralInfoSideSheetPartnershipIdSelector = createSelector(
  [editCompanyGeneralInfoSidePanelSelector],
  (sidePanelState) => sidePanelState?.partnershipId,
);

/**
 * Returns partnership from current partnership sidesheet
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Company} partnership
 */
export const editGeneralInfoPartnershipSelector = createSelector(
  [editGeneralInfoSideSheetPartnershipIdSelector, partnershipsByIdSelector],
  (partnershipId, partnerships) => partnerships[partnershipId] || {},
);

/**
 * Returns partner company id from current partnership sidesheet
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {String|undefined} partner's company id
 */
export const editGeneralInfoCompanyIdSelector = createSelector(
  [editGeneralInfoPartnershipSelector],
  (partnership) => partnership?.partner,
);

/**
 * Selects the partner company belonging to the partnership id stored in the
 * Edit General Info side sheet state
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Company|undefined} partner company, if it exists
 */
export const editGeneralInfoCompanySelector = createSelector(
  [companiesSelector, editGeneralInfoCompanyIdSelector],
  (companiesById, partnerCompanyId) => companiesById[partnerCompanyId],
);

/**
 * Returns companyType from current partnership sidesheet
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {String|undefined} companyType
 */
export const editGeneralInfoCompanyTypeSelector = createSelector(
  [editGeneralInfoCompanySelector],
  (company) => company?.companyType,
);

/**
 * Returns partnership from match modal
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Partnership|undefined}
 */
export const partnershipFromMatchModalSelector = createSelector(
  [matchingPartnershipsSelector, modalMatchPlatformPartnershipSelector],
  (partnerships, modal) => {
    const partnershipId = modal?.partnershipId;

    return partnerships.byId[partnershipId];
  },
);

/**
 * Returns partnership from merge modal
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Partnership|undefined}
 */
export const partnershipFromMergeModalSelector = createSelector(
  [ledgerPartnershipsSelector, modalMergeLedgerPartnershipSelector],
  (partnerships, modal) => {
    const partnershipId = modal?.partnershipId;

    return partnerships.byId[partnershipId];
  },
);
