import {} from '@routable/types';
import clsx from 'clsx';
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { usePress } from 'react-aria';
import { Container } from './row.styled';
import {} from './row.types';
export const TableRow = ({ children, onClick, onPress, ...more }) => {
    const elemRef = useRef(null);
    const onPressHandler = onClick || onPress;
    const { pressProps } = usePress({ ref: elemRef, onPress: onPressHandler });
    return (React.createElement(Container, { ...pressProps, onClick: onPressHandler ? pressProps.onClick : undefined, "aria-disabled": more.isDisabled, ref: elemRef, className: clsx('table-row', { 'routable-click': !!onPressHandler }), role: "row", ...more }, children));
};
TableRow.Link = ({ children, className, to, isDisabled, }) => {
    if (isDisabled) {
        return React.createElement(TableRow, { isDisabled: true }, children);
    }
    return (React.createElement(Link, { to: to, className: clsx(className, 'hover:no-underline', 'focus:no-underline') },
        React.createElement(TableRow, { className: "routable-click table-row" }, children)));
};
