import { Close } from '@carbon/icons-react';
import { Button, Dialog, useModalState } from '@routable/gross-ds';
import PropTypes from 'prop-types';
import React, { useCallback, useRef } from 'react';
import { components } from 'react-select';

const { ClearIndicator } = components;

const ClearIndicatorWithConfirm = ({ innerProps, selectProps, ...rest }) => {
  const dialogState = useModalState({});
  const eventRef = useRef(null);
  const { onMouseDown, onTouchEnd } = innerProps;
  const {
    clearIndicatorAlertMessage,
    clearIndicatorAlertTitle = 'Are you sure?',
    clearIndicatorShouldConfirm = true,
  } = selectProps;

  const onMouseDownOrTouchEnd = useCallback(
    (evt, eventCallback) => {
      if (evt && evt.persist) {
        evt.persist();
      }

      if (!clearIndicatorShouldConfirm) {
        eventCallback(evt);
      }

      eventRef.current = { evt, eventCallback };
      dialogState.open();
    },
    [clearIndicatorShouldConfirm, dialogState],
  );

  const handleConfirm = () => {
    if (eventRef.current) {
      eventRef.current.eventCallback(eventRef.current.evt);
    }
    dialogState.close();
    eventRef.current = null;
  };

  const handleMouseDown = useCallback(
    (evt) => {
      onMouseDownOrTouchEnd(evt, onMouseDown);
    },
    [onMouseDown, onMouseDownOrTouchEnd],
  );

  const handleTouchEnd = useCallback(
    (evt) => {
      onMouseDownOrTouchEnd(evt, onTouchEnd);
    },
    [onMouseDownOrTouchEnd, onTouchEnd],
  );

  return (
    <>
      <ClearIndicator
        {...rest}
        innerProps={{
          ...innerProps,
          onMouseDown: handleMouseDown,
          onTouchEnd: handleTouchEnd,
        }}
        selectProps={selectProps}
      >
        <Close className="clear-indicator" data-icon="cross" data-testid="icon-cross" />
      </ClearIndicator>
      <Dialog
        footer={
          <Dialog.Footer>
            <Button data-testid="clear-dialog--btn--cancel" onPress={dialogState.close} variant="outline">
              Cancel
            </Button>
            <Button
              autoFocus
              className="focus:ring-4 focus:ring-blue-40/20"
              data-testid="clear-dialog--btn--confirm"
              onPress={handleConfirm}
            >
              Confirm
            </Button>
          </Dialog.Footer>
        }
        header={<Dialog.Header label={clearIndicatorAlertTitle} />}
        state={dialogState}
      >
        <span>{clearIndicatorAlertMessage}</span>
      </Dialog>
    </>
  );
};

ClearIndicatorWithConfirm.propTypes = {
  innerProps: PropTypes.shape().isRequired,
  selectProps: PropTypes.shape({
    clearIndicatorAlertMessage: PropTypes.string.isRequired,
    clearIndicatorAlertTitle: PropTypes.string,
    clearIndicatorShouldConfirm: PropTypes.bool,
  }).isRequired,
};

export default ClearIndicatorWithConfirm;
