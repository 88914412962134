import { ZIndexLayers } from 'constants/ui';

import { uncapitalize } from 'helpers/stringHelpers';
import { anyValues } from 'helpers/utility';
import { getZIndex } from 'helpers/zIndex';

import { MenuList } from '../components';

import { InputActions } from './constants';

export const isInputActionInputChange = (action) => action === InputActions.INPUT_CHANGE;

export const isInputActionInputBlur = (action) => action === InputActions.INPUT_BLUR;

/**
 * Returns placeholder value string for select field
 * @param {Object} options
 * @param {boolean} options.hideLabel
 * @param {string} [options.label]
 * @param {string} [options.placeholder]
 * @return {string}
 */
export const getSelectPlaceholderValue = ({ hideLabel, label, placeholder }) => {
  if (placeholder) {
    return placeholder;
  }

  let placeholderValue = `Select`;

  if (label && !hideLabel) {
    placeholderValue += ` ${uncapitalize(label)}`;
  }

  return placeholderValue;
};

/**
 * Returns components that we want to supply to the React Select instance. On mobile devices, it overrides
 * default Menu component with supplied custom BottomSheet component. If locked, we want to override
 * the IndicatorsContainer
 * @param {Object} options
 * @param {Node} options.BottomSheetComponent
 * @param {Object} options.components
 * @param {Object} options.defaultComponents
 * @param {boolean} options.isLocked
 * @param {boolean} options.isMobile
 * @param {Node} options.LockIndicatorComponent
 * @return {Object}
 */
export const getSelectComponents = ({
  BottomSheetComponent,
  components,
  defaultComponents,
  footer,
  isLocked,
  isMobile,
  LockIndicatorComponent,
}) => ({
  ...components,
  MenuList,
  MenuListFooter: footer,
  Menu: isMobile ? BottomSheetComponent : defaultComponents.Menu,
  IndicatorsContainer: isLocked ? LockIndicatorComponent : defaultComponents.IndicatorsContainer,
});

/**
 * Override the styles for the component to show pointer events. This is necessary for us to use hover.
 * @param {Object} options
 * @param {Object} options.base
 * @param {Object} options.selectState
 * @returns {Object} styles prop for Select
 */
export const getRequiredStylesForDisabledSelectTooltip = ({ base, selectState }) => ({
  ...base,
  ...(selectState.isDisabled && {
    // YOU MUST OVERRIDE POINTER-EVENTS, because isDisabled removes them in the react-select library. We need
    // pointer events for the hover to work.
    pointerEvents: 'auto',
  }),
});

/**
 * Returns styles for Select Control component
 * @param {Object} options
 * @param {Object} options.base
 * @param {boolean} options.isMobile
 * @param {boolean} options.isSearchable
 * @param {Object} options.selectState
 * @return {Object}
 */
export const getSelectControlStyles = ({
  base,
  hasTooltip,
  isLockedAndDisabled,
  isMobile,
  isSearchable,
  selectState,
}) => {
  // if the component is disabled, locked, and has a tooltip
  if (isLockedAndDisabled && hasTooltip) {
    return {
      ...base,
      // enable pointer events so we can hover for the tooltip
      ...getRequiredStylesForDisabledSelectTooltip({ base, selectState }),
    };
  }

  // If we are not on mobile, select menu is not open or the menu is not searchable,
  // we return the default select control styles
  if (!isMobile || !selectState.menuIsOpen || !isSearchable) {
    return base;
  }

  // This is not so pretty hack for iOS; basically, since we don't want to loose focus on menu open, and
  // searchable bottom sheet spans across the entire screen, the parent input field indicator was still visible and
  // was breaking the designs.
  // This fixes it - applying opacity 0 when selectState.menuIsOpen is true and Select field is searchable
  return {
    ...base,
    opacity: 0,
  };
};

/**
 * Returns styles for Select Menu component. It supplies correct style overrides for bottom sheet element
 * on mobile devices.
 * @param {Object} options
 * @param {Object} options.base
 * @param {boolean} options.isMobile
 * @return {Object}
 */
export const getSelectMenuStyles = ({ base, isMobile }) => {
  if (!isMobile) {
    return base;
  }

  return {
    ...base,
    position: 'relative',
    height: '100%',
    top: 'unset',
    width: '100%',
  };
};

/**
 * Returns styles for Select Menu List component. On mobile devices, when the select field is searchable, we
 * override the default max height and add padding to the bottom to ensure correct behavior of scrollable list
 * in bottom sheet when the keyboard is shown on the device's screen.
 * @param {Object} options
 * @param {Object} options.base
 * @param {boolean} options.isMobile
 * @param {boolean} options.isSearchable
 * @return {Object}
 */
export const getSelectMenuListStyles = ({ base, isMobile, isSearchable }) => {
  // If not on mobile devices, return default Select Menu List
  // styles
  if (!isMobile) {
    return base;
  }

  // We supply enough padding (limit the max-height) to make sure that
  // list of options is scrollable to the end even if keyboard
  // is shown. iOS uses so-called "soft keyboard" meaning the keyboard overlays the content of the screen, rather
  // then shrinking the viewport (which is the behavior on Android).
  // We add padding bottom, which yields in menu list being able to "overscroll" past the last item.
  const maxHeight = isSearchable ? 'calc(100% - 80px)' : base.maxHeight;
  const paddingBottom = isSearchable ? '200px !important' : 0;

  return {
    ...base,
    maxHeight,
    paddingBottom,
  };
};

/**
 * Returns styles for Select Menu Portal component. We displayed that component as fixed positioned element at the
 * bottom of the screen, and we make it full screen if select field is searchable.
 * @param {Object} options
 * @param {Object} options.base
 * @param {boolean} options.isSearchable
 * @param {Object} [options.styles]
 * @return {Object}
 */
export const getSelectMenuPortalStyles = ({ base, isMobile, isSearchable, styles = {} }) => {
  const { menuPortal } = styles;

  let menuPortalStyles = {
    ...base,
    zIndex: getZIndex(ZIndexLayers.SELECT.BOTTOM_SHEET),
  };

  if (isMobile) {
    // If select field is searchable, we want to display bottom sheet as full height,
    // therefore we set the top property to 0. Otherwise, we unset it.
    const top = isSearchable ? 0 : 'unset';

    menuPortalStyles = {
      ...menuPortalStyles,
      position: 'fixed',
      left: 0,
      right: 0,
      width: '100%',
      bottom: 0,
      top,
    };
  }

  if (typeof menuPortal === 'function' && !isMobile) {
    menuPortalStyles = { ...menuPortalStyles, ...menuPortal(base) };
  }

  return menuPortalStyles;
};

/**
 * Returns map of Select components styles overrides.
 * @see SelectFieldV2
 * @param {Object} options
 * @param {boolean} options.isMobile
 * @param {boolean} options.isSearchable
 * @param {Object} [options.styles]
 * @return {Object}
 */
export const getSelectComponentsStyles = ({
  isDisabled,
  isLocked,
  isMobile,
  isSearchable,
  lockedTooltipProps,
  styles,
}) => {
  const isLockedAndDisabled = isDisabled && isLocked;
  const hasTooltip = anyValues(lockedTooltipProps);

  return {
    ...styles,
    control: (base, selectState) =>
      getSelectControlStyles({
        base,
        hasTooltip,
        isLockedAndDisabled,
        isMobile,
        isSearchable,
        selectState,
      }),
    menu: (base) => getSelectMenuStyles({ base, isMobile }),
    menuList: (base) => getSelectMenuListStyles({ base, isMobile, isSearchable }),
    menuPortal: (base) => getSelectMenuPortalStyles({ base, isMobile, isSearchable, styles }),
  };
};
