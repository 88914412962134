import React, {} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import { submitFundingStateRoutineWithPayload } from 'actions/routines/external';
import { updatePaymentMethodFormFields } from 'constants/formFields';
import { formNamesExternal } from 'constants/forms';
import { getItemKindFromExternalPath } from 'helpers/external';
import { getFundingAccountPaymentDeliveryMethod } from 'helpers/funding';
import { anyValues } from 'helpers/utility';
import { withWarnAboutUnsavedChanges } from 'hoc';
import { useFieldValue, useSelectorWithProps } from 'hooks';
import {} from 'interfaces/global';
import { makeFormSubmitFailHandler } from 'modules/createPartnerCompany/helpers/submitFailure';
import MaxPaymentMethodsReachedHint from 'modules/external/MaxPaymentMethodsReachedHint';
import MicroDepositsNeedVerificationHint from 'modules/external/MicroDepositsNeedVerificationHint';
import UnableToUpdatePaymentMethodHint from 'modules/external/UnableToUpdatePaymentMethodHint';
import { isCurrentCompanyMaxBankAccountsReachedHintVisibleSelector } from 'queries/currentCompanyCompoundSelectors';
import { fundingAccountsForCompanyIdQuerySelector } from 'queries/fundingCompoundSelectors';
import { isFormFundingInternalAndInvalidOrPendingSelector, updatePaymentFormInitialValuesSelector, } from 'queries/updatePaymentFormSelectors';
import { fundingAccountForIdSelector } from 'selectors/fundingSelectors';
import { modalConnectBankManualIsOpenSelector, modalCreateManualAddressIsOpenSelector } from 'selectors/modalsSelector';
import { partnershipFundingStatesErrorsSelector } from 'selectors/partnershipFundingStatesSelectors';
import { alertOptions } from '../../../constants';
import { UpdatePaymentFlowFormLabel, UpdatePaymentMethodFundingAccounts, AcceptPendingPaymentsCheckboxField, } from '../UpdatePaymentMethodFormInternational/components/UpdatePaymentMethodInternationalLayout/components';
import { AddFundingAccountOptions } from './components';
export const SelectDomesticPaymentMethodPage = ({ form, handleSubmit, location }) => {
    const dispatch = useDispatch();
    const fundingAccounts = useSelectorWithProps(fundingAccountsForCompanyIdQuerySelector, { location });
    const showMaxPaymentMethodsReachedHint = useSelector(isCurrentCompanyMaxBankAccountsReachedHintVisibleSelector);
    const showMicroDepositsHint = useSelector(isFormFundingInternalAndInvalidOrPendingSelector);
    const errors = useSelector(partnershipFundingStatesErrorsSelector);
    const isAddressModalOpen = useSelector(modalCreateManualAddressIsOpenSelector);
    const isConnectBankModalOpen = useSelector(modalConnectBankManualIsOpenSelector);
    const itemKind = getItemKindFromExternalPath(location);
    const selectedFundingAccountId = useFieldValue(formNamesExternal.UPDATE_PAYMENT_METHOD, updatePaymentMethodFormFields.FUNDING_ACCOUNT);
    const selectedFundingAccount = useSelectorWithProps(fundingAccountForIdSelector, selectedFundingAccountId);
    const handleSubmitReceivablePaymentMethod = async (values) => {
        if (!isAddressModalOpen && !isConnectBankModalOpen) {
            const paymentDeliveryMethod = getFundingAccountPaymentDeliveryMethod(selectedFundingAccount);
            const defaultReceivable = {
                ...values.defaultReceivable,
                paymentDeliveryMethod,
            };
            dispatch(submitFundingStateRoutineWithPayload({ defaultReceivable, itemKind }));
        }
    };
    return (React.createElement("form", { className: "form update-payment-method-form", id: form, onSubmit: handleSubmit(handleSubmitReceivablePaymentMethod) },
        !!fundingAccounts.length && (React.createElement(React.Fragment, null,
            React.createElement(UpdatePaymentFlowFormLabel, { labelText: "Select preferred payment method" }),
            React.createElement(UpdatePaymentMethodFundingAccounts, { fundingAccounts: fundingAccounts }),
            React.createElement(AcceptPendingPaymentsCheckboxField, null))),
        !showMaxPaymentMethodsReachedHint && (React.createElement(React.Fragment, null,
            React.createElement(UpdatePaymentFlowFormLabel, { className: "mt-8", labelText: "Add another way to get paid" }),
            React.createElement(AddFundingAccountOptions, { itemKind: itemKind }))),
        React.createElement("div", { className: "[&:has(*)]:mt-8 flex flex-col gap-3" },
            showMaxPaymentMethodsReachedHint && React.createElement(MaxPaymentMethodsReachedHint, null),
            showMicroDepositsHint && (React.createElement(MicroDepositsNeedVerificationHint, null, "Enter your micro-deposits once they arrive to verify this payment method. You may also select another payment method, or connect to your bank account to continue.")),
            anyValues(errors?.parsedErrors) && React.createElement(UnableToUpdatePaymentMethodHint, null))));
};
const UpdatePaymentForm = reduxForm({
    destroyOnUnmount: false,
    form: formNamesExternal.UPDATE_PAYMENT_METHOD,
    onSubmitFail: makeFormSubmitFailHandler(formNamesExternal.UPDATE_PAYMENT_METHOD),
})(SelectDomesticPaymentMethodPage);
export const UpdatePaymentMethodFormContainer = () => {
    const location = useLocation();
    const initialValues = useSelectorWithProps(updatePaymentFormInitialValuesSelector, { location });
    return React.createElement(UpdatePaymentForm, { initialValues: initialValues, location: location });
};
export default withWarnAboutUnsavedChanges(UpdatePaymentMethodFormContainer, formNamesExternal.UPDATE_PAYMENT_METHOD, alertOptions);
