import { fetchExternalPartnershipRequestRoutine } from 'actions/routines/external';
import { createPayPalManualFundingAccount } from 'actions/routines/funding';
import { createByIdReducer } from 'store/redux';
import { FETCH_PARTNERSHIP_RECEIVABLE_FUNDING_ACCOUNTS_SUCCESS } from 'types/partnership';
export const fundingInfoPayPalDirectByIdReducer = createByIdReducer({
    initialState: {},
    key: 'fundingInfoPayPalDirect',
    types: [
        fetchExternalPartnershipRequestRoutine.SUCCESS,
        FETCH_PARTNERSHIP_RECEIVABLE_FUNDING_ACCOUNTS_SUCCESS,
        createPayPalManualFundingAccount.SUCCESS,
    ],
});
