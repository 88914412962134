import { apiCall } from '@routable/framework';
import { getCurrenciesApiResponseModel } from '../models';
export const currencyDataService = {
    getCurrencies: () => apiCall({
        camelCaseResponse: true,
        headers: { accept: 'application/json' },
        method: 'get',
        responseModel: getCurrenciesApiResponseModel,
        url: '/data/funding/currencies/',
    }),
};
