import dayjs from 'dayjs';
import _groupBy from 'lodash/groupBy';
import _omit from 'lodash/omit';
import _set from 'lodash/set';
import { change } from 'redux-form';
import { call, select } from 'redux-saga/effects';

import { DateFormats } from 'constants/date';
import { formNamesItem } from 'constants/forms';

import { ItemStatuses } from 'enums/items';

import { getApproverFieldName } from 'helpers/createItem';
import { getPaymentOrInvoiceText, isAllowedEditPayloadStatuses, isKindReceivable } from 'helpers/items';
import { capitalize, uppercaseToUnderscore } from 'helpers/stringHelpers';
import { deepMergeWithArrayReplacement } from 'helpers/transform';
import {
  allKeys,
  allValues,
  flattenArray,
  forEachEntry,
  isEqual,
  isObject,
  objectHasKey,
  toPathString,
} from 'helpers/utility';

import { LINE_ITEMS_SUBSECTIONS } from 'modules/dashboard/createItems/invoiceGenerator/components/InvoiceGeneratorView/constants';

import {
  maybeShowApprovalLevelErrors,
  maybeShowBouncedEmailsErrors,
  maybeShowUnsupportedFileTypeErrorSwal,
} from 'sagas/createItem/sideEffects';

import { companySelector } from 'selectors/companiesSelectors';
import { currentCompanySelector } from 'selectors/currentCompanySelectors';
import { partnershipMembersByIdSelector } from 'selectors/partnershipMemberSelectors';
import { partnershipSelector } from 'selectors/partnershipsSelectors';

/**
 * Helper to get item-specific text related to changed approval levels.
 *
 * @param {Item} item
 * @returns {string}
 */
export const getApprovalLevelErrorText = (item) => {
  const paymentOrInvoice = getPaymentOrInvoiceText(item);
  return `The approval settings for your account have been updated. Your approval requirements for this ${paymentOrInvoice} may have changed or may no longer be needed.`;
};

/**
 * When we get errors from the backend, we'll get those errors as nested objects, corresponding
 * to the structure that we sent in the request payload.
 * HOWEVER, for dynamic fields, the keys in this error object will not match our state structure,
 * which uses a unique id system. We need to translate error keys back to keys found in our form
 * state.
 * @param {Object} fieldErrors
 * @param {?Map} [fieldIdMap]
 * @param {LineItem[]} lineItems
 * @return {{ dynamicErrors: Object, otherErrors: Object }}
 */
export const getErrorsForDynamicAndStaticFields = ({ fieldErrors = {}, fieldIdMap, lineItems = [] }) => {
  const dynamicErrors = {};
  const otherErrors = {};

  // if we don't have an id map, return whatever the server originally gave us
  if (!fieldIdMap) {
    return { dynamicErrors, otherErrors: fieldErrors };
  }

  // group all line items by their table type
  const lineItemsGroupedByStyle = _groupBy(lineItems, 'style');
  const allStyleTypes = allKeys(lineItemsGroupedByStyle);

  /**
   * Walks the tree of the given error object, generating path strings for each error field,
   * and checks whether any of those path strings point to a state path in the fieldIdMap.
   * If there's a map entry, the error value is added to the dynamic errors object, under the found
   * id. If there isn't a map entry, the error value is used under the key that was provided.
   * @param {Object} errs - Any errors object
   * @param {string} path - The generated path used to accompany tablematic's id tracking
   * @param {string} realPath - The actual path, as provided by the server
   */
  const parseDynamicErrorsObject = (errs, path = '', realPath = '') => {
    /**
     * Called for each key:value pair in the errors object.
     * @param {string} errorKey
     * @param {*} errorValue
     */
    const parseErrorEntry = ([errorKey, errorValue]) => {
      const currentRealPath = toPathString(realPath, errorKey);

      // if the current value has nested keys, extract the next generated path,
      // and continue walking the tree
      if (isObject(errorValue)) {
        let nextPath;

        if (!Number.isNaN(parseInt(errorKey, 10))) {
          // we want to quickly/easily search for an index path (aka a field in a table row) later,
          // without parsing the whole string for solitary numbers again; using delimiters is more
          // explicit to target by regex in a string.replace
          nextPath = toPathString(path, `{${errorKey}}`);
        } else {
          nextPath = toPathString(path, errorKey);
        }

        // keep on going, with the updated path values
        parseDynamicErrorsObject(errorValue, nextPath, currentRealPath);

        return;
      }

      // if the current value has no nested keys, we should check the dynamic map
      // to see if we should swap this error key with its dynamic counterpart
      let fieldSearchPath = toPathString(path, errorKey);
      // we'll set this value if we find a dynamic id path for this error entry
      let fieldId;

      // if this is an index path, it's a field in a repeatable table row; we'll search for it with the
      // {index} template variable, then splice its actual index back into the final error object
      if (/{\d+}/g.test(fieldSearchPath)) {
        // remember the actual row index
        const index = fieldSearchPath.split('{')[1].split('}')[0];
        // swap out the index with our dynamic template variable
        fieldSearchPath = fieldSearchPath.replace(`{${index}}`, '{index}');

        // a defined schema for this fieldSearchPath does not exist
        // here we add `lineItems${style}` to the field path based on the table it is in
        const lineItemStyleType = lineItems[index]?.style;
        const tablePrefix = `lineItems${capitalize(lineItemStyleType)}`;

        // calculate the real index of the line item in its corresponding table
        // this is equal to the $index minus the length of the sum of the line items from previous tables (if any)
        // realIndex = index - sum(lineItems[i].length) -> where: i=[0,typeIndex)
        const typeIndex = allStyleTypes.indexOf(lineItemStyleType);
        let pastItemsLength = 0;
        for (let i = 0; i < typeIndex; i += 1) {
          pastItemsLength += lineItemsGroupedByStyle[allStyleTypes[i]]?.length ?? 0;
        }
        const realIndex = index - pastItemsLength;

        // replace extended. with extended_, to match the fields' name
        fieldSearchPath = fieldSearchPath.replace('extended.', 'extended_');
        // replace our {index} placeholder with the real index
        // and add tablePrefix before it -> `{tablePrefix}.{index}`
        fieldId = uppercaseToUnderscore(
          fieldSearchPath.replace('{index}', toPathString(tablePrefix, String(realIndex))),
        );
      } else {
        // underscore the id to match dynamic state
        fieldId = uppercaseToUnderscore(fieldIdMap.get(fieldSearchPath));
      }

      if (fieldId) {
        // set the found id path in our dynamic errors object
        _set(dynamicErrors, fieldId, errorValue);
      } else {
        // no found id; keep the error as-is
        _set(otherErrors, currentRealPath, errorValue);
      }
    };

    // walk the errors object
    forEachEntry(errs, parseErrorEntry);
  };

  // run it
  parseDynamicErrorsObject(fieldErrors);

  return { dynamicErrors, otherErrors };
};

/**
 * Merges form values with known keys, with the dynamic output object (keys unknown).
 * @param {Object} allFormValues
 * @param {Object} dynamicOutput
 * @param {SchemaViewModelManager} viewModelManager
 * @return {Object}
 */
export const getMergedFormOutputValues = ({ allFormValues, dynamicOutput, viewModelManager }) => {
  const knownValues = _omit(allFormValues, viewModelManager.parser.sectionPaths);
  // we have some modifications to date field values that needs to be made before we
  // do a deepMergeWithArrayReplacement (below). this function can't handle dayjs objects,
  // so we need to turn format them as strings before calling it.

  if (isKindReceivable(knownValues.item.kind)) {
    const {
      item: { dateDue },
    } = knownValues;

    const dateObject = typeof dateDue === 'string' ? dayjs(dateDue) : dateDue;

    knownValues.item = {
      ...knownValues.item,
      dateDue: dateObject.format(DateFormats.FULL_NUMERIC_YEAR_MONTH_DAY),
    };
  }

  if (knownValues.item.dateScheduled) {
    const {
      item: { dateScheduled },
    } = knownValues;

    const dateObject = typeof dateScheduled === 'string' ? dayjs(dateScheduled) : dateScheduled;

    knownValues.item = {
      ...knownValues.item,
      dateScheduled: dateObject.format(DateFormats.FULL_NUMERIC_YEAR_MONTH_DAY),
    };
  }

  return deepMergeWithArrayReplacement(knownValues, dynamicOutput);
};

/**
 * getActionsForApproverFieldReset
 * when submitting an item, we may get errors if the approval level config has been edited
 * in this case, we want to reset the various approver fields
 * @param {ApproverLevel[]} levels
 * @return {ReduxAction[]}
 */
export const getActionsForApproverFieldReset = ({ levels }) => {
  const actions = [];
  levels.forEach(({ position }) => {
    const approverFieldKey = getApproverFieldName(position);
    actions.push(change(formNamesItem.CREATE_ITEM, approverFieldKey, []));
  });
  return actions;
};

/**
 * Returns the first file error if there are any attachment submitErrors
 * @param {object} submitErrors
 * @returns {string}
 */
export const getFirstFileAttachmentError = (submitErrors) => {
  /*
  When submitting a new/payable or new/receivable, there is (currently) only 1
  input for attachments on the form ("Attach items" under the line items). As a result, if the attachment is
  unsupported, the error comes back at `0`
  When submitting a new/bill however, there are 2 inputs on the form: the first is the main bill input and the second
  is the "Attach items"

  errors: {
    fields: {
      attachments: {
         // in new/bill, the API can return 0 and/or 1 depending on the errors in either of the file inputs
         // in new/payment|invoice, we have a single attachment input so will only get errors at '0'
         0: {
            file: ['some error message']
            fileType: ['some other error message' ]
         },
         1: {
            file: ['some error message']
            fileType: ['some other error message' ]
         }
      }
    }
  }
  */
  const { fields: { attachments = {} } = {} } = submitErrors;

  const attachmentErrors = allValues(attachments);
  const file = attachmentErrors.find((error) => error.file)?.file;

  const hasFileAttachmentError = attachmentErrors && !!file?.length;

  return hasFileAttachmentError ? file : undefined;
};

/**
 * Handles higher level field errors by showing error alerts/SWALs instead of field errors
 * For eg., when contacts bounce or when approval levels are outdated
 * @param {object} props
 * @param {object|array} props.submitErrors
 * @param {Item} props.item
 * @return {IterableIterator<*>}
 */
export function* getActionsForHigherLevelFieldErrors({ submitErrors, item }) {
  yield call(maybeShowApprovalLevelErrors, { submitErrors, item });

  const partnershipMembersById = yield select(partnershipMembersByIdSelector);
  const { name } = yield select(currentCompanySelector);
  const partnership = yield select(partnershipSelector, item.partnership.id);
  const partnershipCompany = yield select(companySelector, partnership.partner);

  maybeShowBouncedEmailsErrors({
    partnershipMembersById,
    partnerCompanyName: partnershipCompany.name,
    currentCompanyName: name,
    submitErrors,
    itemMembers: item.itemMembers,
    itemKind: item.kind,
  });

  maybeShowUnsupportedFileTypeErrorSwal({ submitErrors });
}

/**
 * Takes item members in PartnershipMember format (id is PartnershipMember's) and turns it into a ItemMember format (id
 * is ItemMember's with foreign key to partnershipMember)
 * @param {array} itemMembers
 * @param {object} itemMembersById
 * @return {array}
 */
export function getFormattedItemMembersForPayload(itemMembers = [], itemMembersById = {}) {
  const newItemMembers = [];
  const formattedItemMembers = [];
  // Take all item members and see if they exist on itemMembersById.
  itemMembers.forEach((partnershipMember) => {
    const itemMember = allValues(itemMembersById).find((member) =>
      isEqual(member.partnershipMember, partnershipMember.id),
    );
    // If they do edit them with real itemMember id and correct partnershipMember id.
    if (itemMember) {
      formattedItemMembers.push({
        ...partnershipMember,
        ...itemMember,
        accessItem: partnershipMember.accessItem,
      });
    } else if (!objectHasKey(partnershipMember, 'partnershipMember')) {
      // If there is no partnershipMember key in the partnership member it's a new item member, so the id from the form
      // corresponds to partnershipMember id.
      const newItemMember = { ...partnershipMember };
      newItemMember.partnershipMember = newItemMember.id;
      delete newItemMember.id;
      newItemMembers.push(newItemMember);
    } else {
      // Leave unchanged
      newItemMembers.push(partnershipMember);
    }
  });
  return formattedItemMembers.concat(newItemMembers);
}

/**
 * Returns a diffedPayload with the right status.
 * @param {Object} params
 * @param {Object} params.item
 * @param {Object} params.diffedPayload
 * @param {Boolean} params.sendItem
 * @return {Object}
 */
export const resolveItemEditStatus = ({ item, diffedPayload, sendItem }) => {
  const editedDiffedPayload = { ...diffedPayload };

  if (sendItem) {
    editedDiffedPayload.status = ItemStatuses.PENDING;
  } else if (diffedPayload.status && isAllowedEditPayloadStatuses(diffedPayload.status)) {
    editedDiffedPayload.status = diffedPayload.status;
  } else if (item.statusNext) {
    editedDiffedPayload.status = item.statusNext;
  } else {
    editedDiffedPayload.status = item.status;
  }

  return editedDiffedPayload;
};

/**
 * Conditions to consider a line item to be empty.
 * @param {Object} lineItem
 * @return {Boolean}
 */
export const isLineItemNotEmpty = (lineItem) =>
  lineItem.amount !== undefined &&
  lineItem.amount !== '' &&
  (lineItem.account?.id || lineItem.item?.id || lineItem.description);

/**
 * Dynamic output brings back lineItems without IDs, this function put the IDs back.
 * @param {Object} lineItemsFullObject - here we get the full object, containing both account and item blocks
 * @param {Array} formattedLineItems
 * @return {Array}
 */
export const putIdsOnDynamicOutputsFormattedLineItems = (lineItemsFullObject, formattedLineItems) => {
  // go through both account/item sections, and get the bill line items
  const lineItemsByType = allValues(lineItemsFullObject).map(
    (block) => block?.[LINE_ITEMS_SUBSECTIONS.ACCOUNT] || block?.[LINE_ITEMS_SUBSECTIONS.ITEM],
  );
  // flatten lineItemsByType (object containing arrays) to an array and remove all empty line items
  const lineItems = flattenArray(lineItemsByType).filter(isLineItemNotEmpty);

  // Filling dynamicOutput's missing lineItems IDs. Diffing lineItems payload does not work if line items doesn't
  // have IDs.
  return formattedLineItems.map((lineItem, idx) => {
    const thisLineItemId = lineItems[idx]?.id;
    const dup = { ...lineItem };

    if (thisLineItemId) {
      dup.id = thisLineItemId;
    }

    return dup;
  });
};

/**
 * Extract item members from values or initial form values and returns them in payload format
 * @param {Object} values
 * @return {Array}
 */
export function getItemMembersFromFormValues(values) {
  return values?.itemMembers?.map((member) => ({
    id: member.id,
    accessItem: member.accessItem,
    partnershipMember: { id: member.partnershipMember },
  }));
}
