import PropTypes from 'prop-types';
import React from 'react';

import { getMethodDeliveryOptionsFormatted } from 'components/selectTypes/helpers';
import { SelectFieldV2 } from 'components/selectV2';
import { MultiLineOption } from 'components/selectV2/components';
import { Text } from 'components/text';

import { field } from 'constants/styles/formStyles';
import { TextSize } from 'constants/styles/typography';

import { isPaymentDeliveryMethodInternational } from 'helpers/paymentDeliveryOption';
import { getPaymentDeliveryOptions, getPaymentDeliveryOptionLockedTooltipText } from 'helpers/paymentMethods';

/**
 * Component rendering Select field with sorted available Payment Delivery
 * Options
 *
 * @param {Object} props
 * @param {Object} props.billingDataByCode
 * @param {Item} props.item
 * @return {JSX.Element}
 */
const PaymentDeliveryOptionSelect = ({
  billingDataByCode,
  item,
  partnerPaymentOptions,
  currencyCodeReceiver,
  companyName,
  selectedFundingAccount,
  ...rest
}) => {
  const availableOptions = getPaymentDeliveryOptions({
    item,
    billingCodeData: billingDataByCode,
    partnerPaymentOptions,
    paymentFundingSourceSelected: selectedFundingAccount,
  });

  const options = getMethodDeliveryOptionsFormatted(availableOptions);
  const isDisabled = options.length <= 1;
  const isLocked = isDisabled && isPaymentDeliveryMethodInternational(item.paymentDeliveryMethod);
  const lockedProps = isLocked
    ? {
        isLocked: true,
        lockedTooltipProps: {
          title: (
            <Text.Regular size={TextSize.LEVEL_100}>
              {getPaymentDeliveryOptionLockedTooltipText(item, currencyCodeReceiver, companyName)}
            </Text.Regular>
          ),
        },
      }
    : {};

  return (
    <SelectFieldV2
      className={field.xl.full}
      components={{ Option: MultiLineOption }}
      displayFieldName="label"
      isDisabled={isDisabled}
      {...lockedProps}
      isSearchable={false}
      options={options}
      valueFieldName="value"
      {...rest}
    />
  );
};

PaymentDeliveryOptionSelect.propTypes = {
  billingDataByCode: PropTypes.shape().isRequired,
  item: PropTypes.shape().isRequired,
  partnerPaymentOptions: PropTypes.arrayOf(PropTypes.shape()),
  currencyCodeReceiver: PropTypes.string,
  companyName: PropTypes.string,
};

PaymentDeliveryOptionSelect.defaultProps = {
  partnerPaymentOptions: undefined,
  currencyCodeReceiver: '',
};

export default PaymentDeliveryOptionSelect;
