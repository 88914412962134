import { useMemo } from 'react';
import { useCurrencyData } from '../useCurrencyData';
import {} from '../../models';
const newFormatter = (precision) => new Intl.NumberFormat('en-US', {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
});
export const useCurrencyAmountFormatter = () => {
    const { data: currencyData } = useCurrencyData();
    const formatters = useMemo(() => {
        const uniquePrecisions = new Set(Object.values(currencyData ?? {}).map(({ decimalPrecision }) => decimalPrecision));
        return new Map(Array.from(uniquePrecisions).map((precision) => [precision, newFormatter(precision)]));
    }, [currencyData]);
    return {
        formatCurrencyAmount: ({ amount = 0, currency, precision, }) => {
            const { currencyCode, decimalPrecision } = currencyData?.[currency] || {};
            if (!currencyCode || decimalPrecision === undefined)
                return;
            const transformedAmount = typeof amount === 'string' ? parseFloat(amount || '0') : amount;
            const consideredPrecision = precision || decimalPrecision;
            const formatter = formatters.get(consideredPrecision) || newFormatter(consideredPrecision);
            if (!formatter)
                return;
            return `${formatter.format(transformedAmount)} ${currencyCode}`;
        },
    };
};
