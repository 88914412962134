import { useItemFieldMapping } from '@routable/tablematic';
import React, { useState, useEffect } from 'react';
import { formValueSelector } from 'redux-form';
import { formNamesItem } from 'constants/forms';
import { ItemKinds } from 'constants/item';
import { useIsOcrEnabled } from 'helpers/ocr';
import { useSelectorWithProps } from 'hooks';
import { hasOCRValues } from './Provenance.helpers';
import { ProvenanceWrapper } from './Provenance.styled';
const createItemFormValueSelector = formValueSelector(formNamesItem.CREATE_ITEM);
const Provenance = ({ annotationValue, children, className = '', conditional = false, isBillView = false, watch, watchInclude = false, watchProp = '', }) => {
    const [ocrEnabled, setOCREnabled] = useState(false);
    const [fieldChanged, setFieldChanged] = useState(false);
    const baseFieldValue = useSelectorWithProps(createItemFormValueSelector, watchProp);
    const { isOcrEnabled, isInboxBillView } = useIsOcrEnabled();
    const { data: itemFieldMapping } = useItemFieldMapping({ kind: ItemKinds.PAYABLE });
    useEffect(() => {
        const propsBeingWatched = isOcrEnabled &&
            baseFieldValue &&
            hasOCRValues({ isBillView, watchProp, watchInclude, conditional, itemFieldMapping });
        if (propsBeingWatched) {
            setFieldChanged(false);
            setOCREnabled(true);
        }
        if (annotationValue) {
            setOCREnabled(true);
        }
    }, [annotationValue, itemFieldMapping]);
    useEffect(() => {
        if (ocrEnabled) {
            setFieldChanged(true);
        }
    }, [baseFieldValue]);
    const shouldUseValueMatchesAnnotation = Boolean(annotationValue);
    const valueMatchesAnnotation = annotationValue === watch;
    if (!ocrEnabled || !isInboxBillView || (shouldUseValueMatchesAnnotation && !valueMatchesAnnotation)) {
        return React.createElement(React.Fragment, null, children);
    }
    return (React.createElement(ProvenanceWrapper, { className: className, "data-edit": fieldChanged, "data-ocr": true }, children));
};
export default Provenance;
