import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getFormValues } from 'redux-form';

import { openSubmitForVerificationConfirmation } from 'actions/signUpFlow';

import { BaseHint } from 'components/hintTypes';
import { IconNames } from 'components/icon';

import { Intent } from 'constants/ui';

import GuideSectionList from 'modules/dashboard/guide/global/presenters/GuideSectionList';
import InvitedTeamMembers from 'modules/signup-v3/components/InvitedTeamMembers/InvitedTeamMembersContainer';
import { allSubStepsHaveValues, getStepStatus } from 'modules/signup-v3/components/ProgressBarStepperV2';
import BusinessAddress from 'modules/signup-v3/components/ReviewSteps/BusinessAddress';
import BusinessCategory from 'modules/signup-v3/components/ReviewSteps/BusinessCategory';
import BusinessPhoneNumber from 'modules/signup-v3/components/ReviewSteps/BusinessPhoneNumber';
import BusinessType from 'modules/signup-v3/components/ReviewSteps/BusinessType';
import DocumentUpload from 'modules/signup-v3/components/ReviewSteps/DocumentUpload';
import LegalName from 'modules/signup-v3/components/ReviewSteps/LegalName';
import RepresentativeInformation from 'modules/signup-v3/components/ReviewSteps/RepresentativeInformation';
import SubmitForVerificationConfirmationModal from 'modules/signup-v3/components/SubmitForVerificationConfirmationModal';
import { containerWidthMap } from 'modules/signup-v3/constants/container';

import {
  currentCompanyHasAllRepresentativeInfoSelector,
  currentCompanyRepresentativesSelector,
} from 'selectors/currentCompanySelectors';
import { hasKYCOrKYBError } from 'selectors/kycKybSelectors';
import { currentMembershipSelector } from 'selectors/membershipsSelector';

import { stepStatuses } from '../components/ProgressBarStepperV2/constants';
import { signupFlowForm, views } from '../constants';
import steps from '../constants/steps';
import FlowStepperMain from '../FlowStepperMain';
import '../../dashboard/guide/global/presenters/Guide.scss';
import { viewSteps } from '../helpers/viewSteps';

import { GuideSectionListWrapper, Hints } from './ReviewStep.styles';

/*
This page supports various states based on what information has been filled in on the business verification steps
as well as how business reps have filled in info
- "not started" = form has no value in either verification or representative fields
- "making progress" = form has some verification or representative values
- "waiting" = current member has submitted all verification info but other biz reps haven't
    note: as long as the current member hasn't submitted all info, the status fallsback to "making progress"
    only once that user has completed their info do we show "waiting" if other business reps haven't
    yet submitted their info
- no status label = on 'retry' status
- "verification failed" = any of the kyc/Dwolla KYC error statuses

The page also looks slightly different if user is in the standard/first time flow, or whether they are in retry
*/

// If making edits here, also have a look at CompanyLegalDetails (the business verification view of account settings)
// as we want to show similar hints for failure & retry states there

const formValueSelector = getFormValues(signupFlowForm);

export const ReviewStep = ({ activeView }) => {
  const formValues = useSelector(formValueSelector);
  const dispatch = useDispatch();

  const businessVerification = steps.find(({ id }) => id === views.businessVerification);
  const representative = steps.find(({ id }) => id === views.businessRepresentative);

  const currentCompanyHasAllRepresentativeInfo = useSelector(currentCompanyHasAllRepresentativeInfoSelector);
  const businessVerificationCompletedSteps = allSubStepsHaveValues({
    substeps: businessVerification.substeps,
    formValues,
  });
  const representativeVerificationCompletedSteps = allSubStepsHaveValues({
    substeps: representative?.substeps,
    formValues,
  });
  const businessVerificationStatus = getStepStatus({
    step: businessVerification,
    formValues,
    allSubStepsAreCompleted: businessVerificationCompletedSteps,
    currentCompanyHasAllRepresentativeInfo,
  });
  const representativeVerificationStatus = getStepStatus({
    step: representative,
    formValues,
    allSubStepsAreCompleted: representativeVerificationCompletedSteps,
    currentCompanyHasAllRepresentativeInfo,
  });
  const invalid =
    businessVerificationStatus !== stepStatuses.completed ||
    representativeVerificationStatus !== stepStatuses.completed;
  const currentMembership = useSelector(currentMembershipSelector);

  const businessReps = useSelector(currentCompanyRepresentativesSelector);

  const {
    hasAnyVerificationErrors,
    isKycStatusRetry,
    isKycStatusDocument,
    hasCurrentCompanyKYBFailed,
    hasAnyBusinessRepKYCFailed,
  } = useSelector(hasKYCOrKYBError);

  let isBusinessVerificationOpen = true;
  if (businessVerificationCompletedSteps) {
    isBusinessVerificationOpen = false;
  } else if (hasCurrentCompanyKYBFailed) {
    isBusinessVerificationOpen = false;
  }

  const [sectionState, setSectionState] = React.useState({
    [views.businessVerification]: {
      open: isBusinessVerificationOpen,
    },
    [views.businessRepresentative]: {
      open: hasAnyBusinessRepKYCFailed || false,
    },
  });

  const onToggleList = (listKey) => {
    setSectionState({
      ...sectionState,
      [listKey]: {
        open: !sectionState[listKey].open,
      },
    });
  };

  const handleSubmitForVerification = () => {
    dispatch(openSubmitForVerificationConfirmation());
  };

  // always show the current user first if possible
  const sortedBusinessReps = [
    ...businessReps.filter(({ id }) => id === currentMembership.id),
    ...businessReps.filter(({ id }) => id !== currentMembership.id),
  ];

  const showErrorHint = hasAnyVerificationErrors && !isKycStatusRetry && !isKycStatusDocument;

  const subTitle = showErrorHint
    ? ''
    : "Once you've completed all of these items, double-check your information and submit. It can take up to 1-2 days for your account to get verified, but we'll keep you posted throughout the process.";

  let submitIsDisabled = invalid || hasAnyVerificationErrors;
  if (isKycStatusRetry) {
    submitIsDisabled = false; // allow RCTM to re-submit on retry
  }
  if (isKycStatusDocument) {
    submitIsDisabled = false; // prevent RCTM from re-submitting. In document status, RCTM needs to re-submit docs
  }

  let showStatusLabel = true;
  if (isKycStatusRetry || isKycStatusDocument) {
    // when RCTM has to re-submit info and are accessing the page from account settings, we don't want to show statuses
    showStatusLabel = false;
  }

  return (
    <FlowStepperMain
      containerWidth={containerWidthMap.large}
      fields={
        <div>
          <SubmitForVerificationConfirmationModal />
          <Hints>
            {showErrorHint && (
              <BaseHint
                intent={Intent.DANGER}
                text="There's nothing you need to do. Our team will contact you once we've received the error."
                title="We couldn't verify your account."
              />
            )}
            {(isKycStatusRetry || isKycStatusDocument) && (
              <BaseHint
                className="margin-top--large"
                intent={Intent.WARNING}
                text="There's nothing you need to do at this time. Our team will contact you shortly."
                title="We couldn't verify your account."
              />
            )}
          </Hints>
          <GuideSectionListWrapper>
            <GuideSectionList
              iconName={IconNames.SHIELD}
              listKey={views.businessVerification}
              listState={sectionState}
              onToggleList={onToggleList}
              showStatusLabel={showStatusLabel}
              status={hasCurrentCompanyKYBFailed ? stepStatuses.failed : businessVerificationStatus}
              title="Business"
            >
              <LegalName />
              <BusinessType />
              <BusinessCategory />
              <BusinessAddress />
              <BusinessPhoneNumber />
              <DocumentUpload />
            </GuideSectionList>
            <GuideSectionList
              iconName={IconNames.PERSON}
              listKey={views.businessRepresentative}
              listState={sectionState}
              onToggleList={onToggleList}
              showStatusLabel={showStatusLabel}
              status={hasAnyBusinessRepKYCFailed ? stepStatuses.failed : representativeVerificationStatus}
              title="Representative"
            >
              {!sortedBusinessReps?.length && <RepresentativeInformation membership={currentMembership} notStarted />}
              {sortedBusinessReps?.map((rep) => (
                <RepresentativeInformation key={rep.id} membership={rep} />
              ))}
            </GuideSectionList>
          </GuideSectionListWrapper>

          <InvitedTeamMembers />
        </div>
      }
      invalid={submitIsDisabled}
      onBack={viewSteps[activeView].previous}
      onSubmit={handleSubmitForVerification}
      subTitle={{ content: subTitle }}
      title={{ content: "Let's make sure you have everything you need" }}
    />
  );
};

export default ReviewStep;
