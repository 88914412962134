import { Renew } from '@carbon/icons-react';
import { useUpdatePartnershipVendorTaxCollection } from '@routable/companies-management';
import { Button, Menu, Tooltip, useToastController } from '@routable/gross-ds';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { partnershipEndpointsUtil } from 'ducks/routableApi';
import { fetchSinglePartnershipSuccess } from 'actions/partnership';
import PermissionResourceAction from 'constants/permissions';
import { useGrossDsPermissions } from 'hooks';
import { currentCompanyCollectVendorTaxInfoSettingsSelector } from 'selectors/currentCompanySelectors';
export const ToggleVendorTaxCollection = ({ collectVendorTaxInfo, displayType, partnershipId, partnershipName, }) => {
    const collectVendorTaxInfoEnabled = useSelector(currentCompanyCollectVendorTaxInfoSettingsSelector);
    const dispatch = useDispatch();
    const toaster = useToastController();
    const { isLoading: isTogglingTaxCollection, mutate: toggleTaxCollection } = useUpdatePartnershipVendorTaxCollection();
    const permissions = useGrossDsPermissions([PermissionResourceAction.VENDOR_EDIT]);
    const handleToggleTaxCollection = () => {
        toggleTaxCollection({ id: partnershipId, collectVendorTaxInfo: !collectVendorTaxInfo }, {
            onSuccess: (responseData) => {
                const message = collectVendorTaxInfo
                    ? `${partnershipName} excluded from automated tax collection`
                    : `Automated tax collection re-enabled for ${partnershipName}`;
                toaster.addToast({ type: 'success', message });
                dispatch(fetchSinglePartnershipSuccess(responseData));
                dispatch(partnershipEndpointsUtil.updateQueryData('partnershipForId', partnershipId, (draft) => {
                    draft.partnership[partnershipId].attributes.collectVendorTaxInfo = !collectVendorTaxInfo;
                }));
            },
        });
    };
    const actionNode = useMemo(() => {
        if (!collectVendorTaxInfo) {
            return (React.createElement(React.Fragment, null,
                React.createElement(Renew, null),
                " ",
                React.createElement("span", null, "Re-enable automated tax collection")));
        }
        return 'Exclude vendor from automated tax collection';
    }, [collectVendorTaxInfo]);
    if (!collectVendorTaxInfoEnabled) {
        return null;
    }
    if (displayType === 'dropdown') {
        return (React.createElement(Menu, { "data-testid": "company-tax-and-docs-additional-actions-menu", intent: "secondary", placement: "bottom", size: "small" },
            React.createElement(Menu.Item, { disabled: !permissions.hasPermission, key: "toggleExcludeVendorFromAutomatedTaxRequests", onAction: handleToggleTaxCollection, textValue: "Toggle vendor automated tax collection", tooltip: permissions.tooltipProps }, actionNode)));
    }
    return (React.createElement(Tooltip, { "data-testid": "tooltip--toggle-exclude-vendor-from-automated-tax-requests", ...permissions.tooltipProps, position: "top", variant: "light" },
        React.createElement(Button, { "data-testid": "button--toggle-exclude-vendor-from-automated-tax-requests", disabled: !permissions.hasPermission, loading: isTogglingTaxCollection, onPress: handleToggleTaxCollection, size: "small", variant: "outline" }, actionNode)));
};
