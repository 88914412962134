import { Skeleton, TruncatableText } from '@routable/components';
import { formNamesItem } from '@routable/shared';
import clsx from 'clsx';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { ExistingItem } from 'context';
import {} from 'hooks/purchaseOrders/purchaseOrder.models';
import { DiscrepanciesObjectKeyMap } from 'constants/discrepancies';
import { LineItemStyles } from 'constants/lineItems';
import { getLineItemsPathFromStyle, isEmptyLineItem } from 'helpers/lineItems';
import { parseCurrency, setDecimalsOnNumber } from 'helpers/numbers';
import { useDiscrepancyContext, useRefreshDiscrepanciesContext } from 'hooks/context';
import { usePurchaseOrderItemData } from 'hooks/purchaseOrders';
import { InvoiceListPoReceiptCell } from 'modules/dashboard/createItems/invoiceListTable/components';
import { createItemsTableViewModelManagerForKindSelector } from 'queries/tableCompoundSelectors';
import { createFieldValueSelector } from 'selectors/createFormSelectors';
import { createItemFormItemSelector, createItemFormPurchaseOrderSelector } from 'selectors/forms';
const keyMap = {
    unbilled_amount: 'amount',
    unbilled_quantity: 'quantity',
    description: 'description',
    item: 'item.name',
    rate: 'unit_price',
    account: 'account.name',
};
const POLineItemsRow = ({ CellComponent, fieldProps, fixCellWidths, getHeaderOrBodyCellProps, getTableSectionFieldSize, headersConfigById, isOnDetailsPage, rowIdx, section, }) => {
    const contextItem = useContext(ExistingItem);
    const selectorItem = useSelector(createItemFormItemSelector);
    const item = isOnDetailsPage ? contextItem : selectorItem;
    const purchaseOrder = useSelector(createItemFormPurchaseOrderSelector);
    const purchaseOrderId = isOnDetailsPage ? item?.purchaseOrders[0]?.ledgerRef : purchaseOrder?.value;
    const { data: poData, isFetching: isFetchingPoData } = usePurchaseOrderItemData(purchaseOrderId, item?.ledgerRef);
    const poLineItems = poData?.data?.attributes?.line_items || [];
    const { hasDiscrepancyForColumn } = useDiscrepancyContext();
    const { isRefreshingDiscrepancies } = useRefreshDiscrepanciesContext();
    const lineItemStyle = section.sectionPath.includes('line_items_item_block')
        ? LineItemStyles.ITEM
        : LineItemStyles.ACCOUNT;
    const sectionPath = getLineItemsPathFromStyle(lineItemStyle);
    const fieldPath = `${sectionPath}.${rowIdx}`;
    const billLineItemFromForm = useSelector(createFieldValueSelector(formNamesItem.CREATE_ITEM, fieldPath));
    const billLineItemFromItem = contextItem?.lineItems
        ?.filter?.((lineItem) => lineItem.style === lineItemStyle)
        ?.at?.(rowIdx);
    const viewModelManager = useSelector(createItemsTableViewModelManagerForKindSelector);
    const billItemIsEmptyLineItem = isEmptyLineItem(billLineItemFromForm, viewModelManager, sectionPath);
    const rowValues = isOnDetailsPage ? billLineItemFromItem : billLineItemFromForm;
    const purchaseOrderLineItemId = isOnDetailsPage
        ? rowValues?.purchaseOrderLineItemId
        : rowValues?.purchase_order_line_item_id;
    const lineItemId = rowValues?.id || purchaseOrderLineItemId;
    const poLineItem = poLineItems.find((lineItem) => lineItem.purchase_order_line_item_id === purchaseOrderLineItemId);
    if (!isFetchingPoData && (!rowValues || !poLineItem)) {
        return (React.createElement(CellComponent, { className: clsx('w-full', {
                'bg-grey-5': !isOnDetailsPage,
            }), isOnDetailsPage: isOnDetailsPage },
            React.createElement("div", { className: "ledger-form-field w-full" },
                React.createElement("div", { className: "po-cell w-full" },
                    React.createElement("div", { className: "py-3 h-full flex align-middle" }, !billItemIsEmptyLineItem && 'Non-PO line')))));
    }
    const poLineItemMapped = Object.keys(fieldProps).reduce((prev, key) => {
        if (key === 'item') {
            return { ...prev, [key]: poLineItem?.[key]?.name };
        }
        if (key === 'account') {
            return {
                ...prev,
                [key]: `${poLineItem?.[key]?.code ? `${poLineItem?.[key]?.code} ` : ''}${poLineItem?.[key]?.name}`,
            };
        }
        if (key === 'rate' || key === 'unbilled_amount') {
            const amount = parseFloat(String(poLineItem?.[keyMap[key]])) < 0 ? 0 : poLineItem?.[keyMap[key]];
            let amountFormatted = parseCurrency(amount, {
                precision: fieldProps[key].decimalScale,
                symbol: '',
            });
            amountFormatted = setDecimalsOnNumber({
                fixedDecimalScale: fieldProps[key].fixedDecimalScale,
                minDecimalScale: fieldProps[key].minDecimalScale,
                number: amountFormatted,
            });
            return {
                ...prev,
                [key]: `${amountFormatted} ${purchaseOrder?.currencyCode || item?.currencyCode}`,
            };
        }
        if (key === 'unbilled_quantity') {
            const quantity = parseFloat(String(poLineItem?.[keyMap[key]])) < 0 ? 0 : poLineItem?.[keyMap[key]];
            const formattedQuantity = setDecimalsOnNumber({
                fixedDecimalScale: fieldProps[key].fixedDecimalScale,
                maxDecimalScale: fieldProps[key].decimalScale,
                minDecimalScale: fieldProps[key].minDecimalScale,
                number: quantity,
            });
            return {
                ...prev,
                [key]: formattedQuantity,
            };
        }
        return { ...prev, [key]: poLineItem?.[keyMap[key]] };
    }, {});
    return (React.createElement(React.Fragment, null, Object.values(headersConfigById).map((cell, cellIdx) => {
        const headerConfig = headersConfigById[cell.id];
        if (!headerConfig?.isVisible) {
            return null;
        }
        const fieldSize = getTableSectionFieldSize(fieldProps[cell.id]);
        const bodyCellProps = fixCellWidths(getHeaderOrBodyCellProps(fieldSize));
        const value = poLineItemMapped[cell.id];
        const hasDiscrepancy = hasDiscrepancyForColumn(lineItemId, DiscrepanciesObjectKeyMap[cell.id]);
        const showDiscrepancy = !isRefreshingDiscrepancies && hasDiscrepancy;
        return (React.createElement(CellComponent, { key: `table-cell-${cellIdx + 1}`, ...bodyCellProps, className: clsx(bodyCellProps.className, {
                'bg-grey-5': !isOnDetailsPage,
            }), isOnDetailsPage: isOnDetailsPage }, isFetchingPoData ? (React.createElement(Skeleton, { className: "flex items-center justify-center h-[40px] w-full", shape: "round" })) : (React.createElement("div", { className: clsx('ledger-form-field', {
                'justify-end': fieldProps[cell.id]?.minDecimalScale > 0,
                'justify-center': isOnDetailsPage && cell.id === 'unbilled_quantity',
            }) },
            !isOnDetailsPage && showDiscrepancy && (React.createElement("div", { className: "absolute border border-gold-40 rounded-[13px] inset-y-[6px] inset-x-[4px]", "data-testid": "discrepancy-border" })),
            React.createElement("div", { className: clsx('po-cell min-w-0 relative', {
                    'text-grey-60': value === undefined || value === null,
                    'min-w-[16px] text-center': isOnDetailsPage && cell.id === 'unbilled_quantity',
                    'w-full': cell.id === 'receipt',
                }) },
                isOnDetailsPage && showDiscrepancy && (React.createElement("div", { className: "absolute border border-gold-40 rounded-[13px] inset-y-[-6px] inset-x-[-8px]", "data-testid": "discrepancy-border" })),
                cell.id === 'receipt' ? (React.createElement(InvoiceListPoReceiptCell, { linkVariant: "black", rowData: { ...poLineItem, poMatchType: item.poMatchType } })) : (React.createElement(TruncatableText, { position: "top", text: value ?? '' })))))));
    })));
};
export default POLineItemsRow;
