import _find from 'lodash/find';
import _omit from 'lodash/omit';
import { createSelector } from 'reselect';

import { FundingSourceProviderSubClasses } from 'constants/funding';

import { doesBalanceNeedCorrection } from 'helpers/balance';
import { getExternalPartnershipDefaultFundingAccount } from 'helpers/external';
import {
  getFilteredFundingAccountsForCompanyId,
  getFundingAccountForFundingBalanceInfo,
  getFundingAccountsByProviderSubClass,
  getFundingAccountWithDisplayStatusAndSort,
  getFundingSourcesForFundingAccount,
  isFundingAccountDeleted,
  isFundingAccountDisabled,
  isFundingAccountUsable,
  isFundingAccountValid,
} from 'helpers/funding';
import { sortObjectArray } from 'helpers/sort';

import { itemFromParamSelector, itemFromQuerySelector } from 'queries/itemRouterSelectors';
import {
  partnershipFromCurrentPartnershipRequestSelector,
  partnershipCountryCodeFromQuerySelector,
  partnershipFundingAccountsForPartnershipPropSelector,
} from 'queries/partnershipCompoundSelectors';

import { billingAllCodesSelector, billingDataByCodeSelector } from 'selectors/billingSelectors';
import {
  currentCompanyIdSelector,
  currentCompanyMembersWithUserInfoSelector,
  currentCompanyNameSelector,
  currentCompanySettingsIsInternationalPaymentsEnabledSelector,
} from 'selectors/currentCompanySelectors';
import { createItemFormItemSelector, existingItemFormFundingAccountSelector } from 'selectors/forms';
import {
  fundingAccountCreatorIdFromFundingAccountPropSelector,
  fundingSourceIdsFromFundingAccountPropSelector,
  fundingAccountForIdSelector,
  fundingAccountFromPropsSelector,
  fundingAccountIdFromFundingAccountPropSelector,
  fundingAccountsAllValuesSelector,
  fundingAccountsByIdSelector,
  fundingAccountsWithFundingSourceSelector,
  fundingInfoAddressesByIdSelector,
  fundingSourcesAllValuesSelector,
  fundingSourcesByIdSelector,
  getState,
} from 'selectors/fundingSelectors';
import { allStateSelector, idSelector, propsSelector } from 'selectors/globalSelectors';
import { partnershipFundingStatesCurrentFundingAccountIdSelector } from 'selectors/partnershipFundingStatesSelectors';
import { partnershipRequestFromLocationSelector } from 'selectors/partnershipRequestSelectors';
import { partnershipFundingAccountPropSelector } from 'selectors/propSelectors';
import { companyIdQuerySelector, locationSelector } from 'selectors/routerSelectors';

/**
 * Selects all the fundingAccounts belonging to the current company from the state
 * and preps them for React select
 * @function
 * @param {Object} state
 * @return {Object[]}
 */
export const fundingAccountsAllForCurrentCompanySelector = createSelector(
  [fundingAccountsAllValuesSelector, currentCompanyIdSelector],
  (fundingAccounts, currentCompanyId) =>
    fundingAccounts.filter((fundingAccount) => fundingAccount.company === currentCompanyId),
);

/**
 * Selects the balance fundingAccount belonging to the current company from the state
 * and preps them for React select
 * @function
 * @param {Object} state
 * @return {Object[]}
 */
export const fundingAccountWhereTypeIsBalanceForCurrentCompanySelector = createSelector(
  [fundingAccountsAllForCurrentCompanySelector],
  // Always taking the first key, as there should only be one fundingAccount that represents a balance
  (fundingAccounts) => _find(fundingAccounts, (fundingAccount) => fundingAccount.balance),
);

/**
 * Selects all the fundingAccounts belonging to the current company from the state
 * that are not disabled
 * @function
 * @param {Object} state
 * @return {Object[]}
 */
export const fundingAccountsAllWhereNonDisabledOrDeletedForCurrentCompanySelector = createSelector(
  [fundingAccountsAllForCurrentCompanySelector],
  (fundingAccounts) =>
    fundingAccounts.filter(
      (fundingAccount) => !isFundingAccountDeleted(fundingAccount) && !isFundingAccountDisabled(fundingAccount),
    ),
);

/**
 * Selects all the bank fundingAccounts belonging to the current company from the state
 * @function
 * @param {Object} state
 * @return {Object[]}
 */
export const fundingAccountsWhereBankForCurrentCompanySelector = createSelector(
  [fundingAccountsAllForCurrentCompanySelector],
  (fundingAccounts) => fundingAccounts.filter((fundingAccount) => fundingAccount.bank),
);

/**
 * Selects all the bank fundingAccounts belonging to the current company from the state and tallies 'em up
 * @function
 * @param {Object} state
 * @return {number}
 */
export const fundingAccountsWhereBankForCurrentCompanyCountSelector = createSelector(
  [fundingAccountsWhereBankForCurrentCompanySelector],
  (accounts) => accounts.length,
);

/**
 * Selects all the bank fundingAccounts, grouped into arrays by status.
 * @function
 * @param {Object} state
 * @return {Object}
 */
export const fundingAccountsWhereBankByStatusSelector = createSelector(
  [fundingAccountsWhereBankForCurrentCompanySelector],
  (fundingAccounts) => {
    // Add extra data (display status and sort order)
    const fundingAccountWithExtraData = fundingAccounts.map((fundingAccount) =>
      getFundingAccountWithDisplayStatusAndSort(fundingAccount),
    );

    // Sort by sort order key
    const sortedFundingAccounts = sortObjectArray(fundingAccountWithExtraData, 'sortOrder');

    // Bunch the funding accounts by display status
    return sortedFundingAccounts.reduce((collector, acct) => {
      const { displayStatus } = acct;

      if (collector[displayStatus]) {
        return {
          ...collector,
          [displayStatus]: [...collector[displayStatus], acct],
        };
      }
      return { ...collector, [displayStatus]: [acct] };
    }, {});
  },
);

/**
 * Selects all the valid fundingAccounts belonging to the current company from the state
 * and preps them for the SelectFundingAccount component
 * @function
 * @param {Object} state
 * @return {Object[]}
 */
export const fundingAccountsWhereValidForCurrentCompanySelector = createSelector(
  [fundingAccountsAllForCurrentCompanySelector],
  (fundingAccounts) => fundingAccounts.filter((fundingAccount) => fundingAccount.isValid),
);

/**
 * Selects all the usable fundingAccounts belonging to the current company from the state
 * @function
 * @param {Object} state
 * @return {Object[]}
 */
export const fundingAccountsWhereUsableForCurrentCompanySelector = createSelector(
  [fundingAccountsWhereValidForCurrentCompanySelector],
  (fundingAccounts) => fundingAccounts.filter(isFundingAccountUsable),
);

/**
 * Selects all the ids of usable fundingAccounts belonging to the current company from the state
 * @function
 * @param {Object} state
 * @return {Object[]}
 */
export const fundingAccountsIdsWhereUsableForCurrentCompanySelector = createSelector(
  [fundingAccountsWhereUsableForCurrentCompanySelector],
  (fundingAccounts) =>
    fundingAccounts.reduce((accum, instance) => {
      accum.push(instance.id);
      return accum;
    }, []),
);

/**
 * Selects all the valid bank fundingAccounts belonging to the current company from the state
 * @function
 * @param {Object} state
 * @return {Object[]}
 */
export const fundingAccountsWhereValidBankForCurrentCompanyBankSelector = createSelector(
  [fundingAccountsAllWhereNonDisabledOrDeletedForCurrentCompanySelector],
  (fundingAccounts) =>
    fundingAccounts.filter((fundingAccount) => fundingAccount.bank && isFundingAccountValid(fundingAccount)),
);

/**
 * Selects all the "valid for externally paid" fundingAccounts belonging to the current company from the state
 * @function
 * @param {Object} state
 * @return {Object[]}
 */
export const fundingAccountsWhereValidForExternallyPaidForCurrentCompanyBankSelector = createSelector(
  [fundingAccountsAllWhereNonDisabledOrDeletedForCurrentCompanySelector, fundingSourcesAllValuesSelector],
  (fundingAccounts, fundingSources) =>
    getFundingAccountsByProviderSubClass(
      fundingAccounts,
      fundingSources,
      FundingSourceProviderSubClasses.EXTERNAL_LEDGER,
    ),
);

/**
 * Selects non-disabled funding accounts belonging to the current company with the provider ACH
 * @type {StandardSelector}
 * @return {FundingAccount[]}
 */
export const fundingAccountsAllForAchProviderSelector = createSelector(
  [fundingAccountsAllWhereNonDisabledOrDeletedForCurrentCompanySelector, fundingSourcesAllValuesSelector],
  (fundingAccounts, fundingSources) =>
    getFundingAccountsByProviderSubClass(fundingAccounts, fundingSources, FundingSourceProviderSubClasses.ACH),
);

/**
 * Selects non-disabled funding accounts belonging to the current company with the provider ADDRESS
 * @type {StandardSelector}
 * @return {FundingAccount[]}
 */
export const fundingAccountsAllForAddressProviderSelector = createSelector(
  [fundingAccountsAllWhereNonDisabledOrDeletedForCurrentCompanySelector, fundingSourcesAllValuesSelector],
  (fundingAccounts, fundingSources) =>
    getFundingAccountsByProviderSubClass(fundingAccounts, fundingSources, FundingSourceProviderSubClasses.ADDRESS),
);

/**
 * Maps fundingSources to fundingAccounts from the state for the current company
 * @function
 * @param {Object} state
 * @return {Object}
 */
export const fundingSourcesForCurrentCompanyByAccountSelector = createSelector(
  [fundingAccountsAllForCurrentCompanySelector, fundingSourcesByIdSelector],
  (allCurrentCompanyFundingAccounts, fundingSources) => {
    const mappedFundingSourcesByAccount = {};

    allCurrentCompanyFundingAccounts.forEach((fundingAccount) => {
      const filteredFundingSources = Object.values(fundingSources).filter(
        (fundingSource) => fundingAccount.fundingSources && fundingAccount.fundingSources.includes(fundingSource.id),
      );

      mappedFundingSourcesByAccount[fundingAccount.id] = sortObjectArray(filteredFundingSources, 'providerSubClass');
    });

    return mappedFundingSourcesByAccount;
  },
);

/**
 * Selects fundingSources by a singular fundingAccountId, when fundingAccount is from props
 * @function
 * @param {Object} state
 * @param {Object} props
 * @return {FundingSource[]}
 */
export const fundingSourcesFromFundingAccountPropSelector = createSelector(
  [fundingSourcesForCurrentCompanyByAccountSelector, fundingAccountIdFromFundingAccountPropSelector],
  (fundingSources, fundingAccountId) => fundingSources[fundingAccountId],
);

/**
 * Selects fundingSources that belonds to a funding account passed as a prop
 * @type {StandardSelector}
 * @param {Object} state
 * @param {Object} props
 * @return {FundingSource[]}
 */
export const fundingAccountFundingSourcesFromFundingAccountPropSelector = createSelector(
  [fundingSourcesAllValuesSelector, fundingAccountFromPropsSelector],
  (fundingSources, fundingAccount) => getFundingSourcesForFundingAccount(fundingAccount, fundingSources),
);

/**
 * Selects non-disabled funding accounts belonging to the current company given a provider subClass
 * @function
 * @param {Object} state
 * @param {string} providerSubClass
 * @return {FundingAccount[]}
 */
export const fundingAccountsAllForProviderSubClassSelector = createSelector(
  [fundingAccountsAllWhereNonDisabledOrDeletedForCurrentCompanySelector, fundingSourcesAllValuesSelector, idSelector],
  getFundingAccountsByProviderSubClass,
);

/**
 * Selects fundingAccount based on create form's item object. This item object
 * has a fundingAccount field which is a sub-object containing a string id.
 * Access is item.fundingAccount.id
 * @function
 * @param {Object} state
 * @return {ObjectMaybe}
 */
export const fundingAccountForItemFromCreateItemSelector = createSelector(
  [fundingAccountsByIdSelector, createItemFormItemSelector],
  (fundingAccounts, item) => fundingAccounts[item?.fundingAccount?.id],
);

/**
 * Selects fundingAccount based on finalize item form object. This form object
 * has a fundingAccount field which is a sub-object containing a string id.
 * Access is form.fundingAccount.id
 * @function
 * @param {Object} state
 * @return {ObjectMaybe}
 */
export const fundingAccountForFinalizeItemFormSelector = createSelector(
  [fundingAccountsByIdSelector, existingItemFormFundingAccountSelector],
  (fundingAccounts, fundingAccount) => fundingAccounts[fundingAccount?.id],
);

/**
 * Selects fundingAccount based on item from param selector. This item object
 * has a fundingAccount field which is a string id. Access is item.fundingAccount
 * @function
 * @param {Object} state
 * @return {ObjectMaybe}
 */
export const fundingAccountForItemFromParamSelector = createSelector(
  [fundingAccountsByIdSelector, itemFromParamSelector],
  (fundingAccounts, item) => fundingAccounts[item?.fundingAccount],
);

/**
 * Selects fundingAccount from possible forms:
 * - Create item form
 * - Finalize item form
 * And returns one of them to be used under rate estimate.
 * @function
 * @param {Object} state
 * @return {ObjectMaybe}
 */
export const fundingAccountForEstimateRequestSelector = createSelector(
  [fundingAccountForItemFromCreateItemSelector, fundingAccountForFinalizeItemFormSelector],
  (fundingAccountFromCreateItemForm, fundingAccountFromFinalizeItemForm) =>
    fundingAccountFromCreateItemForm || fundingAccountFromFinalizeItemForm,
);

/**
 * Selects item.fundingAccount from a queried item
 * @type {StandardSelector}
 * @param {Object} state
 * @param {Object} props
 * @return {?string}
 */
export const fundingAccountIdFromQueryItemSelector = createSelector(
  [itemFromQuerySelector],
  (item) => item?.fundingAccount,
);

/**
 * Selects a funding account using the id from a queried item
 * @function
 * @param {Object} state
 * @param {Object} props
 * @return {?string}
 */
export const fundingAccountFromQueryItemSelector = createSelector(
  [fundingAccountsByIdSelector, fundingAccountIdFromQueryItemSelector],
  (fundingAccounts, fundingAccountId) => fundingAccounts[fundingAccountId],
);

/**
 * Selects fundingAccount.address using a queried item's funding account
 * @function
 * @param {Object} state
 * @param {Object} props
 * @return {?string}
 */
export const fundingInfoAddressIdFromQueryItemSelector = createSelector(
  [fundingAccountFromQueryItemSelector],
  (fundingAccount) => (fundingAccount ? fundingAccount.address : undefined),
);

/**
 * Selects a funding info address using the fundingAccount.address, from a queried item
 * @function
 * @param {Object} state
 * @param {Object} props
 * @return {?string}
 */
export const fundingInfoAddressFromQueryItemSelector = createSelector(
  [fundingInfoAddressesByIdSelector, fundingInfoAddressIdFromQueryItemSelector],
  (fundingInfoAddresses, fundingAddressId) => fundingInfoAddresses[fundingAddressId],
);

/**
 * Selects member info for the creator of the funding account, when funding account in props
 * @function
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {Object} props
 * @returns {User}
 */
export const fundingAccountCreatorFromPropSelector = createSelector(
  [fundingAccountCreatorIdFromFundingAccountPropSelector, currentCompanyMembersWithUserInfoSelector],
  (creatorId, members) => members[creatorId],
);

/**
 * Selects funding sources of the funding account, when funding account in props
 * @function
 * @param {Object} state
 * @param {Object} props
 * @return {ObjectMaybe}
 */
export const fundingAccountSourcesFromPropSelector = createSelector(
  [fundingSourceIdsFromFundingAccountPropSelector, fundingSourcesByIdSelector],
  (fundingSourceIds, fundingSourcesById) => fundingSourceIds.map((id) => fundingSourcesById[id]),
);

/**
 * Selects the funding info balance object
 * @function
 * @param {ReduxState} state
 * @return {Object}
 */
export const fundingInfoBalancesSelector = createSelector(
  [getState],
  (fundingState) => fundingState.fundingInfoBalances,
);

/**
 * Selects the funding info balance byId object
 * @function
 * @param {ReduxState} state
 * @return {Object}
 */
export const fundingInfoBalanceByIdSelector = createSelector(
  [fundingInfoBalancesSelector],
  (fundingInfoBalances) => fundingInfoBalances.byId,
);

/**
 * Selects the funding info balance isFetching bool
 * @function
 * @param {ReduxState} state
 * @return {boolean}
 */
export const fundingInfoBalanceIsFetchingSelector = createSelector(
  [fundingInfoBalancesSelector],
  (fundingInfoBalances) => fundingInfoBalances.isFetching,
);

/**
 * Selects the funding info balance byId object
 * @function
 * @param {ReduxState} state
 * @return {Object}
 */
export const fundingInfoBalanceSelector = createSelector(
  [fundingInfoBalanceByIdSelector],
  (fundingInfoBalancesById) => {
    if (!fundingInfoBalancesById) {
      return undefined;
    }

    const keys = Object.keys(fundingInfoBalancesById);

    if (!keys || keys.length === 0) {
      return undefined;
    }

    // Always taking the first key, as there should only be one fundingInfoBalance
    return fundingInfoBalancesById[keys[0]];
  },
);

/**
 * Selects the balance amount from balance object
 * @function
 * @param {ReduxState} state
 * @return {NumberMaybe}
 */
export const fundingInfoBalanceIdSelector = createSelector([fundingInfoBalanceSelector], (balance) =>
  balance ? balance.id : undefined,
);

/**
 * Selects the areTransfersPending boolean from balance object
 * @function
 * @param {ReduxState} state
 * @return {Boolean}
 */
export const fundingInfoBalanceAreTransfersPendingSelector = createSelector([fundingInfoBalanceSelector], (balance) =>
  Boolean(balance && balance.areTransfersPending),
);

/**
 * Selects the balance amount from balance object
 * @function
 * @param {ReduxState} state
 * @return {NumberMaybe}
 */
export const fundingInfoBalanceAmountSelector = createSelector([fundingInfoBalanceSelector], (balance) =>
  balance ? parseFloat(balance.balance) : undefined,
);

/**
 * Selects the canTransact boolean from balance object
 * @function
 * @param {ReduxState} state
 * @return {Boolean}
 */
export const fundingInfoBalanceCanTransactSelector = createSelector([fundingInfoBalanceSelector], (balance) =>
  Boolean(balance && balance.canTransact),
);

/**
 * Selects the balance amount pending from balance object
 * @function
 * @param {ReduxState} state
 * @return {NumberMaybe}
 */
export const fundingInfoBalancePendingSelector = createSelector([fundingInfoBalanceSelector], (balance) =>
  balance ? parseFloat(balance.balancePending) : undefined,
);

/**
 * Selects the direction prop.
 * @function
 * @param {ReduxState} state
 * @return {?BalanceTransactionDirection}
 */
export const fundingBalanceTransactionDirectionFromPropsSelector = createSelector(
  [propsSelector],
  (props) => props.direction,
);

/**
 * Selects the funding account connected to the balance id.
 * @function
 * @param {ReduxState} state
 * @return {ObjectMaybe}
 */
export const fundingAccountForBalanceInfoSelector = createSelector(
  [fundingInfoBalanceIdSelector, fundingAccountsByIdSelector],
  (balanceId, fundingAccounts) => getFundingAccountForFundingBalanceInfo(balanceId, fundingAccounts),
);

/**
 * Selects the funding account connected to the balance id.
 * @function
 * @param {ReduxState} state
 * @return {ObjectMaybe}
 */
export const fundingAccountIdForBalanceInfoSelector = createSelector(
  [fundingAccountForBalanceInfoSelector],
  (fundingAccount) => (fundingAccount ? fundingAccount.id : undefined),
);

/**
 * Selects whether the balance account is in need of correction.
 * @function
 * @param {ReduxState} state
 * @return {ObjectMaybe}
 */
export const fundingAccountBalanceNeedsCorrectionSelector = createSelector(
  [fundingInfoBalanceSelector],
  (balanceInfo) => doesBalanceNeedCorrection(balanceInfo),
);

/**
 * Returns billing data by code
 * @function
 * @param {ReduxState} state
 * @returns {Object}
 */
export const applicableBillingDataByCodeSelector = createSelector(
  [billingAllCodesSelector, billingDataByCodeSelector, fundingInfoBalanceIdSelector],
  (codes, billingDataByCode, balanceId) => {
    const hasBalance = !!balanceId;

    return codes.reduce((accum, code) => {
      const billingData = billingDataByCode[code];

      const maxAboveZero = billingData.paymentDeliveryOptionMax > 0;
      const hasBalanceOrAllowed = hasBalance || !billingData.requireBalance;

      if (maxAboveZero && hasBalanceOrAllowed) {
        return {
          ...accum,
          [code]: billingData,
        };
      }

      return accum;
    }, {});
  },
);

/**
 * Returns a selector that gets billing data by code, excluding the codes given.
 * @function
 * @param {string[]} excludedCodes
 * @returns {Function}
 */
export const makeBillingDataExcludingCodesSelector = (excludedCodes) =>
  createSelector([applicableBillingDataByCodeSelector], (billingDataByCode) => _omit(billingDataByCode, excludedCodes));

/**
 * Gets default funding account if from current partnership request
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {ComponentProps} props
 * @param {Location} props.location
 * @returns {FundingAccount.id}
 */
export const defaultFundingAccountIdFromCurrentPartnershipRequestSelector = createSelector(
  [locationSelector, partnershipFromCurrentPartnershipRequestSelector],
  (location, partnership) => getExternalPartnershipDefaultFundingAccount({ location, partnership }),
);

/**
 * Selects all funding accounts that belong to the company id received as a query param
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {ComponentProps} props
 * @param {Location} props.location
 * @returns {FundingAccount[]}
 */
export const fundingAccountsForCompanyIdQuerySelector = createSelector(
  [fundingAccountsAllValuesSelector, companyIdQuerySelector],
  getFilteredFundingAccountsForCompanyId,
);

/**
 * Selects funding accounts with funding sources that belong to the company id received as a query param
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {ComponentProps} props
 * @param {Location} props.location
 * @returns {FundingAccount[]}
 */
export const fundingAccountsWithFundingSourceForCompanyIdQuerySelector = createSelector(
  [fundingAccountsWithFundingSourceSelector, companyIdQuerySelector],
  getFilteredFundingAccountsForCompanyId,
);

/**
 * Selects the preferredFundingAccount property of current partnership request
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {ComponentProps} props
 * @param {Location} props.location
 * @returns {FundingAccount.id}
 */
export const preferredFundingAccountIdFromCurrentPartnershipRequestSelector = createSelector(
  [partnershipRequestFromLocationSelector],
  (partnershipRequest) => partnershipRequest?.preferredFundingAccount,
);

/**
 * Gets the funding account that belongs to the current partnership request
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {ComponentProps} props
 * @param {Location} props.location
 * @returns {FundingAccount}
 */
export const fundingAccountFromCurrentPartnershipRequestSelector = createSelector(
  [
    allStateSelector,
    fundingAccountIdFromQueryItemSelector,
    partnershipFundingStatesCurrentFundingAccountIdSelector,
    preferredFundingAccountIdFromCurrentPartnershipRequestSelector,
    defaultFundingAccountIdFromCurrentPartnershipRequestSelector,
  ],
  (state, itemFundingAccountId, currentFundingAccountId, preferredFundingAccountId, defaultFundingAccountId) =>
    fundingAccountForIdSelector(
      state,
      itemFundingAccountId || currentFundingAccountId || preferredFundingAccountId || defaultFundingAccountId,
    ),
);

/**
 * Gets the funding info address from funding account info that belongs to the current
 * partnership request
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {ComponentProps} props
 * @param {Location} props.location
 * @returns {FundingInfoAddress}
 */
export const fundingInfoAddressFromCurrentPartnershipRequestSelector = createSelector(
  [fundingAccountFromCurrentPartnershipRequestSelector, fundingInfoAddressesByIdSelector],
  (fundingAccount, fundingInfoAddresses) => fundingInfoAddresses[fundingAccount?.address],
);

/**
 * Selects initial values for the Manually Add Bank Account form
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {ComponentProps} props
 * @param {Location} props.location
 * @returns {Object}
 */
export const manuallyAddBankAccountFormInitialValuesSelector = createSelector(
  [
    partnershipCountryCodeFromQuerySelector,
    currentCompanyNameSelector,
    currentCompanySettingsIsInternationalPaymentsEnabledSelector,
  ],
  (partnerCountryCode, companyName, isInternationalPaymentsEnabled) => {
    const values = {
      partner: {
        country: partnerCountryCode,
      },
      ui: {
        closeAddFundingAccountSideSheetOnSuccess: true,
      },
    };

    if (isInternationalPaymentsEnabled) {
      values.form = {
        // Set the applyRegisteredAddress checkbox to be toggled off by default.
        applyRegisteredAddress: false,
        companyName,
      };
    }

    return values;
  },
);

/**
 * Selects funding accounts belonging to the partnership from partnership prop
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {ComponentProps} props
 * @param {Partnership} props.partnership
 * @returns {FundingAccount[]}
 */
export const fundingAccountsForPartnershipFromPartnershipPropSelector = createSelector(
  [fundingAccountsByIdSelector, partnershipFundingAccountsForPartnershipPropSelector],
  (fundingAccountsById, partnershipFundingAccounts) =>
    partnershipFundingAccounts.map(
      (partnershipFundingAccount) => fundingAccountsById[partnershipFundingAccount.fundingAccount],
    ),
);

/**
 * Selects funding account belonging to the given partnershipFundingAccount
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {ComponentProps} props
 * @param {PartnershipFundingAccount} props.partnershipFundingAccount
 * @returns {FundingAccount}
 */
export const fundingAccountFromPartnershipFundingAccount = createSelector(
  [fundingAccountsByIdSelector, partnershipFundingAccountPropSelector],
  (fundingAccountsById, partnershipFundingAccount) => fundingAccountsById[partnershipFundingAccount?.fundingAccount],
);
