import { internationalBeneficiaryFieldName } from '@routable/fund-routes';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useUnmount } from 'react-use';
import { Field, change, untouch } from 'redux-form';
import ReduxFormFieldRenderInternationalPhoneInput from 'components/form/InternationalPhoneInput';
import { internationalPhoneNumberValidator, phoneNumberLengthValidator, phoneNumberRequiredValidator, } from 'helpers/fieldValidation';
export const BeneficiaryPhoneNumberField = ({ className, defaultCountry, form }) => {
    const dispatch = useDispatch();
    useUnmount(() => {
        dispatch(change(form, internationalBeneficiaryFieldName.Enum.phone_number, undefined));
        dispatch(untouch(form, internationalBeneficiaryFieldName.Enum.phone_number));
    });
    return (React.createElement("div", { className: className },
        React.createElement(Field, { component: ReduxFormFieldRenderInternationalPhoneInput, defaultCountry: defaultCountry, isRequired: true, name: internationalBeneficiaryFieldName.Enum.phone_number, placeholder: "Phone number", validate: [phoneNumberRequiredValidator, phoneNumberLengthValidator, internationalPhoneNumberValidator] })));
};
