import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSelectorWithProps } from 'hooks';
import { isInternationalPartnershipSelector } from 'queries/partnershipCompoundSelectors';
import { SelectDomesticPaymentMethodPage } from './SelectDomesticPaymentMethodPage';
import UpdatePaymentMethodFormInternational from './UpdatePaymentMethodFormInternational';
const UpdatePaymentMethodFormWithCheckboxAndHints = ({ isUpdatePaymentMethodFlow }) => {
    const location = useLocation();
    const isInternational = useSelectorWithProps(isInternationalPartnershipSelector, { location });
    if (isInternational) {
        return React.createElement(UpdatePaymentMethodFormInternational, { isUpdatePaymentMethodFlow: isUpdatePaymentMethodFlow });
    }
    return React.createElement(SelectDomesticPaymentMethodPage, null);
};
export default UpdatePaymentMethodFormWithCheckboxAndHints;
