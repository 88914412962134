import { queryContext } from '@routable/shared';
import { useMutation } from '@tanstack/react-query';
import { partnershipRequestsService } from '../../services';
export const useUpdatePartnershipRequestVendorCountry = () => {
    return useMutation({
        context: queryContext,
        mutationFn: async ({ id, countryCode }) => {
            return partnershipRequestsService.updateExternalVendorCountry(id, countryCode);
        },
    });
};
