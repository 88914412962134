import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FieldArray } from 'redux-form';

import { deleteCompanyDocumentRoutine, postCompanyDocumentRoutine } from 'actions/routines/documents';

import CannotUpdateFieldHint from 'components/hintTypes/CannotUpdateFieldHint';

import { DOCUMENT_APPROVED_IMAGE } from 'constants/images';
import { colors } from 'constants/styles';

import helpDocs from 'helpers/helpDocs';
import * as swal from 'helpers/swal';

import { containerWidthMap } from 'modules/signup-v3/constants/container';
import { viewSteps } from 'modules/signup-v3/helpers/viewSteps';

import {
  currentCompanySelector,
  currentCompanyVerificationDocumentUpdateAllowedSelector,
  isFetchingCurrentCompanySelector,
} from 'selectors/currentCompanySelectors';

import DocumentModal from '../components/DocumentModal';
import UploadFile from '../components/UploadFile';
import { fields as fieldNames, getViewToFieldsMap } from '../fields';
import FlowStepperMain from '../FlowStepperMain';

import {
  Container,
  DocumentOptionsContainer,
  Image,
  RecommendedDocument,
  RecommendedDocumentLink,
  DocumentOptions,
  TextWeight,
} from './UploadDocument.styles';

// we only want RCTMs to send PDFs but the sandbox formats provided by Dwolla are PNGs
// https://developers.dwolla.com/guides/sandbox#simulate-document-upload-approved-and-failed-events
//  isNodeEnvProduction() ? 'application/pdf' :
const acceptedFileFormats = ['application/pdf', 'image/png'];

const UploadDocumentField = ({ isDisabled }) => {
  const currentCompany = useSelector(currentCompanySelector);
  const dispatch = useDispatch();

  const isFetchingCurrentCompany = useSelector(isFetchingCurrentCompanySelector);

  return (
    <>
      <DocumentOptionsContainer>
        <Image alt="Document with a blue star" src={DOCUMENT_APPROVED_IMAGE} />
        <div>
          <RecommendedDocument color={colors.colorGreyXXDarkHex}>
            Upload your{' '}
            <RecommendedDocumentLink
              color={colors.colorBlueBoldHex}
              href={helpDocs.BUSINESS_VERIFICATION_DETAILS}
              rel="noopener noreferrer"
              target="_blank"
            >
              IRS-issued EIN letter
            </RecommendedDocumentLink>{' '}
            <TextWeight weight="400">(Recommended)</TextWeight>{' '}
          </RecommendedDocument>
          <DocumentOptions
            color={colors.colorBlueBoldHex}
            onClick={() => {
              swal.showSwal({
                Content: <DocumentModal />,
              });
            }}
            type="button"
          >
            What are my other options?
          </DocumentOptions>
        </div>
      </DocumentOptionsContainer>

      <FieldArray
        component={({ fields }) => {
          const hintText = 'To speed up the verification process, consider uploading more than one document. ';
          const handleDocumentDelete = (file, documentId) => {
            dispatch(
              deleteCompanyDocumentRoutine.trigger({
                documentId,
                currentCompanyId: currentCompany.id,
                removeFileFromList: () => fields.remove(file),
              }),
            );
          };

          const handleDocumentUpload = (file, addFileToList) => {
            dispatch(
              postCompanyDocumentRoutine.trigger({
                file,
                currentCompanyId: currentCompany.id,
                addFileToList,
              }),
            );
          };

          return (
            <UploadFile
              accept={acceptedFileFormats}
              addFile={handleDocumentUpload}
              errorMessage="You can only upload PDF files. Please try again."
              fields={fields}
              hintText={hintText}
              isDisabled={isDisabled}
              isFetching={isFetchingCurrentCompany}
              remove={handleDocumentDelete}
              supportText="Only PDF files are allowed"
            />
          );
        }}
        name={fieldNames.documents}
        validate={(fields) => (fields?.length > 0 ? undefined : 'Must have at least one document')}
      />
    </>
  );
};

const UploadDocumentMain = ({ activeView, invalid }) => {
  const updateAllowed = useSelector(currentCompanyVerificationDocumentUpdateAllowedSelector);
  const viewToFieldsMap = useMemo(() => getViewToFieldsMap(), []);

  return (
    <>
      <FlowStepperMain
        containerWidth={containerWidthMap.medium}
        fields={
          <Container>
            {!updateAllowed && <CannotUpdateFieldHint />}
            {viewToFieldsMap[activeView]?.map(({ name, id }) => (
              <span key={`${name}-${id}`}>
                <UploadDocumentField id={id} isDisabled={!updateAllowed} name={name} />
              </span>
            ))}
          </Container>
        }
        invalid={invalid || !updateAllowed}
        onBack={viewSteps[activeView].previous}
        onNext={viewSteps[activeView].next}
        onSkip={(invalid || updateAllowed) && viewSteps[activeView].skip}
        submit={false}
        subTitle={{
          content: "You'll need this to send and request payments on Routable. ",
        }}
        title={{ content: 'Time to upload documents to verify your business.' }}
      />
    </>
  );
};

export default UploadDocumentMain;
