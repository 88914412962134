export const BILLING_CODE_ADJUSTMENT_CHANGE = 'billing_code_adjustment_change';
export const BULK_IMPORT_CHANGE = 'bulk_import_change';
export const COMPANY_BRAND_CHANGE = 'company_brand_change';
export const COMPANY_MEMBERSHIPS_CHANGE = 'company_memberships_change';
export const COMPANY_SETTINGS_ITEM_APPROVAL_CHANGE = 'company_settings_item_approval_change';
export const COMPANY_SETTINGS_PRODUCT_CHANGE = 'company_settings_product_change';
export const CORNER_DIALOG_PUSH_NOTIFICATION_EVENT = 'corner_dialog_push_notification_event';
export const TOGGLE_WARNING_MODAL_PROMPT = '@item/warning_modal_prompt';
export const FUNDING_CUSTOMER_CHANGE = 'funding_customer_change';
export const FUNDING_INFO_BALANCE_CHANGE = 'funding_info_balance_change';
export const INTEGRATION_CONFIG_SYNC = 'integration_config_sync';
export const ITEM_APPROVAL_CHANGE = 'item_approval_change';
export const ITEM_CREATE = 'item_create';
export const ITEM_STATUS_CHANGE = 'item_status_change';

export const ITEM_CHANGE = 'item_change';
export const ITEM_ATTACHMENT_CHANGE = 'item_attachment_change';
export const ITEM_ATTACHMENT_REFRESH = 'item_attachment_refresh';
export const ITEM_TASK_COMPLETE = 'item_task_complete';

export const OCR_ANNOTATION_STATUS_CHANGE = 'ocr_annotation_status_change';
export const OCR_ANNOTATION_CHANGE = 'annotation_change';
export const MEMBERSHIP_CHANGE = 'membership_change';
export const MEMBERSHIP_INVITE_CHANGE = 'membership_invite_change';
export const ITEM_VERSION_CHANGE = 'item_version_change';
export const PO_DISCREPANCIES_REFRESHED = 'po_discrepancies_refreshed';
export const WEBHOOK_SETTINGS_CHANGE = 'webhook_settings_change';
export const WEBHOOK_TEST_RESULTS_CHANGE = 'webhook_test_results_change';

/**
 * !!NOTE!! [DEV-15462]
 * This is currently a duplicate constant. Until it is removed per the above ticket,
 * changes made to it also need to be made in packages/shared.
 *
 * @enum {string}
 */
export const EventKeyValues = {
  ENTER: 'Enter',
  ESCAPE: 'Escape',
  SPACE: ' ',
};
