export const bulkActionsFormFields = {
    UI_DATE_SCHEDULED_TYPE: 'ui.dateScheduledType',
    UI_HAS_SUBMITTED: 'ui.hasSubmitted',
};
export const commonFormFields = {
    CURRENCY_CODE: 'currencyCode',
    FUNDING_ACCOUNT: 'fundingAccount',
    FUNDING_PAYMENT_DELIVERY_METHOD: 'funding.paymentDeliveryMethod',
    META_PARTNERSHIP_MEMBERS_BANK: 'meta.partnershipMembers',
    PARTNER_COMPANY_TYPE: 'partner.companyType',
    PARTNER_COUNTRY: 'partner.country',
    PARTNER_NAME: 'partner.name',
    PARTNER_LEDGER_NAME: 'partner.ledgerName',
    PARTNERSHIP_MEMBERS: 'partnershipMembers',
    NAME: 'name',
};
export const createItemFormFields = {
    ADDRESS: 'address',
    ADDRESS_COUNTRY: 'address.country',
    ADDRESS_CITY: 'address.city',
    ADDRESS_POSTALCODE: 'address.postalcode',
    ADDRESS_PRINT_COMPANY: 'address.printCompany',
    ADDRESS_PRINT_NAME: 'address.printName',
    ADDRESS_STATE: 'address.state',
    ADDRESS_STREET_ADDRESS: 'address.streetAddress',
    ADDRESS_STREET_ADDRESS_UNIT: 'address.streetAddressUnit',
    APPROVERS: 'approvers',
    COUNTRY_CODE: 'countryCode',
    FUNDING_PAYMENT_DELIVERY_METHOD: commonFormFields.FUNDING_PAYMENT_DELIVERY_METHOD,
    FUNDING_PROVIDER_MEMO: 'item.fundingProviderMemo',
    FUNDING_ACH_ACCOUNT_MEMO: 'fundingAccountMemoForACH',
    ITEM_ATTACHMENTS: 'item.attachments',
    ITEM_BILLS: 'item.bills',
    ITEM_CURRENCY_CODE_RECEIVER: 'item.currencyCodeReceiver',
    ITEM_DATE_DUE: 'item.dateDue',
    ITEM_FUNDING_ACCOUNT_ID: 'item.fundingAccount.id',
    ITEM_IS_CONSOLIDATED: 'item.isConsolidated',
    ITEM_ID: 'item.id',
    ITEM_KIND: 'item.kind',
    ITEM_MEMBERS: 'itemMembers',
    ITEM_PARTNER_RECEIVABLE_ACCOUNT: 'item.partnerReceivableAccount',
    ITEM_PAYMENT_DELIVERY_METHOD: 'item.paymentDeliveryMethod',
    ITEM_PAYMENT_DELIVERY_METHODS_ACCEPTED: 'item.paymentDeliveryMethodsAccepted',
    ITEM_PAYMENT_METHOD_OPTIONS: 'item.paymentMethodOptions',
    ITEM_PAYMENT_DELIVERY_OPTION: 'item.paymentDeliveryOption',
    ITEM_SWIFT_CHARGE_OPTION: 'item.swiftChargeOption',
    ITEM_PURPOSE_CODE: 'item.purposeCode',
    LEDGER_INVOICE_REFS: 'ledgerInvoiceRefs',
    MAIN_GENERAL_BILL_CURRENCY: 'main.general.bill_currency',
    PARTNER: 'partner',
    PARTNER_COUNTRY_CODE: 'countryCode',
    PARTNER_NAME: 'partner.name',
    PARTNERSHIP: 'partnership',
    PARTNERSHIP_MEMBERS: 'partnershipMembers',
    PARTNERSHIP_TYPE: 'partnershipType',
    PURCHASE_ORDER: 'item.purchaseOrder',
    PO_MATCH_TYPE: 'item.poMatchType',
    SHOW_MARK_AS_PAID: 'showMarkAsPaid',
    UI_BLOCK_DETAILS_SECTIONS: 'ui.blockDetailsSections',
    UI_FILTERS: 'ui.filters',
    UI_IS_BILL_VIEW: 'ui.isBillView',
    UI_IS_PURCHASE_ORDER_FETCHED: 'ui.isPoFetched',
    UI_IS_ITEM_EDIT: 'ui.isItemEdit',
    UI_IS_PURPOSE_CODE_REQUIRED: 'ui.isPurposeCodeRequired',
    UI_IS_REINITIALIZING_FORM: 'ui.isReinitializingForm',
    UI_SELECTED_BILL_INDEX: 'ui.selectedBillIndex',
    UI_SELECTED_COMPANY: 'ui.selectedCompany',
    UI_SELECTED_COMPANY_NAME: 'ui.selectedCompany.companyName',
    UI_COUNTRY_CODE_PARTNER: 'ui.selectedCompany.countryCodePartner',
    UI_SHOW_INVOICE_DISCOUNT: 'ui.showInvoiceDiscount',
    UI_SHOW_INVOICE_GENERATOR: 'ui.showInvoiceGenerator',
    UI_SHOW_LINE_ITEMS_IN_DRAWER: 'ui.showLineItemsInDrawer',
    UI_SKIP_PURCHASE_ORDER: 'ui.skipPurchaseOrder',
    UI_WARNINGS_DISMISSED: 'ui.warningsDismissed',
    UI_PARTNERSHIP_MEMBER_CREATED: 'ui.partnershipMemberCreated',
};
