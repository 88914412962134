import React from 'react';
import { compose } from 'redux';
import { hasLength } from 'helpers/utility';
import { withCopyAction, withLockedAction, withRevealAction, withTooltipAction } from './hoc';
export const PaymentMethodDetailItem = ({ actionsElements = [], label, value }) => (React.createElement("div", { className: "payment_method__detail-item-container" },
    React.createElement("div", { className: "payment_method__detail-item-label-value-container" },
        React.createElement("div", { className: "payment_method__detail-item-label" }, label),
        React.createElement("div", { className: "payment_method__detail-item-value", "data-fullstory": true }, value)),
    hasLength(actionsElements) && React.createElement("div", { className: "payment_method__detail-item-actions" }, actionsElements)));
const enhance = compose(withTooltipAction, withRevealAction, withCopyAction, withLockedAction);
export default enhance(PaymentMethodDetailItem);
