import { createItemFormFields } from '@routable/shared';
import {} from '@routable/types';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { change } from 'redux-form';
import { formNamesItem } from 'constants/forms';
import { LineItemStyles } from 'constants/lineItems';
import { getDefaultLineItem, getLineItemsPathFromStyle } from 'helpers/lineItems';
import { deCamelCaseObjectKeys } from 'helpers/utility';
import { useSelectorWithProps } from 'hooks';
import { createItemsTableViewModelManagerForKindSelector } from 'queries/tableCompoundSelectors';
import { createItemFormAccountLineItemsSelector, createItemFormItemLineItemsSelector, createItemFormPurchaseOrderSelector, } from 'selectors/forms';
import { consolidatedLineItemsSelector } from 'selectors/itemsSelectors';
import { INDIVIDUAL_LINE_ITEMS_DISABLED_TOOLTIP_TEXT, INDIVIDUAL_LINE_ITEMS_DISABLED_TOOLTIP_TEXT_SHORT, LINE_ITEM_MAX_COUNT, LINE_ITEMS_BLOCKS, LINE_ITEMS_SUBSECTIONS, SUMMARIZE_DISABLED_FOR_LINKED_PO_TOOLTIP_TEXT, } from './constants';
export const useSummarizeLineItemsToggle = ({ activeTabIsItem, annotationCount, isForcedConsolidatedLineItem, ocrAnnotation, unsummarizedLineItems, }) => {
    const dispatch = useDispatch();
    const [isSummarized, setIsSummarized] = useState(true);
    const activeSection = activeTabIsItem ? LineItemStyles.ITEM : LineItemStyles.ACCOUNT;
    const purchaseOrder = useSelector(createItemFormPurchaseOrderSelector);
    const viewModelManager = useSelector(createItemsTableViewModelManagerForKindSelector);
    const consolidatedLineItemsFromSelector = useSelectorWithProps(consolidatedLineItemsSelector, undefined);
    const hasPO = !!purchaseOrder?.value;
    const accountLineItems = useSelector(createItemFormAccountLineItemsSelector);
    const itemLineItems = useSelector(createItemFormItemLineItemsSelector);
    const activeLineItems = activeTabIsItem ? itemLineItems : accountLineItems;
    const currentSectionDefaultLineItem = getDefaultLineItem(viewModelManager, getLineItemsPathFromStyle(activeSection));
    const consolidatedLineItemObj = consolidatedLineItemsFromSelector?.find((lineItem) => lineItem.value.id === ocrAnnotation?.id)?.value?.data || {};
    const consolidatedLineItem = deCamelCaseObjectKeys({
        ...currentSectionDefaultLineItem,
        ...consolidatedLineItemObj,
        is_ocr: true,
    });
    const unsummarizedAccountLineItems = unsummarizedLineItems?.[LINE_ITEMS_BLOCKS.ACCOUNT]?.[LINE_ITEMS_SUBSECTIONS.ACCOUNT] || [];
    const unsummarizedItemLineItems = unsummarizedLineItems?.[LINE_ITEMS_BLOCKS.ITEM]?.[LINE_ITEMS_SUBSECTIONS.ITEM] || [];
    const activeUnsummarizedLineItems = activeTabIsItem ? unsummarizedItemLineItems : unsummarizedAccountLineItems;
    const onHandleSummarize = useCallback(() => {
        const accountPath = getLineItemsPathFromStyle(LineItemStyles.ACCOUNT);
        const itemPath = getLineItemsPathFromStyle(LineItemStyles.ITEM);
        const sectionPath = activeTabIsItem ? itemPath : accountPath;
        const resultLineItems = isSummarized ? activeUnsummarizedLineItems : [consolidatedLineItem];
        dispatch(change(formNamesItem.CREATE_ITEM, sectionPath, resultLineItems));
        setIsSummarized((x) => !x);
        dispatch(change(formNamesItem.CREATE_ITEM, createItemFormFields.ITEM_IS_CONSOLIDATED, !isSummarized));
    }, [activeTabIsItem, activeUnsummarizedLineItems, consolidatedLineItem, dispatch, isSummarized]);
    let disabledTooltipText;
    const hasOver30LineItems = annotationCount > LINE_ITEM_MAX_COUNT || activeLineItems.length > LINE_ITEM_MAX_COUNT;
    if (hasPO) {
        disabledTooltipText = SUMMARIZE_DISABLED_FOR_LINKED_PO_TOOLTIP_TEXT;
    }
    else if (hasOver30LineItems) {
        disabledTooltipText = INDIVIDUAL_LINE_ITEMS_DISABLED_TOOLTIP_TEXT;
    }
    else if (isForcedConsolidatedLineItem) {
        disabledTooltipText = INDIVIDUAL_LINE_ITEMS_DISABLED_TOOLTIP_TEXT_SHORT;
    }
    const summarizeOrSeparateText = hasPO || !isSummarized ? 'Revert to summarized line item' : 'Separate line items';
    return { disabledTooltipText, onHandleSummarize, summarizeOrSeparateText };
};
