import { useFeatureFlagsQuery } from '@routable/feature-configs';
import PropTypes from 'prop-types';
import React from 'react';

import { redirectTo } from 'helpers/urls';

import ExternalLoadingIndicator from 'modules/external/ExternalLoadingIndicator';
import ExternalWorkflowContent from 'modules/external/global/presenters/ExternalWorkflow';
import ExternalWorkflowWrapper from 'modules/external/global/presenters/ExternalWorkflowWrapper';

import { shouldRedirectToConfirmationUrl } from './ExternalRouteWrapper.helpers';

/**
 * Wrapper for base external routes components. Handles initial fetching logic,
 * renders either loading indicator if state is fetching or provided component
 * once the state has finished fetching.
 * @param {ComponentProps} props
 * @param {Number} props.activeStepIndex
 * @param {Brand} props.brand
 * @param {Company} props.company
 * @param {Object} [props.currentStep]
 * @param {Function} props.fetchExternalPartnershipDetails
 * @param {Boolean} props.isLoading
 * @param {Function} props.onFetchFeatureFlags
 * @param {Function} props.onFetchPermissionsAndPermissionGroups
 * @param {Function} props.onFetchRoles
 * @param {Function} props.onFetchSupportedCountries
 * @param {Object} [props.partnershipRequest]
 * @param {URLString} [props.redirectUrl]
 * @param {Object[]} [props.steps]
 * @return {FunctionComponent}
 */
const ExternalRouteWrapper = ({
  activeStepIndex,
  brand,
  company,
  currentStep,
  fetchExternalPartnershipDetails,
  isLoading,
  onFetchPermissionsAndPermissionGroups,
  onFetchRoles,
  onFetchSupportedCountries,
  partnershipFundingStateHasDateCompleted,
  partnershipRequest,
  redirectUrl,
  steps,
}) => {
  const shouldFetchExternalPartnershipDetails = !partnershipRequest || !steps;
  const shouldRedirect = shouldRedirectToConfirmationUrl({
    isLoading,
    currentStep,
    redirectUrl,
    requestType: partnershipRequest?.requestType,
    partnershipFundingStateHasDateCompleted,
  });
  const { isLoading: isLoadingFeatureFlags } = useFeatureFlagsQuery();

  React.useEffect(() => {
    if (shouldRedirect) {
      redirectTo(redirectUrl);
    }
  }, [redirectUrl, shouldRedirect]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStepIndex]);

  React.useEffect(() => {
    if (shouldFetchExternalPartnershipDetails) {
      fetchExternalPartnershipDetails();
      onFetchPermissionsAndPermissionGroups();
      onFetchRoles();
      onFetchSupportedCountries();
    }
  }, [
    fetchExternalPartnershipDetails,
    onFetchPermissionsAndPermissionGroups,
    onFetchRoles,
    onFetchSupportedCountries,
    shouldFetchExternalPartnershipDetails,
  ]);

  if (isLoadingFeatureFlags || shouldFetchExternalPartnershipDetails) {
    return <ExternalLoadingIndicator />;
  }

  return (
    <ExternalWorkflowWrapper brand={brand} company={company} currentStep={currentStep} steps={steps}>
      <ExternalWorkflowContent currentStep={currentStep} />
    </ExternalWorkflowWrapper>
  );
};

ExternalRouteWrapper.propTypes = {
  activeStepIndex: PropTypes.number.isRequired,
  brand: PropTypes.shape({}),
  company: PropTypes.shape({}),
  currentStep: PropTypes.shape({}),
  fetchExternalPartnershipDetails: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  onFetchRoles: PropTypes.func.isRequired,
  onFetchPermissionsAndPermissionGroups: PropTypes.func.isRequired,
  partnershipFundingStateHasDateCompleted: PropTypes.bool,
  partnershipRequest: PropTypes.shape({}),
  redirectUrl: PropTypes.string,
  steps: PropTypes.arrayOf(PropTypes.shape({})),
};

ExternalRouteWrapper.defaultProps = {
  brand: undefined,
  company: undefined,
  currentStep: undefined,
  isLoading: undefined,
  partnershipFundingStateHasDateCompleted: undefined,
  partnershipRequest: undefined,
  redirectUrl: undefined,
  steps: undefined,
};

export default ExternalRouteWrapper;
