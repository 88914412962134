import { RoutableEventsSync } from '@routable/framework';
import React, { useEffect } from 'react';
import { removeUndefined } from '../helpers/generic.helper';
import { ShowMoreFooter, TableFooter } from './components';
import { useTableOptions } from './hooks';
import { TableProvider } from './table-name.context';
import { TableCell, TableContainer, TableFullScreenWrapper, TableWrapper } from './table.styled';
import { useTableStore } from './table.store';
import clsx from 'clsx';
export const Table = ({ children, className, clearSelectionOnMount = true, defaultPageSize = 25, display, fixedHeight, fixedWidth, footerType = 'paging', headers, showGoToPage, showRowsPerPage, reactTable, style, tableName, verticalScrollDisabled, }) => {
    const internalHeaders = useTableOptions({ name: tableName, headers }) || [];
    const { setTableStateItem } = useTableStore(tableName);
    const cellWidths = (internalHeaders && internalHeaders.map(({ initialSettings }) => initialSettings.width || 'ColumnWidth3')) || [];
    const hiddenCells = internalHeaders?.map(({ initialSettings }) => {
        if (initialSettings.hidden) {
            return 'none';
        }
        return reactTable ? 'flex' : 'block';
    }) || [];
    const margins = internalHeaders?.map(({ initialSettings }) => initialSettings.margins ?? 'initial') || [];
    const componentProperties = removeUndefined({
        className: clsx('routable-table', className, { 'use-scrollbars': !verticalScrollDisabled }),
        display: display || 'full-width',
        fixedHeight: fixedHeight,
        hiddenCells: hiddenCells,
        widths: cellWidths,
        margins: margins,
        isVerticalScrollDisabled: verticalScrollDisabled,
    });
    const componentWrapperProperties = removeUndefined({
        style: { ...(style || {}) },
        display: display || 'full-width',
        fixedWidth: fixedWidth,
    });
    useEffect(() => {
        if (clearSelectionOnMount) {
            setTableStateItem('selections', []);
            RoutableEventsSync.Publish(`table:${tableName}:selected:updated`, []);
        }
    }, []);
    return (React.createElement(TableProvider, { tableName: tableName, display: display },
        React.createElement(TableWrapper, { ...componentWrapperProperties, role: "table", "aria-label": tableName },
            React.createElement(TableContainer, { ...componentProperties, reactTable: reactTable }, children),
            footerType && footerType === 'paging' && (React.createElement(TableFooter, { tableName: tableName, showGoToPage: showGoToPage, showRowsPerPage: showRowsPerPage })),
            footerType && footerType === 'showmore' && (React.createElement(ShowMoreFooter, { tableName: tableName, defaultPageSize: defaultPageSize })))));
};
Table.FullScreenWrapper = TableFullScreenWrapper;
export { TableCell };
