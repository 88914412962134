import { PaymentDeliveryMethodType } from '@routable/shared';

import { CompanySearchPartnerTypes } from 'constants/company';
import { StaticCountryCode } from 'constants/countries';
import { PartnershipTypes } from 'constants/partnership';
import { UserTypes } from 'constants/user';

import { valueOrEmptyString, valueOrFalse } from 'helpers/forms';
import { initialValuesBankAccount } from 'helpers/initialValues';
import { isExclusivelyVendor } from 'helpers/partnerships';
import { filterPaymentDeliveryOptions, getPaymentMethodOptionsInitialValue } from 'helpers/paymentMethods';
import { valueOrDefault } from 'helpers/utility';

import { getEmptyFundingAddressValues } from 'modules/address/helpers/initialValues';

import { hasLedgerIntegrationSelector } from 'selectors/integrationsSelectors';

import { storeAccessor as store } from 'store/accessor';

/**
 * Returns initial values for funding, for create partnership form.
 * @param {Object} options
 * @param {string} options.partnerName
 * @return {Object}
 */
export const getCreatePartnershipInitialFundingValues = ({
  partnerName,
  paymentDeliveryMethod = PaymentDeliveryMethodType.ANY,
}) => ({
  address: getEmptyFundingAddressValues({ partnerName }),
  bankAccount: initialValuesBankAccount.getInitialBankAccountValues(),
  paymentDeliveryMethod,
});

/**
 * Returns initial values for meta, for create partnership form.
 * @return {Object}
 */
export const getCreatePartnershipInitialMetaValues = () => ({
  partnershipId: undefined,
  partnershipMembers: {},
});

/**
 * Returns initial values for partner, for create partnership form.
 * @param {Object} options
 * @param {string} options.partnerCompanyType
 * @param {string} options.partnerLedgerName
 * @param {string} options.partnerName
 * @return {Object}
 */
export const getCreatePartnershipInitialPartnerValues = ({
  partnerCompanyType,
  partnerLedgerLegalName,
  partnerLedgerName,
  partnerName,
}) => {
  const initialPartnerValues = {
    companyType: valueOrDefault(partnerCompanyType, UserTypes.BUSINESS),
    name: partnerLedgerLegalName || partnerName,
  };

  const reduxState = store.getState();
  const hasLedger = hasLedgerIntegrationSelector(reduxState);

  if (hasLedger) {
    return {
      ...initialPartnerValues,
      ledgerName: partnerLedgerName || partnerName,
    };
  }

  return initialPartnerValues;
};

/**
 * Returns the initial value for ui.selectedCompany.
 * @function
 * @param {boolean} doesCompanyExist
 * @param {?Partnership} partnership
 * @returns {ObjectMaybe}
 */
export const getCreatePartnershipUiSelectedCompanyInitialValue = ({ doesCompanyExist, partnership }) => {
  if (doesCompanyExist) {
    return {
      ...partnership,
      // the search companies select field uses this
      label: partnership.name,
      // this company exists on the ledger, but the partnership we're creating is new
      type: CompanySearchPartnerTypes.NEW,
    };
  }

  return undefined;
};

/**
 * Returns initial values for ui, for create partnership form.
 * @param {Object} options
 * @param {boolean} options.doesCompanyExist
 * @param {?Partnership} options.partnership
 * @param {Array.<ItemPaymentDeliveryMethod>} options.paymentMethods
 * @return {Object}
 */
export const getCreatePartnershipInitialUiValues = (options) => {
  const { doesCompanyExist, paymentMethods } = options;

  const selectedCompany = getCreatePartnershipUiSelectedCompanyInitialValue(options);
  const searchCompaniesDefaultOptions = selectedCompany ? [selectedCompany] : undefined;

  return {
    bankInstitutionName: '',
    blockDetailsSections: true,
    doesCompanyExist,
    formSubmitted: false,
    partnershipSubmitted: false,
    paymentMethodOptions: getPaymentMethodOptionsInitialValue({
      methodsAccepted: paymentMethods,
    }),
    searchCompaniesDefaultOptions,
    selectedCompany,
    showCustomizePrintedCheckToggle: true,
    showExternalRef: false,
  };
};

/**
 * Returns all initial values for create partnership form.
 * @param {Object} options
 * @param {Boolean} options.hasLedgerIntegration
 * @param {Boolean} options.isInternationalPaymentsEnabled
 * @param {Object} options.modal
 * @param {Array.<ItemPaymentDeliveryMethod>} options.paymentMethods
 * @return {Object}
 */
export const getCreatePartnershipInitialValues = ({
  hasLedgerIntegration,
  isInternationalPaymentsEnabled,
  modal,
  paymentMethods,
}) => {
  const { doesCompanyExist, partnership, sendEmail } = modal;

  const isCustomer = valueOrFalse(partnership.isCustomer);
  const isVendor = valueOrFalse(partnership.isVendor);
  const partnerName = valueOrEmptyString(partnership.name);
  const allowCountrySelection = isInternationalPaymentsEnabled && isVendor;
  const partnerLedgerName = isVendor ? partnership.ledgerNameVendor : partnership.ledgerNameCustomer;
  const partnerLedgerLegalName = isVendor ? partnership.ledgerLegalNameVendor : partnership.ledgerLegalNameCustomer;

  // Don't default to `Vendor's Choice/ ANY` for vendors
  const paymentDeliveryMethod = isExclusivelyVendor(partnership) ? null : PaymentDeliveryMethodType.ANY;

  return {
    address: getEmptyFundingAddressValues(),
    countryCode: allowCountrySelection ? null : StaticCountryCode.US,
    externalRef: undefined,
    funding: getCreatePartnershipInitialFundingValues({
      partnerName,
      paymentDeliveryMethod,
    }),
    inviteMessage: '',
    isCustomer,
    isVendor,
    ...(isVendor && hasLedgerIntegration ? { ledgerCurrencyCode: null } : {}),
    meta: getCreatePartnershipInitialMetaValues(),
    name: partnerName,
    partner: getCreatePartnershipInitialPartnerValues({
      partnerCompanyType: partnership.partner?.companyType,
      partnerLedgerName,
      partnerName,
      partnerLedgerLegalName,
    }),
    partnership: {
      ...partnership,
      isCustomer,
      isVendor,
    },
    partnershipMembers: [],
    partnershipType: partnership.isVendor ? PartnershipTypes.VENDOR : PartnershipTypes.CUSTOMER,
    sendEmail: sendEmail ?? true,
    ui: getCreatePartnershipInitialUiValues({
      doesCompanyExist,
      partnership,
      paymentMethods,
    }),
  };
};

/**
 * getDefaultCreatePartnershipInitialValue
 * returns the initial state of the create partnership form
 * this is used to re-initialize & then reset the form to clean initial values (ie. no given partnership)
 * @return {Object}
 */
export const getDefaultCreatePartnershipInitialValue = ({ partnership = {}, paymentMethodOptions, ...rest } = {}) =>
  getCreatePartnershipInitialValues({
    modal: {
      doesCompanyExist: false,
      sendEmail: true,
      partnership,
    },
    // We want to exclude PaymentDeliveryMethodType.ANY here since it's added by default later on
    paymentMethods: filterPaymentDeliveryOptions(paymentMethodOptions, PaymentDeliveryMethodType.ANY),
    ...rest,
  });
